import {Capacitor} from "@capacitor/core";

let adPlatform;

if (Capacitor.isNativePlatform()) {
    adPlatform = await import("./admob")
}
else {
    adPlatform = await import("./adsense")
}

adPlatform.initialize()

export {adPlatform}