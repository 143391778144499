import Popups from "./Popups"
import {Capacitor} from "@capacitor/core";
import { AdMob, AdmobConsentStatus } from '@capacitor-community/admob';
import {SplashScreen} from "@capacitor/splash-screen";
import {i18n} from "../../lib/i18nHelper";

function ensureSettingsLoaded() {
	return Promise.allSettled([
		window.settings.locale.loaded,
		window.settings.autoSortTiles.loaded,
		window.settings.soundEffectVolume.loaded,
		window.settings.voiceVolume.loaded,
		window.settings.voiceSpeed.loaded,
		window.settings.tileBorder,
		window.settings.tileCenter,
		window.settings.tileDecoration,
	])
}


function createSettingsIcon(iconClass) {
	let settingsIcon = document.createElement("img")
	settingsIcon.src = "assets/settings.svg"
	settingsIcon.className = iconClass
	settingsIcon.style.opacity = "0"
	
	//If settings don't load, don't display the menu.
	ensureSettingsLoaded().then(() => {
		settingsIcon.style.opacity = ""
	})

	settingsIcon.addEventListener("click", openSettingsMenu)

	return settingsIcon
}


let listings = {}

async function loadListing(listingName) {
	let resp = await fetch(`assets/tile/${listingName}/listing.json`)
	let listing = await resp.json()

	return listing
}

["border", "center", "decoration"].forEach((listingName) => {
	loadListing(listingName).then((listing) => {
		listings[listingName] = listing
		//TODO: If the currently selected setting is not available under the listings, we should reset it to default. 
	})
})


async function openSettingsMenu() {
	try {
		//For some reason everything blows up if we try to import this.
		const adLibrary = (await require("./monetization/ad_library")).default
		adLibrary.setBannerStatus(adLibrary.BannerStatus.HIDDEN)
	}
	catch (e) {
		console.error(e)
	}
	await ensureSettingsLoaded()

	let elem = document.createElement("div")

	//Create a container div for the actual settings inside the settings menu. This container is left justified.
	let settingsMenuDiv = document.createElement("div")
	settingsMenuDiv.className = "settingsMenuDiv"
	elem.appendChild(settingsMenuDiv)

	//Language choices are displayed in the target language, to allow users to recognize languages they know (and not get stuck)
	const choices = [
		{
			name: "English",
			value: "en"
		},
		{
			name: "中文",
			value: "zh"
		}
	]

	function updateLocaleSetting() {
		let oldLocale = i18n.getLocale()
		i18n.setLocale(window.settings.locale.getValue())
		let newLocale = i18n.getLocale()
		if (oldLocale !== newLocale) {
			//Once it saves to disk, reload.
			window.settings.locale.saved.then(() => {
				if (Capacitor.isNativePlatform()) {
					try {
						SplashScreen.show()
					}
					catch (e) {console.error(e)}
				}
				window.location.reload()
			})
		}
	}
	window.settings.locale.createSelector("Choose Language: ", choices, settingsMenuDiv)
	window.settings.locale.addOnValueSetListener(updateLocaleSetting)

	let langCredit = document.createElement("p")
	langCredit.innerText = i18n.__("localization credit")
	settingsMenuDiv.appendChild(langCredit)

	window.settings.soundEffectVolume.createSelector("Sound Effect Volume: ", {
		min: {
			value: 0,
			label: "0%"
		},
		max: {
			value: 1,
			label: "100%"
		}
	}, settingsMenuDiv)


	window.settings.voiceVolume.createSelector("Voice Volume: ", {
		min: {
			value: 0.25,
			label: "25%"
		},
		max: {
			value: 1,
			label: "100%"
		}
	}, settingsMenuDiv)


	window.settings.voiceSpeed.createSelector("Voice Speed: ", {
		min: {
			value: 0.25,
			label: "25%"
		},
		max: {
			value: 2,
			label: "200%"
		}
	}, settingsMenuDiv)


	window.settings.autoSortTiles.createSelector("Auto-Sort Tiles: ", settingsMenuDiv)


	if (listings["border"]) {
		let borderChoices = listings["border"].map((item) => {
			return {
				name: item.name,
				value: item.ID
			}
		})
	
		window.settings.tileBorder.createSelector("Tile Frame: ", borderChoices, settingsMenuDiv)
	}


	if (listings["center"]) {
		let centerChoices = [
			{
				name: "Automatic",
				value: "auto"
			}, 
		].concat(listings["center"].map((item) => {
			return {
				name: item.name,
				value: item.ID
			}
		}))
	
		window.settings.tileCenter.createSelector("Tileset: ", centerChoices, settingsMenuDiv)
	}


	if (listings["decoration"]) {
		let decorationChoices = [
			{
				name: "No Decoration",
				value: ""
			}, 
		].concat(listings["decoration"].map((item) => {
			return {
				name: item.name,
				value: item.ID
			}
		}))
	
		window.settings.tileDecoration.createSelector("Tile Decoration: ", decorationChoices, settingsMenuDiv)
	}


	//Display elem inside a popup.
	let popup = new Popups.Notification(i18n.__("General Settings"), elem)
	let popupElem = popup.show()
	popupElem.style.width = "100vw" //Force max width allowed. Prevents jumping.


	if (Capacitor.getPlatform() === "ios") {
		let trackingStatus = (await AdMob.trackingAuthorizationStatus()).status;

		let trackingDescription = document.createElement("div")
		trackingDescription.innerText = `Tracking/Personalized Ads: ${trackingStatus === "authorized" ? "On" : "Off"} `

		let changeTrackingSettingButton = document.createElement("button")
		changeTrackingSettingButton.innerText = trackingStatus === "authorized" ? "Disable" : "Enable"
		changeTrackingSettingButton.style.fontSize = "0.5em"
		changeTrackingSettingButton.style.verticalAlign = "text-bottom"

		changeTrackingSettingButton.addEventListener("click", function() {
			if (trackingStatus === "notDetermined") {
				window.settings.iosAlwaysRequestTracking.value = true
				new Popups.Notification("Tracking Setting Change", "We'll prompt you the next time Mahjong 4 Friends restarts. ").show()
			}
			else if (trackingStatus === "authorized") {
				new Popups.Notification("Tracking Setting Change", "You'll have to make this change in system settings. Go to Settings > Privacy & Security > Tracking, then deactivate Mahjong 4 Friends").show()
			}
			else {
				window.settings.iosAlwaysRequestTracking.value = true
				new Popups.Notification("Tracking Setting Change", "You'll have to make this change in system settings. Go to Settings > Privacy & Security > Tracking, ensure 'Allow Apps to Request to Track' is enabled, and enable Mahjong 4 Friends if it is displayed (if not, no worries - the app will prompt you next reload). ").show()
			}
		})

		settingsMenuDiv.appendChild(trackingDescription)
		trackingDescription.appendChild(changeTrackingSettingButton)
	}

	//GDPR reprompt option. 
	AdMob.requestConsentInfo().then((consentInfo) => {
		if (!Capacitor.isNativePlatform()) {return;} //GDPR handled by AdSense.
		if (consentInfo.isConsentFormAvailable && (consentInfo.status === AdmobConsentStatus.REQUIRED || consentInfo.status === AdmobConsentStatus.OBTAINED)) {
			let consentDescription = document.createElement("div")
			consentDescription.innerText = `GDPR Completed: ${consentInfo.status === AdmobConsentStatus.OBTAINED ? "Yes" : "No"} `
	
			let changeConsentSettingButton = document.createElement("button")
			changeConsentSettingButton.innerText = "Configure Choices"
			changeConsentSettingButton.style.fontSize = "0.5em"
			changeConsentSettingButton.style.verticalAlign = "text-bottom"
	
			changeConsentSettingButton.addEventListener("click", async function() {
				await AdMob.resetConsentInfo()
				await AdMob.requestConsentInfo()
				await AdMob.showConsentForm()
			})
	
			settingsMenuDiv.appendChild(consentDescription)
			consentDescription.appendChild(changeConsentSettingButton)
		}
	})
}


export {createSettingsIcon}
