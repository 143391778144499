const {i18n} = require("../../../lib/i18nHelper.js")
const {InstallerInfoPlugin} = require("capacitor-installer-info");

function createRatingLink() {
	let ratingPrompt = document.createElement("p")
	ratingPrompt.id = "supportInfo"
	let link
	let prompt

	async function setTextAndLink() {
		if (window.nativePlatform === "ios") {
			link = "https://apps.apple.com/us/app/mahjong-4-friends/id1552704332"
			prompt = "rate us in the App Store"
		}
		else if ( window.nativePlatform === "android") {
			let {installingPackageName} = await InstallerInfoPlugin.getInstallSourceInfo({})

			if (installingPackageName.indexOf("amazon") === -1) {
				link = "https://play.google.com/store/apps/details?id=com.mahjong4friends.twa"
				prompt = "leave a review on Google Play"
			}
			else {
				link = "https://www.amazon.com/review/create-review/?asin=B099WK2KB2" //Not sure if this is the best link to use.
				prompt = "leave a review on Amazon"
			}
		}
		else if (window.nativePlatform === "windows") {
			link = "ms-windows-store://pdp/?productid=9NQS9Z5JJJ8P"
			prompt = "leave a review in the Microsoft Store"
		}
		link =  `href="${link}" target="_blank"` //Properties for anchor element
		ratingPrompt.innerHTML = i18n.__("Enjoying Mahjong 4 Friends? Please <a %s>%s</a>", link, i18n.__(prompt))
	}

	setTextAndLink()
	return ratingPrompt
}

module.exports = {createRatingLink}