const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
//Supported.
module.exports = [
    //AA 444 555 6 6 6 777 (AA any Pr.; any consec. run, 3 suits) - 25 Points
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            allOptions.slice(0, -3).forEach((offset) => {
                let newArr = []
                tiles.push(newArr)
                
                //AA
                newArr.push(createTiles({type: "any", value: "any", amount: 2}))
                
                //444
                newArr.push(createTiles({type: suitOrder[0], value: offset + 0, amount: 3}))
                
                //555
                newArr.push(createTiles({type: suitOrder[1], value: offset + 1, amount: 3}))
                
                //6
                newArr.push(createTiles({type: suitOrder[0], value: offset + 2, amount: 1}))
                
                //6
                newArr.push(createTiles({type: suitOrder[1], value: offset + 2, amount: 1}))
                
                //6 777
                newArr.push(createTiles({type: suitOrder[2], value: offset + 2, amount: 1}))
                newArr.push(createTiles({type: suitOrder[2], value: offset + 3, amount: 3}))
            })
        })	
        
        return {
            tiles,
            score: 25,
            concealed: false,
        }
    },
    //456 7777 888 9999 (Any consec. run, 1 or 3 suits) - 25
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            allOptions.slice(0, -5).forEach((offset) => {
                let newArr = []
                tiles.push(newArr)
                
                //456 7777
                newArr.push(createTiles({type: suitOrder[0], value: offset + 0, amount: 1}))
                newArr.push(createTiles({type: suitOrder[0], value: offset + 1, amount: 1}))
                newArr.push(createTiles({type: suitOrder[0], value: offset + 2, amount: 1}))
                newArr.push(createTiles({type: suitOrder[0], value: offset + 3, amount: 4}))
                
                //888
                newArr.push(createTiles({type: suitOrder[1], value: offset + 4, amount: 3}))
                
                //9999
                newArr.push(createTiles({type: suitOrder[2], value: offset + 5, amount: 4}))
            })
        })	
        
        allSuits.forEach((suit) => {
            allOptions.slice(0, -5).forEach((offset) => {
                let newArr = []
                tiles.push(newArr)
                
                //456 7777
                newArr.push(createTiles({type: suit, value: offset + 0, amount: 1}))
                newArr.push(createTiles({type: suit, value: offset + 1, amount: 1}))
                newArr.push(createTiles({type: suit, value: offset + 2, amount: 1}))
                newArr.push(createTiles({type: suit, value: offset + 3, amount: 4}))
                
                //888
                newArr.push(createTiles({type: suit, value: offset + 4, amount: 3}))
                
                //9999
                newArr.push(createTiles({type: suit, value: offset + 5, amount: 4}))
            })
        })	
        
        return {
            tiles,
            score: 25,
            concealed: false,
        }
    },
    //12 33 45 666 78 999 (1 or 3 suits) - 25
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            allOptions.slice(0, -8).forEach((offset) => {
                let newArr = []
                tiles.push(newArr)
                
                //12 33 45
                newArr.push(createTiles({type: suitOrder[0], value: offset + 0, amount: 1}))
                newArr.push(createTiles({type: suitOrder[0], value: offset + 1, amount: 1}))
                
                newArr.push(createTiles({type: suitOrder[0], value: offset + 2, amount: 2}))
                
                newArr.push(createTiles({type: suitOrder[0], value: offset + 3, amount: 1}))
                newArr.push(createTiles({type: suitOrder[0], value: offset + 4, amount: 1}))
                
                //666
                newArr.push(createTiles({type: suitOrder[1], value: offset + 5, amount: 3}))
                
                //78 999
                newArr.push(createTiles({type: suitOrder[2], value: offset + 6, amount: 1}))
                newArr.push(createTiles({type: suitOrder[2], value: offset + 7, amount: 1}))
                
                newArr.push(createTiles({type: suitOrder[2], value: offset + 8, amount: 3}))
            })
        })	
        
        allSuits.forEach((suit) => {
            allOptions.slice(0, -8).forEach((offset) => {
                let newArr = []
                tiles.push(newArr)
                
                //12 33 45
                newArr.push(createTiles({type: suit, value: offset + 0, amount: 1}))
                newArr.push(createTiles({type: suit, value: offset + 1, amount: 1}))
                
                newArr.push(createTiles({type: suit, value: offset + 2, amount: 2}))
                
                newArr.push(createTiles({type: suit, value: offset + 3, amount: 1}))
                newArr.push(createTiles({type: suit, value: offset + 4, amount: 1}))
                
                //666
                newArr.push(createTiles({type: suit, value: offset + 5, amount: 3}))
                
                //78 999
                newArr.push(createTiles({type: suit, value: offset + 6, amount: 1}))
                newArr.push(createTiles({type: suit, value: offset + 7, amount: 1}))
                
                newArr.push(createTiles({type: suit, value: offset + 8, amount: 3}))
            })
        })	
        
        return {
            tiles,
            score: 25,
            concealed: false,
        }
    },
    //AA 789 789 111 000 (AA any Pr; #’s 3 suits) - 30
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            let newArr = []
            tiles.push(newArr)
            
            //AA
            newArr.push(createTiles({type: "any", value: "any", amount: 2}))
            
            //789
            newArr.push(createTiles({type: suitOrder[0], value: 7, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 1}))
            
            //789
            newArr.push(createTiles({type: suitOrder[1], value: 7, amount: 1}))
            newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 1}))
            newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 1}))
            
            //111
            newArr.push(createTiles({type: suitOrder[2], value: 1, amount: 3}))
            
            //000
            newArr.push(createTiles({type: "dragon", value: "white", amount: 3}))
        })	
        
        return {
            tiles,
            score: 30,
            concealed: false,
        }
    },
    //20 333 4 555 666 24 (Any consec. run, 3 suits) - 30
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            allOptions.slice(0, -3).forEach((offset) => {
                let newArr = []
                tiles.push(newArr)
                
                //20
                newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
                newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
                
                //333 4 555 666
                newArr.push(createTiles({type: suitOrder[1], value: offset + 0, amount: 3}))
                newArr.push(createTiles({type: suitOrder[1], value: offset + 1, amount: 1}))
                newArr.push(createTiles({type: suitOrder[1], value: offset + 2, amount: 3}))
                newArr.push(createTiles({type: suitOrder[1], value: offset + 3, amount: 3}))
                
                //24
                newArr.push(createTiles({type: suitOrder[2], value: 2, amount: 1}))
                newArr.push(createTiles({type: suitOrder[2], value: 4, amount: 1}))
            })
        })	
        
        return {
            tiles,
            score: 30,
            concealed: false,
        }
    },
    //MJ 55 666 7777 PPP (M=W, J=Joker; any consec. run, 1 suit; P any Pung) - 35
    function(tiles = []) {
        allSuits.forEach((suit) => {
            allOptions.slice(0, -2).forEach((offset) => {
                let newArr = []
                tiles.push(newArr)
                
                //MJ
                newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
                newArr.push(createTiles({type: "joker", amount: 1}))
                
                //55 666 7777
                newArr.push(createTiles({type: suit, value: offset + 0, amount: 2}))
                newArr.push(createTiles({type: suit, value: offset + 1, amount: 3}))
                newArr.push(createTiles({type: suit, value: offset + 2, amount: 4}))
                
                //PPP
                newArr.push(createTiles({type: "any", value: "any", amount: 3}))
            })
        })	
        
        return {
            tiles,
            score: 35,
            concealed: false,
        }
    },
    //1 1 2 3 5 8 13 21 34 55 (Mix or match; Fibonacci seq.) - C40
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)
        
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        
        newArr.push(createTiles({type: "any", value: 2, amount: 1}))
        
        newArr.push(createTiles({type: "any", value: 3, amount: 1}))
        
        newArr.push(createTiles({type: "any", value: 5, amount: 1}))
        
        newArr.push(createTiles({type: "any", value: 8, amount: 1}))
        
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "any", value: 3, amount: 1}))
        
        newArr.push(createTiles({type: "any", value: 2, amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        
        newArr.push(createTiles({type: "any", value: 3, amount: 1}))
        newArr.push(createTiles({type: "any", value: 4, amount: 1}))
        
        newArr.push(createTiles({type: "any", value: 5, amount: 1}))
        newArr.push(createTiles({type: "any", value: 5, amount: 1}))
        
        return {
            tiles,
            score: 40,
            concealed: true,
        }
    },
].map((func, index) => {
    let output = func()
    output.section = "Running Scared"
    output.sectionIndex = index + 1 //Sections indexed starting at 1. 
    output.handName = `${output.section} #${output.sectionIndex}`
    return new CardHand(output)
})

