import {purchasePackage, getPurchasesInstance, getCustomerInfo} from "./store"
import {getStoreToUse}  from "./revenuecat_utilities";
import {createAccountManagerButton} from "../firebase/auth/accountManager";
import {durationStringMap} from "./timeStrings";
import {findUnusedSubscriptions, isInferiorSubscription, determineOwnershipStatus, getPackageProduct, getDurationStringForProduct, getPriceStringForProduct} from "./storeUtilities";

import {CUSTOMER_INFO_UPDATED_EVENT_NAME} from "./event_names";
import adLibrary from "./ad_library";




//For the store, we will need the available subscription information (to display the offerings),
//as well as the latest customer info (to display the current subscriptions).
function matchToGroup(identifier, identifier_groups) {
    for (let group of identifier_groups.values()) {
        if (group.identifiers.includes(identifier)) {
            return group
        }
    }
}


const Popups = require("../Popups.js")

async function openStoreMenu() {
    adLibrary.setBannerStatus(adLibrary.BannerStatus.BOTTOM_ONLY)

    window?.FirebaseAnalytics?.setCurrentScreen({
		screenName: "StoreScreen",
		screenClassOverride: "StoreScreen",
	});


    //We should show current subscriptions at the very top. 
    let storePopup = new Popups.Panel("Store")
    storePopup.show(document.body)

    //Add an account manager button to the title.
    storePopup.titleSpan.appendChild(createAccountManagerButton())

    storePopup.panel.style.backgroundColor = "rgb(186 255 183)"
    storePopup.panel.style.zIndex = "999" //Reduce by 1 so that we can use Popups for messages.

    let purchasesFAQLink = document.createElement("div")
    purchasesFAQLink.innerHTML = `Having trouble with subscriptions? See the <a target="_blank" href="${document.body.id}pages/faqs/purchases/">Purchases FAQ</a>`
    purchasesFAQLink.style.fontSize = "1.5em"
    storePopup.panel.appendChild(purchasesFAQLink)

    //This div is used as a place to put details about subscriptions that are no longer available for purchase, so don't
    //have a good place to be located inside the UI.
    let noLongerForSaleSubscriptionManagementDiv = document.createElement("div")
    noLongerForSaleSubscriptionManagementDiv.classList.add("noLongerForSaleSubscriptionManagementDiv")
    storePopup.panel.appendChild(noLongerForSaleSubscriptionManagementDiv)

    let storeContainer = document.createElement("div")
    storeContainer.classList.add("storeContainer")
    storePopup.panel.appendChild(storeContainer)

    let bottomDiv = document.createElement("div")
    bottomDiv.classList.add("storePopupBottomDiv")
    bottomDiv.style.textAlign = "center"
    storePopup.panel.appendChild(bottomDiv) //Add the restore purchases button. Also handles bottom padding if banner ads are present and is native.

    getStoreToUse().then((store) => {
        //Provide the restore purchases button on non-Web platforms.
        if (store !== "Web") {
            let restorePurchasesButton = document.createElement("button")
            restorePurchasesButton.innerText = "Restore Purchases"
            restorePurchasesButton.classList.add("restorePurchasesButton")
            restorePurchasesButton.addEventListener("click", function() {
                getPurchasesInstance().restorePurchases().then(() => {
                    alert("Restoration Complete")
                })
            })
            bottomDiv.appendChild(restorePurchasesButton)
        }
    })


    let offeringDetails;
    try {
        let loadingMessage = document.createElement("p")
        loadingMessage.innerText = "Loading store..."
        loadingMessage.style.fontSize = "1.5em"
        storeContainer.appendChild(loadingMessage)
        offeringDetails = await getPurchasesInstance().getOfferings()
    }
    catch (e) {
        console.error("Failed to load store", e)
        new Popups.Notification("Store Temporarily Unavailable", "The store could not be loaded due to an error. This is mostly commonly caused by a weak internet connection. Please try again later. ").show()
        return
    }
    let customerInfo;

    function renderSubscriptionTier(subscriptionTier, offerings, ownershipHTML = "") {
        let subscriptionTierDiv = document.createElement("div")
        subscriptionTierDiv.classList.add("storeCategory")
        subscriptionTierDiv.style.background = subscriptionTier.backgroundCss

        let title = document.createElement("p")
        title.classList.add("storeCategoryTitle")
        title.innerText = subscriptionTier.title
        subscriptionTierDiv.appendChild(title)

        //Below the title we will add details about the users current subscription, if one.
        let ownedSubscriptionDetails = document.createElement("p")
        ownedSubscriptionDetails.classList.add("storeCategoryOwnedSubscriptionDetails")
        subscriptionTierDiv.appendChild(ownedSubscriptionDetails)

        if (ownershipHTML) {
            title.innerHTML += " (Owned)"
            ownedSubscriptionDetails.innerHTML = ownershipHTML
        }

        let descriptionContainer = document.createElement("div")
        descriptionContainer.classList.add("storeCategoryDescriptionContainer")

        let description = document.createElement("p")
        description.classList.add("storeCategoryDescription")
        description.innerText = subscriptionTier.description

        descriptionContainer.appendChild(description)
        subscriptionTierDiv.appendChild(descriptionContainer)

        offerings.sort((a, b) => {
            //Sort by lowest duration first.
            let aProduct = getPackageProduct(a)
            let bProduct = getPackageProduct(b)
            return durationStringMap[getDurationStringForProduct(aProduct)].durationDays - durationStringMap[getDurationStringForProduct(bProduct)].durationDays
        })

        for (let offering of offerings) {
            let packageProduct = getPackageProduct(offering)

            let durationDetails = durationStringMap[getDurationStringForProduct(packageProduct)]

            let price = getPriceStringForProduct(packageProduct)

            //Now offer the plan.
            let planDiv = document.createElement("div")
            planDiv.classList.add("storePlan")

            let planDuration = document.createElement("span")
            planDuration.classList.add("storePlanDuration")
            planDuration.innerText = durationDetails.inWords

            let planPrice = document.createElement("span")
            planPrice.classList.add("storePlanPrice")
            planPrice.innerText = price

            //The plan price and duration will be in a clickable area.
            //When clicked, we will popup the purchase menu.

            planDiv.appendChild(planDuration)
            planDiv.appendChild(planPrice)

            planDiv.addEventListener("click", async function() {
                try {
                    await purchasePackage(offering)
                }
                catch (e) {
                    if (e.message.includes("You need to sign in before you can make a purchase")) {
                        let signInRequiredPopup = new Popups.Notification("Log In Required", "Please log in before making purchases. The log in button is located in the right hand corner of the screen.")
                        signInRequiredPopup.show()
                    }
                    else {
                        //TODO: We need to alert the user depending on the reason.
                        console.warn("Purchase Failed", e)
                    }
                }
            })

            subscriptionTierDiv.appendChild(planDiv)
        }

        storeContainer.appendChild(subscriptionTierDiv)
    }




    async function renderStore() {
        console.log("Rendering store", customerInfo)
        while (storeContainer.firstChild) {
            storeContainer.firstChild.remove()
        }

        //Subscriptions we are currently offering in the store. Configured remotely via RevenueCat.
        let currentOfferings = offeringDetails.current

        //identifier_groups is an array of objects, sorted in the order to be displayed, with the following properties:
        //{title: "Name for Subscription Tier", description: "Tier Description", identifiers: ["xxx_yearly", "xxx_monthly"], entitlements: ["adfree", "silver"]}
        //It is used to group the offerings into categories, and determine how they will be presented.
        let identifier_groups = currentOfferings.metadata.identifier_groups

        //availablePackages is the list of packages that are available for purchase.
        //While every package is guaranteed to fit into exactly one identifier group,
        //it is not guaranteed that every identifier group will have a package.
        let availablePackages = currentOfferings.availablePackages

        function getPackagesForGroup(group) {
            return availablePackages.filter((offerPackage) => {
                return group.identifiers.includes(offerPackage.identifier)
            })
        }



        //First, we need to determine which tierGroups are owned.
        let tierGroupOwnershipDetails = []
        for (let tierGroup of identifier_groups) {
            tierGroupOwnershipDetails.push(determineOwnershipStatus(tierGroup, customerInfo))
        }

        //Now we will determine the ownership message (if any) for each subscription.
        let ownershipHTMLStrings = []
        for (let i=0;i<tierGroupOwnershipDetails.length;i++) {
            let ownershipDetails = tierGroupOwnershipDetails[i]
            let correspondingTierGroup = identifier_groups[i]

            //If this is inferior to another subscription with the same expiration date, we will display a different message.
            let isInferiorToAnotherOwnedSubscription = false
            for (let j = 0; j < tierGroupOwnershipDetails.length; j++) {
                if (i === j) {
                    continue
                }

                let otherOwnershipDetails = tierGroupOwnershipDetails[j]
                let otherTierGroup = identifier_groups[j]
                if (otherOwnershipDetails.hasEntitlements
                    && isInferiorSubscription(correspondingTierGroup, otherTierGroup)
                    && otherOwnershipDetails.expirationDate.getTime() === ownershipDetails.expirationDate.getTime()
                ) {
                    isInferiorToAnotherOwnedSubscription = true
                    break
                }
            }

            let managementStr = customerInfo.managementURL ? ` - <a target="_blank" href="${customerInfo.managementURL}">Manage</a>` : ""

            if (isInferiorToAnotherOwnedSubscription) {
                ownershipHTMLStrings.push("Included in your existing subscription " + managementStr)
            }
            else if (ownershipDetails.hasEntitlements) {
                let expirationDate = ownershipDetails.expirationDate
                let dateStr = `${expirationDate.getMonth() + 1}/${expirationDate.getDate()}/${expirationDate.getFullYear()}`
                ownershipHTMLStrings.push(`Owned. ${ownershipDetails.willRenew? "Renews" : "Expires"} on ${dateStr}` + managementStr)
            }
            else {
                ownershipHTMLStrings.push("")
            }
        }


        for (let i=0;i<identifier_groups.length;i++) {
            let tierGroup = identifier_groups[i]
            let packageOptions = getPackagesForGroup(tierGroup)
            let ownershipHTML = ownershipHTMLStrings[i]
            renderSubscriptionTier(tierGroup, packageOptions, ownershipHTML)
        }


        let unusedSubscriptions = findUnusedSubscriptions(customerInfo)

        if (unusedSubscriptions.length > 0) {
            noLongerForSaleSubscriptionManagementDiv.innerHTML = `You have ${unusedSubscriptions.length} subscription${unusedSubscriptions.length > 1? "s" : ""} with duplicate benefits. These subscription(s) cannot be displayed in the store, and may or may not be scheduled to renew. `

            if (customerInfo.managementURL) {
                noLongerForSaleSubscriptionManagementDiv.innerHTML += ` - <a target="_blank" href="${customerInfo.managementURL}">Manage</a>`
            }
        }
        else {
            noLongerForSaleSubscriptionManagementDiv.innerHTML = ""
        }
    }

    function updateInfoAndRender() {
        getCustomerInfo().then((info) => {
            console.log("Rendering with new info")
            customerInfo = info
            renderStore()
        })
    }

    window.addEventListener(CUSTOMER_INFO_UPDATED_EVENT_NAME, async function() {
        updateInfoAndRender()
    })
    updateInfoAndRender()
}

export {openStoreMenu}