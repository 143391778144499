const Popups = require("../Popups.js")
const {i18n} = require("../../../lib/i18nHelper.js")
const Room = require("../../../server/Room")

function resumeOfflineGame(saveText, resumeAtEnd = true) {
	//First, delete all other rooms and clients. Avoids possible complications. 
	serverStateManager.getAllRoomIds().forEach((roomId) => {
		serverStateManager.deleteRoom(roomId)
	})

	serverStateManager.getAllClients().forEach((client) => {
		client.delete()
	})

	//saveText could be a room save or a server state save. 
	//First, try to load as if it's a server save. If it loads, convert to room save. 
	try {
		let {startRooms} = serverStateManager.loadState(saveText)
		startRooms()
		let roomId = serverStateManager.getAllRoomIds()[0] //Room ID could be "Offline", or a translated version of it.

		//Now convert to a room save. 
		saveText = serverStateManager.getRoom(roomId).toJSON()
		serverStateManager.deleteRoom(roomId)
	}
	catch (e) {
		console.warn(e)
	}
	//Now, if we had a server save, it has been converted to a room save. 
	//Proceed with room save. 

	let room = Room.fromJSON(saveText)
	console.log(room)

	//TODO: Give people a choice of who to take over for. 
	let playingAsId = room.clientIds.find((clientId) => {
		return !clientId.startsWith("bot")
	})

	saveText = saveText.replaceAll(playingAsId, "offlineUserClientId") //Swap client ID. 

	//Recreate room with swapped client IDs. 
	room = Room.fromJSON(saveText)
	room.roomId = "Offline"
	room.state.roomId = "Offline"

	room.clientNicknames["offlineUserClientId"] = "Player 1" //Our name will always be Player 1. 
	//TODO: Should we ensure that the bots are always named Bot 1, Bot 2, and Bot 3?
	
	globalThis.serverStateManager.createRoom("Offline", room)
	room.state.moves = [] //Resume at beginning
	room.init()

	//Connect to offline server. 
	stateManager.setServerOnline(false)
	stateManager.getCurrentRoom() //Sync with offline server. 

	function startRoomAtEnd() {
		window.settings.msUntilNextInterstitial.value = Math.max(window.settings.msUntilNextInterstitial.value, 60 * 1000) //We need to temporarily disable ads before we load the saved game - we don't want loading the game to trigger an ad immediately (if the game ends in Mahjong). 
		room.delete()
		room = Room.fromJSON(saveText)
		room.roomId = "Offline"
		room.state.roomId = "Offline"
		globalThis.serverStateManager.createRoom("Offline", room)
		room.init()

	}
	if (resumeAtEnd) {
		startRoomAtEnd()
	}
	else {
		//If the user wants to go to the end of the game, allow them to. 
		let messageBar = new Popups.MessageBar(i18n.__("Click Here to Load End of Game (Closes Automatically)"))
		messageBar.elem.addEventListener("click", function() {
			startRoomAtEnd()
			messageBar.dismiss()
		})
		messageBar.elem.style.cursor = "pointer"
		messageBar.show(10000)
	}
}

module.exports = {resumeOfflineGame}