//Specific combo on card - Portion of a hand (the full hand may contain many combos)

class CardCombo {
    constructor(props) {
        Object.assign(this, props)
    }

    freeze() {
        //Prevent this object from being modified. 
        Object.freeze(this)
        Object.freeze(this.tiles)
        for (let arr of this.tiles) {Object.freeze(arr)}
    }

    isIdenticalTo(otherCombo) {
        //This function is intended to be used to remove duplicate combos created when generating the card. 
    
        //Preconditions:
        //otherCombo must be from the same hand on the same card. 
        //otherCombo must be a valid Mah Jongg combo. 
        //otherCombo must be the raw combos as received from generating the card ("any" tiles in combos 1 only match the identical "any" tiles in combo 2)
    
    
        //usedSubarraysOtherCombo is used to remember which subarrays in otherCombo have already been matched with. 
        //It uses bitwise operations. Since a (valid) combo can only have at most 14 subarrays, we don't run out of bits.
    
        //1 << 0 bit refers to 0 index in array, 1 << 1 the 1 index, etc
        //If bit 0, not yet matched. If bit 1, matched. 
        //So 5 (101) means index 2 and index 0 subarrays are matched, no others are. 
        let usedSubarraysOtherCombo = 0
    
        ourTilesIterator:
        for (let subarray1 of this.tiles) {
            //Attempt to remove a matching subarray in otherCombo. 
            //If a strictly identical subarray cannot be found, then the combos are not identical. 
    
            for (let i=0;i<otherCombo.tiles.length;i++) {
                if (usedSubarraysOtherCombo & 1 << i) {continue} //Subarray already used. 
    
                let subarray2 = otherCombo.tiles[i]
    
                if (subarray2.length !== subarray1.length) {
                    continue; //Cannot match - different lengths. 
                }
    
                if (subarray1[0].matches(subarray2[0])) {
                    //Determine if the tiles contained in the subarray are the same. Note that, since all tiles in the subarray are identical, 
                    //if the first tiles in each subarray are identical, all the tiles are identical. 
                    
                    usedSubarraysOtherCombo += 1 << i //Mark subarray as used. 
    
                    continue ourTilesIterator; //subarray1 successfully matched - continue to next subarray. 
                }
            }
    
            return false //We failed to match a subarray
        }
        return true; //We successfully eliminated every one of our subarrays. 
    }
}

module.exports = CardCombo