import { initializeApp } from "firebase/app";

if (Capacitor.getPlatform() === "web") {
    const firebaseConfig = {
        apiKey: "AIzaSyDm9VvLtAODCqH5gCwBYVu70yR09rOURFE",
        authDomain: "mahjong-4-friends.firebaseapp.com",
        projectId: "mahjong-4-friends",
        storageBucket: "mahjong-4-friends.appspot.com",
        messagingSenderId: "1098861638485",
        appId: "1:1098861638485:web:757848979ad64b97e075dc",
        measurementId: "G-7GL4P6K98T"
    };

    const app = initializeApp(firebaseConfig);
}

import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
window.FirebaseAnalytics = FirebaseAnalytics

import { FirebaseRemoteConfig } from "@capacitor-firebase/remote-config";
window.FirebaseRemoteConfig = FirebaseRemoteConfig

import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import {Capacitor} from "@capacitor/core";
window.FirebaseAuthentication = FirebaseAuthentication

try {require("./analytics.js")} catch (e) {console.error(e)}
try {require("./auth")} catch (e) {console.error(e)}
try {require("./remoteConfig.js")} catch (e) {console.error(e)}
