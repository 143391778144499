function removeClient(clientId, explaination = "You left the room. ") {
	let clientIdIndex = this.clientIds.findIndex((currentClientId) => {return currentClientId === clientId})
	if (clientIdIndex === -1) {
		return "Client Not Found"
	}
	else {
		this.setClientNickname(clientId) //Set to undefined - so deletes. 
		this.clientIds.splice(clientIdIndex, 1)
		if (this.hostClientId === clientId) {
			//Choose a new host client. Make sure NOT to pick a bot.
			this.hostClientId = null;
			this.clientIds.forEach(((clientId) => {
				if (this.hostClientId) {return}
				if (!globalThis.serverStateManager.getClient(clientId).isBot) {
					this.hostClientId = clientId
				}
			}).bind(this))
		}
		this.sendStateToClients()

		let clientBeingKicked = globalThis.serverStateManager.getClient(clientId)
		if (clientBeingKicked) {
			clientBeingKicked.message("roomActionLeaveRoom", explaination, "success")
			//The client is going to change their client Id. We can now delete the old client.
			clientBeingKicked.delete()
		}
		if (this.hostClientId === null) {
			//We have no clients. Delete this room.
			this.clientIds.forEach((clientId, i) => {
				//Delete any bots.
				let client = globalThis.serverStateManager.getClient(clientId)
				if (client) {client.delete()}
			});
			this.delete()
		}
	}
}

module.exports = removeClient
