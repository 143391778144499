// to be used in Client, late binded, for tile/summary just-in-time translations

const Tile = require("../lib/Tile")

function localizeTileName(locale, tileJson) {
  let tile = Tile.fromJSON(tileJson)

  let tileName = tile.getTileName(this.getRoom().state.settings.gameStyle, locale)
  return tileName
}

function localizeSummary(locale) {
  let summary = this.getRoom().lastSummary[locale]
  return summary
}

module.exports = {localizeTileName, localizeSummary};
