const mongoose = require ('mongoose');

const url = 'mongodb://mongodb_container:27017';
let connectionPromise = mongoose.connect(url)

connectionPromise.then(() => {
    console.log('Connected to MongoDB');
}, (e) => {
    console.error(e)
    mongoose.connect("mongodb://localhost:27017") //For local server in development. TODO: Try both connections in parallel
})

/*
docker run -d \
    -p 27017:27017 \
    --name m4f-mongo \
    -v m4f-mongo-volume:/data/db \
    mongo:latest
*/

