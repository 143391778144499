const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//1 222 3 33333 KKKK
	function(tiles = []) {
		allOptions.slice(0, -2).forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				[0,1,2].forEach((secondOffset) => {
					let newArr = []
					tiles.push(newArr)
					
					
					newArr.push(createTiles({type: suitOrder[0], value: offset+0, amount: 1}))
					newArr.push(createTiles({type: suitOrder[0], value: offset+1, amount: 3}))
					newArr.push(createTiles({type: suitOrder[0], value: offset+2, amount: 1}))
					newArr.push(createTiles({type: suitOrder[1], value: offset+secondOffset, amount: 5}))
					
					newArr.push(createTiles({type: "any", value: "any", amount: 4}))
				})
			})
		})
		
		return {
			tiles,
			score: 35,
			concealed: false,
		}
	},
	//AA 33333 4 55555 6
	function(tiles = []) {
		allOptions.slice(0, -3).forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: "any", value: "any", amount: 2}))
				
				newArr.push(createTiles({type: suitOrder[0], value: offset+0, amount: 5}))
				
				newArr.push(createTiles({type: suitOrder[1], value: offset+1, amount: 1}))
				
				newArr.push(createTiles({type: suitOrder[2], value: offset+2, amount: 5}))
				
				newArr.push(createTiles({type: suitOrder[1], value: offset+3, amount: 1}))
			})
			
			allSuits.forEach((suit) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: "any", value: "any", amount: 2}))
				
				newArr.push(createTiles({type: suit, value: offset+0, amount: 5}))
				
				newArr.push(createTiles({type: suit, value: offset+1, amount: 1}))
				
				newArr.push(createTiles({type: suit, value: offset+2, amount: 5}))
				
				newArr.push(createTiles({type: suit, value: offset+3, amount: 1}))
			})
		})
		
		return {
			tiles,
			score: 40,
			concealed: false,
		}
	},
	//IM L 88888 IM L 888
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "any", value: 1, amount: 1}))
			newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
			
			newArr.push(createTiles({type: "any", value: 7, amount: 1}))
			
			newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 5}))
			
			newArr.push(createTiles({type: "any", value: 1, amount: 1}))
			newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
			
			newArr.push(createTiles({type: "any", value: 7, amount: 1}))
			
			newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 3}))
		})
		
		return {
			tiles,
			score: 50,
			concealed: false,
		}
	},
	//PPP RMIS QQQQQ OS
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)
		
		newArr.push(createTiles({type: "any", value: "any", amount: 3}))
		
		newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
		newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
		newArr.push(createTiles({type: "any", value: 1, amount: 1}))
		newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
		
		newArr.push(createTiles({type: "any", value: "any", amount: 5}))
		
		newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
		newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
		
		return {
			tiles,
			score: 50,
			concealed: false,
		}
	},
	//AA BB CC DD E FFFFF
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)
		
		newArr.push(createTiles({type: "any", value: "any", amount: 2}))
		newArr.push(createTiles({type: "bamboo", value: "any", amount: 2}))
		newArr.push(createTiles({type: "character", value: "any", amount: 2}))
		newArr.push(createTiles({type: "dragon", value: "any", amount: 2}))
		newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
		
		newArr.push(createTiles({type: "flower", amount: 5}))
		
		return {
			tiles,
			score: 50,
			concealed: false,
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "J AC KKKK ALO PPP E"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

