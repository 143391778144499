
import { AdMob, AdOptions, AdLoadInfo, InterstitialAdPluginEvents, BannerAdPluginEvents, BannerAdSize, BannerAdPosition  } from '@capacitor-community/admob';
import admobIds from './ids.js';

let interstitialRefreshTimeout;
let currentlyPreparingInterstitial = false;

let interstitialExpiryTime = 58 * 60 * 1000 //Meta and Google only guarantee interstitial ads valid for 60 minutes, do 58 for caution. 
let timeInterstitialPrepared;

AdMob.addListener(InterstitialAdPluginEvents.FailedToLoad, function() {
    currentlyPreparingInterstitial = false
})

AdMob.addListener(InterstitialAdPluginEvents.Loaded, function() {
    currentlyPreparingInterstitial = false
    timeInterstitialPrepared = Date.now() //The ad was just prepared
})



let videoMuteInterval;
let microphoneEnabledBefore;

AdMob.addListener(InterstitialAdPluginEvents.Showed, function() {
    console.warn("Showed", Date.now())

    timeInterstitialPrepared = undefined //We no longer have any ad prepared. 
    clearTimeout(interstitialRefreshTimeout) //We don't need to refresh the interstitial as we have shown it. 

    //We need to mute the videos (so audio from other users does not play overtop any ad audio)
    //We also need to mute ourselves (so that our ad can't be played through to others)
    //The videoMuteIntervals are only really necessary if people in room change while ad is open. 

    clearInterval(videoMuteInterval) //Shouldn't be needed (should never be an active interval here), but clear it just in case. 

    // //First, mute ourselves. Save our prior mute state so we can restore after. 
    let ourUserVideo = window.userVideos.find((userVideo) => {
        return userVideo.associatedClientId === window.stateManager.connectedClientId
    })
    microphoneEnabledBefore = ourUserVideo.isMicrophoneEnabled()
    ourUserVideo.setMicrophoneEnabled(false) //Mute ourselves

    window.userVideos.forEach((userVideo) => {
        userVideo.setForcedSilent(true) //Ensure that all videos are silenced. 
    })
    videoMuteInterval = setInterval(muteAllVideos, 500)
    console.warn("Video Mute Interval", videoMuteInterval)
})

AdMob.addListener(InterstitialAdPluginEvents.FailedToShow, function() {
     //TODO: Why did it fail to show? Do we need to load a new ad?

     timeInterstitialPrepared = undefined //Just load a new ad if the prior one failed to show. 
})

AdMob.addListener(InterstitialAdPluginEvents.Dismissed, function() {
    console.warn("Dismissed", Date.now())

    //The ad is gone. We can stop muting videos. 
    clearInterval(videoMuteInterval)
    console.warn("Video Mute Interval End", videoMuteInterval)

    // If we weren't muted before, unmute us
    let ourUserVideo = window.userVideos.find((userVideo) => {
        return userVideo.associatedClientId === window.stateManager.connectedClientId
    })
    ourUserVideo.setMicrophoneEnabled(microphoneEnabledBefore)

    //Unsilence the videos. 
    window.userVideos.forEach((userVideo) => {
        userVideo.setForcedSilent(false)
    })
})




function isInterstitialReady() {
    //Returns true if we are ready to show an interstitial, false if we aren't (not prepared or expired)
    return Date.now() - interstitialExpiryTime < timeInterstitialPrepared
}
 

async function prepareInterstitial() {
    if (isInterstitialReady()) {
        //We're ready to show an ad so no need to prepare another. 
        return
    }

    if (currentlyPreparingInterstitial) {
        //We are already in the process of preparing an interstitial ad so ignore this call. 
        return
    }

    const options = {
        adId: admobIds.newGameInterstitial,
    };

    currentlyPreparingInterstitial = true
    await AdMob.prepareInterstitial(options)

    setTimeout(prepareInterstitial, interstitialExpiryTime) //Refresh the interstitial ad (after it expires)
}



async function displayInterstitial() {
    if (!isInterstitialReady()) {
        //Oh no! We aren't preloaded. Go ahead and try to load now but it likely won't load in time. 
        prepareInterstitial()
    }

    let minimumDisplayDelay = 1500 //Minimum delay between when function is called and ad shown. 
    await new Promise((resolve) => {setTimeout(resolve, minimumDisplayDelay)})

    if (isInterstitialReady()) {
        await AdMob.showInterstitial();
        return true //Showed ad (we don't actually check for the show event but this is good enough)
    }
    else {
        //Expired OR not loaded when requested and couldn't be loaded in time. . 
        window?.FirebaseAnalytics?.logEvent?.({
            name: "interstitialExpired",
            params: {}
        });
        return false;
    }
}

export {
    isInterstitialReady, prepareInterstitial, displayInterstitial, 
}