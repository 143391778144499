const { FirebaseAuthentication } = require("@capacitor-firebase/authentication")
let weakPasswordErrorText = "This password is too short or too common. Please choose a stronger password and try again."

import {PasswordConfirmingUnit, Display_Templates} from "ui-for-firebase-authentication";

function createPasswordInputBox() {
    let passwordEntryContainer = document.createElement("div")
    
    let passwordEntry = document.createElement("input")
    passwordEntry.classList.add("loginEntry", "passwordEntry")
    passwordEntryContainer.appendChild(passwordEntry)

    let hidden = true
    let hideShowButton = document.createElement("img")
    hideShowButton.classList.add("passwordVisibilityToggle")
    function updateState() {
        passwordEntry.type = hidden ? "password" : "text"
        hideShowButton.src = `assets/loginProviders/${hidden ? "show" : "hide"}.svg`
    }
    hideShowButton.addEventListener("click", function() {
        hidden = !hidden
        updateState()
    })
    updateState()
    passwordEntryContainer.appendChild(hideShowButton)

    return {container: passwordEntryContainer, passwordEntry}
}


function createChangePasswordButton() {
    let changePasswordDiv = document.createElement("form")

  changePasswordDiv.addEventListener("submit", function(e) {
    e.preventDefault()
  })

    changePasswordDiv.addEventListener("click", function(e) {
        e.preventDefault() //Prevent form submission. 
    })
    changePasswordDiv.style.display = "inline-block"

    //Chrome wants hidden username fields to help password managers (https://goo.gl/9p2vKq)
    //This is unnecessary but removes a warning from the browser in the console.
    let hiddenUsernameInput = document.createElement("input")
    hiddenUsernameInput.type = "text"
    hiddenUsernameInput.style.display = "none"
    hiddenUsernameInput.autocomplete = "username"
    FirebaseAuthentication.getCurrentUser().then((userObj) => {
        if (userObj?.user?.email) {
            hiddenUsernameInput.value = userObj.user.email
        }
    })
    changePasswordDiv.appendChild(hiddenUsernameInput)

   let passwordInputUnit = new PasswordConfirmingUnit({hidden: true, minLength: 6, autoComplete: "new-password"})
    changePasswordDiv.appendChild(passwordInputUnit.container)
    passwordInputUnit.container.style.display = "none"

    let changePasswordButton = document.createElement("button")
    changePasswordButton.innerText = "Change Password"
    changePasswordDiv.appendChild(changePasswordButton)

    changePasswordButton.addEventListener("click", function() {
        if (passwordInputUnit.container.style.display === "none") {
            //The first time the button is clicked we show the buttons. 
            passwordInputUnit.container.style.display= ""
            changePasswordDiv.style.display = ""
        }
        else {
          //Submitting form.
          changePasswordDiv.requestSubmit()

            FirebaseAuthentication.updatePassword({newPassword: passwordInputUnit.getValue()}).then(() => {
                passwordInputUnit.setValue("")
              passwordInputUnit.container.style.display = "none"
              alert("Password Change Successful")
            }, (e) => {
                if (e.code === "auth/requires-recent-login") {
                    alert("Apologies, however you have been logged in for quite some time. For security purposes, you must log out then log in again before your password can be updated. ")
                }
                else if (e.code === "auth/weak-password") {
                    alert(weakPasswordErrorText)
                }
                else {
                    alert("Error: " + (e.code || e.message))
                }
            })

        }
    })

    return changePasswordDiv
}


export {createChangePasswordButton}