const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
    //222 AA 888 444 666 (AA any Pr.; 1 or 3 suits) - 25 Points
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            let newArr = []
            tiles.push(newArr)

            newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 3}))
            newArr.push(createTiles({type: "any", value: "any", amount: 2}))
            newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 3}))
            newArr.push(createTiles({type: suitOrder[1], value: 4, amount: 3}))
            newArr.push(createTiles({type: suitOrder[2], value: 6, amount: 3}))
        })

        allSuits.forEach((suit) => {
            let newArr = []
            tiles.push(newArr)

            newArr.push(createTiles({type: suit, value: 2, amount: 3}))
            newArr.push(createTiles({type: "any", value: "any", amount: 2}))
            newArr.push(createTiles({type: suit, value: 8, amount: 3}))
            newArr.push(createTiles({type: suit, value: 4, amount: 3}))
            newArr.push(createTiles({type: suit, value: 6, amount: 3}))
        })
        
        
        return {
            tiles,
            score: 25,
            concealed: false,
        }
    },
    //FFF 2468 EEE KKKK (#’s 1 suit; any Wind; K any Kong) - 25 Points
    function(tiles = []) {
        allSuits.forEach((suit) => {
            let newArr = []
            tiles.push(newArr)

            newArr.push(createTiles({type: "flower", amount: 3}))
            
            //2468
            newArr.push(createTiles({type: suit, value: 2, amount: 1}))
            newArr.push(createTiles({type: suit, value: 4, amount: 1}))
            newArr.push(createTiles({type: suit, value: 6, amount: 1}))
            newArr.push(createTiles({type: suit, value: 8, amount: 1}))
            
            //EEE
            newArr.push(createTiles({type: "wind", value: "any", amount: 3}))
            
            //KKKK
            newArr.push(createTiles({type: "any", value: "any", amount: 4}))
        })
        
        return {
            tiles,
            score: 25,
            concealed: false,
        }
    },
    //JS2 AA 222 222 222 (J=Joker; AA any Pr.; any same even, 3 suits) - 30 Points
    function(tiles = []) {
        evenOptions.forEach((option) => {
            
            let newArr = []
            tiles.push(newArr)

            //JS2
            newArr.push(createTiles({type: "joker", amount: 1}))
            newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
            newArr.push(createTiles({type: "any", value: 2, amount: 1}))
            
            //AA
            newArr.push(createTiles({type: "any", value: "any", amount: 2}))
            
            //222 222 222
            newArr.push(createTiles({type: allSuits[0], value: option, amount: 3}))
            newArr.push(createTiles({type: allSuits[1], value: option, amount: 3}))
            newArr.push(createTiles({type: allSuits[2], value: option, amount: 3}))
        })
        
        return {
            tiles,
            score: 30,
            concealed: false,
        }
    },
    //FF 666 24 24 24 888 (3 suits) - 30 Points
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            
            let newArr = []
            tiles.push(newArr)

            //FF
            newArr.push(createTiles({type: "flower", amount: 2}))
            
            //666 24
            newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 3}))
            newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 1}))
            
            //24
            newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 1}))
            newArr.push(createTiles({type: suitOrder[1], value: 4, amount: 1}))
            
            //24
            newArr.push(createTiles({type: suitOrder[2], value: 2, amount: 1}))
            newArr.push(createTiles({type: suitOrder[2], value: 4, amount: 1}))
            
            //888
            newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 3}))
        })
        
        return {
            tiles,
            score: 30,
            concealed: false,
        }
    },
    //20 666 KKKK 888 24 (3 suits; K any Kong) - 30 Points
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            
            let newArr = []
            tiles.push(newArr)

            //20
            newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
            newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
            
            //666
            newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 3}))
            
            //KKKK
            newArr.push(createTiles({type: "any", value: "any", amount: 4}))
            
            //888
            newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 3}))
            
            //24
            newArr.push(createTiles({type: suitOrder[2], value: 2, amount: 1}))
            newArr.push(createTiles({type: suitOrder[2], value: 4, amount: 1}))
        })
        
        return {
            tiles,
            score: 30,
            concealed: false,
        }
    },
    //MJ 222 444 666 888 (M=W, J=Joker; #”s 1 suit) - 35 Points
    function(tiles = []) {
        allSuits.forEach((suit) => {
            
            let newArr = []
            tiles.push(newArr)

            //MJ
            newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
            newArr.push(createTiles({type: "joker", amount: 1}))

            //222 444 666 888
            newArr.push(createTiles({type: suit, value: 2, amount: 3}))
            newArr.push(createTiles({type: suit, value: 4, amount: 3}))
            newArr.push(createTiles({type: suit, value: 6, amount: 3}))
            newArr.push(createTiles({type: suit, value: 8, amount: 3}))
        })
        
        return {
            tiles,
            score: 35,
            concealed: false,
        }
    },
    //AA GR 888 222 AA IL (AA any Pr.; #’s 1 suit; I=1, L=7) - 40
    function(tiles = []) {
        allSuits.forEach((suit) => {
            
            let newArr = []
            tiles.push(newArr)

            //AA
            newArr.push(createTiles({type: "any", value: "any", amount: 2}))

            //GR
            newArr.push(createTiles({type: "dragon", value: "green", amount: 1}))
            newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))

            //888 222
            newArr.push(createTiles({type: suit, value: 8, amount: 3}))
            newArr.push(createTiles({type: suit, value: 2, amount: 3}))

            //AA
            newArr.push(createTiles({type: "any", value: "any", amount: 2}))

            //IL
            newArr.push(createTiles({type: "any", value: 1, amount: 1}))
            newArr.push(createTiles({type: "any", value: 7, amount: 1}))
        })
        
        return {
            tiles,
            score: 40,
            concealed: false,
        }
    },
].map((func, index) => {
    let output = func()
    output.section = "Even If You Don't Believe"
    output.sectionIndex = index + 1 //Sections indexed starting at 1. 
    output.handName = `${output.section} #${output.sectionIndex}`
    return new CardHand(output)
})

