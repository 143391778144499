import {Capacitor} from "@capacitor/core";

function createLinkElement(subject = "Mahjong 4 Friends Feedback", body = "") {
    let supportEmail = "support@mahjong4friends.com"

    //Add support details to body. 
    body += 
`


----- Please type your message above this line ----
If you are reporting a bug, please include screenshots with your issue if possible. 

Device Details: 
Platform: ${Capacitor.getPlatform()}
Browser Version: ${navigator.userAgent}`

    let emailLink = document.createElement("a")
    emailLink.innerText = supportEmail
    emailLink.href = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`

    return emailLink
}

export {createLinkElement}