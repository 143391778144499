const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//A 333 555 KKKK PPP
	function(tiles = []) {
		allSuits.forEach((suitForRun) => {
			for (let num1 of oddOptions) {
				for (let num2 of oddOptions) {
					if (num2 === num1) {continue}
					let newArr = []
					tiles.push(newArr)
					
					newArr.push(createTiles({type: "any", value: "any", amount: 1}))
					
					newArr.push(createTiles({type: suitForRun, value: num1, amount: 3}))
					newArr.push(createTiles({type: suitForRun, value: num2, amount: 3}))
					
					newArr.push(createTiles({type: "any", value: "any", amount: 4}))
					newArr.push(createTiles({type: "any", value: "any", amount: 3}))
				}
			}
			
		}) 
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//F 333 555 PPP 999 F 
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 1}))
			
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 3}))
			newArr.push(createTiles({type: suitOrder[1], value: 5, amount: 3}))
			
			newArr.push(createTiles({type: "any", value: "any", amount: 3}))
			
			newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 3}))
			
			
			newArr.push(createTiles({type: "flower", amount: 1}))
		})
		
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 1}))
			
			newArr.push(createTiles({type: suit, value: 3, amount: 3}))
			newArr.push(createTiles({type: suit, value: 5, amount: 3}))
			
			newArr.push(createTiles({type: "any", value: "any", amount: 3}))
			
			newArr.push(createTiles({type: suit, value: 9, amount: 3}))
			
			
			newArr.push(createTiles({type: "flower", amount: 1}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FFF 333 57 999 PPP
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 3}))
			
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 3}))
			
			newArr.push(createTiles({type: suitOrder[1], value: 5, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 7, amount: 1}))
			
			newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 3}))
			
			newArr.push(createTiles({type: "any", value: "any", amount: 3}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//PPP LAIN 555 5 555
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			oddOptions.forEach((offset) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: "any", value: "any", amount: 3}))
				
				newArr.push(createTiles({type: "any", value: 7, amount: 1}))
				newArr.push(createTiles({type: "any", value: "any", amount: 1}))
				newArr.push(createTiles({type: "any", value: 1, amount: 1}))
				newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
				
				newArr.push(createTiles({type: suitOrder[0], value: offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: offset, amount: 1}))
				newArr.push(createTiles({type: suitOrder[2], value: offset, amount: 3}))
			})
		})
		
		return {
			tiles,
			score: 30,
			concealed: false
		}
	},
	//2023 111 333 555 9
	function(tiles = []) {
		allSuitArrangements.forEach((triColorOrder) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: triColorOrder[0], value: 2, amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
				newArr.push(createTiles({type: triColorOrder[1], value: 2, amount: 1}))
				newArr.push(createTiles({type: triColorOrder[2], value: 3, amount: 1}))
				
				newArr.push(createTiles({type: suitOrder[0], value: 1, amount: 3}))
				
				newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: 5, amount: 3}))
				
				newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 1}))
			})
			
			allSuits.forEach((suit) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: triColorOrder[0], value: 2, amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
				newArr.push(createTiles({type: triColorOrder[1], value: 2, amount: 1}))
				newArr.push(createTiles({type: triColorOrder[2], value: 3, amount: 1}))
				
				newArr.push(createTiles({type: suit, value: 1, amount: 3}))
				
				newArr.push(createTiles({type: suit, value: 3, amount: 3}))
				newArr.push(createTiles({type: suit, value: 5, amount: 3}))
				
				newArr.push(createTiles({type: suit, value: 9, amount: 1}))
			})
		})
		
		
		return {
			tiles,
			score: 35,
			concealed: false
		}
	},
	//FLO PPP SIE 333 55
	function(tiles = []) {
		permutations(oddOptions, 2).forEach((orderedOptions) => {
			//Tons of duplicates but they'll be removed. 
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: "flower", amount: 1}))
				newArr.push(createTiles({type: "any", value: 7, amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
				
				newArr.push(createTiles({type: "any", value: "any", amount: 3}))
				
				newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
				newArr.push(createTiles({type: "any", value: 1, amount: 1}))
				newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
				
				newArr.push(createTiles({type: suitOrder[0], value: orderedOptions[0], amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: orderedOptions[1], amount: 2}))
			})
			
			allSuits.forEach((suit) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: "flower", amount: 1}))
				newArr.push(createTiles({type: "any", value: 7, amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
				
				newArr.push(createTiles({type: "any", value: "any", amount: 3}))
				
				newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
				newArr.push(createTiles({type: "any", value: 1, amount: 1}))
				newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
				
				newArr.push(createTiles({type: suit, value: orderedOptions[0], amount: 3}))
				newArr.push(createTiles({type: suit, value: orderedOptions[1], amount: 2}))
			})
		})
		
		return {
			tiles,
			score: 35,
			concealed: false
		}
	},
	//PPP RO LLL III FIC
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)
		
		newArr.push(createTiles({type: "any", value: "any", amount: 3}))
		
		newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
		
		newArr.push(createTiles({type: "any", value: 7, amount: 3}))
		
		newArr.push(createTiles({type: "any", value: 1, amount: 3}))
		
		newArr.push(createTiles({type: "flower", amount: 1}))
		newArr.push(createTiles({type: "any", value: 1, amount: 1}))
		newArr.push(createTiles({type: "character", value: "any", amount: 1}))
		
		return {
			tiles,
			score: 40,
			concealed: false
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "FLO PPP SIE"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

