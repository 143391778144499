const Card = require("../Card.js")

class ImplementedCard extends Card {
	name = "2023 Marvelous Mah Jongg"

	generateHands() {
		let output = []
	
		output.push(...require("./P2R.js")) //PPP EEE 2R
		output.push(...require("./FLOPSIE.js")) //FLO PPP SIE
		output.push(...require("./atomic.js")) 
		output.push(...require("./sub-atomic.js"))
		output.push(...require("./MAGICAL.js")) //M AA GIC AA L
		output.push(...require("./MOPSIE.js")) //MO PPP SIE
		output.push(...require("./ENERGIZER.js")) //ENERGISER
		output.push(...require("./hop-skip-run.js")) //HO PPP, S KKKK I PPP, AND RUN 
		output.push(...require("./JACKALOPE.js")) //J AC KKKK ALO PPP E 
		output.push(...require("./pull-this-out-of-your-hat.js")) //PPP ULL THIS OUT OF YOUR HAT!
	
		return output
	}
}

module.exports = new ImplementedCard()