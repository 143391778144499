const Tile = require("../../Tile")
const Wall = require("../../Wall")

class TileFrequencies {
    constructor() {
        this.frequencies = {}
        this.keyTileMap = {}
    }

    getAllTiles() {
        return Object.values(this.keyTileMap)
    }

    setAndReturnKey(tile) {
        let key = tile.getTileName("american")
        this.keyTileMap[key] = tile
        return key
    }

    set(tile, count) {
        this.frequencies[this.setAndReturnKey(tile)] = count
    }

    increase(tile, delta) {
        if (!this.frequencies[this.setAndReturnKey(tile)]) {
            this.frequencies[this.setAndReturnKey(tile)] = 0
        }
        this.frequencies[this.setAndReturnKey(tile)] += delta
    }

    decrease(tile, delta) {
        this.increase(tile, -delta)
    }

    get(tile) {
        return this.frequencies[this.setAndReturnKey(tile)]
    }
}

module.exports = {TileFrequencies}