function addBot(obj = {}) {
	//Create a clientId for the bot.
	let client = globalThis.serverStateManager.createBot()

	client.setRoomId(this.roomId)

	this.addClient(client.clientId)
	this.sendStateToClients()
}

module.exports = addBot
