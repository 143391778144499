const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
//Supports all hands. 
module.exports = [
	function(tiles = []) {
		allOptions.slice(0, -6).forEach((num, i) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				//AA 234567 888 888
				newArr.push(createTiles({type: "any", value: "any", amount: 2}))

				newArr.push(createTiles({type: suitOrder[0], value: num + 0, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: num + 1, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: num + 2, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: num + 3, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: num + 4, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: num + 5, amount: 1}))

				newArr.push(createTiles({type: suitOrder[1], value: num + 6, amount: 3}))
				newArr.push(createTiles({type: suitOrder[2], value: num + 6, amount: 3}))
			})
		});
		return {
			tiles,
			score: 25,
			concealed: false,
		}
	},
	function(tiles = []) {
		allOptions.slice(0, -4).forEach((num, i) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				//1234 555 KKKK PPP
				newArr.push(createTiles({type: suitOrder[0], value: num + 0, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: num + 1, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: num + 2, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: num + 3, amount: 1}))

				newArr.push(createTiles({type: suitOrder[1], value: num + 4, amount: 3}))

				newArr.push(createTiles({type: "any", value: "any", amount: 4}))
				newArr.push(createTiles({type: "any", value: "any", amount: 3}))
			})
		});
		return {
			tiles,
			score: 25,
			concealed: false,
		}
	},
	function(tiles = []) {
		allOptions.slice(0, -5).forEach((num, i) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				//AA 333 4 555 6 777 8
				newArr.push(createTiles({type: "any", value: "any", amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: num + 0, amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: num + 1, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: num + 2, amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: num + 3, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: num + 4, amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: num + 5, amount: 1}))
			})
		});
		return {
			tiles,
			score: 25,
			concealed: false,
		}
	},
	function(tiles = []) {
		allOptions.slice(0, -5).forEach((num, i) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				//4444 5555 PPP 999
				newArr.push(createTiles({type: suitOrder[0], value: num + 0, amount: 4}))
				newArr.push(createTiles({type: suitOrder[1], value: num + 1, amount: 4}))
				newArr.push(createTiles({type: "any", value: "any", amount: 3}))

				newArr.push(createTiles({type: suitOrder[2], value: num + 5, amount: 3}))
			})

			allSuits.forEach((suit) => {
				let newArr = []
				tiles.push(newArr)

				//4444 5555 PPP 999
				newArr.push(createTiles({type: suit, value: num + 0, amount: 4}))
				newArr.push(createTiles({type: suit, value: num + 1, amount: 4}))
				newArr.push(createTiles({type: "any", value: "any", amount: 3}))

				newArr.push(createTiles({type: suit, value: num + 5, amount: 3}))
			})
		});
		return {
			tiles,
			score: 25,
			concealed: false,
		}
	},
	function(tiles = []) {
		allOptions.slice(0, -3).forEach((num, i) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				//20 FFF 1234 NNN 22
				newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))

				newArr.push(createTiles({type: "flower", amount: 3}))

				newArr.push(createTiles({type: suitOrder[1], value: num + 0, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: num + 1, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: num + 2, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: num + 3, amount: 1}))

				newArr.push(createTiles({type: "wind", value: "any", amount: 3}))

				newArr.push(createTiles({type: suitOrder[2], value: 2, amount: 2}))
			})
		});
		return {
			tiles,
			score: 30,
			concealed: false,
		}
	},
	function(tiles = []) {
		allOptions.slice(0, -4).forEach((num, i) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				//333 444 NN 666 777
				newArr.push(createTiles({type: suitOrder[0], value: num + 0, amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: num + 1, amount: 3}))
				newArr.push(createTiles({type: "wind", value: "any", amount: 2}))
				newArr.push(createTiles({type: suitOrder[1], value: num + 3, amount: 3}))
				newArr.push(createTiles({type: suitOrder[2], value: num + 4, amount: 3}))
			})

			allSuits.forEach((suit) => {
				let newArr = []
				tiles.push(newArr)

				//333 444 NN 666 777
				newArr.push(createTiles({type: suit, value: num + 0, amount: 3}))
				newArr.push(createTiles({type: suit, value: num + 1, amount: 3}))
				newArr.push(createTiles({type: "wind", value: "any", amount: 2}))
				newArr.push(createTiles({type: suit, value: num + 3, amount: 3}))
				newArr.push(createTiles({type: suit, value: num + 4, amount: 3}))
			})
		});
		return {
			tiles,
			score: 30,
			concealed: false,
		}
	},
	function(tiles = []) {
		//MJ 17 18 19 20 21 22
		//We can use any types as single tiles here because EVERY NUMBERED TILE is an any type.
		//Therefore, the greedy matching is not an issue.
		let newArr = []
		tiles.push(newArr)

		newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
		newArr.push(createTiles({type: "joker"}))

		newArr.push(createTiles({type: "any", value: 1, amount: 1}))
		newArr.push(createTiles({type: "any", value: 7, amount: 1}))

		newArr.push(createTiles({type: "any", value: 1, amount: 1}))
		newArr.push(createTiles({type: "any", value: 8, amount: 1}))

		newArr.push(createTiles({type: "any", value: 1, amount: 1}))
		newArr.push(createTiles({type: "any", value: 9, amount: 1}))

		newArr.push(createTiles({type: "any", value: 2, amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))

		newArr.push(createTiles({type: "any", value: 2, amount: 1}))
		newArr.push(createTiles({type: "any", value: 1, amount: 1}))

		newArr.push(createTiles({type: "any", value: 2, amount: 1}))
		newArr.push(createTiles({type: "any", value: 2, amount: 1}))

		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "Out Run"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

