const Card = require("../Card.js")

class ImplementedCard extends Card {
	name = "2022 Marvelous Mah Jongg"

	generateHands() {
		let output = []
	
		output.push(...require("./evenly-striped.js"))
		output.push(...require("./circus-oddity.js"))
		output.push(...require("./atomic.js"))
		output.push(...require("./sub-atomic.js"))
		output.push(...require("./animal-magnetism.js"))
		output.push(...require("./nine-lives.js"))
		output.push(...require("./out-numbered.js"))
		output.push(...require("./out-run.js"))
		output.push(...require("./it's-a-jungle-in-here.js"))
		output.push(...require("./crak-the-whip.js"))
	
		return output
	}
}

module.exports = new ImplementedCard()