const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//NNNN EEE WWW SSSS or NNN EEEE WWWW SSS
	function(tiles = []) {
		;[0].forEach((num) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "wind", value: "north", amount: 4}))
			newArr.push(createTiles({type: "wind", value: "east", amount: 3}))
			newArr.push(createTiles({type: "wind", value: "west", amount: 3}))
			newArr.push(createTiles({type: "wind", value: "south", amount: 4}))
		})

		;[0].forEach((num) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "wind", value: "north", amount: 3}))
			newArr.push(createTiles({type: "wind", value: "east", amount: 4}))
			newArr.push(createTiles({type: "wind", value: "west", amount: 4}))
			newArr.push(createTiles({type: "wind", value: "south", amount: 3}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FFFF DDD DDDD DDD
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "flower", amount: 4}))
			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[0]], amount: 3}))
			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 4}))
			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[2]], amount: 3}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//NNN SSS 1111 2222 or EEE WWW 1111 2222 (Any 2 Suits)
	function(tiles = []) {
		allOptions.slice(0, -1).forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "wind", value: "north", amount: 3}))
				newArr.push(createTiles({type: "wind", value: "south", amount: 3}))

				newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: 4}))
				newArr.push(createTiles({type: suitOrder[1], value: 1+offset, amount: 4}))
			})
		})


		allOptions.slice(0, -1).forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "wind", value: "east", amount: 3}))
				newArr.push(createTiles({type: "wind", value: "west", amount: 3}))

				newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: 4}))
				newArr.push(createTiles({type: suitOrder[1], value: 1+offset, amount: 4}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FF NN EEE WWW SSSS 
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)

		newArr.push(createTiles({type: "flower", amount: 2}))

			newArr.push(createTiles({type: "wind", value: "north", amount: 2}))
			newArr.push(createTiles({type: "wind", value: "east", amount: 3}))
			newArr.push(createTiles({type: "wind", value: "west", amount: 3}))
			newArr.push(createTiles({type: "wind", value: "south", amount: 4}))


		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//NNNN 11 22 33 SSSS or EEEE 11 22 33 WWWW - 1 Suit consec
	function(tiles = []) {
		allSuits.forEach((suit => {
			allOptions.slice(0, -2).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "wind", value: "north", amount: 4}))

				newArr.push(createTiles({type: suit, value: 0+offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 1+offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 2+offset, amount: 2}))
			
				newArr.push(createTiles({type: "wind", value: "south", amount: 4}))
			})

			allOptions.slice(0, -2).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "wind", value: "east", amount: 4}))

				newArr.push(createTiles({type: suit, value: 0+offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 1+offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 2+offset, amount: 2}))
			
				newArr.push(createTiles({type: "wind", value: "west", amount: 4}))
			})
		}))

		return {
			tiles,
			score: 30,
			concealed: false
		}
	},
	//FF DDDD NEWS DDDD
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "flower", amount: 2}))

			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[0]], amount: 4}))

			newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
			newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
			newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
			newArr.push(createTiles({type: "wind", value: "south", amount: 1}))

			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 4}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//NNN E W SSS 111 111 (consec nums, 2 suits)
	function(tiles = []) {
		allOptions.forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "wind", value: "north", amount: 3}))

				newArr.push(createTiles({type: "wind", value: "east", amount: 1}))

				newArr.push(createTiles({type: "wind", value: "west", amount: 1}))

				newArr.push(createTiles({type: "wind", value: "south", amount: 3}))

				newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: 0+offset, amount: 3}))
			})
		})

		return {
			tiles,
			score: 30,
			concealed: true
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "Winds and Dragons"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

