import {Capacitor} from "@capacitor/core";

let admobIds;

if (Capacitor.getPlatform() === "android") {
    //Android
    admobIds = {
        newGameInterstitial: "ca-app-pub-8612551580236582/2319168272",
        bottomBanner: "ca-app-pub-8612551580236582/3140580641",
        topBanner: "ca-app-pub-8612551580236582/7134530508",
    }
} 
else {
    //iOS
    admobIds = {
        newGameInterstitial: "ca-app-pub-8612551580236582/2590631014",
        bottomBanner: "ca-app-pub-8612551580236582/7103876388",
        topBanner: "ca-app-pub-8612551580236582/6373765561",
    }
}

export default admobIds;