import {FirebaseAuthentication} from "@capacitor-firebase/authentication"

import {
    Display_Templates,
    CallbackBindingsInterface,
    injectDefaultStyles,
    Initialize_UI,
    SignInOptionDisplay
} from "ui-for-firebase-authentication"
injectDefaultStyles()

interface M4FAuthProvider {
    providerName: string,
    scopes?: string[]
}

let providers: { provider: M4FAuthProvider | string; display: SignInOptionDisplay }[] = [
    {
        provider: {
            providerName: "Google",
            scopes: ["https://www.googleapis.com/auth/userinfo.email"]
        },
        display: Display_Templates.Google_Light
    },
    {
        provider: {
            providerName: "Apple",
            scopes: ["email"]
        },
        display: Display_Templates.Apple_Dark
    },
    {
        provider: "email",
        display: Display_Templates.Email_Dark
    },
]


//Bindings for FirebaseAuthentication
let bindings: CallbackBindingsInterface = {
    signInWithEmailPassword: function(email: string, password: string) {
        return FirebaseAuthentication.signInWithEmailAndPassword({email, password})
    },
    createUserWithEmailPassword: function(email: string, password: string) {
        return FirebaseAuthentication.createUserWithEmailAndPassword({email, password})
    },
    sendPasswordResetEmail: function(email: string) {
        return FirebaseAuthentication.sendPasswordResetEmail({email})
    },
    fetchSignInMethodsForEmail: async function(email: string) {
        let res = await FirebaseAuthentication.fetchSignInMethodsForEmail({email})
        return res.signInMethods
    },
    signInWithProvider: function(provider: M4FAuthProvider) {
        if (provider.providerName === "Google") {
            return FirebaseAuthentication.signInWithGoogle({scopes: provider.scopes})
        }
        else if (provider.providerName === "Apple") {
            return FirebaseAuthentication.signInWithApple({scopes: provider.scopes})
        }
        else {
            throw new Error(`Provider not yet implemented: ${provider.providerName}`)
        }
    }
}


module.exports = {
  initializeSignInUI: function(elem: HTMLElement) {
    Initialize_UI(bindings, providers, elem)
  }
}