import {Capacitor} from "@capacitor/core";
import {InstallerInfoPlugin} from "capacitor-installer-info";

import {PUBLIC_AMAZON_API_KEY, PUBLIC_APPLE_API_KEY, PUBLIC_GOOGLE_API_KEY, PUBLIC_REVENUECATBILLING_API_KEY, SANDBOX_PUBLIC_REVENUECATBILLING_API_KEY} from "../../../lib/monetization_constants";

function getAPIKeyForStore(store: string) {
  switch (store) {
    case "Amazon":
      return PUBLIC_AMAZON_API_KEY;
    case "Apple":
      return PUBLIC_APPLE_API_KEY;
    case "Google":
      return PUBLIC_GOOGLE_API_KEY;
    case "Web":
      // return SANDBOX_PUBLIC_REVENUECATBILLING_API_KEY
      return PUBLIC_REVENUECATBILLING_API_KEY;
    default:
      throw new Error(`Unknown store: ${store}`);
  }
}

async function getStoreToUse() {
  if (Capacitor.getPlatform() === "ios") {
    //Use App Store billing on iOS.
    return "Apple"
  }
  if (Capacitor.getPlatform() === "android") {
    try {
      //On Android, if we can determine the store we were installed from, we will use its native billing.
      let {installingPackageName} = await InstallerInfoPlugin.getInstallSourceInfo({})

      if (installingPackageName.startsWith("com.amazon")) {
        return "Amazon"
      }
      else if (installingPackageName == "com.android.vending") {
        return "Google"
      }
    }
    catch (e) {
      console.error(e)
    }
  }
  //Fallback to web if native billing not available.
  return "Web"
}


export {getAPIKeyForStore, getStoreToUse}
