const Tile = require("../Tile")
const Match = require("../Match")
const Sequence = require("../Sequence")
const Pretty = require("../Pretty")
const Wall = require("../Wall")
const TileContainer = require("../TileContainer")
const calculateJokerAmount = require("./calculateJokerAmount.js")
const {i18n} = require("../i18nHelper.js")

class Hand {
	constructor(config = {}) {
		this.wind = config.wind

		this.contents = [] //Contents of hand.
		this.inPlacemat = [] //Additional contents of hand. In placemat.

		this.syncContents = (require("./syncContents.js")).bind(this)
		this.sync = (function(hand, addAdditionsToPlacematIfOpen) {
			//Used to sync from server.
			this.syncContents(hand.contents, addAdditionsToPlacematIfOpen)
			this.wind = hand.wind
			this.status = hand.status
		}).bind(this)

		this.score = (require("./score.js")).bind(this)
		this.getClearHandInfo = (require("./getClearHandInfo.js")).bind(this)
		this.isMahjong = (require("./isMahjong.js")).bind(this)
		this.isCalling = (require("./isCalling.js")).bind(this)
		this.calculateJokerAmount = (function(...args) {
			return calculateJokerAmount(this.contents, ...args)
		}).bind(this)
	}


	add(obj) {
		//We will insert the tile where our sorting algorithm would find it most appropriate.
		//TODO: this should probably receive some improvement, as if the user changes the location of suits, or puts, say honors first, it will fail to properly insert.

		//This detection code must not crash on either server or client!
		if (!globalThis?.settings?.autoSortTiles?.value) {
			//TODO: In Chinese mahjong mode, we need to sort Pretty tiles to the beginning unconditionally 
			//to ensure they are kept together rather than split up by pongs, etc. 
			return this.contents.push(obj)
		}

		let newItemScore;
		if (obj instanceof Sequence) {
			newItemScore = obj.tiles[0].getTileValue() //Use value of first tile in sequence.
		}
		else if (obj instanceof TileContainer) {
			//Same as sequence for now - use first tile.
			newItemScore = obj.tiles[0].getTileValue() //Use value of first tile in TileContainer.
		}
		else {
			newItemScore = obj.getTileValue()
		}

		for (let i=0;i<this.contents.length;i++) {
			//Find where to insert this tile.
			let currentItem = this.contents[i]
			if (currentItem instanceof Sequence) {
				//Not quite sure how to handle this.
				currentItem = currentItem.tiles[2] //Get the value using the last tile in sequence.
			}
			if (currentItem instanceof TileContainer) {
				//Use the first tile in container.
				currentItem = currentItem.tiles[0]
			}
			let currentScore = currentItem.getTileValue() //Value of the tile in that position

			if (newItemScore < currentScore) {
				this.contents.splice(i, 0, obj)
				return
			}
		}
		this.contents.push(obj)
	}

	remove(obj) {
		let index = this.contents.indexOf(obj)
		let placematIndex = this.inPlacemat.indexOf(obj)
		if (index !== -1) {
			this.contents.splice(index, 1)
		}
		else if (placematIndex !== -1) {
			this.inPlacemat.splice(placematIndex, 1)
		}
		else {throw obj + " does not exist in hand. "}
	}


	moveTile(tile, switchPlace = true, targetPosition) {
		//Tile is the object in either the hand or placemat.
		//targetPosition is the position to the left of where we want to move this tile.

		let placematIndex = this.inPlacemat.indexOf(tile)
		let contentsIndex = this.contents.indexOf(tile)

		console.log(targetPosition)

		if (placematIndex + contentsIndex === -2) {
			console.error("Tile does not exist. ")
			return
		}

		let target = [this.inPlacemat, this.contents];
		if (switchPlace) {
			if (placematIndex === -1) {
				//Moving from hand to placemat.
				if (this.inPlacemat.length >= this.placematLength) {
					alert(i18n.__("Placemat is already full. "))
					return
				}
				else {
					this.inPlacemat.push(this.contents.splice(contentsIndex, 1)[0])
				}
			}
			else {
				//Moving from placemat to hand.
				if (placematIndex === 0 && this.inPlacemat[0].evicting) {
					alert(i18n.localizeMessage({format: ["This tile was discarded. ", "To claim it, select the tiles you would like to match with it, then hit proceed. "]}))
					return;
				}
				let currentTile = this.inPlacemat.splice(placematIndex, 1)[0]
				if (!isNaN(targetPosition)) {
					//Moving to specfic place in hand.
					this.contents.splice(targetPosition, 0, currentTile)
				}
				else {
					//Add with auto sort.
					this.add(currentTile)
				}
			}
		}
		else if (!isNaN(targetPosition)) {
			if (contentsIndex === -1) {
				console.error("Reordering in placemat is not supported. Must be in hand.")
			}
			else {
				if (targetPosition > contentsIndex) {targetPosition--} //Compensate for the splice.

				this.contents.splice(targetPosition, 0, this.contents.splice(contentsIndex, 1)[0])
			}
		}
		else {console.error("Unable to determine how this tile should be moved. ")}
	}

	removeMatchingTile(obj) {
		//Removes a Tile that matches the object passed, although may not be the same object.

		if (!obj instanceof Tile) {throw "removeMatchingTile only supports Tiles"}
		if (this.inPlacemat.length > 0) {console.warn("Hand.removeMatchingTile is intended for server side use only. ")}
		if (this.contents.some(((item, index) => {
			if (obj.matches(item)) {
				this.contents.splice(index, 1)
				return true
			}
			return false
		}).bind(this))) {return true}
		return false
	}

	getExposedTiles(includeFaceDown = false) {
		let exposedTiles = []
		this.contents.forEach((item) => {
			if (item.exposed) {
				exposedTiles.push(item)
			}
			else if (item instanceof Match && item.amount === 4) {
				//If it is stored as a match, but not exposed, is in hand kong.
				//Is not stored as a match if the user never placed them down
				exposedTiles.push(item)
			}
			else if (includeFaceDown) {
				exposedTiles.push(Tile.get({faceDown: true}))
			}
		})
		return exposedTiles
	}

	removeMatchingTilesFromHand(obj, amount = 1, simulated = false) {
		if (!obj instanceof Tile) {throw "You must send a tile. "}
		return this.removeTilesFromHand(new Array(amount).fill(obj), simulated)
	}

	removeTilesFromHand(tiles, simulated = false) {
		if (tiles instanceof Sequence || tiles instanceof TileContainer) {tiles = tiles.tiles}
		if (tiles instanceof Tile) {tiles = [tiles]}
		else if (!tiles instanceof Array) {throw "Must send a Sequence, Tile, or Array. "}

		//We will verify that the tiles CAN be removed before removing them.
		let indexes = []
		tiles.forEach((tile, index) => {
			if (!(tile instanceof Tile)) {throw "Your Sequence or Array contains non-tiles. "}

			for (let i=this.contents.length-1;i>=0;i--) {
				let matchResult = tile.matches(this.contents[i])

				if (!indexes.includes(i)) {
					if (matchResult === 2) {
						//Non-exact match (like a 1 flower vs 4 season - same in gameplay, not visually)
						//We will prefer exact matches, but accept non exact ones.
						indexes[index] = i
					}
					else if (matchResult) {
						indexes[index] = i
						return
					}
				}
			}
		})

		let allDefined = true
		for (let i=0;i<tiles.length;i++) {
			if (indexes[i] === undefined) {
				allDefined = false
			}
		}
		if (allDefined) {
			if (simulated) {return true}
			//Remove the item the farthest back in the hand to avoid position shifting.
			indexes.sort((a,b) => {return b-a}).forEach((index) => {
				this.contents.splice(index, 1)
			})
			return true
		}
		else {return false}
	}

	setEvictingThrownTile(tile) {
		//Clear the other evicting tile, even if it's position has moved due to some glitch or user hacking.
		for (let i=this.inPlacemat.length - 1;i>=0;i--) {
			let item = this.inPlacemat[i]
			if (item.evicting) {
				this.inPlacemat.splice(i, 1)
			}
		}
		if (tile) {
			if (this.inPlacemat.length >= this.placematLength) {
				this.contents.push(this.inPlacemat.pop())
			}
			this.inPlacemat.unshift(tile)
			tile.evicting = true
		}
	}

	getStringContents(prop = "contents") {
		//Can also pass "inPlacemat" for placemat contents.
		return this[prop].map((item) => {return item.toJSON()})
	}

	toJSON() {
		return JSON.stringify({
			wind: this.wind,
			contents: this.contents,
			status: this.status
		})
	}

	static sortTiles(tiles) {
		return tiles.sort(function (tile1, tile2) {
			return tile1.getTileValue() - tile2.getTileValue()
		})
	}

	static convertStringsToTiles(arr) {
		//arr is an array, with the stringified contents of the hand.
		let contents = arr.map((itemStr) => {
			let obj = JSON.parse(itemStr)
			if (obj.class === "Pretty") {
				return Pretty.fromJSON(itemStr)
			}
			else if (obj.class === "Tile") {
				return Tile.fromJSON(itemStr)
			}
			else if (obj.class === "Sequence") {
				return Sequence.fromJSON(itemStr)
			}
			else if (obj.class === "TileContainer") {
				return TileContainer.fromJSON(itemStr)
			}
			else if (obj.class === "Match") {
				return Match.fromJSON(itemStr)
			}
			else {throw "Unable to identify itemString " + itemStr}
		})
		return contents
	}

	static fromString(str) {
		//Hand.fromString is only meant to be used on the server side. Therefore, it will not attempt to carry over any functionality that would be client side.
		let obj = JSON.parse(str)
		let wind = obj.wind

		let hand = new Hand({wind: obj.wind})
		hand.status = obj.status
		hand.contents = Hand.convertStringsToTiles(obj.contents)
		return hand
	}
}

module.exports = Hand
