const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
    //S PPP I KKKK E 2AIL (P any Pung, I=1, K any Kong; A any tile, L=7) - 35 Points
    function(tiles = []) {
        evenOptions.forEach((option) => {
            
            let newArr = []
            tiles.push(newArr)

            newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
            
            newArr.push(createTiles({type: "any", value: "any", amount: 3}))
            
            newArr.push(createTiles({type: "any", value: 1, amount: 1}))
            
            newArr.push(createTiles({type: "any", value: "any", amount: 4}))
            
            newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
            
            newArr.push(createTiles({type: "any", value: 2, amount: 1}))
            newArr.push(createTiles({type: "any", value: "any", amount: 1}))
            newArr.push(createTiles({type: "any", value: 1, amount: 1}))
            newArr.push(createTiles({type: "any", value: 7, amount: 1}))
        })
        
        return {
            tiles,
            score: 35,
            concealed: false,
        }
    },
    //A DDD R AA GGG O NNN (A any tile; D any D Pung, AA any Pr.) - 35 Points
    function(tiles = []) {
        evenOptions.forEach((option) => {
            
            let newArr = []
            tiles.push(newArr)

            newArr.push(createTiles({type: "any", value: "any", amount: 1}))

            newArr.push(createTiles({type: "dragon", value: "any", amount: 3}))
            
            newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))

            newArr.push(createTiles({type: "any", value: "any", amount: 2}))

            newArr.push(createTiles({type: "dragon", value: "green", amount: 3}))

            newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))

            newArr.push(createTiles({type: "wind", value: "north", amount: 3}))
        })
        
        return {
            tiles,
            score: 35,
            concealed: false,
        }
    },
    //RE PPP 222 ILI AA N (I=1, L=7, AA any Pr.) - 35
    function(tiles = []) {
        evenOptions.forEach((option) => {
            
            let newArr = []
            tiles.push(newArr)

            //RE
            newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
            newArr.push(createTiles({type: "wind", value: "east", amount: 1}))

            //PPP
            newArr.push(createTiles({type: "any", value: "any", amount: 3}))

            //222
            newArr.push(createTiles({type: "any", value: 2, amount: 3}))

            //ILI
            newArr.push(createTiles({type: "any", value: 1, amount: 1}))
            newArr.push(createTiles({type: "any", value: 7, amount: 1}))
            newArr.push(createTiles({type: "any", value: 1, amount: 1}))

            //AA
            newArr.push(createTiles({type: "any", value: "any", amount: 2}))

            //N
            newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
        })
        
        return {
            tiles,
            score: 35,
            concealed: false,
        }
    },
    //A MAGIC S PPP E LLL (A any tile; M=W, I=1, C any Crak; P any Pung, L any 7 Pung) - 35 Points
    function(tiles = []) {
        evenOptions.forEach((option) => {
            
            let newArr = []
            tiles.push(newArr)

            //A
            newArr.push(createTiles({type: "any", value: "any", amount: 1}))

            //MAGIC
            newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
            newArr.push(createTiles({type: "any", value: "any", amount: 1}))
            newArr.push(createTiles({type: "dragon", value: "green", amount: 1}))
            newArr.push(createTiles({type: "any", value: 1, amount: 1}))
            newArr.push(createTiles({type: "character", value: "any", amount: 1}))

            //S
            newArr.push(createTiles({type: "wind", value: "south", amount: 1}))

            //PPP
            newArr.push(createTiles({type: "any", value: "any", amount: 3}))

            //E
            newArr.push(createTiles({type: "wind", value: "east", amount: 1}))

            //LLL
            newArr.push(createTiles({type: "any", value: 7, amount: 3}))
        })
        
        return {
            tiles,
            score: 35,
            concealed: false,
        }
    },
    //WINGS AN FFFF IRE (I=1; A any tile) - 35 Points
    function(tiles = []) {
        evenOptions.forEach((option) => {
            
            let newArr = []
            tiles.push(newArr)

            //WINGS
            newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
            newArr.push(createTiles({type: "any", value: 1, amount: 1}))
            newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
            newArr.push(createTiles({type: "dragon", value: "green", amount: 1}))
            newArr.push(createTiles({type: "wind", value: "south", amount: 1}))

            //AN
            newArr.push(createTiles({type: "any", value: "any", amount: 1}))
            newArr.push(createTiles({type: "wind", value: "north", amount: 1}))

            //FFFF
            newArr.push(createTiles({type: "flower", amount: 4}))

            //IRE
            newArr.push(createTiles({type: "any", value: 1, amount: 1}))
            newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
            newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
        })
        
        return {
            tiles,
            score: 35,
            concealed: false,
        }
    },
    //W II N GGG S PPP AA N (I any 1 Pr.; P any Pung, AA any Pr.) - 35 Points
    function(tiles = []) {
        evenOptions.forEach((option) => {
            
            let newArr = []
            tiles.push(newArr)

            //W
            newArr.push(createTiles({type: "wind", value: "west", amount: 1}))

            //II
            newArr.push(createTiles({type: "any", value: 1, amount: 2}))

            //N
            newArr.push(createTiles({type: "wind", value: "north", amount: 1}))

            //GGG
            newArr.push(createTiles({type: "dragon", value: "green", amount: 3}))

            //S
            newArr.push(createTiles({type: "wind", value: "south", amount: 1}))

            //PPP
            newArr.push(createTiles({type: "any", value: "any", amount: 3}))

            //AA
            newArr.push(createTiles({type: "any", value: "any", amount: 2}))

            //N
            newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
        })
        
        return {
            tiles,
            score: 35,
            concealed: false,
        }
    },
    //JM PPP ON BAC KKKK (J=Joker, M=W, P any Pung; B any Bam, A any tile, C any Crak, K any Kong) - 40 Points
    function(tiles = []) {
        evenOptions.forEach((option) => {
            
            let newArr = []
            tiles.push(newArr)

            //JM
            newArr.push(createTiles({type: "joker", amount: 1}))
            newArr.push(createTiles({type: "wind", value: "west", amount: 1}))

            //PPP
            newArr.push(createTiles({type: "any", value: "any", amount: 3}))

            //ON
            newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
            newArr.push(createTiles({type: "wind", value: "north", amount: 1}))

            //BAC
            newArr.push(createTiles({type: "bamboo", value: "any", amount: 1}))
            newArr.push(createTiles({type: "any", value: "any", amount: 1}))
            newArr.push(createTiles({type: "character", value: "any", amount: 1}))

            //KKKK
            newArr.push(createTiles({type: "any", value: "any", amount: 4}))
        })
        
        return {
            tiles,
            score: 40,
            concealed: false,
        }
    },
].map((func, index) => {
    let output = func()
    output.section = "Flights of Fantasy"
    output.sectionIndex = index + 1 //Sections indexed starting at 1. 
    output.handName = `${output.section} #${output.sectionIndex}`
    return new CardHand(output)
})

