//This is a purely client-side code file. 

//The TileSet class will load a tileset, and will be called to obtain the image for a specific type of tile. 
//It will cache internally. 

const minimumDimension = 256 //All images will be scaled up to ensure they hit the minimum dimensions in width and height. 

class TileSet {
    //IDs for the tilesets. 
    borderID: string;
    centerID: string;
    decorationID: string;
    
    //Cache structure:
    //Map keys are the different categories ("flower", "bamboo", etc). 
    //Each value is another map, mapping from the specific tile "red", "1", "any", etc to the image url. 
    //For tiles without a value (like jokers), an empty string is used as the default. 
    cache: Record<string, Record<string, string>> = Object.create(null);
    
    //Insets for the border (from the info.json for the border). 
    //{"front": {"top": 0, "left": 0, "right": 0, "bottom": 0}, "back": {"top": 0, "left": 0, "right": 0, "bottom": 0}}
    borderInfo: Record<string, Record<string, number>> = null;
    
    //Paths for the center tiles (from info.json for the centers chosen).
    centerInfo: Record<string, Record<string, string>> = null;
    
    //Instance variables for the different faces. 
    decorationCanvas: HTMLCanvasElement;
    tileFrontCanvas: HTMLCanvasElement;
    tileBackCanvas: HTMLCanvasElement;
    tileBackSrc: string;
    transparentTileSrc: string;
    
    
    constructor(borderID: string, centerID: string, decorationID: string) {
        if (!borderID) {throw "Must provide borderID"}
        if (!centerID) {throw "Must provide centerID"}
        
        this.borderID = borderID
        this.centerID = centerID
        this.decorationID = decorationID
    }
    
    //Loads the decoration image and canvas for the current decorationID. 
    async loadDecoration() {
        if (this.decorationID) {
            //We can load the decoration directly. 
            let decorationImage = await loadImage(`assets/tile/decoration/${this.decorationID}.svg`)
            this.decorationCanvas = imageToCanvas(decorationImage)
        }
        else {
            this.decorationCanvas = null
        }
    }
    
    //Loads the tile front image and canvas for the current borderID.
    async loadBorderFront() {
        let tileFrontImage = await loadImage(`assets/tile/border/${this.borderID}/front.${this.borderInfo.front.type}`)
        this.tileFrontCanvas = imageToCanvas(tileFrontImage)
    }
    
    //Loads the tile back image and canvas for the current borderID.
    async loadBorderBack() {
        let tileBackImage = await loadImage(`assets/tile/border/${this.borderID}/back.${this.borderInfo.back.type}`)
        
        //If decorationCanvas is undefined, nothing will be drawn (but, the empty background will still be scaled like other tiles)
        this.tileBackCanvas = await drawImageIntoBoundaries(tileBackImage, this.decorationCanvas, this.borderInfo.back)            
        this.tileBackSrc = await getPNGBlobURL(this.tileBackCanvas)
    }
    
    //Create a transparent version of this.tileFrontCanvas
    async loadTransparentTileSrc() {
        let cnv = document.createElement("canvas")
        cnv.width = this.tileFrontCanvas.width
        cnv.height = this.tileFrontCanvas.height
        
        this.transparentTileSrc = await getPNGBlobURL(cnv)
    }
    
    
    writeTileIntoCache(category: string, value = "", data: string) {
        //Value is like the value in the category - so the 9 in 9 bamboo. 
        //Data is the blob url to the image. 
        
        if (!this.cache[category]) {
            this.cache[category] = Object.create(null)
        }
        
        let categoryMap = this.cache[category]
        if (categoryMap[value]) {
            //Existing item for this value in cache - should not happen, but handle if it does. 
            console.warn("Overwriting existing entry for ", category, value)
            URL.revokeObjectURL(categoryMap[value])
        }
        
        categoryMap[value] = data
    }
    
    async loadTileIntoCache(category: string, value: string = "") {
        //Loads tile from network and places an entry into cache. 
        //Requires border and info to be loaded
        
        let path = `assets/tile/center/${this.centerInfo[category][value]}`
        let centerImage = await loadImage(path)
        
        let url = await getPNGBlobURL(await drawImageIntoBoundaries(this.tileFrontCanvas, centerImage, this.borderInfo.front))
        URL.revokeObjectURL(centerImage.src)
        
        this.writeTileIntoCache(category, value, url)
    }
    
    //Obtains a tile src from cache. DOES NOT check to see if it was loaded into cache. 
    getTileSrc(category: string, value: string = "") {
        if (category == "face-down") {
            return this.tileBackSrc
        }
        
        let categoryMap: Record<string, string>;
        let tileUrl: string;
        
        try {
            categoryMap = this.cache[category]
            tileUrl = categoryMap[value]
        }
        catch (e) {
            console.error(e, category, value) //If this function errors it is catastrophic, but undefined is OK (not ideal, of course)
        }
        
        return tileUrl
    }
    
    async initiate(sourceTileset: TileSet) {
        //Initiates entire tileset. 
        //sourceTileset is optional, and used to transfer already computed stuff from an existing TileSet to this one to reduce processing times. 
        
        //Changing borderID requires redoing all tiles. Changing centerID requires changing ONLY centers that have changed. Changing decoration ID requires changing back of tile. 
        let decorationIDSame = sourceTileset?.decorationID === this.decorationID
        let borderIDSame = sourceTileset?.borderID === this.borderID
        let centerIDSame = sourceTileset?.centerID === this.centerID
                
        let deocrationLoadPromise = new Promise<void>((resolve, reject) => {
            if (decorationIDSame && sourceTileset.decorationCanvas) {
                this.decorationCanvas = sourceTileset.decorationCanvas
                resolve()
            }
            else {
                this.loadDecoration().then(resolve, reject)
            }
        })
        
        let borderInfoLoadPromise = new Promise<void>((resolve, reject) => {
            if (borderIDSame && sourceTileset.borderInfo) {
                this.borderInfo = sourceTileset.borderInfo
                resolve()
            }
            else {
                loadMetadata(`assets/tile/border/${this.borderID}/info.json`).then((metadata) => {
                    this.borderInfo = metadata
                    resolve()
                }, reject)
            }
        })
        
        let centerInfoLoadPromise = new Promise<void>((resolve, reject) => {
            if (centerIDSame && sourceTileset.centerInfo) {
                this.centerInfo = sourceTileset.centerInfo
                resolve()
            }
            else {
                loadMetadata(`assets/tile/center/${this.centerID}.json`).then((metadata) => {
                    this.centerInfo = metadata
                    resolve()
                }, reject)
            }
        })
        
        await Promise.all([borderInfoLoadPromise, centerInfoLoadPromise])
        
        let promises = []
        
        //Now load the border. Border front is blocking, border back is not (though border back is blocked on decorations). 
        if (borderIDSame && sourceTileset.tileFrontCanvas && sourceTileset.transparentTileSrc) {
            this.tileFrontCanvas = sourceTileset.tileFrontCanvas
            this.transparentTileSrc = sourceTileset.transparentTileSrc
            
            if (decorationIDSame && sourceTileset.tileBackCanvas) {
                this.tileBackCanvas = sourceTileset.tileBackCanvas
                this.tileBackSrc = sourceTileset.tileBackSrc
            }
            else {
                promises.push(deocrationLoadPromise.then(this.loadBorderBack.bind(this)))
            }
        }
        else {
            promises.push(deocrationLoadPromise.then(this.loadBorderBack.bind(this)))
            await this.loadBorderFront() //Loading the border front is blocking. 
            promises.push(this.loadTransparentTileSrc())
        }
        
        //Now we can proceed with loading the tile centers. 
        //If borderID is different, we must reload all. 
        //If borderID is same, we must only change if the asset pointed to by the tile center package has changed. 
        
        //Ensures entire tileset in cache. 
        
        for (let category of Object.keys(this.centerInfo)) {
            let categoryObj = this.centerInfo[category]
            
            for (let value of Object.keys(categoryObj)) {
                let sameCenterPath = this.centerInfo[category][value] === sourceTileset?.centerInfo?.[category]?.[value]
                
                if (borderIDSame && sameCenterPath && sourceTileset?.cache?.[category]?.[value]) {
                    this.writeTileIntoCache(category, value, sourceTileset?.cache?.[category]?.[value])
                }
                else {
                    promises.push(this.loadTileIntoCache(category, value))
                }
            }
        }
        
        await Promise.all(promises)
    }
}

//Returns a blob URL. 
async function drawImageIntoBoundaries(outerImage: HTMLImageElement | HTMLCanvasElement, innerImage: HTMLImageElement | HTMLCanvasElement | undefined, drawArea: Record<string, number>): Promise<HTMLCanvasElement> {
    let tileImage = document.createElement("canvas")
    tileImage.width = outerImage.width
    tileImage.height = outerImage.height
    
    //Scale up until at least one dimension reaches minimumDimension. 
    let scaleFactor = 1
    while (outerImage.width < minimumDimension / scaleFactor && outerImage.height < minimumDimension / scaleFactor) {
        scaleFactor += 1
    }

    tileImage.width *= scaleFactor
    tileImage.height *= scaleFactor
    
    let ctx = tileImage.getContext("2d")
    ctx.drawImage(outerImage, 0, 0, tileImage.width, tileImage.height)

    if (!innerImage) {return tileImage} //It is totally allowable to use this function merely to scale the other image. 
    
    //TileImage is now the scaled border image.
    //Scale drawArea bounds:
    let scaledBounds: Record<string, number> = {}
    for (let key in drawArea) {
        scaledBounds[key] = drawArea[key]// * scaleFactor
    }
    
    //Now we want to draw the center as large as possible, centered in the draw area. 
    
    //Calculate the maximum width and height. 
    let drawableWidth = tileImage.width - scaledBounds.left - scaledBounds.right
    let drawableHeight = tileImage.height - scaledBounds.top - scaledBounds.bottom
    
    let drawableRatio = drawableHeight / drawableWidth
    let innerRatio = innerImage.height / innerImage.width
    
    let usableRatio = Math.min(drawableRatio, innerRatio)
    let usableHeight = drawableWidth * usableRatio
    let usableWidth = drawableWidth
    
    
    //Now center the image. 
    //Evenly distribute the unused width and height alongside the margins
    
    let spareWidth = (drawableWidth - usableWidth)
    let marginLeft = scaledBounds.left + spareWidth / 2 
    
    let spareHeight = (drawableHeight - usableHeight)
    
    //NOTE: topExpansion property is extra area that should only be used if necessary. 
    //We will move image down to avoid using topExpansion
    
    let marginTop = scaledBounds.top + spareHeight / 2 + Math.min(scaledBounds.topExpansion || 0, spareHeight / 2)
    
    ctx.drawImage(innerImage, marginLeft, marginTop, usableWidth, usableHeight)
    return tileImage
}


async function loadMetadata(src: string): Promise<Record<string, any>> {
    let response = await fetch(src)
    return await response.json()
}

function loadImage(src: string) {
    let img = document.createElement("img")
    return new Promise<HTMLImageElement>((resolve, reject) =>{
        img.onload = () => {resolve(img)}
        img.onerror = reject
        img.src = src
    })
}

function imageToCanvas(img: HTMLImageElement) {
    let cnv = document.createElement("canvas")
    cnv.width = img.width
    cnv.height = img.height
    let ctx = cnv.getContext("2d")
    ctx.drawImage(img, 0, 0)
    return cnv
}

//TODO: We seem to be spending ~450ms in getPNGBlobURL. Perhaps it would be possible for us to somehow optimize this by handling the image processing
//in a web worker, though I'd be concerned about the startup time. 
function getPNGBlobURL(canvas: HTMLCanvasElement) {
    return new Promise<string>((resolve, reject) => {
        canvas.toBlob((blob) => {
            resolve(URL.createObjectURL(blob))
        }, "image/png") //TOD): As of April 2024, webp appears to be faster here in Chrome, same speed in Firefox, and Safari just returns a PNG (per spec, default is PNG if unspecified or unsupported)
    })
}

module.exports = TileSet