const Card = require("../Card.js")

class ImplementedCard extends Card {
	name = "2021 National Mah Jongg League"

	generateHands() {
		let output = []
	
		output = output.concat(require("./2021.js"))
		output = output.concat(require("./2468.js"))
		output = output.concat(require("./any-like-numbers.js"))
		output = output.concat(require("./quints.js"))
		output = output.concat(require("./consecutive-run.js"))
		output = output.concat(require("./13579.js"))
		output = output.concat(require("./winds-dragons.js"))
		output = output.concat(require("./369.js"))
		output = output.concat(require("./singles-and-pairs.js"))
	
		return output
	}
}

module.exports = new ImplementedCard()