const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//DDDDD NNNN 11111
	function(tiles = []) {
		allOptions.forEach((offset) => {
			let newArr = []
			tiles.push(newArr)
			newArr.push(createTiles({type: "dragon", value: "any", amount: 5}))
			newArr.push(createTiles({type: "wind", value: "any", amount: 4}))
			newArr.push(createTiles({type: "any", value: offset, amount: 5}))
		})

		return {
			tiles,
			score: 40,
			concealed: false
		}
	},
	//22 444 6666 88888 - 1 Suit
	function(tiles = []) {
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)
			newArr.push(createTiles({type: suit, value: 2, amount: 2}))
			newArr.push(createTiles({type: suit, value: 4, amount: 3}))
			newArr.push(createTiles({type: suit, value: 6, amount: 4}))
			newArr.push(createTiles({type: suit, value: 8, amount: 5}))
		})

		return {
			tiles,
			score: 40,
			concealed: false
		}
	},
	//(1122) 33333 33333 - 3 Suits, 3 consec numbers
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			allOptions.slice(0, -2).forEach((offset, i) => {
				let newArr = []
				tiles.push(newArr)
				newArr.push(createTiles({type: suitOrder[0], value: offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 1 + offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[1], value: 2 + offset, amount: 5}))
				newArr.push(createTiles({type: suitOrder[2], value: 2 + offset, amount: 5}))
			});
		})

		return {
			tiles,
			score: 45,
			concealed: false
		}
	},
	//FFFF 11111 22222 - 1 Suit, Consec Num
	function(tiles = []) {
		allSuits.forEach((suit) => {
			allOptions.slice(0, -1).forEach((offset, i) => {
				let newArr = []
				tiles.push(newArr)
				newArr.push(createTiles({type: "flower", amount: 4}))
				newArr.push(createTiles({type: suit, value: 0 + offset, amount: 5}))
				newArr.push(createTiles({type: suit, value: 1 + offset, amount: 5}))
			});
		})

		return {
			tiles,
			score: 45,
			concealed: false
		}
	}
].map((func, index) => {
	let output = func()
	output.section = "Quints"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

