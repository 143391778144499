import {Purchases, LOG_LEVEL} from '@revenuecat/purchases-capacitor'
import { getStoreToUse, getAPIKeyForStore } from "./revenuecat_utilities"

import {CUSTOMER_INFO_UPDATED_EVENT_NAME} from "./event_names";

Purchases.setLogLevel({level: LOG_LEVEL.DEBUG})

//When customer info is changed, dispatch an event to let the rest of the app know.
Purchases.addCustomerInfoUpdateListener((info) => {
  window.dispatchEvent(new Event(CUSTOMER_INFO_UPDATED_EVENT_NAME))
}).catch((e) => {
  console.error("Critical error registering customer info listener", e)
})

async function arePurchasesConfigured() {
  return (await Purchases.isConfigured()).isConfigured
}

function getPurchasesInstance() {
  return Purchases
}

async function purchasePackage(packageToBuy) {
  let res = await getPurchasesInstance().purchasePackage({aPackage: packageToBuy})

  //This shouldn't be necessary due to the addCustomerInfoUpdateListener, except that doesn't
  //appear to fire properly with Google Play Billing.
  window.dispatchEvent(new Event(CUSTOMER_INFO_UPDATED_EVENT_NAME))
  return res
}

async function configurePurchases(uid) {
  //Ensure RevenueCat is properly configured for the current platform.
  if (await arePurchasesConfigured()) {
    console.warn("configurePurchases called but Purchases already configured. Refusing to configure again. ")
    return
  }

  let storeToUse = await getStoreToUse()
  let apiKey = getAPIKeyForStore(storeToUse)
  await Purchases.configure({
    apiKey,
    appUserID: uid,
    useAmazon: storeToUse === "Amazon"
  });
}

async function setUserUid(uid) {
  console.log("Setting User UID", uid)
  if (await arePurchasesConfigured()) {
    //On native, we can log in or log out at any time.
    if (uid) {
      await getPurchasesInstance().logIn({appUserID: uid})
    }
    else {
      let isAnonymous = (await getPurchasesInstance().isAnonymous()).isAnonymous
      if (!isAnonymous) {
        await getPurchasesInstance().logOut()
      }
    }
  }
  else {
    await configurePurchases(uid)
  }
}


async function getCustomerInfo(){
  return (await getPurchasesInstance().getCustomerInfo()).customerInfo
}

export {purchasePackage, setUserUid, getPurchasesInstance, getCustomerInfo}
