const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//20 333 4444 555 23 
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			allOptions.slice(0, -2).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)
				

				newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))

				newArr.push(createTiles({type: suitOrder[1], value: offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: offset + 1, amount: 4}))
				newArr.push(createTiles({type: suitOrder[1], value: offset + 2, amount: 3}))

				newArr.push(createTiles({type: suitOrder[2], value: 2, amount: 1}))
				newArr.push(createTiles({type: suitOrder[2], value: 3, amount: 1}))
			});
		});

		return {
			tiles,
			score: 35,
			concealed: false
		}
	},
	//4444 00 1111 KKKK
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 4}))

				newArr.push(createTiles({type: "dragon", value: "white", amount: 2}))

				newArr.push(createTiles({type: suitOrder[1], value: 1, amount: 4}))

				newArr.push(createTiles({type: "any", value: "any", amount: 4}))
		})

		return {
			tiles,
			score: 35,
			concealed: false
		}
	},
	//37.5421 N 84.9655 W
	function(tiles = []) {
				let newArr = []
				tiles.push(newArr)



				newArr.push(createTiles({type: "any", value: 3, amount: 1}))
				newArr.push(createTiles({type: "any", value: 7, amount: 1}))
				newArr.push(createTiles({type: "any", value: 5, amount: 1}))
				newArr.push(createTiles({type: "any", value: 4, amount: 1}))
				newArr.push(createTiles({type: "any", value: 2, amount: 1}))
				newArr.push(createTiles({type: "any", value: 1, amount: 1}))

				newArr.push(createTiles({type: "wind", value: "north", amount: 1}))


				newArr.push(createTiles({type: "any", value: 8, amount: 1}))
				newArr.push(createTiles({type: "any", value: 4, amount: 1}))
				newArr.push(createTiles({type: "any", value: 9, amount: 1}))
				newArr.push(createTiles({type: "any", value: 6, amount: 1}))
				newArr.push(createTiles({type: "any", value: 5, amount: 1}))
				newArr.push(createTiles({type: "any", value: 5, amount: 1}))

				newArr.push(createTiles({type: "wind", value: "west", amount: 1}))


		return {
			tiles,
			score: 25,
			concealed: true
		}
	},
	//AA 1951 1963 1975
	function(tiles = []) {

		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
	
	
			newArr.push(createTiles({type: "any", value: "any", amount: 2}))

			newArr.push(createTiles({type: suitOrder[0], value: 1, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 5, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 1, amount: 1}))

			newArr.push(createTiles({type: suitOrder[1], value: 1, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 1}))

			newArr.push(createTiles({type: suitOrder[2], value: 1, amount: 1}))
			newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 1}))
			newArr.push(createTiles({type: suitOrder[2], value: 7, amount: 1}))
			newArr.push(createTiles({type: suitOrder[2], value: 5, amount: 1}))
		})

		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)
	
	
			newArr.push(createTiles({type: "any", value: "any", amount: 2}))

			newArr.push(createTiles({type: suit, value: 1, amount: 1}))
			newArr.push(createTiles({type: suit, value: 9, amount: 1}))
			newArr.push(createTiles({type: suit, value: 5, amount: 1}))
			newArr.push(createTiles({type: suit, value: 1, amount: 1}))

			newArr.push(createTiles({type: suit, value: 1, amount: 1}))
			newArr.push(createTiles({type: suit, value: 9, amount: 1}))
			newArr.push(createTiles({type: suit, value: 6, amount: 1}))
			newArr.push(createTiles({type: suit, value: 3, amount: 1}))

			newArr.push(createTiles({type: suit, value: 1, amount: 1}))
			newArr.push(createTiles({type: suit, value: 9, amount: 1}))
			newArr.push(createTiles({type: suit, value: 7, amount: 1}))
			newArr.push(createTiles({type: suit, value: 5, amount: 1}))
		})



return {
	tiles,
	score: 40,
	concealed: true
}
},
].map((func, index) => {
	let output = func()
	output.section = "ENERGISER"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

