const cards = require("../../../lib/american/cards.js")


function createAchievementBlockItem(tasksCompleted, totalTasks, categoryName, categoryDetils) {
    let achievementBlockItem = document.createElement("div")
    achievementBlockItem.classList.add("achievementBlockItem")
    
    let achievementContainer = document.createElement("div")
    achievementContainer.innerText = `${tasksCompleted}/${totalTasks}`
    achievementContainer.classList.add("achievementContainer")
    let percentComplete = tasksCompleted / totalTasks * 100
    
    //Blur over 1% to prevent sharp pixelation boundaries. 
    achievementContainer.style.backgroundImage = `linear-gradient(#222222, #222222), conic-gradient(hsl(100 100% 60%), hsl(200 100% 60%), hsl(100 100% 60%) ${percentComplete - 0.5}%, #444444 ${percentComplete + (percentComplete > 0 ? 0.5 : 0)}%, #444444)`
    achievementBlockItem.appendChild(achievementContainer)
    
    
    let detailsContainer = document.createElement("div")
    detailsContainer.classList.add("detailsContainer")
    achievementBlockItem.appendChild(detailsContainer)
    
    let achievementBlockItemTitle = document.createElement("div")
    achievementBlockItemTitle.classList.add("achievementBlockItemTitle")
    achievementBlockItemTitle.innerText = categoryName
    detailsContainer.appendChild(achievementBlockItemTitle)
    
    let achievementBlockDetails = document.createElement("p")
    if (categoryDetils instanceof HTMLElement) {
        achievementBlockDetails.appendChild(categoryDetils)
    }
    else {
        achievementBlockDetails.innerText = categoryDetils
    }
    detailsContainer.appendChild(achievementBlockDetails)
    
    return achievementBlockItem
}



function createCardTrackingPage(panel) {
    //Create the HTML container for the news. 
    let cardTrackingContainer = document.createElement("div")
    cardTrackingContainer.classList.add("cardTrackingContainer")
    
    let gameTypeSelectorBar = document.createElement("div")
    gameTypeSelectorBar.classList.add("gameTypeSelectorBar")
    
    let backToCardsButton;
    let gameTypeOptions = ["Back to Cards", "Total", "Single Player", "Multiplayer", "In-Person"]
    let gameTypeSelectors = []
    for (let option of gameTypeOptions) {
        let gameTypeSelectorItem = document.createElement("p")
        gameTypeSelectorItem.innerText = option
        gameTypeSelectors.push(gameTypeSelectorItem)
        
        if (option === gameTypeOptions[0]) {
            backToCardsButton = gameTypeSelectorItem
            backToCardsButton.classList.add("backToCardsButton")
        }
        else {
            gameTypeSelectorItem.addEventListener("click", function() {
                selectGameType(option)
                render()
            })
        }
        
        gameTypeSelectorBar.appendChild(gameTypeSelectorItem)
    }
    panel.panel.appendChild(gameTypeSelectorBar)
    
    let selectedGameType;
    function selectGameType(gameType) {
        selectedGameType = gameType
        for (let i=0;i<gameTypeOptions.length;i++) {
            let currentType = gameTypeOptions[i]
            let associatedSelector = gameTypeSelectors[i]
            
            if (currentType === gameType) {associatedSelector.classList.add("selectedGameType")}
            else {associatedSelector.classList.remove("selectedGameType")}
        }
    }
    selectGameType("Total")

    let selectedCard;
    let sectionNameEditable;
    
    async function render() {
        let userInfo = await stateManager.getUserData()

        panel.panel.style.backgroundColor = "black"
        panel.panel.style.color = "white"
        
        while (cardTrackingContainer.firstChild) {cardTrackingContainer.firstChild.remove()}
        
        if (window.settings.gamesToSync.value.length > 0) {
            let notConnectedToAccountMessage = document.createElement("div")
            notConnectedToAccountMessage.innerText = "Card Tracking data will be synced with your account when you are signed in and connected to the internet. "
            notConnectedToAccountMessage.style.color = "#FFCCCC"
            notConnectedToAccountMessage.style.fontSize = "1.5em"
            notConnectedToAccountMessage.style.padding = "40px 20px"
            notConnectedToAccountMessage.style.margin = "auto"
            cardTrackingContainer.appendChild(notConnectedToAccountMessage)
        }
        
        
        function gameTypeToProperty(gameType) {
            if (gameType === "Total") {
                return
            }
            else if (gameType === "Single Player") {
                return "singlePlayer"
            }
            else if (gameType === "Multiplayer") {
                return "multiplayer"
            }
            else if (gameType === "In-Person") {
                return "inPerson"
            }
        }
        
        function getCountForGameType(obj, gameType) {
            if (!obj) {return 0}
            
            if (!gameTypeToProperty(gameType)) {
                return obj.singlePlayer + obj.multiplayer + obj.inPerson
            }
            else {
                return obj[gameTypeToProperty(selectedGameType)]
            }
        }
        
        backToCardsButton.style.display = "none"
        if (selectedCard) {
            //Button to return back to cards screen. 
            backToCardsButton.style.display = ""
            backToCardsButton.onclick = function() {
                selectedCard = undefined
                sectionNameEditable = undefined
                render()
            }
            
            //We will render sections for the card. 
            let cardName = selectedCard
            
            let card = cards[cardName]
            let userCardInfo = userInfo?.cards?.[cardName]
            
            
            //We need to show buttons to log wall games and losses.
            //We don't show buttons on Totals screen
            let wallGamesItem = document.createElement("div")
            let wallGameCount = getCountForGameType(userCardInfo?.wallGames, selectedGameType)
            wallGamesItem.innerText = `${wallGameCount} Wall Game${wallGameCount !== 1 ? "s" : ""} ${gameTypeToProperty(selectedGameType) ? "(Click to Record)" : ""}`
            wallGamesItem.classList.add("achievementBlockItem", "logGameWithCardButton")
            wallGamesItem.addEventListener("click", function() {
                if (!gameTypeToProperty(selectedGameType)) {return alert("Select game type (Single Player, Multiplayer, or In-Person) to record a wall game. ")}
                if (confirm(`Record wall game for ${cardName}?`)) {
                    stateManager.logGame({
                        gameType: gameTypeToProperty(selectedGameType),
                        result: "wall",
                        cardName: cardName,
                    })
                    render() //Update the numbers
                }
            })
            cardTrackingContainer.appendChild(wallGamesItem)
            
            let gamesLostItem = document.createElement("div")
            let gamesLostCount = getCountForGameType(userCardInfo?.gamesLost, selectedGameType)
            gamesLostItem.innerText = `${gamesLostCount} Loss${gamesLostCount !== 1 ? "es" : ""} ${gameTypeToProperty(selectedGameType) ? "(Click to Record)" : ""}`
            gamesLostItem.classList.add("achievementBlockItem", "logGameWithCardButton")
            gamesLostItem.addEventListener("click", function() {
                if (!gameTypeToProperty(selectedGameType)) {return alert("Select game type (Single Player, Multiplayer, or In-Person) to record a loss. ")}
                if (confirm(`Record loss for ${cardName}?`)) {
                    stateManager.logGame({
                        gameType: gameTypeToProperty(selectedGameType),
                        result: "loss",
                        cardName: cardName,
                    })
                    render() //Update the numbers
                }
            })
            cardTrackingContainer.appendChild(gamesLostItem)
            
            let sectionsOrder = []
            let sections = {}
            for (let hand of card.hands) {
                if (!sections[hand.section]) {
                    sectionsOrder.push(hand.section)
                    sections[hand.section] = []
                }
                sections[hand.section].push(hand)
            }
            
            for (let sectionName of sectionsOrder) {
                let section = sections[sectionName]
                
                let timesAccomplished = []
                for (let hand of section) {
                    timesAccomplished.push(getCountForGameType(userCardInfo?.hands?.[hand.handName]?.timesAchieved, selectedGameType))
                }
                
                
                let isEditable = sectionName === sectionNameEditable
                
                
                let handsList = document.createElement("div")
                handsList.classList.add("handsListDiv")
                
                for (let i=0;i<timesAccomplished.length;i++) {
                    //Remember - user facing numbers in section should start at 1 not 0. 
                    
                    let handContainer = document.createElement("div")
                    handContainer.classList.add("handCompletedDiv")
                    
                    let handNumber = document.createElement("label")
                    handNumber.innerText = `#${i+1}`
                    handContainer.appendChild(handNumber)
                    
                    let timesWon = document.createElement("label")
                    timesWon.innerText = `${timesAccomplished[i]} win${timesAccomplished[i] !== 1 ? "s" : ""}`
                    handContainer.appendChild(timesWon)
                    
                    if (isEditable) {
                        let recordWinButton = document.createElement("button")
                        recordWinButton.innerText = "Enter Win"
                        recordWinButton.addEventListener("click", function() {
                            if (!gameTypeToProperty(selectedGameType)) {
                                alert("Please select Single Player, Multiplayer, or In-Person category using bar the near the top of the screen. ")
                                return;
                            }
                            
                            let handName = `${sectionName} #${i+1}`
                            if (confirm(`Record win for ${handName}?`)) {
                                stateManager.logGame({
                                    gameType: gameTypeToProperty(selectedGameType),
                                    result: "win",
                                    cardName: cardName,
                                    handName: handName,
                                })
                                render() //Update the numbers
                            }
                        })
                        handContainer.appendChild(recordWinButton)
                    }
                    
                    handsList.appendChild(handContainer)
                }
                
                let sectionEditLockContainer = document.createElement("div")
                sectionEditLockContainer.classList.add("sectionEditLockContainer")
                
                let editLockButton = document.createElement("button")
                editLockButton.classList.add("sectionEditLockButton")
                editLockButton.innerHTML = `<span>${isEditable ? "Lock" : "Edit"}</span>`
                
                let editLockIcon = document.createElement("img")
                editLockIcon.src = `assets/${isEditable ? "lock" : "edit"}.svg`
                editLockIcon.classList.add("editLockIcon")
                editLockButton.appendChild(editLockIcon)
                
                editLockButton.addEventListener("click", function() {
                    //If user clicks edit from "Total" screen, force them to pick a type. 
                    if (isEditable) {
                        sectionNameEditable = undefined
                    }
                    else {
                        sectionNameEditable = sectionName
                    }
                    render()
                })
                
                sectionEditLockContainer.appendChild(editLockButton)
                handsList.appendChild(sectionEditLockContainer)
                
                let winsInSection = timesAccomplished.reduce((total, current) => {return total + current}, 0)
                let achievementBlockItem = createAchievementBlockItem(timesAccomplished.reduce((total, current) => {return total + (current > 0 ? 1 : 0)}, 0), timesAccomplished.length, `${sectionName} (${winsInSection} win${winsInSection !== 1 ? "s" : ""})`, handsList)
                cardTrackingContainer.appendChild(achievementBlockItem)
                
            }
            
            history.pushState("Card Tracking:" + selectedCard, "")
            return;
        }
        else {
            //Render ALL of the cards. 
            let cardNameOrder = Object.keys(cards).sort().reverse()
            //TODO: We should consider sending unplayed cards to the bottom. 
            
            for (let cardName of cardNameOrder) {
                let card = cards[cardName]
                
                let userCardInfo = userInfo?.cards?.[cardName]
                
                let gamesWon = getCountForGameType(userCardInfo?.gamesWon, selectedGameType)
                let totalGames = gamesWon + getCountForGameType(userCardInfo?.gamesLost, selectedGameType) + getCountForGameType(userCardInfo?.wallGames, selectedGameType)
                
                
                let accomplished = 0
                for (let hand of card.hands) {
                    if (getCountForGameType(userCardInfo?.hands?.[hand.handName]?.timesAchieved, selectedGameType) > 0) {
                        accomplished++
                    }
                }
                
                let achievementBlockItem = createAchievementBlockItem(accomplished, card.hands.length, card.name, `${totalGames} Game${totalGames !== 1 ? "s" : ""}, ${gamesWon} Win${gamesWon !== 1 ? "s" : ""} (${Math.round(gamesWon/totalGames * 100) || 0}%)`)
                cardTrackingContainer.appendChild(achievementBlockItem)
                
                achievementBlockItem.addEventListener("click", function() {
                    selectedCard = cardName
                    render()
                })
            }
            
            history.pushState("", "")
            history.pushState("Card Tracking", "")
        }
    }
    
    //Backwards navigation on Android and on Web.
    window?.navigation && (window.navigation.onnavigatesuccess = function(obj) {
        if (obj.navigationType === "push" || obj.navigationType === "replace") {return}
        
        if (!history.state?.includes("Card Tracking")) {
            panel.dismiss()
        }
        else if (history.state === "Card Tracking") {
            console.log(selectedCard, sectionNameEditable)
            if (selectedCard !== undefined || sectionNameEditable !== undefined) {
                selectedCard = undefined
                sectionNameEditable = undefined
                render()
            }
        }
    })

    panel.panel.appendChild(cardTrackingContainer)

    window.stateManager.addEventListener("apiGetUserData", render)
    render()
}

module.exports = {
    createCardTrackingPage,
}
