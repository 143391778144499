const Client = require("../Client")
const handleMessage = require("./handleMessage.js")
class Bot extends Client {
	constructor(clientId, websocket) {
		super(clientId, websocket)
	}

	isBot = true

	getSeed() {
		return this.clientId + this.getRoom().state.seed //We need the same random tiles every time, even reloading from state.
	}

	message(type, message, status) {
		if (this.suppressed) {return} //Isn't really necessary, as the bot should never receive roomActionState while suppressed, however a good measure.

		if (this.websocket) {
			//Bot being manually controlled.
			super.message(type, message, status)
		}

		handleMessage.call(this, {type, message, status})
	}
}

module.exports = Bot
