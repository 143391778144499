const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
//Supported.
module.exports = [
	function(tiles = []) {
		allSuits.forEach((suit) => {
			allSuitArrangements.forEach((triColorOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "any", value: "any", amount: 1}))
				allOptions.forEach((num) => {
					newArr.push(createTiles({type: suit, value: num, amount: 1}))
				})

				newArr.push(createTiles({type: triColorOrder[0], value: 2, amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
				newArr.push(createTiles({type: triColorOrder[1], value: 2, amount: 1}))
				newArr.push(createTiles({type: triColorOrder[2], value: 3, amount: 1}))
			})
		})

		return {
			tiles,
			score: 40,
			concealed: true,
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "Sub Atomic"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

