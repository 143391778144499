//News will always fetch from remote server on mobile. 
import {Capacitor} from "@capacitor/core";

let prefix = Capacitor.isNativePlatform() ? "https://mahjong4friends.com/" : ""

async function loadNewsListing() {
    let req = await fetch(`${prefix}pages/news/listing.json`)
    let listing = await req.json()

    listing = listing.filter((item) => {
        //Filter out listings for the future. 
        //This lets news releases be scheduled. 
        return item.publishTimestamp < Date.now()
    })

    listing = listing.sort((item1, item2) => {
        return item2.publishTimestamp - item1.publishTimestamp
    })

    return listing
}

let newsListingPromise = loadNewsListing()

function createNewsPage() {
    //Create the HTML container for the news. 
    let everythingContainer = document.createElement("div")
    everythingContainer.classList.add("everythingContainer")

    let listingDiv = document.createElement("div")
    listingDiv.classList.add("listingDiv")
    everythingContainer.appendChild(listingDiv)

    let permanentListingDiv = document.createElement("div")
    listingDiv.appendChild(permanentListingDiv)

    let articleFrame = document.createElement("iframe")
    articleFrame.classList.add("articleFrame")

    function loadArticle(listingItem) {
        articleFrame.src = `${prefix}pages/news/${listingItem.url}`
    }

    everythingContainer.appendChild(articleFrame)

    window?.FirebaseAnalytics?.setCurrentScreen?.({
        screenName: "NewsScreen",
        screenClassOverride: "NewsScreen",
    });

    newsListingPromise.then((listing) => {
        for (let item of listing) {
            let listingItem = document.createElement("div")

            let title = document.createElement("p")
            title.classList.add("listItemTitle")
            title.innerHTML = item.title
            listingItem.appendChild(title)

            listingItem.classList.add("listingItem")
            listingItem.addEventListener("click", function () {
                loadArticle(item)
            })

            if (item.permanent) {
                //For permanent listing items like meet the developer. 
                permanentListingDiv.appendChild(listingItem)
            } else {
                //For published articles (like version releases)
                let publishedTime = document.createElement("p")
                publishedTime.classList.add("listItemPublishedTime")
                let dateObj = new Date(item.publishTimestamp)
                publishedTime.innerText = `${dateObj.getMonth()
                + 1}/${dateObj.getDate()}/${dateObj.getFullYear()}`
                listingItem.appendChild(publishedTime)

                listingDiv.appendChild(listingItem)
            }
        }

        loadArticle(listing[0])
    })

    return everythingContainer
}

async function getMostRecentNewsTimestamp() {
    let listing = await newsListingPromise
    return listing[0].publishTimestamp
}

export default {
    createNewsPage,
    getMostRecentNewsTimestamp,
}
