const {Schema} = require ('mongoose');


const PlayCountStatisticsSchema = new Schema({
    singlePlayer: { type: Number, default: 0 },
    multiplayer: { type: Number, default: 0 },
    inPerson: { type: Number, default: 0 },
}, { _id : false })


//A hand will be part of a card. 
const HandSchema = new Schema({
    name: { type: String, unique: true },
    timesAchieved: { type: PlayCountStatisticsSchema, default: {} },
}, { _id : false });

const CardSchema = new Schema({
    name: { type: String, unique: true },
    hands: {
        type: Map,
        of: HandSchema,
        default: {}
    },
    wallGames: { type: PlayCountStatisticsSchema, default: {} },
    gamesLost: { type: PlayCountStatisticsSchema, default: {} },
    //gamesWon can be computed from the individual cards in the hand (or with a mongoose virtual), but we have it here for easy access. 
    gamesWon: { type: PlayCountStatisticsSchema, default: {} },
}, { _id : false });

const UserSchema = new Schema({
    uid: { type: String, unique: true },
    cards: {
        type: Map,
        of: CardSchema,
        default: {}
    },
    joinedDate: { type: Date, default: Date.now },
})


module.exports = {HandSchema, CardSchema, UserSchema}