const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//(11 357 99) (11 357 99) - 2 Suits
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suitOrder[0], value: 1, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 5, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 7, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 2}))
			
			newArr.push(createTiles({type: suitOrder[1], value: 1, amount: 2}))
			newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 5, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 7, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 2}))
		})
		
		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//FF (11 22) (11 22) (11 22) - 3 Suits, consec nums
	function(tiles = []) {
		allOptions.slice(0, -1).forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: "flower", amount: 2}))
				
				newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 1+offset, amount: 2}))
				
				newArr.push(createTiles({type: suitOrder[1], value: 0+offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[1], value: 1+offset, amount: 2}))
				
				newArr.push(createTiles({type: suitOrder[2], value: 0+offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[2], value: 1+offset, amount: 2}))
			})
		})
		
		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//(33 66 99 D) (33 66 99 D)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 2}))
			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[0]], amount: 1}))
			
			newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 2}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 2}))
			newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 2}))
			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 1}))
		})
		
		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//FF (2 4 66 88) (2 4 66 88) - Any 2 Suits
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 2}))
			
			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 2}))
			
			newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 4, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 2}))
			newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 2}))
		})
		
		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//11 22 33 44. 55 66 77 - 1 suit consec nums
	function(tiles = []) {
		allSuits.forEach((suit) => {
			allOptions.slice(0, -6).forEach((offset, i) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: suit, value: 0 + offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 1 + offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 2 + offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 3 + offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 4 + offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 5 + offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 6 + offset, amount: 2}))
			});
		})
		
		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//FF 2023 2023 2023
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)
		
		newArr.push(createTiles({type: "flower", amount: 2}))
		
		newArr.push(createTiles({type: "circle", value: 2, amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
		newArr.push(createTiles({type: "circle", value: 2, amount: 1}))
		newArr.push(createTiles({type: "circle", value: 3, amount: 1}))
		
		newArr.push(createTiles({type: "bamboo", value: 2, amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
		newArr.push(createTiles({type: "bamboo", value: 2, amount: 1}))
		newArr.push(createTiles({type: "bamboo", value: 3, amount: 1}))
		
		newArr.push(createTiles({type: "character", value: 2, amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
		newArr.push(createTiles({type: "character", value: 2, amount: 1}))
		newArr.push(createTiles({type: "character", value: 3, amount: 1}))
		
		return {
			tiles,
			score: 75,
			concealed: true
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "Singles and Pairs"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

