const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//FFFF 2222 46 8888 (1 Suit)
	function(tiles = []) {
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 4}))
			newArr.push(createTiles({type: suit, value: 2, amount: 4}))
			newArr.push(createTiles({type: suit, value: 4, amount: 1}))
			newArr.push(createTiles({type: suit, value: 6, amount: 1}))
			newArr.push(createTiles({type: suit, value: 8, amount: 4}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//22 46 88 2222 2222 (3 Suits, Kongs any Like Evens)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			evenOptions.forEach((num) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 2}))
				
				newArr.push(createTiles({type: suitOrder[1], value: num, amount: 4}))
				
				newArr.push(createTiles({type: suitOrder[2], value: num, amount: 4}))
			})
		})
		
		return {
			tiles,
			score: 30,
			concealed: false
		}
	},
	//(222 4444) (666 8888) (1 or 2 suits)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 3}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 4}))
			
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 3}))
			newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 4}))
		})
		
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suit, value: 2, amount: 3}))
			newArr.push(createTiles({type: suit, value: 4, amount: 4}))
			newArr.push(createTiles({type: suit, value: 6, amount: 3}))
			newArr.push(createTiles({type: suit, value: 8, amount: 4}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//(22 4444) (44 6666) (88) - 3 Suits
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 4}))
			
			newArr.push(createTiles({type: suitOrder[1], value: 4, amount: 2}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 4}))
			
			newArr.push(createTiles({type: suitOrder[2], value: 8, amount: 2}))
		})
		
		return {
			tiles,
			score: 30,
			concealed: false
		}
	},
	//(222 888) DDDD DDDD (3 Suits)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 3}))
			newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 3}))
			
			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 4}))
			
			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[2]], amount: 4}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FF 222 44 66 888 DD (1 Suit)
	function(tiles = []) {
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 2}))
			
			newArr.push(createTiles({type: suit, value: 2, amount: 3}))
			newArr.push(createTiles({type: suit, value: 4, amount: 2}))
			newArr.push(createTiles({type: suit, value: 6, amount: 2}))
			newArr.push(createTiles({type: suit, value: 8, amount: 3}))
			
			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suit], amount: 2}))
		})
		
		return {
			tiles,
			score: 30,
			concealed: true
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "2468"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

