import {Directory, Encoding, Filesystem} from "@capacitor/filesystem";

async function writeTextFile(path: string, text: string, directory = Directory.Data) {
  return await Filesystem.writeFile({
    path,
    directory,
    data: text,
    recursive: true,
    encoding: Encoding.UTF8
  })
}

export {writeTextFile};