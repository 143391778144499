const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//(111 33) (5555) (77 999) - 1 or 3 suits
	function(tiles = []) {
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suit, value: 1, amount: 3}))
			newArr.push(createTiles({type: suit, value: 3, amount: 2}))
			newArr.push(createTiles({type: suit, value: 5, amount: 4}))
			newArr.push(createTiles({type: suit, value: 7, amount: 2}))
			newArr.push(createTiles({type: suit, value: 9, amount: 3}))
		})
		
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suitOrder[0], value: 1, amount: 3}))
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 2}))
			newArr.push(createTiles({type: suitOrder[1], value: 5, amount: 4}))
			newArr.push(createTiles({type: suitOrder[2], value: 7, amount: 2}))
			newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 3}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//(111 333) (3333 5555) or (555 777) (7777 9999) 2 suits
	function(tiles = []) {
		;[1,5].forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[0], value: 2+offset, amount: 3}))
				
				newArr.push(createTiles({type: suitOrder[1], value: 2+offset, amount: 4}))
				newArr.push(createTiles({type: suitOrder[1], value: 4+offset, amount: 4}))
			})
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FF 11 333 5555 DDD or FF 55 777 9999 DDD (1 suit matching dragons)
	function(tiles = []) {
		;[1,5].forEach((offset) => {
			allSuits.forEach((suit) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: "flower", amount: 2}))
				
				newArr.push(createTiles({type: suit, value: 0+offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 2+offset, amount: 3}))
				newArr.push(createTiles({type: suit, value: 4+offset, amount: 4}))
				
				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suit], amount: 3}))
			})
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//(11 33 55) 7777 9999 (3 Suits)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suitOrder[0], value: 1, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 5, amount: 2}))
			
			newArr.push(createTiles({type: suitOrder[1], value: 7, amount: 4}))
			newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 4}))
		})
		
		return {
			tiles,
			score: 30,
			concealed: false
		}
	},
	//FFFF 3333 5555 15 or FFFF 5555 7777 35 (3 Suits)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 4}))
			
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 4}))
			newArr.push(createTiles({type: suitOrder[1], value: 5, amount: 4}))
			
			newArr.push(createTiles({type: suitOrder[2], value: 1, amount: 1}))
			newArr.push(createTiles({type: suitOrder[2], value: 5, amount: 1}))
		})
		
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 4}))
			
			newArr.push(createTiles({type: suitOrder[0], value: 5, amount: 4}))
			newArr.push(createTiles({type: suitOrder[1], value: 7, amount: 4}))
			
			newArr.push(createTiles({type: suitOrder[2], value: 3, amount: 1}))
			newArr.push(createTiles({type: suitOrder[2], value: 5, amount: 1}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//(11 33) (333 555) DDDD or (55 77) (777 999) DDDD
	function(tiles = []) {
		;[1,5].forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)
								
				newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 2+offset, amount: 2}))
				
				newArr.push(createTiles({type: suitOrder[1], value: 2+offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: 4+offset, amount: 3}))

				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[2]], amount: 4}))
			})
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//(111 33 555) (333) (333) or (555 77 999) (777) (777) - 3 suits 
	function(tiles = []) {
		;[1,5].forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[0], value: 2+offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 4+offset, amount: 3}))
				
				newArr.push(createTiles({type: suitOrder[1], value: 2+offset, amount: 3}))
				
				newArr.push(createTiles({type: suitOrder[2], value: 2+offset, amount: 3}))
			})
		})
		
		return {
			tiles,
			score: 35,
			concealed: true
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "13579"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

