import SettingsMenu from "./GameSettings.js";

const Popups = require("../Popups.js")
const {i18n} = require("../../../lib/i18nHelper.js")

const QRCode = require("qrcode-generator")
const Tile = require("../Tile/index.js")

const {resumeOfflineGame} = require("./resumeOfflineGame.js")
const {openSaveFileMenu} = require("./openSaveFileMenu.js")
const {saveOfflineGame, deleteSavedGame, readSavedGame} = require("./saveOfflineGame.js")

//Allow the user to join and create rooms.
let roomManager = document.createElement("div")
roomManager.id = "roomManager"
document.body.appendChild(roomManager)
require("./audioVideo.js")

let roomManagerMainContent = document.createElement("div")
roomManagerMainContent.id = "roomManagerMainContent"
roomManager.appendChild(roomManagerMainContent)

let heading = require("../branding.js").createBrandNameHeading()
roomManagerMainContent.appendChild(heading)

//notInRoomContainer: The stuff to create or join a room.
let notInRoomContainer = document.createElement("div")
notInRoomContainer.id = "notInRoomContainer"
roomManagerMainContent.appendChild(notInRoomContainer)

//Intended for maintenance warnings, etc.
let staticMessageBar = document.createElement("h4")
staticMessageBar.style.marginTop = 0
staticMessageBar.style.display = "none"
notInRoomContainer.appendChild(staticMessageBar)

window.stateManager.addEventListener("setStaticMessageBar", function({message}) {
	if (message) {
		staticMessageBar.innerHTML = message
		staticMessageBar.style.display = ""
	}
	else {
		staticMessageBar.style.display = "none"
	}
})



let modeSelectorDiv = document.createElement("div")
modeSelectorDiv.className = "modeSelectorDiv"
notInRoomContainer.appendChild(modeSelectorDiv)

let singlePlayerDiv = document.createElement("div")
singlePlayerDiv.style.display = "none"
singlePlayerDiv.className = "gameModeDiv"
notInRoomContainer.appendChild(singlePlayerDiv)

let multiplayerDiv = document.createElement("div")
multiplayerDiv.style.display = "none"
multiplayerDiv.className = "gameModeDiv"
notInRoomContainer.appendChild(multiplayerDiv)

let modeAlreadySet; //In case the setting takes some time to load, we don't want to mess with anything the user did. 
function selectGameMode(mode) {
	modeAlreadySet = true
	singlePlayerDiv.style.display = (mode === "single") ? "" : "none"
	multiplayerDiv.style.display = (mode === "multi") ? "" : "none"

	if (mode === "single") {
		singlePlayerSelect.innerText = i18n.__("Single Player (Selected)")
		multiplayerSelect.innerText = i18n.__("Switch to Multiplayer")
		modeSelectorDiv.classList.remove("secondSelected")
	}
	else if (mode === "multi") {
		if (!window.stateManager.online) {
			//If the user came out of an offline game, their server status will be offline. 
			//Therefore, we need to connect to the server when we switch to multiplayer mode. 
			window.stateManager.setServerOnline(true)
		}

		singlePlayerSelect.innerText = i18n.__("Switch to Single Player")
		multiplayerSelect.innerText = i18n.__("Multiplayer (Selected)")
		modeSelectorDiv.classList.add("secondSelected")
	}
}


let singlePlayerSelect = document.createElement("span")
singlePlayerSelect.classList.add("modeSelectSpan")

let multiplayerSelect = document.createElement("span")
multiplayerSelect.classList.add("modeSelectSpan")


modeSelectorDiv.addEventListener("click", function() {
	//Toggle selection. 
	if (window.settings.selectedGameMode.value === "single") {
		selectGameMode("multi")
		window.settings.selectedGameMode.setValue("multi")
	}
	else {
		selectGameMode("single")
		window.settings.selectedGameMode.setValue("single")
	}
})

selectGameMode(window.settings.selectedGameMode.value)
modeAlreadySet = false
window.settings.selectedGameMode.loaded.then(() => {
	if (!modeAlreadySet) {
		//If the game mode has been updated before setting loads, DON'T touch it. 
		selectGameMode(window.settings.selectedGameMode.value)
	}
})

modeSelectorDiv.appendChild(singlePlayerSelect)
modeSelectorDiv.appendChild(multiplayerSelect)

//Configure multiplayerDiv
let connectionStatus = document.createElement("span")
connectionStatus.id = "connectionStatus"

multiplayerDiv.appendChild(connectionStatus)

let disconnectedPopup = new Popups.MessageBar(i18n.__("You are disconnected from the Server. Attempting to Reconnect..."))
let mostRecentConnectedValue;

let dots = 1 //We could make these go a bit faster...
window.setConnectionStatus = function({connected}) {
	mostRecentConnectedValue = connected

	//Show disconnect message after a 2 second delay. 
	if (connected) {disconnectedPopup.dismiss()}
	else {
		setTimeout(function() {
			if (
				mostRecentConnectedValue === connected
				&& window.stateManager.inRoom //Only display popup bar if we're in a room or in game. 
				&& window.stateManager.online
				) {
				disconnectedPopup.show(5000)
			}
		}, 2000)
	}

	connectionStatus.innerText = i18n.__("Connecting to Server") + ".".repeat(1 + (++dots % 5))
	connectionStatus.style.display = connected ? "none" : ""
}

window.setConnectionStatus({
	connected: (window?.stateManager?.serverConnections?.network?.socket?.readyState === 1) //If websocket is open, true. Else false.
})

let roomIdInput = document.createElement("input")
roomIdInput.id = "roomIdInput"
roomIdInput.placeholder = i18n.__("Enter Room Name...")
roomIdInput.setAttribute("enterkeyhint", "next")
multiplayerDiv.appendChild(roomIdInput)

let nicknameInput = document.createElement("input")
nicknameInput.id = "nicknameInput"
nicknameInput.placeholder = i18n.__("Nickname (Optional)")
nicknameInput.setAttribute("enterkeyhint", "done")
multiplayerDiv.appendChild(nicknameInput)

//Remember the user's nickname preference.
nicknameInput.value = window.settings.lastUserNickname.value
nicknameInput.addEventListener("blur", function() {
	window.settings.lastUserNickname.value = nicknameInput.value
})

//Advance to nicknameInput when enter pressed.
roomIdInput.addEventListener("keydown", function(event) {
	if (event.key === "Enter") {
		roomIdInput.blur()
		nicknameInput.focus()
	}
})

//Blur nicknameInput when enter pressed.
nicknameInput.addEventListener("keydown", function(event) {
	if (event.key === "Enter") {
		nicknameInput.blur()
	}
})


//Allow query params.
let hashParams = new URLSearchParams(window.location.hash.slice(1))
let searchParams = new URLSearchParams(window.location.search)
if (hashParams.has("roomId")) {
	roomIdInput.value = hashParams.get("roomId")
	selectGameMode("multi")
}
if (hashParams.has("name")) {
	nicknameInput.value = hashParams.get("name")
}

//window.nativePlatform is used for setting up some UI components in a platform specific manner (like rating links)
//Used for screenshots and things like the Microsoft PWA.
if (hashParams.has("fakeNative")) {
	window.nativePlatform = hashParams.get("fakeNative")
}
else if (Capacitor.isNativePlatform()) {
	window.nativePlatform = Capacitor.getPlatform()
}
else if (searchParams.get("app-install-source") === "microsoft-store") {
	window.nativePlatform = "windows"
}

let joinRoom = document.createElement("button")
joinRoom.classList.add("multiplayerModeSelectButton")
joinRoom.innerText = i18n.__("Join Room")
joinRoom.addEventListener("click", function() {
	stateManager.setServerOnline(true)

	if (roomIdInput.value.trim().length === 0) {
		return new Popups.Notification(i18n.__("Whoops!"), i18n.__("Please enter the name of the room you would like to join into the box labeled \"Enter Room Name\"")).show()
	}
	window.stateManager.joinRoom(roomIdInput.value.toLowerCase().trim(), nicknameInput.value)
})
multiplayerDiv.appendChild(joinRoom)


let createRoom = document.createElement("button")
createRoom.classList.add("multiplayerModeSelectButton")
createRoom.innerText = i18n.__("Create Room")
createRoom.addEventListener("click", function() {
	stateManager.setServerOnline(true)
	window.stateManager.createRoom(roomIdInput.value.toLowerCase().trim(), nicknameInput.value)
})
multiplayerDiv.appendChild(createRoom)











//singlePlayerDiv
let offlineSinglePlayer = document.createElement("button")
offlineSinglePlayer.classList.add("singlePlayerModeSelectButton")
offlineSinglePlayer.innerText = i18n.__("New Game")

offlineSinglePlayer.addEventListener("click", function() {
	stateManager.setServerOnline(false)
	stateManager.getCurrentRoom() //This syncs locale to the offline server.
	let roomId = i18n.__("Offline")
	let nickname = nicknameInput.value //TODO: Nickname is no longer selectable with move. 
	window.stateManager.createRoom(roomId, nickname)
	for (let i=0;i<3;i++) {
		window.stateManager.addBot()
	}
})
singlePlayerDiv.appendChild(offlineSinglePlayer)

let resumeGameButton = document.createElement("button")
resumeGameButton.innerText = i18n.__("Resume")
resumeGameButton.style.display = "none"
resumeGameButton.classList.add("singlePlayerModeSelectButton")
singlePlayerDiv.appendChild(resumeGameButton)

let uploadSaveButton = document.createElement("button")
uploadSaveButton.innerText = i18n.__("Upload")
uploadSaveButton.classList.add("singlePlayerModeSelectButton")
singlePlayerDiv.appendChild(uploadSaveButton)
uploadSaveButton.addEventListener("click", openSaveFileMenu)

try {
	//Shouldn't error.
	readSavedGame().then((res) => {
		if (res) {
			//Alert the user about the save.
			resumeGameButton.style.display = ""

			resumeGameButton.addEventListener("click", function() {				
				resumeOfflineGame(res)
				resumeGameButton.style.display = "none"
			})
		}
	})
}
catch (e) {
	console.error(e)
}













window.saveOfflineGame = saveOfflineGame //The sync button calls this on iOS.
setInterval(saveOfflineGame, 5000) //Save game relatively frequently
window.onbeforeunload = function() {saveOfflineGame()} //This is async, so it might not actually finish.
if (Capacitor.isNativePlatform()) {
	//Save when app is closed, etc.
	App.addListener("appStateChange", function(event) {
		if (event.isActive === false) {saveOfflineGame()}
	})
}


//Inform user to use landscape.
let screenRotationAlert = document.createElement("p")
screenRotationAlert.id = "screenRotationAlert"
screenRotationAlert.innerText = i18n.__("Rotating your screen to Landscape mode is recommended. ")
notInRoomContainer.appendChild(screenRotationAlert)

function setScreenRotationAlert(event) {
	let orientation = window.screen?.orientation?.type
	//Window.innerWidth is returning the wrong value in simulator. May not be an issue on actual devices, but screen.width works fine.
	if (
		(orientation?orientation.includes("portrait"):(Math.abs(window.orientation) !== 90)) //Support iOS window.orientation
		&& screen.width < 900) {
		screenRotationAlert.style.display = ""
	}
	else {
		screenRotationAlert.style.display = "none"
	}
}
window?.screen?.orientation?.onchange?.(setScreenRotationAlert)
window.addEventListener("resize", setScreenRotationAlert);
setScreenRotationAlert()

let inRoomContainer = document.createElement("div")
inRoomContainer.id = "inRoomContainer"
inRoomContainer.style.display = "none"
roomManagerMainContent.appendChild(inRoomContainer)

let roomInfo = document.createElement("h2")
roomInfo.id = "roomInfo"
inRoomContainer.appendChild(roomInfo)

let gameSettingsElem = document.createElement("div")
gameSettingsElem.id = "gameSettingsElem"
inRoomContainer.appendChild(gameSettingsElem)

let playerView = document.createElement("div")
playerView.id = "playerView"
inRoomContainer.appendChild(playerView)

let leaveRoomButton = document.createElement("button")
leaveRoomButton.innerText = i18n.__("Leave Room")
leaveRoomButton.classList.add("roomManagerButton", "yellowBlueGradient")
inRoomContainer.appendChild(leaveRoomButton)

leaveRoomButton.addEventListener("click", function() {
	window.stateManager.leaveRoom(window.stateManager.roomId)
})

let addBotButton = document.createElement("button")
addBotButton.innerText = i18n.__("Add Bot")
addBotButton.classList.add("roomManagerButton", "purpleBlueGradient")
addBotButton.style.display = "none"
inRoomContainer.appendChild(addBotButton)

addBotButton.addEventListener("click", function() {
	window.stateManager.addBot()
})

let startGameButton = document.createElement("button")
startGameButton.id = "startGameButton" //ID is used by the New Game No Lobby button (to start a new game from the old game screen)
startGameButton.innerText =i18n.__( "Start Game")
startGameButton.classList.add("roomManagerButton", "redBlueGradient")
startGameButton.style.display = "none"
inRoomContainer.appendChild(startGameButton)

let gameSettings = new SettingsMenu(gameSettingsElem)

let settingsMenuButton = document.createElement("button")
settingsMenuButton.innerText = i18n.__("Configuration")
settingsMenuButton.classList.add("roomManagerButton", "orangeGreenGradient")
inRoomContainer.appendChild(settingsMenuButton)

settingsMenuButton.addEventListener("click", function() {
	gameSettings.openMenu()
})

startGameButton.addEventListener("click", function() {
	window.stateManager.startGame(gameSettings.getChoices())
})

let inviteYourFriendsElem = document.createElement("div")
inviteYourFriendsElem.id = "inviteYourFriendsElem"
inRoomContainer.appendChild(inviteYourFriendsElem)

let inviteYourFriendsDiv = document.createElement("div")
inviteYourFriendsDiv.id = "inviteYourFriendsDiv"
inviteYourFriendsElem.appendChild(inviteYourFriendsDiv)

let inviteYourFriendsHeader = document.createElement("h2")
inviteYourFriendsHeader.innerText = i18n.__("Invite Players to Join This Game!")
inviteYourFriendsDiv.appendChild(inviteYourFriendsHeader)




let joinRoomLinkElem = document.createElement("p")
joinRoomLinkElem.classList.add("joinRoomLinkElem")
joinRoomLinkElem.innerText = i18n.__("Share the link: ")
inviteYourFriendsDiv.appendChild(joinRoomLinkElem)

Share.canShare().then(({value}) => {
	if (!value) {return} //Share menu not available.
	let shareRoomLinkButton = document.createElement("button")
	shareRoomLinkButton.innerText = "Open Share Menu"
	shareRoomLinkButton.classList.add("shareRoomLinkButton")
	joinRoomLinkElem.appendChild(shareRoomLinkButton)

	shareRoomLinkButton.addEventListener("click", function() {
		Share.share({
			title: `Link for ${stateManager.inRoom} on Mahjong 4 Friends`,
			text: `Follow this link to join room ${stateManager.inRoom} on Mahjong 4 Friends`,
			url: getRoomLink(),
		});
	})
})


let joinRoomLink = document.createElement("a")
joinRoomLink.target = "_blank"
joinRoomLink.classList.add("joinRoomLinkElem")
inviteYourFriendsDiv.appendChild(joinRoomLink)

let QRImageElement = document.createElement("img")
QRImageElement.id = "QRImageElement"
inviteYourFriendsElem.appendChild(QRImageElement)


let genericButtonRowContainer = document.createElement("span")
genericButtonRowContainer.classList.add("genericButtonRowContainer")
roomManagerMainContent.appendChild(genericButtonRowContainer)



const {createNewsPage, getMostRecentNewsTimestamp} = require(
		"./news.js").default

let newsButton = document.createElement("button")
newsButton.classList.add("genericButton", "newsButton")

let newsButtonSpan = document.createElement("span")
newsButton.appendChild(newsButtonSpan)

let newsButtonIcon = document.createElement("img")
newsButtonIcon.classList.add("newsButtonIcon")
newsButtonIcon.src = "assets/news.svg"
newsButtonSpan.appendChild(newsButtonIcon)
newsButtonSpan.innerText += " News"

let notificationSpan = document.createElement("span")
notificationSpan.classList.add("notificationBadge")
newsButtonSpan.appendChild(notificationSpan)
notificationSpan.style.display = "none"

getMostRecentNewsTimestamp().then((ts) => {
	if (ts > window.settings.lastNewsSeenTimestamp.value) {
		notificationSpan.innerText = "1" //TODO: Count the actual number of unread articles. 
		notificationSpan.style.display = ""
	}
})

newsButton.addEventListener("click", function() {
	adLibrary.setBannerStatus(adLibrary.BannerStatus.BOTTOM_ONLY)
	let panel = new Popups.Panel("Mahjong 4 Friends News")
	panel.panel.appendChild(createNewsPage())
	panel.show(document.body)
	window.settings.lastNewsSeenTimestamp.setValue(Date.now())
	notificationSpan.style.display = "none"
})
genericButtonRowContainer.appendChild(newsButton)


const {createCardTrackingPage} = require("./cardTracking.js")

let cardTrackingButton = document.createElement("button")
cardTrackingButton.classList.add("genericButton")
cardTrackingButton.innerText = i18n.__("Card Tracking")
cardTrackingButton.addEventListener("click", function() {
	let panel = new Popups.Panel("Card Tracking")
	createCardTrackingPage(panel)
	panel.show(document.body)

	adLibrary.setBannerStatus(adLibrary.BannerStatus.BOTTOM_ONLY)

	window?.FirebaseAnalytics?.setCurrentScreen?.({
        screenName: "CardTrackingScreen",
        screenClassOverride: "CardTrackingScreen",
    });
})
genericButtonRowContainer.appendChild(cardTrackingButton)


let tutorialLink = document.createElement("a")
tutorialLink.target = "_blank"
tutorialLink.href = `${Capacitor.isNativePlatform() ? "https://mahjong4friends.com/":""}pages/faqs/choose_variant_landing.html`
tutorialLink.innerHTML = `<button class="genericButton">${i18n.__("Help/Tutorial")}</button>`
genericButtonRowContainer.appendChild(tutorialLink)

// let documentationLink = document.createElement("a")
// documentationLink.href = "documentation.html"
// documentationLink.innerHTML = `<button class="genericButton">${i18n.__("Documentation")}</button>`
// genericButtonRowContainer.appendChild(documentationLink)


let supportInfo = document.createElement("p")
supportInfo.id = "supportInfo"
const {createLinkElement} = require("../composeEmail.js")
supportInfo.innerText = i18n.__("Questions, Comments, or Concerns? ") + i18n.__("Contact ")
supportInfo.appendChild(createLinkElement())

roomManagerMainContent.appendChild(supportInfo)


try {
	if (window.nativePlatform) {
		let {createRatingLink} = require("./createRatingLink.js")
		roomManagerMainContent.appendChild(createRatingLink())
	}
	else {
		let {createLinksToStores} = require("./createLinksToStores.js")
		roomManagerMainContent.appendChild(createLinksToStores())
	}
}
catch (e) {
	console.error("Error creating store links and/or rating link", e)
}


import {openStoreMenu} from "../monetization/storeUI";

let storeSpan = document.createElement("span")
storeSpan.classList.add("storeSpan")
storeSpan.addEventListener("click", openStoreMenu)

let storeIcon = document.createElement("img")
storeIcon.src = "assets/store.svg"
storeIcon.classList.add("storeIcon")

storeSpan.innerText = "Store"
storeSpan.appendChild(storeIcon)
roomManagerMainContent.appendChild(storeSpan)



import {createAccountManagerButton} from "../firebase/auth/accountManager";
roomManagerMainContent.appendChild(createAccountManagerButton())

let copyrightNotice = document.createElement("p")
copyrightNotice.innerHTML = i18n.__("Copyright © 2024, All Rights Reserved") + `. <a target="_blank" href="${Capacitor.isNativePlatform() ? "https://mahjong4friends.com/":""}pages/privacy_policy/">Privacy Policy</a> <a target="_blank" href="${Capacitor.isNativePlatform() ? "https://mahjong4friends.com/":""}pages/terms_of_service/">Terms of Service</a>`
copyrightNotice.id = "copyrightNotice"
roomManagerMainContent.appendChild(copyrightNotice)


import adLibrary from "../monetization/ad_library"
adLibrary.enableInterstitialAds()

let adPanelDiv = document.createElement("div")
roomManager.appendChild(adPanelDiv)
adLibrary.addBannerElement(adPanelDiv)
adLibrary.setBannerStatus(adLibrary.BannerStatus.BOTTOM_ONLY)
require("../monetization/store.js")


import { TextToSpeech } from '@capacitor-community/text-to-speech';
import {Share} from "@capacitor/share";
import {Capacitor} from "@capacitor/core";
import {App} from "@capacitor/app";

if (window.speechSynthesis) {
	try {
		speechSynthesis.onvoiceschanged = function() {
			//iOS doesn't define speechSynthesis.addEventListener("voiceschanged"). Just do this.
			window.dispatchEvent(new Event("onvoiceschanged"))
		}
		
	}
	catch (e) {console.warn("Voice Error", e)}
}

class VoiceSelector {
	constructor(settingId) {
		this.elem = document.createElement("select")
		this.settingId = settingId

		this.generateOptions()
		window.addEventListener("onvoiceschanged", this.generateOptions.bind(this))

		this.elem.addEventListener("change", function() {
			window.settings.voices[this.settingId].setValue(this.elem.value)
		}.bind(this))
	}

	generateOptions() {
		while (this.elem.firstChild) {this.elem.firstChild.remove()}

		let availableVoicesPromise = TextToSpeech.getSupportedVoices()

		//We need to have a default, as some browsers (especially in privacy modes) might an empty array for getVoices, but work.
		let noneChoice = document.createElement("option")
		noneChoice.value = "none"
		noneChoice.innerText = i18n.__("No Voice")
		noneChoice.selected = true
		this.elem.appendChild(noneChoice)

		let defaultChoice = document.createElement("option")
		defaultChoice.value = "default"
		defaultChoice.innerText = i18n.__("Default Voice")
		this.elem.appendChild(defaultChoice)
		this.elem.value = window.settings.voices[this.settingId].value //Update from setting. 

		availableVoicesPromise.then(((availableVoices) => {
			let matchingLanguageVoices = availableVoices.voices.filter((voice) => {
				return voice.lang.includes(window.settings.locale.value)
			})

			for (let voice of matchingLanguageVoices) {
				let choice = document.createElement("option")
				choice.value = voice.voiceURI
				choice.innerText = voice.name
				if (voice.localService === false) {choice.innerText += " (online only)"}
				this.elem.appendChild(choice)
			}

			this.elem.value = window.settings.voices[this.settingId].value //Update from setting again after voices load. 
		}).bind(this))
	}

	get() {
		return this.elem.value
	}

}




function renderPlayerView(clientList = []) {
	while (playerView.firstChild) {playerView.firstChild.remove()}

	let voiceSettingId = 0

	clientList.forEach((obj) => {
		let row = document.createElement("div")
		row.classList.add("playerViewRow")

		let nameSpan = document.createElement("span")
		nameSpan.classList.add("playerViewNameSpan")
		nameSpan.innerText = obj.nickname
		row.appendChild(nameSpan)

		let card = document.createElement("span")
		card.classList.add("playerViewCard")
		row.appendChild(card)

		let voiceChoice = document.createElement("span")
		voiceChoice.classList.add("playerViewVoiceChoice")
		row.appendChild(voiceChoice)


		//TODO: We'll use white circles around the volumeIcon to indicate that a person is speaking
		let volumeIconSpan = document.createElement("span")
		volumeIconSpan.classList.add("volumeIconSpan")
		row.appendChild(volumeIconSpan)

		let muteControl = window.userVideos?.find((userVideo) => {
			return userVideo.associatedClientId === obj.id
		})

		if (muteControl) {
			volumeIconSpan.appendChild(muteControl.roomScreenMicrophone)
		}

		//TODO: We should probably add a mute/unmute button here on each player. 
		//Maybe also do circles when people are talking. 

		function setNicknameEditable(span, targetId) {
			span.classList.add("editableName")

			let promptText = `Enter a new nickname for ${obj.nickname}: `
			if (targetId === window.stateManager.connectedClientId) {
				promptText = "Enter a new nickname: "
			}

			nameSpan.addEventListener("click", function() {
				let res = prompt(promptText)
				if (res !== null) {
					window.stateManager.setNickname(res, obj.id)
				}
			})
		}

		if (obj.id === window.stateManager.connectedClientId) {
			voiceChoice.innerText = i18n.__("N/A")

			//You can edit your own nickname.
			setNicknameEditable(nameSpan, obj.id)

			if (window.stateManager.isHost) {
				card.innerText = i18n.__("You (Host)")
			}
			else {
				card.innerText = i18n.__("You")
			}

			//TODO: Append to voiceChoice a selector for whether to do audio and video or not. 
		}
		else {
			try {
				//Allow picking a voice for this person (TODO: Ignore if they add microphone???)
				//Or maybe we could move these selectors to in game whereever other people's streams are or something. 
				voiceChoice.appendChild(new VoiceSelector(voiceSettingId++).elem)
			}
			catch (e) {console.error("Error Adding Voice Selector", e)}

			if (obj.isHost) {
				card.innerText = i18n.__("Host")
			}
			else if (window.stateManager.isHost) {

				//The host can edit any nicknames.
				setNicknameEditable(nameSpan, obj.id)

				let kickButton = document.createElement("button")
				kickButton.innerText = i18n.__("Remove %s", obj.nickname)
				kickButton.classList.add("playerViewKickButton")
				kickButton.addEventListener("click", function() {
					if (obj.isBot || confirm(i18n.__("Are you sure you want to remove %s", obj.nickname))) {
						window.stateManager.kickUser(obj.id)
					}
				})
				card.appendChild(kickButton)
			}
			else {
				card.innerText = i18n.__("Player")
			}
		}

		playerView.appendChild(row)
	})
}

function getRoomLink() {
	let queryParam = "#roomId=" + stateManager.inRoom
	if (Capacitor.isNativePlatform()) {
		return "https://mahjong4friends.com/" + queryParam
	}
	return queryParam
}

function enterRoom() {
	window?.FirebaseAnalytics?.setCurrentScreen?.({
		screenName: "RoomScreen",
		screenClassOverride: "RoomScreen",
	});

	heading.style.fontSize = "0.7em" //Shrink heading slightly.
	inRoomContainer.style.display = "block"
	notInRoomContainer.style.display = "none"

	//TODO: In the app, these links need to open in the browser. I believe they do on iOS, but they certainly don't on Android,
	//as the link is captured by our link capturing.
	joinRoomLink.href = getRoomLink()
	joinRoomLink.innerText = joinRoomLink.href //We want the full URL, not just the hash.

	inviteYourFriendsElem.style.display = stateManager.online ? "" : "none" //Hide invite friends when offline.

	try {
		let dpi = 4

		let qrGenerator = QRCode(0, "H"); //0 is for auto-detection. H is for maximum error correction.

		//Generate the code.
		qrGenerator.addData(joinRoomLink.href)
		qrGenerator.make()

		//Draw the code into a canvas
		let cnv = document.createElement("canvas")
		let pixelsPerBlock = 3 * dpi
		cnv.width = cnv.height = qrGenerator.getModuleCount() * pixelsPerBlock

		let ctx = cnv.getContext("2d")
		qrGenerator.renderTo2dContext(ctx, pixelsPerBlock)

		//Copy the code into a new canvas, width padding added.
		let paddingPixels = 6 * dpi

		let drawCanvas = document.createElement("canvas")
		drawCanvas.width = drawCanvas.height = cnv.width + paddingPixels * 2

		let drawCtx = drawCanvas.getContext("2d")
		drawCtx.fillStyle = "white"
		drawCtx.fillRect(0, 0, drawCanvas.width, drawCanvas.height)

		drawCtx.drawImage(cnv, paddingPixels, paddingPixels)


		//Insert the Mahjong logo.
		//We'll use a green dragon. 
		//Note that this may fail if the user is already in a room and it is reloaded - the roomManager will load before the tileset. 
		//However this will soft fail, in that the QR code will be generated, simply without the green dragon. 
		let dragonTile = new Tile({type: "dragon", value: "green"})
		let img = dragonTile.createImageElem()
		img.addEventListener("load", function() {
			let centerPadding = 3 * dpi //Pixels to pad the center image.

			let maxCenterSize = drawCanvas.width * 0.3 //No hard requirement on what we can do, but 20% is fine.

			let width = img.width
			let height = img.height

			let ratio = Math.max(1, Math.max(width, height)/maxCenterSize)

			width /= ratio
			height /= ratio

			let left = drawCanvas.width / 2 - width / 2
			let top = drawCanvas.height / 2 - height / 2

			drawCtx.fillRect(left - centerPadding, top - centerPadding, width + centerPadding * 2, height + centerPadding * 2);

			drawCtx.drawImage(img, left, top, width, height)
			QRImageElement.src = drawCanvas.toDataURL("image/png")
			QRImageElement.width = QRImageElement.height = drawCanvas.width / dpi
		})

		QRImageElement.src = drawCanvas.toDataURL("image/png")
		QRImageElement.width = QRImageElement.height = drawCanvas.width / dpi
	}
	catch (e) {
		console.error(e)
	}
}

function exitRoom() {
	window?.FirebaseAnalytics?.setCurrentScreen?.({
		screenName: "HomeScreen",
		screenClassOverride: "HomeScreen",
	});
	heading.style.fontSize = "" //Set heading back to default size.
	inRoomContainer.style.display = "none"
	notInRoomContainer.style.display = "block"
}

let showRestoreNotice = true
window.stateManager.addEventListener("joinRoom", function (obj) {
	if (obj.status === "error") {
		return new Popups.Notification(i18n.__("Unable to Join Room"), i18n.__(obj.message), {plainText: true}).show()
	}
	else {
		if (showRestoreNotice && hashParams.has("roomId") && hashParams.get("roomId") !== obj.message) {
			new Popups.Notification(i18n.__("Room Restored"), i18n.__("You followed a link to room %s, but were already in room %s. Your room has been restored - to join a new room, leave your current one. ", hashParams.get("roomId"), obj.message), {plainText: true}).show()
			showRestoreNotice = false
		}

		enterRoom()
	}
})

window.stateManager.addEventListener("createRoom", function(obj) {
	if (obj.status === "error") {
		return new Popups.Notification(i18n.__("Unable to Create Room"), i18n.__(obj.message), {plainText: true}).show()()
	}
})

window.stateManager.addEventListener("leaveRoom", function(obj) {
	exitRoom()
	if (!stateManager.online) {
		//If we resumed an offline game from an online game, go back to the online game.
		stateManager.setServerOnline(true)
		stateManager.getCurrentRoom()
	}
	//Don't show somebody that they left the room. Just exit.
	//Don't show the host that they closed the room. Just exit.
	if (obj.message !== "You closed the room. " && obj.message !== "You left the room. ") {
		new Popups.Notification(i18n.__("Out of Room"), i18n.__(obj.message), {plainText: true}).show()()
	}
	adLibrary.setBannerStatus(adLibrary.BannerStatus.BOTTOM_ONLY)
})

window.stateManager.addEventListener("state", function(obj) {
	//Link room name when online.

	let roomNameText = document.createElement("a")
	roomNameText.innerText = stateManager.inRoom
	if (stateManager.online) {
		roomNameText.href = getRoomLink()
		roomNameText.target = "_blank"
	}

	let playerCount = obj.message.clients.length
	roomInfo.innerText = i18n.__(`%d player${playerCount > 1 ? "s are":" is"} present in room `, playerCount)
	roomInfo.appendChild(roomNameText)

	if (window.stateManager.isHost) {
		startGameButton.style.display = ""
		addBotButton.style.display = ""

		if (obj.message.clients.length >= 4) {
			addBotButton.style.display = "none" //No reason to allow adding bots when game is full.
		}
	}
	else {
		addBotButton.style.display = "none"
		startGameButton.style.display = "none"
	}

	gameSettings.setHost(window.stateManager.isHost)

	renderPlayerView(obj.message.clients)

	if (obj.message.inGame) {
		//Make extra sure that the banner ad is hidden when we're in a game. 
		adLibrary.setBannerStatus(adLibrary.BannerStatus.HIDDEN)
	}
	else {
		//We are in the room but not in a game, so banners are fine.
		adLibrary.setBannerStatus(adLibrary.BannerStatus.TOP_OR_BOTTOM)
	}
})

window.stateManager.getCurrentRoom() //If we are already in a room, this will issue the correct callbacks to enter us into it.


window.stateManager.addEventListener("startGame", function() {
	roomManager.style.display = "none"
	adLibrary.setBannerStatus(adLibrary.BannerStatus.HIDDEN)
	window?.FirebaseAnalytics?.setCurrentScreen?.({
		screenName: "GameScreen",
		screenClassOverride: "GameScreen",
	  });
})

window.stateManager.addEventListener("endGame", function(obj) {
	roomManager.style.display = ""
	//We are on room screen, can show anywhere.
	adLibrary.setBannerStatus(adLibrary.BannerStatus.TOP_OR_BOTTOM)
	window?.FirebaseAnalytics?.setCurrentScreen?.({
		screenName: "RoomScreen",
		screenClassOverride: "RoomScreen",
	});

	if (obj.message !== "State Sync") {
		//State Sync game ends happen to the person that ends the game, as well as in development mode.
		new Popups.Notification(i18n.__("Game Ended"), i18n.__(obj.message), {plainText: true}).show()()
	}
})

export {roomManager}
