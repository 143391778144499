const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//NNNN EEE WWW SSSS or NNN EEEE WWWW SSS
	function(tiles = []) {
		;[0].forEach((num) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "wind", value: "north", amount: 4}))
			newArr.push(createTiles({type: "wind", value: "east", amount: 3}))
			newArr.push(createTiles({type: "wind", value: "west", amount: 3}))
			newArr.push(createTiles({type: "wind", value: "south", amount: 4}))
		})

		;[0].forEach((num) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "wind", value: "north", amount: 3}))
			newArr.push(createTiles({type: "wind", value: "east", amount: 4}))
			newArr.push(createTiles({type: "wind", value: "west", amount: 4}))
			newArr.push(createTiles({type: "wind", value: "south", amount: 3}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//NNN SSS 11 22 3333 or EEE WWW 11 22 3333 (Any Run)
	function(tiles = []) {
		allOptions.slice(0, -2).forEach((offset) => {
			allSuits.forEach((suit) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "wind", value: "north", amount: 3}))
				newArr.push(createTiles({type: "wind", value: "south", amount: 3}))

				newArr.push(createTiles({type: suit, value: 0+offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 1+offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 2+offset, amount: 4}))
			})
		})

		allOptions.slice(0, -2).forEach((offset) => {
			allSuits.forEach((suit) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "wind", value: "east", amount: 3}))
				newArr.push(createTiles({type: "wind", value: "west", amount: 3}))

				newArr.push(createTiles({type: suit, value: 0+offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 1+offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 2+offset, amount: 4}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//NNNN SSSS DDD DDD or EEEE WWWW DDD DDD (any 2 dragons)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			;[0].forEach((num) => {
				let newArr = []
				tiles.push(newArr)
	
				newArr.push(createTiles({type: "wind", value: "north", amount: 4}))
				newArr.push(createTiles({type: "wind", value: "south", amount: 4}))

				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[0]], amount: 3}))
				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 3}))
			})
	
			;[0].forEach((num) => {
				let newArr = []
				tiles.push(newArr)
	
				newArr.push(createTiles({type: "wind", value: "east", amount: 4}))
				newArr.push(createTiles({type: "wind", value: "west", amount: 4}))

				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[0]], amount: 3}))
				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 3}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FF NNNN 2023 SSSS or FF EEEE 2023 WWWW
	function(tiles = []) {
		allSuits.forEach((suit) => {
			;[0].forEach((num) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 2}))
	
				newArr.push(createTiles({type: "wind", value: "north", amount: 4}))

				newArr.push(createTiles({type: suit, value: 2, amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
				newArr.push(createTiles({type: suit, value: 2, amount: 1}))
				newArr.push(createTiles({type: suit, value: 3, amount: 1}))

				newArr.push(createTiles({type: "wind", value: "south", amount: 4}))
			})
	
			;[0].forEach((num) => {
				let newArr = []
				tiles.push(newArr)
	
				newArr.push(createTiles({type: "flower", amount: 2}))
	
				newArr.push(createTiles({type: "wind", value: "east", amount: 4}))

				newArr.push(createTiles({type: suit, value: 2, amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
				newArr.push(createTiles({type: suit, value: 2, amount: 1}))
				newArr.push(createTiles({type: suit, value: 3, amount: 1}))

				newArr.push(createTiles({type: "wind", value: "west", amount: 4}))
			})	
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FFFF N EE WWW SSSS
	function(tiles = []) {
		;[0].forEach((num) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "flower", amount: 4}))

			newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
			newArr.push(createTiles({type: "wind", value: "east", amount: 2}))
			newArr.push(createTiles({type: "wind", value: "west", amount: 3}))
			newArr.push(createTiles({type: "wind", value: "south", amount: 4}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//DDDD NN E W SS DDDD (any 2 dragons)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			;[0].forEach((num) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[0]], amount: 4}))
	
				newArr.push(createTiles({type: "wind", value: "north", amount: 2}))
				newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
				newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
				newArr.push(createTiles({type: "wind", value: "south", amount: 2}))

				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 4}))
			})
		})

		return {
			tiles,
			score: 30,
			concealed: false
		}
	},
	//NNN E W SSS 111 222 (consec nums, 2 suits)
	function(tiles = []) {
		allOptions.slice(0, -1).forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "wind", value: "north", amount: 3}))

				newArr.push(createTiles({type: "wind", value: "east", amount: 1}))

				newArr.push(createTiles({type: "wind", value: "west", amount: 1}))

				newArr.push(createTiles({type: "wind", value: "south", amount: 3}))

				newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: 1+offset, amount: 3}))
			})
		})

		return {
			tiles,
			score: 30,
			concealed: true
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "Winds and Dragons"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

