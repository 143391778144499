//We'd like to have some sort of native ads here (the native admob ad format for like the suggested hands hand list)
//That said, we would PROBABLY need to use native elements for that. 
//There might be a way to do like a native overlay for the suggested hands menu or something and have that native overlay contain ads. 

import { AdMob, AdmobConsentStatus } from '@capacitor-community/admob';
import { hasAdFree } from '../../ownedProducts';

//AdMob must be initialized before ads can be shown. 
let whenInitializedCallback;
window.onAdmobInitializedPromise = new Promise((resolve) => {
    whenInitializedCallback = resolve
})

import { isInterstitialReady, prepareInterstitial, displayInterstitial } from './interstitial';
import { setBannerStatus, BannerStatus, addBannerElement } from './banner';
import {Capacitor} from "@capacitor/core";
import Popups from "../../../Popups";
import {App} from "@capacitor/app";

async function initialize() {
    //AuthorizationStatus details at https://developer.apple.com/documentation/apptrackingtransparency/attrackingmanager/authorizationstatus
    let [AuthorizationStatus, consentInfo] = await Promise.all([
        AdMob.trackingAuthorizationStatus().then((statusObj) => {return statusObj.status}),
        AdMob.requestConsentInfo(),
    ]);

    
    //Determine how many people are in each category for tracking. 
    if (Capacitor.getPlatform() === "ios") {
        FirebaseAnalytics?.setUserProperty({
            key: 'idfaStatus',
            value: AuthorizationStatus,
        });
    }

    //We need to ensure we have NSUserTrackingUsageDescription -
    //Apple states the app may crash if we request tracking authorization without the description. 
    let appVersion = (await App.getInfo()).version
    let hasNSUserTrackingUsageDescription = (Capacitor.getPlatform() === "ios") && Number(appVersion) > 5.0

    //We'll only request tracking authorization if:
    //1. The user has made no purchases (we'll assume that they play mostly ad-free if they have made any, regardless of whether they have premium or not)
    //2. The user has opened the app at least 5 times. 
    //3. The user has seen at least 1 video ad. 
    let shouldRequestTracking = hasNSUserTrackingUsageDescription && (
        window.settings.iosAlwaysRequestTracking.value //For Apple Review testers, bypasses normal checks 
        ||  (
                !await hasAdFree()
                && window.settings.timesAppOpened.value >= 5
                && window.settings.adsShown.value > 0
            )
        )

    let wasIDFAPromptShown = false
    let promptUsed;
    if (shouldRequestTracking && AuthorizationStatus === 'notDetermined') {
        //We are running testing to see which prompts are most effective at encouraging users to enable tracking. 

        let randomVal = Math.random();

        if (randomVal < 0.33) {
            promptUsed = "none"
        }
        else {
            let idfaPromptTitleText;
            let idfaPromptBodyText;

            //randomVal known to be [0.33, 1) here, so this is ~50%. 
            if (randomVal < 0.66) {
                promptUsed = "long pre-prompt"
                idfaPromptTitleText = "Help us Stay Free for You"
                idfaPromptBodyText = `
Please click "Allow" on the next screen to enable personalized ads. 
<ul>
<li>Ads may be more relevant to you</li>
<li>Ads use an anonymous identifier<ul><li>Account data is <strong>never</strong> shared</li></ul></li>
<li>Change anytime in system settings</li>
</ul>`
            }
            else {
                promptUsed = "simple pre-prompt"
                idfaPromptTitleText = "Help us Stay Free for You"
                idfaPromptBodyText = `Personalized ads better support us, and may be more relevant to you. If you are okay with this, please click "Allow" on the next screen. <br>`
            }

            let idfaPopupMessage = document.createElement("div")
            idfaPopupMessage.classList.add("idfaPopupMessage")
            
            //Current NSUserTrackingUsageDescription
            //Personalized ads better support us, and are more relevant to you. 

            idfaPopupMessage.innerHTML = idfaPromptBodyText
            
            let idfaPopupDismissButton = document.createElement("button")
            idfaPopupDismissButton.classList.add("idfaPopupDismissButton")
            idfaPopupDismissButton.innerHTML = "Continue"
            idfaPopupMessage.appendChild(idfaPopupDismissButton)

            let idfaPopup = new Popups.Notification(idfaPromptTitleText, idfaPopupMessage)
            idfaPopupDismissButton.addEventListener("click", idfaPopup.dismiss)

            //Don't show native prompt until user hits the continue button or dismisses the pre-prompt message. 
            await new Promise((resolve) => {
                idfaPopup.ondismissed(resolve)
                idfaPopup.show()
            })
        }

        wasIDFAPromptShown = true
        await AdMob.requestTrackingAuthorization();
    }

    await AdMob.initialize({});

    if (consentInfo.isConsentFormAvailable && consentInfo.status === AdmobConsentStatus.REQUIRED) {
        await AdMob.showConsentForm();
    }

    if (wasIDFAPromptShown) {
        //Report how successful our IDFA prompt was. 
        let newStatus = (await AdMob.trackingAuthorizationStatus()).status;

        window?.FirebaseAnalytics?.logEvent?.({
            name: "idfaDecision",
            params: {
                idfaChoice: newStatus,
                promptUsed,
            }
        });
    }


    //Enable volume syncing. 
    syncVolume()
    window.settings.soundEffectVolume.addOnValueSetListener(syncVolume)

    whenInitializedCallback()
    window.onAdmobInitializedPromise = true //Mark as ready. 
}

function syncVolume() {
    //Sync app volume with AdMob. 
    let ratio = 0.4 //Our average sound plays at under 50% of configured volume. Bring ads down to same volume. 
    AdMob.setApplicationVolume({
        volume: window.settings.soundEffectVolume.value * ratio
    })
}



export {
    initialize, 
    addBannerElement, setBannerStatus, BannerStatus,
    isInterstitialReady, displayInterstitial, prepareInterstitial
}