const {updateTilesInContainer} = require("../updateTilesInContainer.js")
const {displayCenterTilePopup} = require("../displayCenterTilePopup.js")

const {i18n} = require("../../../lib/i18nHelper.js")
const {Capacitor} = require("@capacitor/core");

function renderWall(div, tiles) {
	if (tiles.length === 0) {
		//The wall is out. Don't render anything.
		while (div.firstChild) {div.firstChild.remove()}
		return
	}

	//Update existing paragraph elem, or create if none.
	let elem = div.querySelector("p")
	if (!elem) {
		elem = document.createElement("p")
		div.insertBefore(elem, div.firstChild)
	}
	elem.innerText = tiles.length

	//Update the tiles.
	updateTilesInContainer(div, tiles)

	window.requestAnimationFrame(function() {
		//Set an animation frame so the tiles are rendered before the class change is applied.
		//Otherwise these are drawn with smallView styles, and never animated.

		//We need both to be in requestAnimationFrame as guaranteed hands call revert state sync, which would otherwise
		//result in smallView being set when not correct.
		if (tiles.length > 30) {
			if (div.classList.contains("smallView")) {
				//This a workaround for an iOS and Safari glitch where the wall renders vertically immediately after
				//a new game is started when the previous game wall ran low enough to become smallView 
				//The glitch only occurs on iOS and Safari. 

				let isSafariOriOS = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) || Capacitor.getPlatform() === "ios";
				if (isSafariOriOS) {
					//When we go back from smallView rapidly hide then unhide wall. 
					window.requestAnimationFrame(function() {
						div.style.display = "none"
						window.requestAnimationFrame(function() {
							div.style.display = ""
						})
					})
				}
			}

			div.className = "wall"
		}
		else {
			div.className = "wall smallView"
		}
	})

	div.onclick = function() {
		displayCenterTilePopup(tiles, i18n.__n(`Wall View %s tile${tiles.length === 1 ? "":"s"} left`, tiles.length))
	}
}

module.exports = renderWall
