import {Directory, Filesystem} from "@capacitor/filesystem";

async function deleteFile(path: string, directory = Directory.Data) {
  try {
    await Filesystem.deleteFile({
      path,
      directory
    })
  }
  catch (e) {
    //As of 6/16/2024 there is no way to determine failure reasons other than checking error messages.
    if (e.message.includes("File does not exist")) {
      //If the file doesn't exist there is no need to error.
      return
    }

    console.warn("Failed to delete file", e)
  }
}

export {deleteFile};