//localizeMessage must be bound to an instance of i18n.
const vsprintf = require('sprintf-js').vsprintf

function localizeMessage(message, locale = this.locale, callbackThisArg = this) {
	//callbackThisArg can be used to pass a different "this" value to the callbacks.

    // massage message object: translate per locale & flatten object with locale info to plain string

    if (typeof message === "string" ) {
        // plain simple text: client.message(.., "this is a test to be xlated")
        message = this.__({phrase:message, locale})
    }
    else if (typeof message === "object" && message.format) {
        // * text with runtime data supplied - use the object notation.
        let fmt = message.format

        if (typeof fmt === "string" ) {
            // client.message(.., {format: "this is a test for %s", args: varData})
            // client.message(.., {format: "this is a test for %{namedVar}s", args:{namedVar: varData}})
            fmt = this.__({phrase:fmt, locale})
        }
        else if (fmt instanceof Array ) {
            // concatenated multiple sentences, w/ or w/o runtime data. - note the object notation, and array format.
            // client.message(.., {foramt: ["first with %(param1)s.", "second with %(param2)s."], args: {param1:Xxx, param2: Yyy})
            fmt = fmt.map((item) => {return this.__({phrase:item, locale})} )
            fmt = fmt.join("")
        }
        else {
            console.error(`internal error bad format ${fmt}`)
            return message
        }

        // let's make a copy of the arg before we set out to modify it
        let args = {}		// assume named value
        if (typeof message.args === "string" || typeof message.argsI18n === "string") {
            args = []		// positional

            if (typeof message.args === "string") {
                // eg: message(.., {format:"test %s", args:var})
                args.push(message.args)		// to avoid turning string into an array of chars via the assign method
            }
            else {
                // eg: message(.., {format:"test %s", argsI18n:var_to_be_localized})
                // if argsI18n is provided, but is not a named object, then, it has to be a single value, used in lieu of args.
                args.push(this.__({phrase:message.argsI18n, locale}))
            }
        }
        else if (typeof message.args === "object") {
            args = Object.assign({}, message.args)
        }

        // some of the dynamic data itself need to be localized first
        // eg: message(.., {format:"test %(param1)s, %(param2)s", args:{param1:Xxx}, argsI18n:{param2:Yyy-toBeLocalized}})
        // note that mixing positional and named placeholders is not supported in sprintf therefore us
        if (typeof message.argsI18n === "object") {
            let argsI18n = message.argsI18n
            for (let key in argsI18n) {
                // save the localized data to the new combined args
                args[key] = this.__({phrase:argsI18n[key], locale})
            }
        }

        // "2 bamboo" case: if args themselves need to be handled, provide additional callback functions as option
        // client.message(.., {foramt: "test %(tile)s.", args: {tile: tileJsonObj}, argsOption: {tile:localizeTileName_CallBackFunction} }
        if (typeof message.argsOption === "object") {
            // additional processing callback option are specified, let's run it on the correponding args
            let callbackFunctions = message.argsOption
            for (let key in callbackFunctions) {
                try {
                    args[key] = callbackFunctions[key].call(callbackThisArg, locale, args[key])
                }
                catch (e) {
                    console.error(e)
                }
            }
        }

        if (fmt.includes("%") && typeof args === "undefined") {
            console.error(`internal error: bad format ${fmt}`)
        }
        else {
            // options here
            // A) vsprintf() which supports %(name)s, does not support {{name}}, does not support mixed-position-named
            // B) or this.__ which does NOT support %(name)s, does {{name}}, no mixed. we can request fixing i18n...
            // like A) for now, less cycle, even though consistency with B) is appealing.
            try {
                message = vsprintf(fmt, args)
                // message = this.__(fmt, args)
            }
            catch(e) {
                console.error(e)	// gotta be internal error
                message = fmt  	// try to get something back
            }
        }
    }

    return message
}

module.exports = localizeMessage
