const {logGame} = require("../database/api.js")

//"this" must be the Room object, so this function should be called with .call if not bound. 
function logCompletedGame(obj) {
    //This will autofill card and gameType. 
    //uid and other properties must still be passed. 

    if (obj.uid?.startsWith("bot")) {return} //Do nothing. 

    //Determine if game is singlePlayer or multiplayer. 
    let nonBotCount = 0
    for (let id in this.gameData.playerHands) {
        if (!id.startsWith("bot")) {
            nonBotCount++
        }
    }

    obj.gameType = nonBotCount > 1 ? "multiplayer": "singlePlayer"
    obj.cardName = this.gameData?.card?.name

    return logGame(obj)
}
    

module.exports = {logCompletedGame}

