const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//(333 666) (6666 9999) - 2 or 3 Suits (split 6666 and 9999 for 3)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 3}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 3}))
			
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 4}))
			newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 4}))
		})
		
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 3}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 3}))
			
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 4}))

			newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 4}))
		})


		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FF (3 66 999) (333) (333) - Any 3 Suits, like 3,6, or 9 pungs
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			;[3, 6, 9].forEach((offset) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: "flower", amount: 2}))
	
				newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 3}))
	
				newArr.push(createTiles({type: suitOrder[1], value: offset, amount: 3}))
	
				newArr.push(createTiles({type: suitOrder[2], value: offset, amount: 3}))
			})
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FF 3333 6666 9999 - 1 or 3 Suits
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 2}))

			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 4}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 4}))
			newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 4}))
		})

		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 2}))

			newArr.push(createTiles({type: suit, value: 3, amount: 4}))
			newArr.push(createTiles({type: suit, value: 6, amount: 4}))
			newArr.push(createTiles({type: suit, value: 9, amount: 4}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//333 DDDD 333 DDDD - 3s, 6s, or 9s, matching dragons, 2 suits
	//Per clarification by NMJL, like pungs (ran out of space on card to specify explicitly)
	function(tiles = []) {
		[3, 6, 9].forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: suitOrder[0], value: offset, amount: 3}))

				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[0]], amount: 4}))

				newArr.push(createTiles({type: suitOrder[1], value: offset, amount: 3}))

				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 4}))
			})
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//3333 66 66 66 9999 - Any 3 Suits, 3s and 9s same suit. 
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 4}))
			
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 2}))
			newArr.push(createTiles({type: suitOrder[2], value: 6, amount: 2}))
			
			newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 4}))
		})
		
		return {
			tiles,
			score: 30,
			concealed: false
		}
	},
	//FFFF 33 66 999 DDD (opposite dragon, 1 suit)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 4}))

			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 3}))

			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 3}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//(333 666) (333 666) 99 - 3 Suits
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 3}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 3}))
			
			newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 3}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 3}))

			newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 2}))
		})
		
		return {
			tiles,
			score: 30,
			concealed: true
		}
	},
	
].map((func, index) => {
	let output = func()
	output.section = "369"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

