const {TileFrequencies} = require("./TileFrequencies")

//Calculates the weighted difference between a combo and the target hand 
//Basically, estimate the difficulty of achieving the hand. 

function calculateWeightedDiff(combo) {
    let weightedDiff = combo.diff
    if (combo.diff <= 0 || combo.diff == Infinity) {return combo.diff} //We are Mahjong

    //This function is obviously far from perfect, but should serve as a decent estimator for the difficulty of winning with a specific hand. 
    
    //We will punish for:
    //1. Uncallable tiles (OR, temporarily uncallable)
    //2. Tiles that cannot use jokers
    //3. Tiles for which multiple are required
    
    //Apply some weighting to reduce the overuse of concealed and jokerless hands.
    let uncallablePenaltyMultiple = 0.40 * (combo.diff - 1) / (combo.diff) //Point penalty per unfilled spot that can't be called for. As we get closer to Mahjong, this matters less as we can always call final tile. 
    
    //Penalty for non-joker should increase as we get closer to Mahjong, as the odds of us getting held up on this tile increase. 
    let noJokerPenaltyMultiple = 0.35 * ((combo.diff + 1) / combo.diff) //Point penalty per unfilled spot that requires a non-joker.

    const freqs = new TileFrequencies()
    
    for (let tile of combo.noFillJoker) {
        freqs.increase(tile, 1)
    }
    for (let tile of combo.canFillJoker) {
        freqs.increase(tile, 1)
    }

    let uncallableMatches = combo.handOption.tiles.filter((item) => {return item.length < 3})
    function isTileUncallable(tile) {
        return uncallableMatches.some((match) => {
            return tile.matches(match[0])
        })
    }


    for (let tile of freqs.getAllTiles()) {
        let count = freqs.get(tile)

        let canFillJoker = combo.canFillJoker.some((tileToMatch) => {
            return tileToMatch.matches(tile)
        })
        
        while (count > 0) {
            let totalPenalty = 1
            if (
                combo.handOption.concealed
                || isTileUncallable(tile) //Tile can never be called for
                ) {
                totalPenalty *= 1 + uncallablePenaltyMultiple
            }
            else if (
                canFillJoker && count - combo.jokerCount > 1 //Cannot currently be called for even with jokers
                || !canFillJoker && count > 1 //Cannot currently be called for without jokers. 
            ) {
                totalPenalty *= 1 + uncallablePenaltyMultiple / 2 //Apply a reduced uncallable penalty for temporarily uncallable. 
            }

            if (!canFillJoker) {
                totalPenalty *= 1 + noJokerPenaltyMultiple
            }

            //Now punish if we need multiple. 
            if (count > 1) {
                //We want to punish more going from 3 to 4 than 2 to 3, for example, as the final tile is harder to acquire. 
                //This penalty will be MUCH higher if jokers aren't possible. 
                if (canFillJoker) {
                    totalPenalty *= 1 + (count - 1) ** 1.5 / 10
                }
                else {
                    totalPenalty *= 1 + (count - 1) ** 1.5 / 5
                }
            }

            weightedDiff += totalPenalty - 1
            count--
        }
    }


    //If jokers are capped, apply a penalty to all tiles that could be filled with jokers.
    //Note that this doesn't account for things like current joker count and what the cap actually is. 
    if (combo.handOption.maxJokers !== undefined) {
        weightedDiff += Math.max(0, combo.canFillJoker.length - combo.handOption.maxJokers) * noJokerPenaltyMultiple /2
    }

    return weightedDiff
}

module.exports = {calculateWeightedDiff}