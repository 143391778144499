const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
//Supported.
module.exports = [
    //WING AN AA PPP RER (I=1; AA any Pr.; P any Pung) - 50
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)
        
        //WING
        newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "green", amount: 1}))

        //AN
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "north", amount: 1}))

        //AA
        newArr.push(createTiles({type: "any", value: "any", amount: 2}))

        //PPP
        newArr.push(createTiles({type: "any", value: "any", amount: 3}))

        //RER
        newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))

        return {
            tiles,
            score: 50,
            concealed: false,
        }
    },
    //A IMAGINAIRE BS2 (A any tile; I=1, M=W; B any Bam) - C50
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)
        
        //A
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))

        //IMAGINAIRE
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "green", amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "east", amount: 1}))


        //BS2
        newArr.push(createTiles({type: "bamboo", value: "any", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
        newArr.push(createTiles({type: "any", value: 2, amount: 1}))


        return {
            tiles,
            score: 50,
            concealed: true,
        }
    },
    //FLI2E OF FAN2ASI (L=7, I=1; A any tile) - C50
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)
        
        //FLI2E
        newArr.push(createTiles({type: "flower", amount: 1}))
        newArr.push(createTiles({type: "any", value: 7, amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "any", value: 2, amount: 1}))
        newArr.push(createTiles({type: "wind", value: "east", amount: 1}))

        //OF
        newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
        newArr.push(createTiles({type: "flower", amount: 1}))


        //FAN2ASI
        newArr.push(createTiles({type: "flower", amount: 1}))
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
        newArr.push(createTiles({type: "any", value: 2, amount: 1}))
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))


        return {
            tiles,
            score: 50,
            concealed: true,
        }
    },
    //FAIRIE 2AIL LORE (A any tile, I=1; L=7) - C75
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)
        
        //FAIRIE
        newArr.push(createTiles({type: "flower", amount: 1}))
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "wind", value: "east", amount: 1}))

        //2AIL
        newArr.push(createTiles({type: "any", value: 2, amount: 1}))
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "any", value: 7, amount: 1}))

        //LORE
        newArr.push(createTiles({type: "any", value: 7, amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "east", amount: 1}))

        return {
            tiles,
            score: 75,
            concealed: true,
        }
    },
    //A SOARING ANIMAL (A any tile; I=1; M=W, L=7) - C75
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)
        
        //A
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))

        //SOARING
        newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "green", amount: 1}))

        //ANIMAL
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))
        newArr.push(createTiles({type: "any", value: 7, amount: 1}))

        return {
            tiles,
            score: 75,
            concealed: true,
        }
    },
    //MJ A LL BI MI SEL FF (M= W, J=Joker; A any tile, L=7 Pr.; B any Bam, I=I) - C75
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)
        
        //MJ
        newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
        newArr.push(createTiles({type: "joker", amount: 1}))

        //A
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))

        //LL
        newArr.push(createTiles({type: "any", value: 7, amount: 2}))

        //BI
        newArr.push(createTiles({type: "bamboo", value: "any", amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))

        //MI
        newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))

        //SEL
        newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
        newArr.push(createTiles({type: "any", value: 7, amount: 1}))

        //FF
        newArr.push(createTiles({type: "flower", amount: 2}))


        return {
            tiles,
            score: 75,
            concealed: true,
        }
    },
    //12345 ING SCARED (any consec. run, 1 suit, I=1; C any Crak, A any tile, D any D) - C1.00
    function(tiles = []) {

        allOptions.slice(0, -4).forEach((offset) => {
            allSuits.forEach((suit) => {
                let newArr = []
                tiles.push(newArr)
                
                //12345
                newArr.push(createTiles({type: suit, value: offset + 0, amount: 1}))
                newArr.push(createTiles({type: suit, value: offset + 1, amount: 1}))
                newArr.push(createTiles({type: suit, value: offset + 2, amount: 1}))
                newArr.push(createTiles({type: suit, value: offset + 3, amount: 1}))
                newArr.push(createTiles({type: suit, value: offset + 4, amount: 1}))
        
                //ING
                newArr.push(createTiles({type: "any", value: 1, amount: 1}))
                newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
                newArr.push(createTiles({type: "dragon", value: "green", amount: 1}))

                //SCARED
                newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
                newArr.push(createTiles({type: "character", value: "any", amount: 1}))
                newArr.push(createTiles({type: "any", value: "any", amount: 1}))
                newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
                newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
                newArr.push(createTiles({type: "dragon", value: "any", amount: 1}))
            })
        })

        return {
            tiles,
            score: 100,
            concealed: true,
        }
    },
].map((func, index) => {
	let output = func()
	output.section = "Slay The Beast"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

