const {i18n} = require("../../../lib/i18nHelper.js")
const Popups = require("../Popups.js")
const {resumeOfflineGame} = require("./resumeOfflineGame.js")
const {Capacitor} = require("@capacitor/core");

//Create dummy file input.
let fileInput = document.createElement("input")
fileInput.style.display = "none"
fileInput.type = "file"
fileInput.accept = ".room,application/json,application/octet-stream" //iOS mobile does not support file extensions in accept, so add application/octet-stream. 
document.body.appendChild(fileInput)

function openSaveFileMenu() {
	//Alert the user about the save.
	let elem = new DocumentFragment() //We want the dismiss button to be on the same line, so use a fake container.
	let popup;

	let p = document.createElement("p")
	p.innerText = i18n.__("You can upload/drag a save file from your device, or download one of ours: ")
	p.id = "messageText"
	elem.appendChild(p)


	function addColumn(row, text) {
		let sectionNameColumn = document.createElement("td")
		sectionNameColumn.innerHTML = `<span>${text}</span>`
		row.appendChild(sectionNameColumn)
	}

	let tableContainer = document.createElement("div")
	tableContainer.className = "guaranteedHandsTableContainer"
	tableContainer.innerText = i18n.__("Loading Tutorials and Challenges...")
	elem.appendChild(tableContainer)

	;((async function() {
		let obj;
		try {
			let req = await fetch((Capacitor.isNativePlatform()?"https://mahjong4friends.com/":"") + "guaranteedHands.json")
			obj = await req.json()
			console.log(obj)
		}
		catch (e) {
			tableContainer.innerText = i18n.__("Error Loading Tutorials and Challenges...")
			return
		}

		let table = document.createElement("table")
		table.className = "guaranteedHandsTable"
		tableContainer.innerText = ""
		tableContainer.appendChild(table)

		let header = document.createElement("tr")
		table.appendChild(header)
		addColumn(header, i18n.__("Card"))
		addColumn(header, i18n.__("Section"))
		addColumn(header, i18n.__("Option"))
		addColumn(header, i18n.__("Item"))

		class ExpandableItem {
			constructor({obj, key, columns = [], depth = 0}) {
				this.elem = document.createElement("tr")

				this.columns = columns.slice(0)
				this.columns.push(key)

				this.depth = depth

				for (let i=0;i<this.columns.length;i++) {
					//Adjust text without adjusting other contents.
					let columnKey = this.columns[i]

					if (i === 2) {
						columnKey = "#" + columnKey
					}
					addColumn(this.elem, columnKey)
				}

				columns = this.columns.slice(0)

				this.elem.className = "guaranteedHands" + depth

				this.obj = obj[key]

				this.elem.addEventListener("click", (function() {
					this.setExpanded()
				}).bind(this))
			}

			emptyColumns = 0
			addEmptyColumns(amount) {
				this.emptyColumns = amount
				while (this.elem.children.length < amount) {
					addColumn(this.elem, "")
				}
				this.items.forEach((item) => {item.addEmptyColumns(amount)})
			}

			items = []
			setExpanded(expanded) {
				console.log(this.obj)
				//If expanded is false, collapse. If true, expand. If undefined, toggle.
				this.items.forEach((item) => {
					item.setExpanded(false)
					item.elem.remove()
				})

				let insertBeforeElem = this.elem.nextSibling
				if (expanded === true || expanded === undefined && this.items.length === 0) {
					this.items = []
					for (let key in this.obj) {

						let item = this.obj[key]
						let itemToInsert;
						if (item instanceof Object) {
							itemToInsert = new ExpandableItem({obj: this.obj, key, columns: this.columns, depth: this.depth + 1})
						}
						else {
							itemToInsert = new GameSaveItem({obj: this.obj, key, columns: this.columns, depth: this.depth + 1})
						}

						itemToInsert.addEmptyColumns(this.emptyColumns)
						this.items.push(itemToInsert)
						this.elem.parentNode.insertBefore(itemToInsert.elem, insertBeforeElem)
					}
				}
				else {
					this.items = []
				}
			}
		}

		class GameSaveItem extends ExpandableItem {
			constructor(config) {
				super(config)
				this.elem.className = "saveRow"
				this.elem.lastChild.remove()
				addColumn(this.elem, config.key) // + ` (${Math.round(config.obj[config.key] / 1000)}kB)`
			}

			setExpanded(status) {
				if (status !== false) {
					//We were clicked.
					let baseUrl = window.location.origin + window.location.pathname
					if (Capacitor.isNativePlatform()) {
						baseUrl = "https://mahjong4friends.com/"
					}
					console.log(this.columns)
					//TODO: The base URI needs to be included in the file.
					baseUrl += encodeURI(`mahjong-tutorials/${this.columns.join("/")}.room`)
					console.log(baseUrl)
					;(async function() {
						try {
							let req = await fetch(baseUrl)
							let text = await req.text()
							console.log(text)
							resumeOfflineGame(text, false)
						}
						catch (e) {
							console.error(e)
							alert("Error Downloading Save File")
						}
					}())
				}
			}
		}

		Object.keys(obj).forEach((cardName) => {
			let expandable = new ExpandableItem({obj, key: cardName})
			expandable.addEmptyColumns(4)
			table.appendChild(expandable.elem)
		})
	})())

	async function processSaveFile(file) {
		if (file) {
			let reader = new FileReader()
			await new Promise((r, j) => {
				reader.onload = r
				reader.onerror = j
				reader.readAsText(file)
			})
			let text = reader.result
			try {
				resumeOfflineGame(text, false)
				popup.dismiss()
				//Reset any selected files - if the user uploads the same file twice, we should load it twice. (they may have closed the game the first time)
				fileInput.value = ""
			}
			catch (e) {
				console.error(e)
				alert("Error loading save. Please make sure you selected the correct file. ")
			}
		}
	}

	let uploadFromDevice = document.createElement("button")
	uploadFromDevice.innerText = i18n.__("Upload From Device")
	fileInput.oninput = function() {
		processSaveFile(fileInput.files[0])
	}

	uploadFromDevice.addEventListener("click", function() {
		fileInput.click()
	})
	uploadFromDevice.classList.add("roomManagerButton", "orangeGreenGradient")
	elem.appendChild(uploadFromDevice)

	popup = new Popups.Notification(i18n.__("Select Save File"), elem)
	let popupBox = popup.show()

	;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
		popupBox.addEventListener(eventName, preventDefaults, false)
	})

	function preventDefaults (e) {
		e.preventDefault()
		e.stopPropagation()
	}

	//Allow drag drop
	;['dragenter', 'dragover'].forEach(eventName => {
		popupBox.addEventListener(eventName, highlight, false)
	})

	;['dragleave', 'drop'].forEach(eventName => {
		popupBox.addEventListener(eventName, unhighlight, false)
	})

	function highlight(e) {
		popupBox.style.filter = "brightness(1.25)"
	}

	function unhighlight(e) {
		popupBox.style.filter = ""
	}

	popupBox.addEventListener('drop', handleDrop, false)

	function handleDrop(e) {
		unhighlight()
		processSaveFile(e.dataTransfer.files[0])
	}
}


module.exports = {openSaveFileMenu}