//Creates fake websocket connection to offline server.

class FakeWebsocket {
	readyState = 1 //Always open. 
    addEventListener(type, listener) {
        if (type === "open") {setTimeout(listener, 0)} //Socket is already open
        else if (type === "close") {} //Socket never closes
        else {
            console.warn("Event listener type " + type + " not supported. ")
        }   
    }
}


class FakeWebsocketForServer extends FakeWebsocket {
	on(listener, func) {
		this.onmessage = func //serverStateManager has set the onmessage event. 
	}
	
	//For serverStateManager to send responses back.
	//stateManager won't exist immediately, so just don't send errors to console if it doesn't exist
	send(message) {
		window?.stateManager?.onMessage?.({data: message})
	}
}


class FakeWebsocketForClient extends FakeWebsocket {
	constructor(targetServerWebsocket) {
		super()
		this.targetServerWebsocket = targetServerWebsocket
	}

	send(message) {
		//Send message to serverStateManager
		this.targetServerWebsocket.onmessage(message)
	}
}


let offlineServerWebsocket = new FakeWebsocketForServer()

//Create the stateManager for the offline server. 
const ServerStateManager = require("../../../server/StateManager.js")
globalThis.serverStateManager = new ServerStateManager()


//Connect fake socket to server
let serverMessageHandler = require("../../../server/messageHandler.js")
serverMessageHandler(offlineServerWebsocket)


let clientConnectorWebsocket = new FakeWebsocketForClient(offlineServerWebsocket)
clientConnectorWebsocket.send(JSON.stringify({clientId: "offlineUserClientId"})) //The actual clientId is irrelevant in offline games. 


export {
	clientConnectorWebsocket as offlineServerWebsocket
}

