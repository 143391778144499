import {purchasePackage, getPurchasesInstance, hasEntitlement} from "./store"
import {AD_FREE_ENTITLEMENT, PREMIUM_ENTITLEMENT} from "../../../lib/monetization_constants";


//It should be incredibly rare for hasEntitlement to fail. Currently, the only
//known situation this happens is when running the new code on the old 7.2 or below apps that
//don't have the native RevenueCat plugin installed.
async function hasPremium() {
    try {
        return await hasEntitlement(PREMIUM_ENTITLEMENT)
    }
    catch (e) {
        console.error("Could not verify premium", e)
        return false
    }}

async function hasAdFree() {
    try {
        return await hasEntitlement(AD_FREE_ENTITLEMENT)
    }
    catch (e) {
        console.error("Could not verify ad-free", e)
        return true
    }
}

export {hasAdFree, hasPremium}