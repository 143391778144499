const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//(333 6666) (666 9999) - 2 Suits
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 3}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 4}))
			
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 3}))
			newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 4}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FFFF 3333 66 9999 (1 or 3 Suits)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 4}))
			
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 4}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 2}))
			newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 4}))
		})
		
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 4}))
			
			newArr.push(createTiles({type: suit, value: 3, amount: 4}))
			newArr.push(createTiles({type: suit, value: 6, amount: 2}))
			newArr.push(createTiles({type: suit, value: 9, amount: 4}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//(33 66) (333 666) (9999)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 2}))
			
			newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 3}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 3}))
			
			newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 4}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FF (33 66 99) DDD DDD (3 suits)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 2}))
			
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 2}))
			
			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 3}))
			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[2]], amount: 3}))
		})
		
		return {
			tiles,
			score: 30,
			concealed: false
		}
	},
	//(3 66 999) (3333) (3333)
	//Per NMJL Clarification, Kongs must be identical numbers. 
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			;[3,6,9].forEach((num) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 3}))
				
				newArr.push(createTiles({type: suitOrder[1], value: num, amount: 4}))
				newArr.push(createTiles({type: suitOrder[2], value: num, amount: 4}))	
			})
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//3333 666 9999 DDD (2 suits, 6's and dragons match)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 4}))
			
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 3}))
			
			newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 4}))
			
			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 3}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//(333 6 999) (333 6 999) - 2 suits
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 3}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 3}))
			
			newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 3}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 3}))
		})
		
		return {
			tiles,
			score: 30,
			concealed: true
		}
	},
	
].map((func, index) => {
	let output = func()
	output.section = "369"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

