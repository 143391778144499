const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
//Supported.
module.exports = [
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)

		for (let i=0;i<7;i++) {
			newArr.push(createTiles({type: "any", value: "any", amount: 2}))
		}

		return {
			tiles,
			score: 30,
			concealed: true,
			isAtomic: true, //Indicates no duplicate anys, custom code.
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "Atomic"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

