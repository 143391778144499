const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
//Supported.
module.exports = [
	//888 2IMES 3333 = 24 (#’s 1 or 3 suits; I=1, M=W) - 30 Points
	function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            let newArr = []
            tiles.push(newArr)

            //888
            newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 3}))

            //2IMES
            newArr.push(createTiles({type: "any", value: 2, amount: 1}))
            newArr.push(createTiles({type: "any", value: 1, amount: 1}))
            newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
            newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
            newArr.push(createTiles({type: "wind", value: "south", amount: 1}))

            //3333
            newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 4}))

            //24
            newArr.push(createTiles({type: suitOrder[2], value: 2, amount: 1}))
            newArr.push(createTiles({type: suitOrder[2], value: 4, amount: 1}))
        })	

        allSuits.forEach((suit) => {
            let newArr = []
            tiles.push(newArr)

            //888
            newArr.push(createTiles({type: suit, value: 8, amount: 3}))

            //2IMES
            newArr.push(createTiles({type: "any", value: 2, amount: 1}))
            newArr.push(createTiles({type: "any", value: 1, amount: 1}))
            newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
            newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
            newArr.push(createTiles({type: "wind", value: "south", amount: 1}))

            //3333
            newArr.push(createTiles({type: suit, value: 3, amount: 4}))

            //24
            newArr.push(createTiles({type: suit, value: 2, amount: 1}))
            newArr.push(createTiles({type: suit, value: 4, amount: 1}))
        })	

		return {
			tiles,
			score: 30,
			concealed: false,
		}
	},
    //38.4325 N 109.186 W (#’s 2 suits; Dragon’s Tail, UT- long.. and lat.) - C 25 Points
	function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            let newArr = []
            tiles.push(newArr)

            //384325N
            newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 5, amount: 1}))
            newArr.push(createTiles({type: "wind", value: "north", amount: 1}))

            //109186W
            newArr.push(createTiles({type: suitOrder[1], value: 1, amount: 1}))
            newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
            newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 1}))
            newArr.push(createTiles({type: suitOrder[1], value: 1, amount: 1}))
            newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 1}))
            newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 1}))
            newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
        })	

		return {
			tiles,
			score: 25,
			concealed: true,
		}
	},
    //FF 1223 2334 3445 (Any increasing consec. runs, 3 suits) - C 40 Points
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            allOptions.slice(0, -4).forEach((offset) => {
                let newArr = []
                tiles.push(newArr)
    
                //FF
                newArr.push(createTiles({type: "flower", amount: 2}))
    
                //1223
                newArr.push(createTiles({type: suitOrder[0], value: offset + 0, amount: 1}))
                newArr.push(createTiles({type: suitOrder[0], value: offset + 1, amount: 2}))
                newArr.push(createTiles({type: suitOrder[0], value: offset + 2, amount: 1}))
    
                //2334
                newArr.push(createTiles({type: suitOrder[1], value: offset + 1, amount: 1}))
                newArr.push(createTiles({type: suitOrder[1], value: offset + 2, amount: 2}))
                newArr.push(createTiles({type: suitOrder[1], value: offset + 3, amount: 1}))

                //3445
                newArr.push(createTiles({type: suitOrder[2], value: offset + 2, amount: 1}))
                newArr.push(createTiles({type: suitOrder[2], value: offset + 3, amount: 2}))
                newArr.push(createTiles({type: suitOrder[2], value: offset + 4, amount: 1}))
            })
        })	

		return {
			tiles,
			score: 40,
			concealed: true,
		}
	},
    //AA 1964 1976 1988 (AA any tile; #’s 3 suits) Year of the Dragon - C50 Points
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            allOptions.slice(0, -4).forEach((offset) => {
                let newArr = []
                tiles.push(newArr)
    
                //AA
                newArr.push(createTiles({type: "any", value: "any", amount: 2}))

                //1964
                newArr.push(createTiles({type: suitOrder[0], value: 1, amount: 1}))
                newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 1}))
                newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 1}))
                newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 1}))


                //1976
                newArr.push(createTiles({type: suitOrder[1], value: 1, amount: 1}))
                newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 1}))
                newArr.push(createTiles({type: suitOrder[1], value: 7, amount: 1}))
                newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 1}))

                //1988
                newArr.push(createTiles({type: suitOrder[2], value: 1, amount: 1}))
                newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 1}))
                newArr.push(createTiles({type: suitOrder[2], value: 8, amount: 1}))
                newArr.push(createTiles({type: suitOrder[2], value: 8, amount: 1}))
            })
        })	

		return {
			tiles,
			score: 50,
			concealed: true,
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "It's a Numbers Game"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

