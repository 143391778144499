const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
//Supported.
module.exports = [
	//KKKK WWW 3 666 999 (K any Kong; any Wind; #’s 1 suit) - 25 Points
    function(tiles = []) {
        allSuits.forEach((suit) => {
            let newArr = []
            tiles.push(newArr)
            
            //KKKK
            newArr.push(createTiles({type: "any", value: "any", amount: 4}))
            
            //WWW
            newArr.push(createTiles({type: "wind", value: "any", amount: 3}))
            
            //3 666 999
            newArr.push(createTiles({type: suit, value: 3, amount: 1}))
            newArr.push(createTiles({type: suit, value: 6, amount: 3}))
            newArr.push(createTiles({type: suit, value: 9, amount: 3}))
        })
        
        return {
            tiles,
            score: 25,
            concealed: false,
        }
    },
    //F 999 36 666 36 999 (3 suits) - 25 Points
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            let newArr = []
            tiles.push(newArr)

            //F
            newArr.push(createTiles({type: "flower", amount: 1}))
            
            //999 36
            newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 3}))

            newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 1}))
            
            //666
            newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 3}))
            
            //36 999
            newArr.push(createTiles({type: suitOrder[2], value: 3, amount: 1}))
            newArr.push(createTiles({type: suitOrder[2], value: 6, amount: 1}))

            newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 3}))
        })
        
        return {
            tiles,
            score: 25,
            concealed: false,
        }
    },
    //FF 33 6666 999 PPP (#’s 2 suits; P any Pung) - 25 Points
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            let newArr = []
            tiles.push(newArr)

            //FF
            newArr.push(createTiles({type: "flower", amount: 2}))
            
            //33
            newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 2}))
            
            //6666
            newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 4}))
            
            //999
            newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 3}))
            
            //PPP
            newArr.push(createTiles({type: "any", value: "any", amount: 3}))
        })
        
        return {
            tiles,
            score: 25,
            concealed: false,
        }
    },
    //MJ PPP 333 666 999 (M=W, J=Joker; P any Pung, 1 or 3 suits) - 35 Points
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            
            let newArr = []
            tiles.push(newArr)

            //MJ
            newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
            newArr.push(createTiles({type: "joker", amount: 1}))
            
            //PPP
            newArr.push(createTiles({type: "any", value: "any", amount: 3}))
            
            //333 666 999
            newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 3}))
            newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 3}))
            newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 3}))
        })

        allSuits.forEach((suit) => {
            
            let newArr = []
            tiles.push(newArr)
            
            //MJ
            newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
            newArr.push(createTiles({type: "joker", amount: 1}))
            
            //PPP
            newArr.push(createTiles({type: "any", value: "any", amount: 3}))
            
            //333 666 999
            newArr.push(createTiles({type: suit, value: 3, amount: 3}))
            newArr.push(createTiles({type: suit, value: 6, amount: 3}))
            newArr.push(createTiles({type: suit, value: 9, amount: 3}))
        })
        
        return {
            tiles,
            score: 35,
            concealed: false,
        }
    },
    //SCALES 333 666 99 (C any Crak, A any tile, L=7; #’s 1 suit) - 40 Points
    function(tiles = []) {
        allSuits.forEach((suit) => {
            
            let newArr = []
            tiles.push(newArr)

            //SCALES
            newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
            newArr.push(createTiles({type: "character", value: "any", amount: 1}))
            newArr.push(createTiles({type: "any", value: "any", amount: 1}))
            newArr.push(createTiles({type: "any", value: 7, amount: 1}))
            newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
            newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
            
            //333 666 99
            newArr.push(createTiles({type: suit, value: 3, amount: 3}))
            newArr.push(createTiles({type: suit, value: 6, amount: 3}))
            newArr.push(createTiles({type: suit, value: 9, amount: 2}))
        })
        
        return {
            tiles,
            score: 40,
            concealed: false,
        }
    },
].map((func, index) => {
	let output = func()
	output.section = "3 Kingdoms"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

