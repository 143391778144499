const Hand = require("../../lib/Hand")
const localizeSummary = require("../localizeJustInTime.js").localizeSummary

const {logCompletedGame} = require("./logCompletedGame.js")

//TODO: Mahjong validation code currently needs to be located in two places - once in turnChoicesProxyHandler to determine if a hand will BECOME mahjong, then again
//in here to determine if a hand IS mahjong. (so seperate paths calling for mahjong, vs in hand mahjong, due to need to verify all moves before advancing with turns)

//The code should be de-duplicated some. This is unnecessary.
function goMahjong(clientId, options = {}) {
	//options.override

	//First, verify the user can go mahjong.
	let client = globalThis.serverStateManager.getClient(clientId)
	let hand = this.gameData.playerHands[clientId]

	let ignoreMahjong = this.state.settings.ignoreBotMahjong && client.isBot

	if (this.state.settings.gameStyle === "chinese") {
		//On override, always allow unlimited (4) sequences, as if the overrides are purely sequence limits (forgot to change the setting,
		//the scoring will now be correct, not incorrect)
		let isMahjong = hand.isMahjong(options.override?4:this.state.settings.maximumSequences, {thrownTile: options.autoExpose && this.gameData.previousTurnPickedUp})
		if (isMahjong instanceof Hand && !ignoreMahjong) {
			hand.contents = isMahjong.contents //Autocomplete the mahjong.
		}
		if (!isMahjong && !options.override) {
			return client.message("roomActionPlaceTiles", "Unable to go mahjong with this hand. If you play by different rules, try again to override. ", "error")
		}
	}
	else if (this.state.settings.gameStyle === "american") {
		//TODO: We need to implement options.autoExpose


		//TODO: Sort hands 



		//TODO: Sort hands so they appear properly sorted (like they appear on the card)
		//See https://github.com/ecc521/mahjong/issues/25

		let score = hand.score({type: "american", card: this.gameData.card})

		if (score.score === 0 && !this.state.settings.unknownCard) {
			let reasons = ""
			if (score.reasons?.length > 0) {
				reasons += "<h3>Common Mistakes Detected: </h3><ul>"
				for (let reason of score.reasons) {
					reasons += `<li>${reason}</li>`
				}
				reasons += "</ul>"
			}

			//TODO: Link instructions on how to change the card. 
			if (this?.gameData?.card?.name?.includes?.("National Mah Jongg")) {
				//We do not permit overrides on the NMJL card. 
				client.message("displayMessage", {title: "THIS HAND IS NOT MAH JONGG", body: `<div class="settingsMenuDiv">${reasons}<h3>General Information: </h3><ul><li>Room configured for ${this.gameData.card.name} card. <ul><li>Card can be changed from the room screen by the host. </li><li>Need Help? <a target="_blank" href="https://www.youtube.com/watch?v=eGm42itrRPQ">Video Walkthrough</a></li></ul></li><li>Please be aware that suits must match card colors. <ul><li>For example, if a 11 and 22 match in color on the card, they must have the same suit. If they are different, they cannot match suit. </li></ul></li><li>If, after reviewing the rules on your card and the tips on this page, you believe this hand should be mahjong, please email support@mahjong4friends.com with a screenshot of this hand, and the hand's position on the card (ex, 369 #5). <ul></ul></li></ul></div>`})
				return;
			}
			else if (!options.override) {
				client.message("displayMessage", {title: "THIS HAND IS NOT MAH JONGG", body: `<div class="settingsMenuDiv">${reasons}<h3>General Information: </h3><ul><li>Room configured for ${this.gameData.card.name} card. <ul><li>Card can be changed from the room screen by the host. </li><li>Need Help? <a target="_blank" href="https://www.youtube.com/watch?v=eGm42itrRPQ">Video Walkthrough</a></li></ul></li><li>Please be aware that suits must match card colors. <ul><li>For example, if a 11 and 22 match in color on the card, they must have the same suit. If they are different, they cannot match suit. </li></ul></li><li>If, after reviewing the rules on your card and the tips on this page, you believe this hand should be mahjong, please email support@mahjong4friends.com with a screenshot of this hand, and the hand's position on the card (ex, 369 #5). <ul></ul></li><li>You can click the Mahjong button again to override Mahjong checking. The hand will be scored as 0 points. </li></ul></div>`})
				return;
			}
		}

		for (let id in this.gameData.playerHands) {
			logCompletedGame.call(this, {
				uid: id,
				result: clientId === id ? "win" : "loss",
				handName: score.handName //If we don't know the handName (overrides), it will be "Unable to Score"
			})
		}
	}
	else if (this.state.settings.gameStyle === "filipino") {
		let isMahjong = hand.isMahjong(this.state.settings.maximumSequences, {thrownTile: options.autoExpose && this.gameData.previousTurnPickedUp})
		if (isMahjong instanceof Hand && !ignoreMahjong) {
			hand.contents = isMahjong.contents //Autocomplete the mahjong.
		}
		if (!isMahjong && !options.override) {
			return client.message("roomActionPlaceTiles", "Mahjong not detected - if this is a special hand, press Mahjong again. ", "error")
		}
	}

	hand.status = {
		status: "mahjong",
		drewOwnTile: !this.gameData.previousTurnPickedUp
	}

	if (!ignoreMahjong) {
		//The game is over.
		this.gameData.currentTurn.userTurn = clientId
		this.gameData.isMahjong = true

		this.sendStateToClients()
		//If East wins, do not rotate.
		//We can't rotate until the game is actually ended, as otherwise that would break revert.
		//Therefore, we simply set a flag that is read is room.endGame.
		if (this.state.settings.windAssignments[clientId] === "east") {
			this.shouldRotateWinds = false
		}
	}

	this.messageAll([clientId], "roomActionGameplayAlert", {format: "%s went mahjong!", args:this.getClientNickname(client.clientId)}, {clientId, speech: "Mahjong"})

	this.setAllInstructions([clientId], {format: ["%s went mahjong!", "\n", "Press End Game to return everybody to the room screen. "], args:this.getClientNickname(client.clientId)})
	this.setInstructions(clientId, {format: ["%s went mahjong!", "\n", "Press End Game to return everybody to the room screen. "], argsI18n:"You"})

	this.getSummary(clientId, options)

	this.messageAll([], "displayMessage", {title: "Mahjong!", body: {format:"%(summary)s", args:{summary:"placeholder"}, argsOption:{summary:localizeSummary}}}, "success")

	setTimeout((function() {
		//Offset this call for bots (which are synchronus) to avoid infinite recursion if their Mahjong is ignored.
		this.sendStateToClients()
	}).bind(this), 0)
}

module.exports = goMahjong
