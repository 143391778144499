const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
//Supported.
module.exports = [
    //33333 45 66666 78 (any run; 1 or 3 suits) - 40
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            allOptions.slice(0, -5).forEach((offset) => {
                let newArr = []
                tiles.push(newArr)
                
                //33333
                newArr.push(createTiles({type: suitOrder[0], value: offset + 0, amount: 5}))
                
                //45
                newArr.push(createTiles({type: suitOrder[1], value: offset + 1, amount: 1}))
                newArr.push(createTiles({type: suitOrder[1], value: offset + 2, amount: 1}))
                
                //66666
                newArr.push(createTiles({type: suitOrder[2], value: offset + 3, amount: 5}))
                
                //78
                newArr.push(createTiles({type: suitOrder[1], value: offset + 4, amount: 1}))
                newArr.push(createTiles({type: suitOrder[1], value: offset + 5, amount: 1}))
            })
        })	
        
        allSuits.forEach((suit) => {
            allOptions.slice(0, -5).forEach((offset) => {
                let newArr = []
                tiles.push(newArr)
                
                //33333
                newArr.push(createTiles({type: suit, value: offset + 0, amount: 5}))
                
                //45
                newArr.push(createTiles({type: suit, value: offset + 1, amount: 1}))
                newArr.push(createTiles({type: suit, value: offset + 2, amount: 1}))
                
                //66666
                newArr.push(createTiles({type: suit, value: offset + 3, amount: 5}))
                
                //78
                newArr.push(createTiles({type: suit, value: offset + 4, amount: 1}))
                newArr.push(createTiles({type: suit, value: offset + 5, amount: 1}))
            })
        })	
        
        return {
            tiles,
            score: 40,
            concealed: false,
        }
    },
    //CCC RL III QQQQQ S (C any Crak Pung, L =7, I=1, Q any Quint) - 50
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)
        
        //CCC
        newArr.push(createTiles({type: "character", value: "any", amount: 3}))
        
        //RL
        newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
        newArr.push(createTiles({type: "any", value: 7, amount: 1}))
        
        //III
        newArr.push(createTiles({type: "any", value: 1, amount: 3}))
        
        //QQQQQ
        newArr.push(createTiles({type: "any", value: "any", amount: 5}))
        
        //S
        newArr.push(createTiles({type: "wind", value: "south", amount: 1}))

        return {
            tiles,
            score: 50,
            concealed: false,
        }
    },
    //AW KKKK QQQQQ ARD (A any tile, K any Kong, Q any Quint, D any D) - 50
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)
        
        //AW
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
        
        //KKKK
        newArr.push(createTiles({type: "any", value: "any", amount: 4}))
        
        //QQQQQ
        newArr.push(createTiles({type: "any", value: "any", amount: 5}))
        
        //ARD
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "any", amount: 1}))
        
        return {
            tiles,
            score: 50,
            concealed: false,
        }
    },
    //QQQQQ ALIFI 44 MJ (Q any Quint, A any tile, L=7, I=1 M=W, J=Joker) - 50
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)

        //QQQQQ
        newArr.push(createTiles({type: "any", value: "any", amount: 5}))        
        
        //ALIFI
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))        
        newArr.push(createTiles({type: "any", value: 7, amount: 1}))        
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))        
        newArr.push(createTiles({type: "flower", amount: 1}))        
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))        

        //44
        newArr.push(createTiles({type: "any", value: 4, amount: 2}))        

        //MJ
        newArr.push(createTiles({type: "wind", value: "west", amount: 1}))        
        newArr.push(createTiles({type: "joker", amount: 1}))
        
        return {
            tiles,
            score: 50,
            concealed: false,
        }
    },
    //BBBBB IG AND LOWD (B any Bam Quint, I=1; A any tile, D any D; L=7) - 75
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)

        //BBBBB
        newArr.push(createTiles({type: "bamboo", value: "any", amount: 5}))        
        
        //IG
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))        
        newArr.push(createTiles({type: "dragon", value: "green", amount: 1}))             

        //AND
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))        
        newArr.push(createTiles({type: "wind", value: "north", amount: 1}))        
        newArr.push(createTiles({type: "dragon", value: "any", amount: 1}))        

        //LOWD
        newArr.push(createTiles({type: "any", value: 7, amount: 1}))        
        newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))        
        newArr.push(createTiles({type: "wind", value: "west", amount: 1}))        
        newArr.push(createTiles({type: "dragon", value: "any", amount: 1}))        
        
        return {
            tiles,
            score: 75,
            concealed: false,
        }
    },
].map((func, index) => {
    let output = func()
    output.section = "It's No Joke"
    output.sectionIndex = index + 1 //Sections indexed starting at 1. 
    output.handName = `${output.section} #${output.sectionIndex}`
    return new CardHand(output)
})

