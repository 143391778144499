const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	function(tiles = []) {
		//FFF 22 44 666 8888 (1 Suit)
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "flower", amount: 3}))
			newArr.push(createTiles({type: suit, value: 2, amount: 2}))
			newArr.push(createTiles({type: suit, value: 4, amount: 2}))
			newArr.push(createTiles({type: suit, value: 6, amount: 3}))
			newArr.push(createTiles({type: suit, value: 8, amount: 4}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	function(tiles = []) {
		//(22 46 88) 2222 2222 (Any 3 Suits, Kongs any same Evens)
		allSuitArrangements.forEach((suitOrder) => {
			evenOptions.forEach((num) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 2}))

				newArr.push(createTiles({type: suitOrder[1], value: num, amount: 4}))
				newArr.push(createTiles({type: suitOrder[2], value: num, amount: 4}))
			})
		})

		return {
			tiles,
			score: 30,
			concealed: false
		}
	},
	function(tiles = []) {
		//(222 444) (6666 8888) or (222 444) (6666) (8888)
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 3}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 3}))

			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 4}))
			newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 4}))
		})

		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 3}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 3}))

			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 4}))
			newArr.push(createTiles({type: suitOrder[2], value: 8, amount: 4}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	function(tiles = []) {
		//(2222 44 6666) 88 88 (Any 3 Suits)
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 4}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 4}))

			newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 2}))
			newArr.push(createTiles({type: suitOrder[2], value: 8, amount: 2}))
		})

		return {
			tiles,
			score: 30,
			concealed: false
		}
	},
	function(tiles = []) {
		//(22 444) (66 888) (DDDD) - 1 or 3 Suits.
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 3}))

			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 2}))
			newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 3}))

			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[2]], amount: 4}))
		})

		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: suit, value: 2, amount: 2}))
			newArr.push(createTiles({type: suit, value: 4, amount: 3}))

			newArr.push(createTiles({type: suit, value: 6, amount: 2}))
			newArr.push(createTiles({type: suit, value: 8, amount: 3}))

			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suit], amount: 4}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	function(tiles = []) {
		//FFF 2222 FFF 8888 (Any 2 Suits)
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "flower", amount: 3}))
			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 4}))

			newArr.push(createTiles({type: "flower", amount: 3}))
			newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 4}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	function(tiles = []) {
		//(22 444) (44 666) (8888) - 3 Suits
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 3}))

			newArr.push(createTiles({type: suitOrder[1], value: 4, amount: 2}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 3}))

			newArr.push(createTiles({type: suitOrder[2], value: 8, amount: 4}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	function(tiles = []) {
		//FF (246 888) (246 888) - 2 Suits
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "flower", amount: 2}))

			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 3}))

			newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 4, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 3}))
		})

		return {
			tiles,
			score: 30,
			concealed: true
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "2468"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

