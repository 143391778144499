const Hand = require("../../../lib/Hand")

const renderTiles = require("./renderTiles.js")
const renderPlacemat = require("./renderPlacemat.js")

class ClientHand extends Hand {
	constructor(config = {}) {
		super(config)

		//handForExposed - Optional. If exposed tiles should be placed in a seperate hand, they will be placed here.
		//interactive: Can the user drag and drop to reorder? Click to swap between hand and placemat?
		//tilePlacemat: Element that will allow user to select tiles to expose.

		this.handToRender = config.handToRender
		this.handForExposed = config.handForExposed
		this.tilePlacemat = config.tilePlacemat
		this.interactive = config.interactive || false


		Object.defineProperty(this, "placematLength", {
			get: function getPlacematLength() {
				if (stateManager?.lastState?.message?.settings?.gameStyle === "american") {return 6}
				else {return 4}
			}
		})

		function allowDrop(ev) {
			ev.preventDefault();
		}

		let dropOnHand = (function dropOnHand(ev) {
			ev.preventDefault();
			let randomClass = ev.dataTransfer.getData("randomClass");
			let elem = document.getElementsByClassName(randomClass)[0]
			elem.classList.remove(randomClass)

			let targetIndex;
			for (let i=0;i<this.handToRender.children.length;i++) {
				let child = this.handToRender.children[i]
				let bounds = child.getBoundingClientRect()

				targetIndex = child.tileIndex + 1

				if (ev.clientX < bounds.left + bounds.width / 2) {
					//This child is to the left of the drop point.
					targetIndex-- //Should not be at the very end.
					break;
				}
			}

			if (elem.placematIndex !== undefined) {
				//We are dragging out of the placemat, into the hand.
				this.moveTile(this.inPlacemat[elem.placematIndex], true, targetIndex)
			}
			else {
				//Reordering hand.
				this.moveTile(this.contents[elem.tileIndex], false, targetIndex)
			}
		}).bind(this)

		let dropOnPlacemat = (function dropOnPlacemat(ev) {
			ev.preventDefault();
			let randomClass = ev.dataTransfer.getData("randomClass");
			let elem = document.getElementsByClassName(randomClass)[0]
			elem.classList.remove(randomClass)

			this.moveTile(this.contents[elem.tileIndex])
		}).bind(this)

		if (this.interactive) {
			this.handToRender.addEventListener("dragover", allowDrop)
			this.handToRender.addEventListener("dragenter", allowDrop)
			this.handToRender.addEventListener("drop", dropOnHand)

			if (this.tilePlacemat) {
				this.tilePlacemat.addEventListener("dragover", allowDrop)
				this.tilePlacemat.addEventListener("dragenter", allowDrop)
				this.tilePlacemat.addEventListener("drop", dropOnPlacemat)

				this.tilePlacemat.addEventListener("dragover", function() {
					this.style.backgroundColor = "lightblue"
				})
				this.tilePlacemat.addEventListener("dragleave", function() {
					this.style.backgroundColor = ""
				})
				this.tilePlacemat.addEventListener("drop", function() {
					this.style.backgroundColor = ""
				})
			}
		}
	}

	moveTile(...args) {
		Hand.prototype.moveTile.call(this, ...args)
		this.renderTiles() //Re-render.
		this.renderPlacemat()
	}
}

ClientHand.prototype.renderTiles = renderTiles
ClientHand.prototype.renderPlacemat = renderPlacemat

module.exports = ClientHand
