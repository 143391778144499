import {Capacitor} from "@capacitor/core";

if (Capacitor.getPlatform() === "web") {
        //Disable methods that aren't available on web to avoid erroring. 
        const handler = {
            get(target, prop, receiver) {
                if (prop === "setCurrentScreen") {return function() {}}
                else {
                    return target[prop]
                }
            },
        };
        window.FirebaseAnalytics = new Proxy(FirebaseAnalytics, handler)
}

FirebaseAnalytics.setEnabled({
    enabled: true,
});

window?.FirebaseAnalytics?.setCurrentScreen?.({
    screenName: "HomeScreen",
    screenClassOverride: "HomeScreen",
});

window?.FirebaseAnalytics?.setUserId?.({
    //Best we can do for now
    userId: window.clientId,
});
