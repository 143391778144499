const {dragstart} = require("./events.js")

function renderPlacemat(isPending) {
	isPending = isPending ?? this.tilePlacemat.firstChild?.classList?.contains("pending") //Default to current value unless passed as true/false.
	while (this.tilePlacemat.firstChild) {this.tilePlacemat.firstChild.remove()} //Delete everything currently rendered in the hand.

	for (let i=0;i<this.placematLength;i++) {
		let tile = this.inPlacemat[i]

		let elem;
		if (tile) {
			elem = tile.createImageElem({
				gameStyle: stateManager?.lastState?.message?.settings?.gameStyle
			})
			elem.draggable = true //Is this even neccessary? It wasn't set earlier, yet it was working fine. Do browsers just assume or something?
			elem.placematIndex = i
			elem.addEventListener("dragstart", dragstart)
			elem.addEventListener("click", (function() {
				this.moveTile(tile) //Closure.
			}).bind(this))
		}
		else {
			elem = document.createElement("img")
			if (window.tileSet?.transparentTileSrc) {
				//This won't exist when the page is first loaded - the tileset must load first - but it will exist once the tileset is loaded. 
				//Therefore, place in if statement to avoid erroneous requests. 
				elem.src = window.tileSet.transparentTileSrc
			}
			elem.classList.add("notFilled")
			elem.alt = "Placemat Slot for Tile"
		}

		if (i === 0 && isPending) {
			elem.classList.add("pending")
		}
		this.tilePlacemat.appendChild(elem)
	}
}

module.exports = renderPlacemat
