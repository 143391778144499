function phraseTimeDuration(deltaTime) {
	deltaTime = Math.abs(deltaTime)

	let deltaString = ""
	let deltaTimeSeconds = deltaTime / 1000
	let deltaTimeMinutes = deltaTime / 1000 / 60
	let deltaTimeHours = deltaTimeMinutes / 60
	let deltaTimeDays = deltaTimeHours / 24

	deltaTimeSeconds %= 60
	deltaTimeMinutes %= 60
	deltaTimeHours %= 24

	deltaTimeSeconds = Math.floor(deltaTimeSeconds)
	deltaTimeMinutes = Math.floor(deltaTimeMinutes)
	deltaTimeHours = Math.floor(deltaTimeHours)
	deltaTimeDays = Math.floor(deltaTimeDays)

	if (deltaTimeDays) {
		deltaString += `${deltaTimeDays} ${deltaTimeDays === 1 ? "day":"days"}`
	}

	if (deltaTimeHours) {
		if (deltaString) {deltaString += ", "}
		deltaString += `${deltaTimeHours} ${deltaTimeHours === 1 ? "hour":"hours"}`
	}

	if (deltaTimeMinutes) {
		if (deltaString) {deltaString += " and "}
		deltaString += `${deltaTimeMinutes} ${deltaTimeMinutes === 1 ? "minute":"minutes"}`
	}

	//Use seconds only when less than 5 minutes remaining.
	if (deltaTime < 1000 * 60 * 5) {
		if (deltaString) {deltaString += " and "}
		deltaString += `${deltaTimeSeconds} ${deltaTimeSeconds === 1 ? "second":"seconds"}`
	}

	return deltaString
}


function phraseTimeDelta(time1, time2 = Date.now()) {
	let deltaTime = new Date(time1).getTime() - new Date(time2).getTime()
	return phraseTimeDuration(deltaTime)
}

module.exports = {
	phraseTimeDuration,
	phraseTimeDelta
}
