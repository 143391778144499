const { offlineServerWebsocket } = require("./offlineServerWebsocket.js")


const Popups = require("../Popups.js")
const {i18n} = require("../../../lib/i18nHelper.js")
const { FirebaseAuthentication } = require("@capacitor-firebase/authentication")
const {CUSTOMER_INFO_UPDATED_EVENT_NAME} = require("../monetization/event_names");

let entitlements;
async function reloadEntitlements() {
	//For some reason, everything explodes if we import this
	const {getCustomerInfo} = await require("../monetization/store");
	let customerInfo = await getCustomerInfo()
	entitlements = Object.keys(customerInfo.entitlements.active)
}
window.addEventListener(CUSTOMER_INFO_UPDATED_EVENT_NAME, reloadEntitlements)
reloadEntitlements() //Load initially just in case we don't have an update after.

class StateManager {
	constructor(websocketURL) {
		//webSocket URL is the websocket for the network server. 
		this.websocketURL = websocketURL
        this.setServerOnline(true)
	}
	
	
	//Setup event listeners. 
	//Event listeners include events like "startGame" for when a state update is received. Evens are in camelCase. 
	//If this class needs to run code on an event, use camelCase with on+Name. For example, onStartGame. 
	listeners = {}
	
	addEventListener(type, listener, {once = false} = {}) {
		this.listeners[type] = this.listeners[type] || []
		this.listeners[type].push(listener)

		if (once) {
			function listenerRemover() {
				window.stateManager.removeEventListener(type, listenerRemover)
				window.stateManager.removeEventListener(type, listener)
			}
			
			this.listeners[type].push(listenerRemover)
		}
	}
	
	removeEventListener(type, listener) {
		let listeners = this.listeners[type]
		let index = listeners.indexOf(listener)
		if (index === -1) {
			console.warn("Unable to find listener to remove")
			return
		}
		listeners.splice(index, 1)
	}
	
	triggerEventListeners(type, objArg) {
		//We will call listeners on the class first (like onStartGame), which should be only utilized by this class. 
		let listenersToCall = []
		let transformedListener = `on${type[0].toUpperCase() + type.slice(1)}` //startGame => onStartGame
		
		if (this[transformedListener]) {
			listenersToCall.push(this[transformedListener])
		}
		if (this.listeners[type]) {
			listenersToCall.push(...this.listeners[type])
		}
		
		for (let listener of listenersToCall) {
			try {
				listener.call(this, objArg)
			}
			catch (e) {
				console.error(e)
			}
		}
	}
	
	
	
	//online - True if connected to the online server. False if connected to the local server. 
	//When we are connected to the online server, we ignore all messages from the internet server, and when we are connected to the internet server, we ignore all messages from online server. 
	online = null 
	
	serverConnections = {
		network: {
            onOpenMessages: [] //Functions to be called as soon as the websocket is available
        },
		local: {
            socket: offlineServerWebsocket
        }
	}
	
	//TODO: Use lastState to compute these. 
	inRoom = false
	isHost = false
	inGame = false
	
	
	//When we receive messages through the websocket, call the respective listeners. 
    //This onMessage event must be called with the StateManager as "this"
	onMessage(message) {
		let obj = JSON.parse(message.data)
		console.log(obj)

        let typeToTrigger = obj.type
        //Shorten roomAction events like roomActionStartGame to just startGame. 
        if (typeToTrigger.startsWith("roomAction")) {
            typeToTrigger = typeToTrigger.slice("roomAction".length)
            typeToTrigger = typeToTrigger[0].toLowerCase() + typeToTrigger.slice(1) //Set back to camelCase. 
        }

		this.triggerEventListeners(typeToTrigger, obj)
	}
	
	
	sendMessage(messageObj, {includeConfig, forceNetwork} = {}) {
		if (includeConfig) {
			messageObj.locale = i18n.getLocale()
			messageObj.entitlements = entitlements
		}
		console.log("Sent", messageObj)
		let message = JSON.stringify(messageObj)
		if (!this.online && !forceNetwork) {
			this.serverConnections.local.socket.send(message)
		}
		else {
            if (this.serverConnections.network.socket?.readyState === 1) {
                //If we are connected, send the message. 
                this.serverConnections.network.socket.send(message)
            }
            else {
                //If we are not connected, queue this message. 
                this.serverConnections.network.onOpenMessages.push(message)
				this.createWebsocketPromise.then(() => {
					//If there still isn't a websocket, open one. 
					if (this.serverConnections.network.socket?.readyState !== 1) {
						this.openNetworkWebsocket()
					}
				})
            }
		}
	}
	

    async getServerAuthMessage() {
		let idToken;
		try {
			idToken = await FirebaseAuthentication.getIdToken()
		}
		catch (e) {
			if (!e.message.includes("No user is signed in")) {
				console.error(e)
			}
		}

        if (idToken) {
			//Auth with token
			return idToken // {token: "............"}
		}
		else {
			//No token. 
			return {
				clientId: window.clientId
			}
		}
		
    }
	


    //We don't NEED to maintain a connection with the online server when offline -
    //However we do need to ensure that we cleanly restore connection with auth if we want to update database, etc. 
	//This command will open the network websocket if it isn't open
	
	errorsForBackoff = 0 //Counts errors for backoff with websockets. 
	
    //Open the server websocket. 
	createWebsocketPromise = new Promise((resolve) => {resolve()}) //We don't want to be opening more than one websocket at any time. 

	async openNetworkWebsocket() {
		await this.createWebsocketPromise
		let resolveWebsocketCompleteOrClosedPromise;
		this.createWebsocketPromise = new Promise((resolve) => {
			resolveWebsocketCompleteOrClosedPromise = resolve
			setTimeout(resolve, 5000) //Maximum of 5 seconds to connect before we allow a creating a new websocket (if both successfully open, the duplicate will be deleted). 
		})

		let websocket = new WebSocket(this.websocketURL)
		let disableCloseEvents = false
		websocket.addEventListener("message", (function(...data) {
			this.onMessage.call(this, ...data)

			if (this.serverConnections.network.socket !== websocket) {
				console.warn("Closing Duplicative Websocket") //We must've somehow gotten two open websockets
				disableCloseEvents = true
				websocket.close()
				return
			}
		}).bind(this))
		
		websocket.addEventListener("open", (async function() {
			let serverAuthMessage = await this.getServerAuthMessage() //We will send the message immediately, however we will not confirm that authentication was successful. 
			this.serverConnections.network.socket = websocket

            this.sendMessage(serverAuthMessage) //Send auth message first. 

            //Now execute all onOpenMessages messages in order. 
            for (let message of this.serverConnections.network.onOpenMessages) {
                websocket.send(message)
            }
            this.serverConnections.network.onOpenMessages = []

            if (window.setConnectionStatus) {window.setConnectionStatus({connected: true})}
            this.errorsForBackoff = 0
			resolveWebsocketCompleteOrClosedPromise()
		}).bind(this))
		
		websocket.addEventListener("close", (function() {
			if (disableCloseEvents) {return}
			delete this.serverConnections.network.socket
            if (window.setConnectionStatus) {window.setConnectionStatus({connected: false})}

			//Let's recreate the websocket, IF we are still in online mode (otherwise don't bother)
			if (this.online) {
				let timeDelay = Math.min(2000, 100 * 2 ** this.errorsForBackoff++)
				setTimeout(this.openNetworkWebsocket.bind(this), timeDelay)
			}
			resolveWebsocketCompleteOrClosedPromise()
		}).bind(this))
	}
	
	setServerOnline(shouldBeOnline) {
		this.online = shouldBeOnline
		
		if (shouldBeOnline === false) {
			//We are already connected to offline server. 
		}
		else {
            //Open websocket if it isn't already open. 
            if (!this.serverConnections.network.socket) {
                this.openNetworkWebsocket()
            }
		}
	}
	
	
	//Do note that we want to ensure users connect to the online server at the beginning so we can restore them into games from state. 
	
	
	
	
	
	
	onDisplayMessage(obj) {
		if (obj?.message?.onlineOnly && !window.stateManager.online) {
			//This should only apply to server maintence messages.
			console.warn("Suppressing displayMessage as offline. ")
		}
		else {
			new Popups.Notification(obj.message.title, obj.message.body).show()
		}
	}
	
	
	onCreateRoom(obj) {
		if (obj.status === "success") {
			this.inRoom = obj.message
			this.isHost = true
		}
	}
	
	onJoinRoom(obj) {
		if (obj.status === "success") {
			this.inRoom = obj.message
		}
	}
	
	onLeaveRoom(obj) {
		if (obj.status === "success") {
			this.inRoom = false
			this.isHost = false
			
			if (this.inGame === true) {
				this.inGame = false
                this.triggerEventListeners("endGame", {status: "success", message: "State Sync"})
			}
		}
		delete this.lastState //State object is invalid when not in room. 
	}
	
	onStartGame(obj) {
		window.scrollTo(0,0)

		if (obj.status === "success") {
			this.inGame = true
		}
	}
	
	onEndGame(obj) {
		if (obj.status === "success") {
			this.inGame = false
		}
	}
	
	
	onState(obj) {
		this.lastState = obj

        for (let client of window.stateManager?.lastState?.message?.clients) {
            if (client.isYou) {this.connectedClientId = client.id}
        }

		this.isHost = obj.message.isHost
		
		if (this.inGame === false && obj.message.inGame === true) {
            this.triggerEventListeners("startGame", {status: "success", message: "State Sync"})
		}
		else if (this.inGame === true && obj.message.inGame === false) {
            this.triggerEventListeners("endGame", {status: "success", message: "State Sync"})
		}
		
		this.currentTurn = obj.message.currentTurn
	}
	
	onGetCurrentRoom(obj) {
		this.inRoom = obj.message || false
		//Now, if we are in a room, we should sync state with the room.
		if (this.inRoom) {
            this.triggerEventListeners("joinRoom", obj)
			this.getState(this.inRoom)
		}
	}
	
	
	joinRoom(roomId, nickname) {
		this.sendMessage({
			type: "joinRoom",
			roomId,
			nickname,
		}, {includeConfig: true})
	}
	
	createRoom(roomId, nickname) {
		this.sendMessage({
			type: "createRoom",
			roomId,
			nickname,
		}, {includeConfig: true})
	}
	
	kickUser(userId) {
		this.sendMessage({
			type: "roomActionKickFromRoom",
			idToKick: userId ///id of user to kick.
		})
	}
	
	leaveRoom() {
		this.sendMessage({
			type: "roomActionLeaveRoom",
		})
	}
	
	startGame(settings = {}) {
		this.sendMessage({
			type: "roomActionStartGame",
			settings: settings
		}, {includeConfig: true})
		
		window?.FirebaseAnalytics?.logEvent?.({
			name: "start_game",
			params: settings //TODO: We might want to simplfy this and only pass parameters we care about analyzing. 
		});
	}
	
	endGame() {
		this.sendMessage({
			type: "roomActionEndGame",
		})
	}
	
	placeTiles(tiles, mahjong, obj = {}) {
		this.sendMessage({
			type: "roomActionPlaceTiles",
			mahjong, //Undefined is equivalent to false.
			message: tiles,
			swapJoker: obj.swapJoker
		})
	}
	
	addBot(botName) {
		this.sendMessage({
			type: "roomActionAddBot",
			botName: botName
		})
	}
	
	setNickname(nickname, targetId = window.stateManager.connectedClientId) {
		this.sendMessage({
			type: "roomActionChangeNickname",
			nickname,
			targetId
		})
	}
	
	getCurrentRoom() {
		//Get our room.
		this.sendMessage({
			"type": "getCurrentRoom",
		})
	}
	
	getMessageHistory() {
		return new Promise((resolve) => {
			this.onGetMessageHistory = resolve
			
			this.sendMessage({
				"type": "roomActionGetMessageHistory",
			})
		})
	}
	
	revertState(turnNumber) {
		this.sendMessage({
			type: "roomActionRevertState",
			message: turnNumber,
		})
	}
	
	
	getState() {
		console.log("Getting state...")
		this.sendMessage({
			type: "roomActionState",
		})
	}

	
	//Handle game logging and data retrival.

	//When we retrive data from server, we will store that data as settings.mostRecentUserData. 
	//We will store games that need syncing in settings.gamesToSync

	//User data will be calculated by combining mostRecentUserData with games that are saved in gamesToSync. 
	//When connected, games in gamesToSync will be synced. 

	//This ensures that we have the most up to date information while online or offline. 

    logGame(obj) {
		let currentGames = window.settings.gamesToSync.value
		currentGames.push(obj)
		window.settings.gamesToSync.value = currentGames

		this.syncCurrentGames()
    }



	//Now the fun part - sync gamesToSync.
	//We can sync one at a time, a batch at a time, or all at a time. 
	//Given potential slowness of storage access with settings, we need batching (otherwise localStorage with a significant number of items could lag the page terribly)

	//When we will sync:
	//1. On auth (more specifically, once we get user data and confirm they have a UID)
	//2. On additions to cache. 

	//Until a sync is confirmed, we will assume it has failed. Hopefully, no websockets close before responses arrive (we should add something here in the future)
	//Furthermore, due to the onWebsocketOpen stuff, we will avoid ever having more than one open sync request for a game - if a request doesn't finish, the next doesn't proceed. 


	//enableSyncing is called once user data has been updated at least once. 
	//That way we do not begin syncing on old user data (where we might get a confirm message on a guest account and inadvertantly confirm as synced)

	enableSyncing = 0;
	currentSyncingPromise = new Promise(((resolve) => {
		this.enableSyncing = resolve
	}).bind(this));

	//TODO: We should adjust the server to report whether or not the synced games were written to disk - ie, if the database is down, DON'T SYNC!
	async syncCurrentGames() {
		this.currentSyncingPromise = new Promise((resolve) => {
			console.log("Beginning Wait to Sync")
			this.currentSyncingPromise.then(async () => {
				let currentGames = window.settings.gamesToSync.value

				if (!window.settings.mostRecentUserData.value?.uid) {
					console.log("Refusing to log to user without account. ")
					resolve()
					return;
				}

				if (currentGames.length > 0) {
					console.log(`Syncing ${currentGames.length} Games. `)
					let promise = new Promise(((resolve) => {
						this.addEventListener("apiLogGames", resolve, {once: true})
					}).bind(this))

					this.sendMessage({
						type: "apiLogGames",
						games: currentGames
					}, {forceNetwork: true})

					await promise
					console.log("Sync Complete. Removing games from local storage. ")
					//Only remove the games we synced
					//New games are added to the end of the array.  
					window.settings.gamesToSync.value = window.settings.gamesToSync.value.slice(currentGames.length)

					this.requestLatestUserData() //Update the locally stored user data to reflect the stuff that we've synced. 
					//Note: We might want to not bother syncing immediately and waiting until there's a few games just to reduce requests. 
				}

				resolve()
			})
		})
	}

    getUserData() {
		let latestData = window.settings.mostRecentUserData.value

		try {
			if (!latestData.cards) {
				latestData.cards = {}
			}

			let currentGames = window.settings.gamesToSync.value
			for (let game of currentGames) {
				let card = latestData.cards[game.cardName] = latestData.cards[game.cardName] || {}
	
				card.gamesWon = card.gamesWon || {singlePlayer: 0, multiplayer: 0, inPerson: 0}
				card.gamesLost = card.gamesLost || {singlePlayer: 0, multiplayer: 0, inPerson: 0}
				card.wallGames = card.wallGames || {singlePlayer: 0, multiplayer: 0, inPerson: 0}
	
	
				if (game.result === "loss") {
					card.gamesLost[game.gameType]++
				}
				else if (game.result === "wall") {
					card.wallGames[game.gameType]++
				}
				else if (game.result === "win") {
					card.gamesWon[game.gameType]++
	
					card.hands = card.hands || {}
					let hand = card.hands[game.handName] = card.hands[game.handName] || {}
					hand.timesAchieved = hand.timesAchieved || {singlePlayer: 0, multiplayer: 0, inPerson: 0}
	
					hand.timesAchieved[game.gameType]++
				}
			}
		}
		catch (e) {
			console.error(e) //This should not error, and an error is serious. 
		}

		return latestData
    }


	requestLatestUserData() {
		this.sendMessage({
			"type": "apiGetUserData",
		}, {forceNetwork: true})
	}

	//Refresh user data when we authenticate. 
	onAuth() {
		this.requestLatestUserData()
	}

	onApiGetUserData(message) {
		window.settings.mostRecentUserData.value = message.message
		this.enableSyncing()
		this.syncCurrentGames()
	}
	
	
	
	//ClientID functions - used to create and store guest clientIds. 
	//Do NOT use setClientId for server based ids - this code is ONLY for guest clientIds. 
	//Guest client ids are set at initial load, and utilized. 
	//They are overwritten with window.clientId for the SESSION ONLY (not in disk, so don't use setClientId) if the user is logged in. 
	
	static setClientId(newId) {
		window.clientId = newId
		localStorage.setItem("clientId", window.clientId)
		
		//Development use only. Warnings should be shown.
		//This will only change the clientId for the session. It will not change localStorage.
		let params = new URLSearchParams("?" + window.location.hash.slice(1))
		if (params.has("clientId")) {
			window.clientId = params.get("clientId")
		}
	}
	
	static createNewClientId() {
		//Create random string for client ID. 
		//This should be suffecient to be unique. (even w/ birthday problem)
		//We'll add Date.now() just in case for some reason people's browsers start with the same seed. 
		let num = Date.now() + Math.floor((Math.random() * 2**53))
		num += Math.floor((Math.random() * 2**32)) //Just in case there's another bug like the Chrome bug that caused Math.random to only have 32 bits of randomness.
		
		let randomStr = num.toString(36)
		
		return "user" + randomStr
	}
	
	static getClientId() {
		//Get the users clientId, or create a new one.
		let clientId = localStorage.getItem("clientId")
		if (clientId === null) {
			clientId = StateManager.createNewClientId()
		}
		
		return clientId
	}
	
	
	
	
	//Deprecated methods - for admin & developer use only. 
	createRoomFromState(saveId) {
		this.sendMessage({
			type: "createRoomFromState",
			saveId: saveId,
		})
	}
	
	callServerSave(auth, saveName) {
		this.sendMessage({
			type: "callServerSave",
			auth,
			saveName,
		})
		console.warn("You will need to manually kill the server on reboot and reload from save. ")
	}
	
	messageAllServerClients({auth, title, body, onlineOnly = true}) {
		this.sendMessage({
			type: "messageAllServerClients",
			auth,
			title,
			body,
			onlineOnly
		})
	}
	
	setStaticMessageBar({auth, timeStamp = undefined, message}) {
		this.sendMessage({
			type: "setStaticMessageBar",
			auth,
			timeStamp,
			message
		})
	}
}

StateManager.setClientId(StateManager.getClientId()) //Set a guest clientId immediately. 

module.exports = StateManager