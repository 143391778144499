require("./db.js")

const mongoose = require ('mongoose');
const {HandSchema, CardSchema, UserSchema} = require("./schemas.js")

const User = mongoose.model("User", UserSchema)

//Gets requested user, creating if it doesn't exist. 
//create: Pass true to create user if user doesn't exist. 
async function getUserByUid(uid, {create} = {}) {
    let usersFound = await User.find({uid}).exec()
    let user = usersFound[0] //Unique index, so only 1 user should ever be found. 

    if (!user && create) {
        user = new User({
            uid,
        })
        await user.save()
    }

    return user
}


async function deleteUserByUid(uid) {
    let usersFound = await User.find({uid}).exec() //Unique index, so only 1 user should ever be found. 
    
    for (let user of usersFound) {
        await user.deleteOne()
    }

    return;
}



function getCardForUser(user, cardName) {
    //Ensure card initialized
    if (!user.cards.has(cardName)) {
        user.cards.set(cardName, {
            name: cardName
        })
    }
    return user.cards.get(cardName)
}

function getHandForCard(card, handName) {
    //Ensure hand initialized
    if (!card.hands.has(handName)) {
        card.hands.set(handName, {
            name: handName
        })
    }

    return card.hands.get(handName)
}



//User data will be READ in the JSON.stringify'd database format, however will be SET using utility functions.  

//Wins are tracked by HAND (we will store by card as well for easy access, but hand is the meaningful one). 
//Wall games and losses are tracked by CARD. 
async function logWinWithHand(user, cardName, handName, gameType) {
    let card = getCardForUser(user, cardName)
    let hand = getHandForCard(card, handName)

    //Log win on hand 
    if (hand.timesAchieved[gameType] !== undefined) {
        hand.timesAchieved[gameType]++
    }
    else {
        throw "Invalid Game Type"
    }

    //Log win on card. 
    if (card.gamesWon[gameType] !== undefined) {
        card.gamesWon[gameType]++
    }
    else {
        throw "Invalid Game Type"
    }

    await user.save()
}


async function logWallGameWithCard(user, cardName, gameType) {
    let card = getCardForUser(user, cardName)

    if (card.wallGames[gameType] !== undefined) {
        card.wallGames[gameType]++
    }
    else {
        throw "Invalid Game Type"
    }

    await user.save()
}

async function logLossWithCard(user, cardName, gameType) {
    let card = getCardForUser(user, cardName)

    if (card.gamesLost[gameType] !== undefined) {
        card.gamesLost[gameType]++
    }
    else {
        throw "Invalid Game Type"
    }

    await user.save()
}


async function logAmericanGame(user, obj) {
    if (obj.result === "win") {
        await logWinWithHand(user, obj.cardName, obj.handName, obj.gameType)
    }
    else if (obj.result === "wall") {
        await logWallGameWithCard(user, obj.cardName, obj.gameType)
    }
    else if (obj.result === "loss") {
        await logLossWithCard(user, obj.cardName, obj.gameType)
    }
}


async function logGame(obj) {
    //obj format:
    // {
    //     uid: "",
    //     gameType: "singlePlayer/multiplayer/inPerson",
    //     result: "wall/win/loss",
    //     cardName: "",
    //     handName: "",
    // }


    let user = await getUserByUid(obj.uid)
    if (!user) {return} //No user to log to. 

    if (obj.cardName) {
        await logAmericanGame(user, obj)
    }
    else {
        //Not yet implemented
    }
}







module.exports = {
    getUserByUid,
    deleteUserByUid,
    logAmericanGame,
    logGame,
}



/*
testId = "1234"
user = await getUserByUid(testId)
await logWinWithHand(user, "2022 National Mah Jongg League", "2468 #3", "inPerson")
*/