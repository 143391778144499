try {
    module.exports = require("./serverApi.js")
}
catch (e) {
    if (e.message.includes("thisVariableIsIntentionallyUndefinedMongoose")) {
		//Not an error - we are on web and mongoose is unavailable 
        module.exports = {
            getUserByUid: function getUserByUid(...args) {console.warn("getUserByUid should never be called on client")},
            logGame: function logGame(...args) {window.stateManager.logGame(...args)},
        }
	}
	else {
		throw e
	}
}