//RevenueCat Billing Production Public Keys
const PUBLIC_AMAZON_API_KEY = "amzn_vqqBVXyJyLjReKNgRCBEDwbaeqG"
const PUBLIC_APPLE_API_KEY = "appl_dlkchrWOjOrEDPmGkjkpbkHwbhq"
const PUBLIC_GOOGLE_API_KEY = "goog_sdTnOLZQSSMgtzsrFpVIlfarrDC"
const PUBLIC_REVENUECATBILLING_API_KEY = "rcb_RnZeLdeXyjzELlODSsVdGOjGFsxM"

//RevenueCat Billing Sandbox Keys
const SANDBOX_PUBLIC_REVENUECATBILLING_API_KEY = "rcb_sb_AdmmaZofBJGNzPrRYwbkdrOWC"

//Entitlement IDs for the app
const AD_FREE_ENTITLEMENT = "adfree"
const PREMIUM_ENTITLEMENT = "silver"

export {
  PUBLIC_AMAZON_API_KEY,
  PUBLIC_APPLE_API_KEY,
  PUBLIC_GOOGLE_API_KEY,
  PUBLIC_REVENUECATBILLING_API_KEY,
  SANDBOX_PUBLIC_REVENUECATBILLING_API_KEY,
  AD_FREE_ENTITLEMENT,
  PREMIUM_ENTITLEMENT
}