const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//FF 1111 6666 7777 (1 suit)
	function(tiles = []) {
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "flower", amount: 2}))
			newArr.push(createTiles({type: suit, value: 1, amount: 4}))
			newArr.push(createTiles({type: suit, value: 6, amount: 4}))
			newArr.push(createTiles({type: suit, value: 7, amount: 4}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
    //2222 5555 7777 (1 suit)
    function(tiles = []) {
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "flower", amount: 2}))
			newArr.push(createTiles({type: suit, value: 2, amount: 4}))
			newArr.push(createTiles({type: suit, value: 5, amount: 4}))
			newArr.push(createTiles({type: suit, value: 7, amount: 4}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
    //3333 4444 7777 (1 Suit)
    function(tiles = []) {
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "flower", amount: 2}))
			newArr.push(createTiles({type: suit, value: 3, amount: 4}))
			newArr.push(createTiles({type: suit, value: 4, amount: 4}))
			newArr.push(createTiles({type: suit, value: 7, amount: 4}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "Addition Hands"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

