const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//NN EE WW SS DD DD DD
	function(tiles = []) {
		[0].forEach(() => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "wind", value: "north", amount: 2}))
			newArr.push(createTiles({type: "wind", value: "east", amount: 2}))
			newArr.push(createTiles({type: "wind", value: "west", amount: 2}))
			newArr.push(createTiles({type: "wind", value: "south", amount: 2}))

			newArr.push(createTiles({type: "dragon", value: "red", amount: 2}))
			newArr.push(createTiles({type: "dragon", value: "green", amount: 2}))
			newArr.push(createTiles({type: "dragon", value: "white", amount: 2}))
		})

		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//(11 33 55 77 99) (11) (11)
	function(tiles = []) {
		oddOptions.forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)
	
				newArr.push(createTiles({type: suitOrder[0], value: 1, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 5, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 7, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 2}))
	
				newArr.push(createTiles({type: suitOrder[1], value: offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[2], value: offset, amount: 2}))
			})
		})

		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//FF (11 22 33) (11 22 33)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			allOptions.slice(0, -2).forEach((offset, i) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 2}))

				newArr.push(createTiles({type: suitOrder[0], value: offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 1 + offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 2 + offset, amount: 2}))

				newArr.push(createTiles({type: suitOrder[1], value: offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[1], value: 1 + offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[1], value: 2 + offset, amount: 2}))
			});
		})

		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//11 22 33 44 55 66 77 - Consec 1 Suit
	function(tiles = []) {
		allSuits.forEach((suit) => {
			allOptions.slice(0, -6).forEach((offset, i) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: suit, value: 0 + offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 1 + offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 2 + offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 3 + offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 4 + offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 5 + offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 6 + offset, amount: 2}))
			});
		})

		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//FF (22 46 88) (22 46 88)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			allOptions.slice(0, -2).forEach((offset, i) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 2}))

				newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 2}))

				newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 2}))
				newArr.push(createTiles({type: suitOrder[1], value: 4, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 2}))
			});
		})

		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//(336) (33669) (336699)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 1}))

			newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 2}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 2}))
			newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 1}))

			newArr.push(createTiles({type: suitOrder[2], value: 3, amount: 2}))
			newArr.push(createTiles({type: suitOrder[2], value: 6, amount: 2}))
			newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 2}))
		})

		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "Singles and Pairs"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

