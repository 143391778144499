const {dragstart} = require("./events.js")

const Tile = require("../Tile")
const Pretty = require("../Pretty")
const Sequence = require("../../../lib/Sequence")
const Match = require("../../../lib/Match")

function renderTiles(displayElevated) {
	if (!this.handToRender) {throw "Unable to render hand. You must pass config.handToRender to the constructor. "}

	if (typeof displayElevated === "string") {displayElevated = Tile.fromJSON(displayElevated)}

	let unexposedTiles = []
	let exposedTiles = []

	for (let i=0;i<this.contents.length;i++) {
		let item = this.contents[i]
		if (item instanceof Tile)	{
			unexposedTiles.push(item)
		}
		else if (item instanceof Pretty) {
			exposedTiles.push(item)
		}
		else if (item instanceof Match || item instanceof Sequence) {
			let items = item.tiles;
			if (item instanceof Match) {
				if (item.amount === 4) {
					//kong. Flip 1 tile.
					items[0] = new Tile(items[0]) //We need a new tile instance so we don't modify by reference.
					items[0].faceDown = true
				}
			}
			if (item.exposed) {
				exposedTiles.push(items)
			}
			else {
				if (item instanceof Match && item.amount === 4) {
					//In hand kong. Expose with 2 flipped tiles. (One already flipped)
					items[3] = items[0]
					exposedTiles.push(items)
				}
				else {
					console.log(items)
					unexposedTiles.push(items)
				}
			}
		}
		else if (item instanceof TileContainer) {
			exposedTiles.push(item.tiles)
		}
		else {console.error("Unknown item " + item)}
	}

	let processingIndex = 0; //Index of the element we are processing.
	let drawTiles = (function drawTiles(tiles, type, applyColorShading = false) {
		//TODO: If flashing remains a problem, instead of deleting the entire hand and redrawing, try replacing each item individually.
		//Ideally we wouldn't replace if not needed, but that doesn't seem easy to implement, and would likely be buggy with matches, etc.

		//We will reset processingIndex UNLESS there is not an exposed hand and we are currently unexposed.
		if (type === "unexposed" && !this.handForExposed) {
			//In this case, both hands are the same, so we want to only clear the first time.
		}
		else {
			processingIndex = 0
		}


		let tileToAnimate; //We'll animate the last tile that matches.

		let drawTile = (function(tile, indexInGroup) {
			let handBeingUsed = this.handToRender;
			if (type === "exposed" && this.handForExposed) {
				handBeingUsed = this.handForExposed
			}

			let currentElem = handBeingUsed.children[processingIndex++]
			if (currentElem instanceof HTMLParagraphElement) {
				//It is possible that we grabbed the message encouraging people to try suggested hands/score summaries
				//If so, continue to the next element. 
				currentElem = handBeingUsed.children[processingIndex++]
			}

			let refElem = tile.createImageElem({
				gameStyle: stateManager?.lastState?.message?.settings?.gameStyle
			})

			if (!currentElem) {
				currentElem = refElem
				handBeingUsed.appendChild(currentElem)
			}
			else {
				currentElem.src = refElem.src
				currentElem.title = refElem.title
			}

			//Group tiles in a match together.
			if (indexInGroup && indexInGroup !== 0) {
				//Grouped overlapping.
				currentElem.style.setProperty("--negativeMarginMultiplier", 1)
			}
			else if (indexInGroup === 0) {
				//Grouped far apart.
				currentElem.style.setProperty("--negativeMarginMultiplier", -1)
			}
			else {
				//Default. Together.
				currentElem.style.setProperty("--negativeMarginMultiplier", 0)
			}

			if (type === "exposed") {
				if (applyColorShading) {
					//There is no hand specifically for exposed tiles. We'll apply some style to make it clear this was exposed.
					currentElem.style.filter = "brightness(1.2)"
				}
				else {
					currentElem.style.filter = ""
				}
			}
			else if (type === "unexposed") {
				if (!this.handForExposed && applyColorShading) {
					//There is no hand for exposed tiles, let's make it clear this is unexposed
					currentElem.style.filter = "brightness(0.8)"
				}
				else {
					currentElem.style.filter = ""
				}
				if (this.interactive) {
					currentElem.classList.remove("animateTile")
					if (displayElevated && tile.matches(displayElevated)) {
						tileToAnimate = currentElem
					}
					currentElem.draggable = true
					currentElem.onclick = (function() {
						this.moveTile(tile) //Closure.
					}).bind(this)
					currentElem.ondragstart = dragstart
					currentElem.tileIndex = this.contents.findIndex((item) => {return item === tile})
				}
			}
		}).bind(this)

		for (let i=0;i<tiles.length;i++) {
			let tile = tiles[i]
			if (tile instanceof Array) {
				for (let j=0;j<tile.length;j++) {
					drawTile(tile[j], j)
				}
			}
			else if (type === "unexposed" && i===0) {
				drawTile(tile, 0) //Add extra margin in front of the first unexposed tile.
			}
			else {
				drawTile(tile)
			}
		}


		if (tileToAnimate) {
			//Keep the new tile transparent, and delay animating until the new image loads.
			//Previously the tile could change mid-animation, which could
			//be incredibly annoying, deceiving users into thinking they received another
			//copy of a tile already in their hand.

			tileToAnimate.style.opacity = "0"

			function beginAnimation() {
				tileToAnimate.classList.add("animateTile")
				tileToAnimate.style.opacity = ""
			}

			tileToAnimate.addEventListener("load", beginAnimation, {once: true})
		}


		//Note: If the window is resized, tiles will not adjust until the hand is redrawn.
		//TODO: Only set for left and right hands (which don't use flexbox)
		function resizeHandTiles(hand) {
			if (hand.children.length > 14) {
				//Downscale tiles to fit.
				let baseVh = parseFloat(document.documentElement.style.getPropertyValue("--vh")) //Pixels.
				baseVh /= hand.children.length / 14
				let baseVw = parseFloat(document.documentElement.style.getPropertyValue("--vw")) //Pixels.
				baseVw /= hand.children.length / 14

				for (let child of hand.children) {
					child.style.setProperty("--vh", baseVh + "px")
					child.style.setProperty("--vw", baseVw + "px")
				}
			}
			else {
				//Remove any existing resizing if it exists. 
				for (let child of hand.children) {
					child.style.removeProperty("--vh")
					child.style.removeProperty("--vw")
				}
			}
		}

		resizeHandTiles(this.handToRender)
		if (this.handForExposed) {resizeHandTiles(this.handForExposed)}
	}).bind(this)

	let applyColorShading = false
	//If there are any tiles in unexposedTiles that are not face down, or there are no unexposed tiles.
	if (unexposedTiles.some((tile) => {return !(tile.faceDown)}) || unexposedTiles.length === 0) {
		applyColorShading = true
	}
	drawTiles(exposedTiles, "exposed", applyColorShading)
	if (this.handForExposed) {
		//Remove all excess tiles.
		while (this.handForExposed.children.length > processingIndex) {
			this.handForExposed.lastChild.remove()
		}

		if (this.handForExposed.children.length === 0) {
			let settingKey;
			let message;

			//Determine which mode we are playing. 
			if (window?.stateManager?.lastState?.message?.settings?.gameStyle === "american") {
				settingKey = "hideSuggestedHandsText"
				message = "Click here to see suggested hands for your card! (You can click on other players' hands to see what hands are still possible with their exposures)"
			}
			else if (window?.stateManager?.lastState?.message?.settings?.gameStyle === "chinese") {
				settingKey = "hideScoreSummaryText"
				message = "Click here to see the score summary for your hand! (You can click on other players' hands as well!)"
			}

			if (message && !window.settings[settingKey]?.value) {
				let infoTextHandForExposed = document.createElement("p")
				infoTextHandForExposed.innerText = message
				infoTextHandForExposed.classList.add("infoTextHandForExposed")
	
				this.handForExposed.appendChild(infoTextHandForExposed)

				this.handForExposed.addEventListener("click", function() {
					infoTextHandForExposed.remove()
					window.settings[settingKey].value = true
				}, {once: true})
			}
		}
		else {
			let infoTextHandForExposed = this.handForExposed.querySelector("p")
			if (infoTextHandForExposed) {infoTextHandForExposed.remove()}
		}
	}
	drawTiles(unexposedTiles, "unexposed", applyColorShading)
	while (this.handToRender.children.length > processingIndex) {
		//Remove all excess tiles.
		this.handToRender.lastChild.remove()
	}
	if (this.tilePlacemat) {
		this.renderPlacemat()
	}
}


module.exports = renderTiles
