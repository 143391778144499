const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//(222 444) (6666 8888) (1 or 2 suits)
	function(tiles = []) {
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suit, value: 2, amount: 3}))
			newArr.push(createTiles({type: suit, value: 4, amount: 3}))
			newArr.push(createTiles({type: suit, value: 6, amount: 4}))
			newArr.push(createTiles({type: suit, value: 8, amount: 4}))
		})

		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 3}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 3}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 4}))
			newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 4}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//(22 444) (44 666) (8888) - 3 Suits
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 3}))

				newArr.push(createTiles({type: suitOrder[1], value: 4, amount: 2}))
				newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 3}))

				newArr.push(createTiles({type: suitOrder[2], value: 8, amount: 4}))
			})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//22 44 666 888 DDDD (1 suit matching dragon)
	function(tiles = []) {
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suit, value: 2, amount: 2}))
			newArr.push(createTiles({type: suit, value: 4, amount: 2}))
			newArr.push(createTiles({type: suit, value: 6, amount: 3}))
			newArr.push(createTiles({type: suit, value: 8, amount: 3}))
			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suit], amount: 4}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FFFF (4444) (6666) (24) or FFFF (6666) (8888) (48) (3 suits)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 4}))

			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 4}))

			newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 4}))

			newArr.push(createTiles({type: suitOrder[2], value: 4, amount: 1}))
			newArr.push(createTiles({type: suitOrder[2], value: 8, amount: 1}))
		})


		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 4}))

			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 4}))

			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 4}))

			newArr.push(createTiles({type: suitOrder[2], value: 2, amount: 1}))
			newArr.push(createTiles({type: suitOrder[2], value: 4, amount: 1}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FF 2222 (44 66) 8888 - 1 or 2 suits, if 2 suits 2222 and 8888 go together.  
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			newArr.push(createTiles({type: "flower", amount: 2}))

			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 4}))
			newArr.push(createTiles({type: suitOrder[1], value: 4, amount: 2}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 4}))
		})

		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)
			newArr.push(createTiles({type: "flower", amount: 2}))

			newArr.push(createTiles({type: suit, value: 2, amount: 4}))
			newArr.push(createTiles({type: suit, value: 4, amount: 2}))
			newArr.push(createTiles({type: suit, value: 6, amount: 2}))
			newArr.push(createTiles({type: suit, value: 8, amount: 4}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FF (222 44 666) (88) (88) - 3 Suits
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 2}))
			
			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 3}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 3}))

			newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 2}))

			newArr.push(createTiles({type: suitOrder[2], value: 8, amount: 2}))
		})
		
		return {
			tiles,
			score: 35,
			concealed: true
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "2468"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

