const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//FFFF 111 1111 111 (3 suits, like numbers)
	function(tiles = []) {
		allOptions.forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: "flower", amount: 4}))
				
				newArr.push(createTiles({type: suitOrder[0], value: offset, amount: 3}))
				
				newArr.push(createTiles({type: suitOrder[1], value: offset, amount: 4}))
				
				newArr.push(createTiles({type: suitOrder[2], value: offset, amount: 3}))
			})
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//11 DDD 11 DDD 1111 (3 suits, dragons match)
	function(tiles = []) {
		allOptions.forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)
								
				newArr.push(createTiles({type: suitOrder[0], value: offset, amount: 2}))
				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[0]], amount: 3}))
				
				newArr.push(createTiles({type: suitOrder[1], value: offset, amount: 2}))
				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 3}))
				
				newArr.push(createTiles({type: suitOrder[2], value: offset, amount: 4}))
			})
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FF 1111 NEWS 1111 (2 Suits)
	function(tiles = []) {
		allOptions.forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: "flower", amount: 2}))
				
				newArr.push(createTiles({type: suitOrder[0], value: offset, amount: 4}))

				newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
				newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
				newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
				newArr.push(createTiles({type: "wind", value: "south", amount: 1}))

				newArr.push(createTiles({type: suitOrder[1], value: offset, amount: 4}))
			})
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "Any Like Numbers"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

