const Room = require("./Room")
const Client = require("./Client")

const {getUserByUid, logGame} = require("./database/api.js")

const {phraseTimeDelta} = require("./phraseTimeDelta.js")

let verifyIdToken;
let isOfflineServer = false
try {
	verifyIdToken = require("./validateClients.js").verifyIdToken
}
catch (e) {
	if (e.message.includes("thisVariableIsIntentionallyUndefinedFirebase")) {
		//Not an error - we are on web and firebase-admin is unavailable. 
		verifyIdToken = function() {return {uid: globalThis.clientId}}
		isOfflineServer = true
	}
	else {
		throw e
	}
}

//Note that these native modules will be empty objects in the browser!
const path = require("path")
const fs = require("fs")
const crypto = require("crypto")

const {i18n} = require("../lib/i18nHelper.js")

function getMessage(type, message, status) {
	return JSON.stringify({
		type, message, status
	})
}



let staticMessageBar;

function getMessageBarText() {
	let timeStamp = staticMessageBar?.timeStamp
	let message = staticMessageBar?.message
	if (message && timeStamp) {
		//Substitute in remainingTime (if it is included in the string)
		//This allows for dynamic messages ("Maintenance in X minutes"), and can help prevent time zone confusion.
		let stringToReplace = "${remainingTime}"
		let substitutionString = phraseTimeDelta(timeStamp)
		
		let composedMessage = message.replace(stringToReplace, substitutionString)
		return composedMessage
	}
	else {
		return message
	}
}



function onConnection(websocket) {
	//It is possible for a user to connect before they are signed in, then disconnect,
	//and have problems since their clientId changed after sign in. 
	
	//Ideally, we switch users from their guest IDs to their account IDs mid-game - 
	//we want users games to be tied to their account, but we also want to avoid distrupting users. 
	
	//For now, we will alert users if they change users during a game. 
	
	let clientId;
	
	let lastMessageBarText = ""; //The last message bar text sent to this user.
	
	websocket.on('message', async function incoming(message) {
		try {
			let obj;
			try {
				obj = JSON.parse(message)
			}
			catch(e) {
				return websocket.send(getMessage("error", "Message must be valid JSON"))
			}
			
			//Identify the user. 
			if (obj.token || obj.clientId) {
				let newClientId;
				
				if (obj.token) {
					//Signing into registered account. 
					try {
						let verifiedUser = await verifyIdToken(obj.token)
						newClientId = verifiedUser.uid
						getUserByUid(newClientId, {create: true}) //Ensure the user exists - this is async but will finish before anyone could conceivably create a game room and play an entire game. (and if it doesn't the game simply isn't recorded)
					}
					catch (e) {
						//TODO: If we catch code == "auth/id-token-expired", we should ask the client to use {forceRefresh: true} and get a new token. 
						console.error(e)
						return websocket.send(getMessage("displayMessage", {title: "Log In Error", body: "Error Authenticating Mahjong 4 Friends account. You may need to sign out and sign back in. "}))
					}
				}
				else if (obj.clientId) {
					//Playing as guest. 
					
					//We cannot allow guests to impersonate registered users, nor do we want them to be able to use clientIds that might become those of registered users
					//Accordingly, we will confirm that all guest IDs match our requirements. 
					
					
					let meetsGuestCriteria = obj.clientId.length < 28 //Under 28 characters in length (Firebase currently uses 28)
					//We do not check the guest clientId against users in Firebase to ensure it doesn't exist out of performance (unless firebase reduces uid length, which should not happen, there is not an issue)
					
					if (meetsGuestCriteria) {
						newClientId = obj.clientId
					}
					else {
						return websocket.send(getMessage("displayMessage", {title: "Guest Account Error", body: "Guest ID did not meet criteria. This is software bug - please report to support@mahjong4friends.com "}))
					}
				}
				
				if (!clientId) {
					//If there isn't an existing clientId, everything is easy. 
					clientId = newClientId
				}
				else if (isOfflineServer) {
					//Offline server will never disconnect, and clientId will be handled by resume buttn, etc. Simply don't change clientId. 
				}
				else if (clientId !== newClientId) {
					//If there is an existing clientId, for example, if the user joined a room before signing in, we need to alert user if we cannot easily swap. 
					let client = globalThis.serverStateManager.getClient(clientId)
					let roomId = client?.getRoomId?.()
					
					if (roomId) {
						//We won't attempt to adjust the users clientId if they are in a room. 
						return websocket.send(getMessage("displayMessage", {title: "Account Changed", body: "You were signed in/out while in a room. Gameplay will continue under the account or guest user that originally joined this room. You may want to leave & rejoin this room. "}))
					}
					
					//We can safely swap the clientId. 
					clientId = newClientId
				}
				return websocket.send(getMessage("auth", "Auth updated"))
			}
			else if (!clientId) {
				return websocket.send(getMessage("authError", "Must provide clientId or token"))
			}
			
			
			
			if (obj.type === "apiGetUserData") {
				let user = await getUserByUid(clientId) || {}
				return websocket.send(getMessage("apiGetUserData", user))
			}
			else if (obj.type === "apiLogGame") {
				//THIS API IS NO LONGER USED. 
				obj.uid = clientId //Ensure clientId is properly set.  
				await logGame(obj)
				return websocket.send(getMessage("apiLogGame", "success"))
			}
			else if (obj.type === "apiLogGames") {
				for (let gameToLog of obj.games) {
					gameToLog.uid = clientId //Ensure clientId is properly set.  
					await logGame(gameToLog)
				}
				return websocket.send(getMessage("apiLogGames", "success"))
			}
			
			
			
			
			
			
			try {
				//Update the message bar if it has changed.
				//This is used for announcing maintenances, etc.
				let newMessageBarText = getMessageBarText()
				if (lastMessageBarText !== newMessageBarText) {
					websocket.send(getMessage("setStaticMessageBar", newMessageBarText))
					lastMessageBarText = newMessageBarText
				}
			}
			catch (e) {
				console.error(e)
			}
			
			
			//Admin actions:
			
			//let auth = "" //Insert real password.
			
			//Set static message bar - useful for scheduling events like maintenance, or other important info to users.
			//${remainingTime} is substituted with the time remaining before timeStamp (if passed).
			//Pass an empty string as message to clear static message bar.
			//stateManager.setStaticMessageBar({auth, message: "Maintenance in ${remainingTime} - Multiplayer games will be lost, single player games will not be affected. ", timeStamp: MaintenanceTime})
			
			//Send a message to all users currently in online games.
			//stateManager.messageAllServerClients({onlineOnly: true, auth, title: "Server Update", body: "Mahjong 4 Friends is entering maintenance in a few minutes to perform a server update. Online games will be lost"})
			
			//Call a server save.
			//stateManager.callServerSave(auth, "update")
			
			
			//TODO: callServerSave is basically useless now - we don't have a system to load from a state.
			if (obj.type === "callServerSave" || obj.type === "messageAllServerClients" || obj.type === "setStaticMessageBar") {
				if (!obj.auth) {
					return websocket.send(getMessage("displayMessage", {title: "Auth Error", body: "This command must be authed"}))
				}
				
				let hash = crypto.createHash("sha256").update(obj.auth).digest("hex")
				if (hash !== "014eea3157474ede4dccc818d1a84efff650b82b8d67d3470f46e4ecc2f5d829") {
					return websocket.send(getMessage("displayMessage", {title: "Auth Error", body: "Invalid Admin Password"}))
				}
				
				if (obj.type === "callServerSave") {
					return websocket.send(getMessage("displayMessage", {title: "Server Save", body: globalThis.saveServerState(obj.saveName)}))
				}
				else if (obj.type === "messageAllServerClients") {
					globalThis.serverStateManager.getAllClients().forEach((client) => {
						client.message("displayMessage", {title: obj.title, body: obj.body, onlineOnly: obj.onlineOnly})
					})
				}
				else if (obj.type === "setStaticMessageBar") {
					//TODO: We should send the updated staticMessageBar text to all clients immediently,
					//rather than waiting for them to send a message to the server before checking.
					staticMessageBar = {message: obj.message, timeStamp: obj.timeStamp}
				}
				return;
			}
			
			console.log('received: ' + JSON.stringify(obj));
			
			
			let client;
			
			if (!globalThis.serverStateManager.getClient(clientId)) {
				if (clientId.startsWith("bot")) {
					//Intended for dev use.
					client = globalThis.serverStateManager.createBot(clientId, websocket)
				}
				else {
					client = globalThis.serverStateManager.createClient(clientId, websocket)
				}
			}
			else {
				client = globalThis.serverStateManager.getClient(clientId)
				client.addWebsocket(websocket)
			}

			if (obj.entitlements) {
				//TODO: We might want to add some sort of verification here as if this was reverse engineered
				// it is ridiculously easy to get premium features for free.
				client.setEntitlements(obj.entitlements)
			}
			
			if (obj.locale) {
				client.setLocale(obj.locale)
			}
			
			if (obj.type === "createRoom") {
				if (typeof obj.roomId !== "string") {
					return websocket.send(getMessage("createRoom", "roomId must be a string", "error"))
				}
				else if (globalThis.serverStateManager.getRoom(obj.roomId)) {
					return websocket.send(getMessage("createRoom", "Room Already Exists", "error"))
				}
				else {
					try {
						let room = globalThis.serverStateManager.createRoom(obj.roomId)
						room.setClientNickname(clientId, obj.nickname)
						room.addClient(clientId)
						return websocket.send(getMessage("createRoom", client.getRoomId(), "success"))
					}
					catch (e) {
						return websocket.send(getMessage("createRoom", e.message, "error"))
					}
				}
			}
			else if (obj.type === "joinRoom") {
				if (!globalThis.serverStateManager.getRoom(obj.roomId)) {
					return websocket.send(getMessage("joinRoom", i18n.__({ phrase: "Room %s does not exist. You can click the Create Room button to create it!",
					locale: client.locale}, obj.roomId), "error"))
				}
				let room = globalThis.serverStateManager.getRoom(obj.roomId)
				room.setClientNickname(clientId, obj.nickname)
				return room.addClient(clientId)
			}
			else if (obj.type === "getCurrentRoom") {
				let roomId = client.getRoomId()
				client.message(obj.type, roomId, "success")
				return websocket.send(getMessage(obj.type, roomId, "success"))
			}
			else if (obj.type === "createRoomFromState") {
				//Intended for developer use.
				try {
					let roomFilePath = path.join(globalThis.serverStateManager.activeLogsDirectory, obj.saveId)
					
					if (fs.existsSync(roomFilePath)) {
						//Technically roomPath could be a ../ path, however this kind of "hacking" shouldn't do any damage here. We don't write or expose non-mahjong data.
						let room = Room.fromJSON(fs.readFileSync(roomFilePath, {encoding: "utf8"}))
						let roomId = room.roomId
						if (!globalThis.serverStateManager.createRoom(roomId, room)) {return console.warn("Room already exists. ")}
						room.init()
					}
					else {console.warn("Invalid save path. Make sure to include file extension. ")}
				}
				catch(e) {console.error(e)}
				return;
			}
			else if (obj?.type?.includes("roomAction")) {
				//The user is in a room, and this action will be handled by the room.
				let room = globalThis.serverStateManager.getRoom(obj.roomId) || client.getRoom()
				if (!room) {
					//The user did not specify a valid room to use, and was not in a room.
					return websocket.send(getMessage(obj.type, "Room Does Not Exist", "error"))
				}
				try {
					return room.onIncomingMessage(clientId, obj)
				}
				catch(e) {
					console.error(e)
					console.error(e.stack)
					return;
				}
			}
			
			console.log("Nothing happened. ")
		}
		catch(e) {
			//Uncaught exceptions now cause server to crash since NodeJS update.
			console.error(e)
		}
	});
}

module.exports = onConnection
