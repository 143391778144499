const {i18n} = require("../../lib/i18nHelper.js")

function createBrandNameHeading() {
    //In order to get the "4 friends" part styled differently, we will need 3 elements for our heading.
    let heading = document.createElement("div")
    heading.id = "heading"

    let mahjongHeading = document.createElement("h1")
    mahjongHeading.innerText = i18n.__("Mahjong")
    mahjongHeading.id = "mahjongHeading"
    heading.appendChild(mahjongHeading)

    let fourFriendsHeading = document.createElement("h1")
    fourFriendsHeading.innerText = i18n.__("4 Friends")
    fourFriendsHeading.id = "fourFriendsHeading"
    heading.appendChild(fourFriendsHeading)
    
    return heading
}

module.exports = {createBrandNameHeading}