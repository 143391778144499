const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
    //3333 157 9999 PPP (#’s 1 or 3 suits; P any Pung) - 25 Points
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            let newArr = []
            tiles.push(newArr)

            //3333
            newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 4}))
            
            //157
            newArr.push(createTiles({type: suitOrder[1], value: 1, amount: 1}))
            newArr.push(createTiles({type: suitOrder[1], value: 5, amount: 1}))
            newArr.push(createTiles({type: suitOrder[1], value: 7, amount: 1}))
            
            //9999
            newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 4}))
            
            //PPP
            newArr.push(createTiles({type: "any", value: "any", amount: 3}))
        })
        
        allSuits.forEach((suit) => {
            let newArr = []
            tiles.push(newArr)

            //3333
            newArr.push(createTiles({type: suit, value: 3, amount: 4}))
            
            //157
            newArr.push(createTiles({type: suit, value: 1, amount: 1}))
            newArr.push(createTiles({type: suit, value: 5, amount: 1}))
            newArr.push(createTiles({type: suit, value: 7, amount: 1}))
            
            //9999
            newArr.push(createTiles({type: suit, value: 9, amount: 4}))
            
            //PPP
            newArr.push(createTiles({type: "any", value: "any", amount: 3}))
        })
        
        
        return {
            tiles,
            score: 25,
            concealed: false,
        }
    },
    //FFF 13579 WWW PPP (#’s 1 suit; any Wind; P any Pung) - 25 Points
    function(tiles = []) {
        allSuits.forEach((suit) => {
            let newArr = []
            tiles.push(newArr)

            //FFF
            newArr.push(createTiles({type: "flower", amount: 3}))
            
            //13579
            newArr.push(createTiles({type: suit, value: 1, amount: 1}))
            newArr.push(createTiles({type: suit, value: 3, amount: 1}))
            newArr.push(createTiles({type: suit, value: 5, amount: 1}))
            newArr.push(createTiles({type: suit, value: 7, amount: 1}))
            newArr.push(createTiles({type: suit, value: 9, amount: 1}))
            
            //www
            newArr.push(createTiles({type: "wind", value: "any", amount: 3}))
            
            //PPP
            newArr.push(createTiles({type: "any", value: "any", amount: 3}))
        })
        
        return {
            tiles,
            score: 25,
            concealed: false,
        }
    },
    //JS2 AA 333 333 333 (J=Joker; AA any Pr.; any same odd, 3 suits) - 30 Points
    function(tiles = []) {
        oddOptions.forEach((option) => {
            
            let newArr = []
            tiles.push(newArr)

            //JS2
            newArr.push(createTiles({type: "joker", amount: 1}))
            newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
            newArr.push(createTiles({type: "any", value: 2, amount: 1}))
            
            //AA
            newArr.push(createTiles({type: "any", value: "any", amount: 2}))
            
            //333 333 333
            newArr.push(createTiles({type: allSuits[0], value: option, amount: 3}))
            newArr.push(createTiles({type: allSuits[1], value: option, amount: 3}))
            newArr.push(createTiles({type: allSuits[2], value: option, amount: 3}))
        })
        
        return {
            tiles,
            score: 30,
            concealed: false,
        }
    },
    //FF 777 35 35 35 999 (#’s 3 suits) - 30 Points
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            
            let newArr = []
            tiles.push(newArr)

            //FF
            newArr.push(createTiles({type: "flower", amount: 2}))
            
            //777
            newArr.push(createTiles({type: suitOrder[0], value: 7, amount: 3}))
            
            //35
            newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 1}))
            newArr.push(createTiles({type: suitOrder[1], value: 5, amount: 1}))
            
            //35
            newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 5, amount: 1}))
            
            //35
            newArr.push(createTiles({type: suitOrder[2], value: 3, amount: 1}))
            newArr.push(createTiles({type: suitOrder[2], value: 5, amount: 1}))
            
            //999
            newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 3}))
        })
        
        return {
            tiles,
            score: 30,
            concealed: false,
        }
    },
    //20 333 5555 999 24 (3 suits) - 30
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            
            let newArr = []
            tiles.push(newArr)

            //20
            newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
            newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
            
            //333 5555 999
            newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 3}))
            newArr.push(createTiles({type: suitOrder[1], value: 5, amount: 4}))
            newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 3}))
            
            //24
            newArr.push(createTiles({type: suitOrder[2], value: 2, amount: 1}))
            newArr.push(createTiles({type: suitOrder[2], value: 4, amount: 1}))
        })
        
        return {
            tiles,
            score: 30,
            concealed: false,
        }
    },
    //MJ 1 333 555 777 9 F (M=W, J=Joker; #’s 1 suit) - 35
    function(tiles = []) {
        allSuits.forEach((suit) => {
            
            let newArr = []
            tiles.push(newArr)

            //MJ
            newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
            newArr.push(createTiles({type: "joker", amount: 1}))
            
            //1 333 555 777 9 F
            newArr.push(createTiles({type: suit, value: 1, amount: 1}))
            newArr.push(createTiles({type: suit, value: 3, amount: 3}))
            newArr.push(createTiles({type: suit, value: 5, amount: 3}))
            newArr.push(createTiles({type: suit, value: 7, amount: 3}))
            newArr.push(createTiles({type: suit, value: 9, amount: 1}))

            newArr.push(createTiles({type: "flower", amount: 1}))
        })
        
        return {
            tiles,
            score: 35,
            concealed: false,
        }
    },
    //BIG WINGS 333 555 (B any Bam, I=1; any 2 odds, 1 suit) - 40
    function(tiles = []) {
        allSuits.forEach((suit) => {
            oddOptions.forEach((oddOption1) => {
                oddOptions.forEach((oddOption2) => {
                    if (oddOption1 == oddOption2) {return;}

                    let newArr = []
                    tiles.push(newArr)

                    //BIG
                    newArr.push(createTiles({type: "bamboo", value: "any", amount: 1}))
                    newArr.push(createTiles({type: "any", value: 1, amount: 1}))
                    newArr.push(createTiles({type: "dragon", value: "green", amount: 1}))
        
                    //WINGS
                    newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
                    newArr.push(createTiles({type: "any", value: 1, amount: 1}))
                    newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
                    newArr.push(createTiles({type: "dragon", value: "green", amount: 1}))
                    newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
        
                    //333 555
                    newArr.push(createTiles({type: suit, value: oddOption1, amount: 3}))
                    newArr.push(createTiles({type: suit, value: oddOption2, amount: 3}))
                })
            })
        })
        
        return {
            tiles,
            score: 40,
            concealed: false,
        }
    },
].map((func, index) => {
    let output = func()
    output.section = "Odd Twists and Turns"
    output.sectionIndex = index + 1 //Sections indexed starting at 1. 
    output.handName = `${output.section} #${output.sectionIndex}`
    return new CardHand(output)
})

