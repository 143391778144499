import { Purchases, LogLevel } from "@revenuecat/purchases-js";
import { getStoreToUse, getAPIKeyForStore } from "./revenuecat_utilities"

import {CUSTOMER_INFO_UPDATED_EVENT_NAME} from "./event_names";

//Web billing requires a user ID to be set to configure.
//We will use this user ID if none is provided.
const DEFAULT_USER_ID = "default_user_id" + Math.random().toString()
Purchases.setLogLevel(LogLevel.Debug)

//Web and Native return different formats for isConfigured.
async function arePurchasesConfigured() {
  return Purchases.isConfigured()
}

function getPurchasesInstance() {
  //TODO: This can throw. We probably want to catch the errors and
  //simply return undefined to make handling easier on the other side.
  return Purchases.getSharedInstance()
}

function purchasePackage(packageToBuy) {
  if (getPurchasesInstance().getAppUserId() === DEFAULT_USER_ID) {
    throw new Error("You need to sign in before you can make a purchase. ")
  }

  let res = getPurchasesInstance().purchase({
    rcPackage: packageToBuy,
  })
  res.then((result) => {
    //The CustomerInfo listener does not exist on web, so we will fire when a purchase is made.
    window.dispatchEvent(new Event(CUSTOMER_INFO_UPDATED_EVENT_NAME))
  })
  return res;
}

async function configurePurchases(uid) {
  if (await arePurchasesConfigured()) {
    console.warn("configurePurchases called but Purchases already configured. Refusing to configure again. ")
    return
  }

  let storeToUse = await getStoreToUse()
  if (storeToUse !== "Web") {
    throw new Error("configurePurchases called on web, but storeToUse is not Web. ")
  }
  let apiKey = getAPIKeyForStore(storeToUse)
  Purchases.configure(apiKey, uid);
}

async function setUserUid(uid = DEFAULT_USER_ID) {
  console.log("Setting User UID", uid)
  if (await arePurchasesConfigured()) {
    console.log(await getPurchasesInstance().changeUser(uid))
  }
  else {
    await configurePurchases(uid)
  }

  console.log("uid change or configured", uid)
  window.dispatchEvent(new Event(CUSTOMER_INFO_UPDATED_EVENT_NAME))
}

async function getCustomerInfo() {
  return await getPurchasesInstance().getCustomerInfo()
}

export {purchasePackage, setUserUid, getPurchasesInstance, getCustomerInfo}