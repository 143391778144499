const {readTextFile, writeTextFile, deleteFile} = require("../Files/FileOps.ts") //TODO: Should we use a Setting to store saves instead of [read/write/delete]Save?

//Save offline games.
const saveKey = "save0.room"

let previouslySaved; //When we have zero rooms, we need to detect if the room was closed, or if the page was reloaded.
async function saveOfflineGame() {
	if (!serverStateManager.getRoom("Offline")) {
		//Since previouslySaved is cleared on reloads, if it is set, we were currently writing the saved game.
		//If that game no longer exists (room closed), there is no game we should save.
		if (previouslySaved) {
			console.error("Deleting")
			await deleteFile(saveKey)
			previouslySaved = false
		}
		return //No rooms to save.
	}

	let toSave = serverStateManager.getRoom("Offline").toJSON()
	let currentlySaved = await readTextFile(saveKey)

	previouslySaved = true //Set previouslySaved. The save was written while this tab was open.

	if (toSave !== currentlySaved) {
		console.log(`Saving Game (${toSave.length} characters)`)
		await writeTextFile(saveKey, toSave)
	}
}


function deleteSavedGame() {
    return deleteFile(saveKey)
}

function readSavedGame() {
    return readTextFile(saveKey)
}

module.exports = {saveOfflineGame, deleteSavedGame, readSavedGame}