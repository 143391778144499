const Card = require("../Card.js")

class ImplementedCard extends Card {
	name = "2021 Marvelous Mah Jongg"

	generateHands() {
		let output = []
	
		output.push(...require("./double-harness.js"))
		output.push(...require("./oddly-matched.js"))
		output.push(...require("./atomic.js"))
		output.push(...require("./sub-atomic.js"))
		output.push(...require("./field-and-stream.js"))
		output.push(...require("./3-more-miles.js"))
		output.push(...require("./what's-your-number-please.js"))
		output.push(...require("./running-with-the-bulls.js"))
		output.push(...require("./quintaabulous.js"))
		output.push(...require("./carpe-diem.js"))
	
		return output
	}
}

module.exports = new ImplementedCard()