import { AdMob, AdOptions, AdLoadInfo, InterstitialAdPluginEvents, BannerAdPluginEvents, BannerAdSize, BannerAdPosition  } from '@capacitor-community/admob';
import admobIds from './ids.js';

import { hasAdFree } from '../../ownedProducts';
import {CUSTOMER_INFO_UPDATED_EVENT_NAME} from "../../event_names";

AdMob.addListener(BannerAdPluginEvents.SizeChanged, (bannerSize) => {
    //Ensure that pages can add bottom padding to enable scrolling when ads present. 
    document.documentElement.style.setProperty("--minimumBottomMargin", bannerSize.height + "px")
});

AdMob.addListener(BannerAdPluginEvents.AdImpression, () => {
    console.log("Banner Impression Counted")
});

AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
    console.log("Banner Loaded")
})

AdMob.addListener(BannerAdPluginEvents.FailedToLoad, (e) => {
    console.error("Banner Failed to Load", e)
})

//What we want to do with banner ads:
// - In Game: No banner.
// - Home Screen: Bottom banner on large devices, else no banner.
// - Room Screen: Bottom banner on large devices, else top banner.

let isSmallDevice = window.innerHeight < 550 || window.innerWidth < 550
let absoluteMinimumWidthForTopAd = 700 //We won't attempt to display an ad below 700px.

//On large devices, we will place a full width banner at the bottom.
const largeDeviceOptions = {
    adId: admobIds.bottomBanner,
    adSize: BannerAdSize.ADAPTIVE_BANNER,
    position: BannerAdPosition.BOTTOM_CENTER,
    margin: 0,
};

//On small devices, we will place a small banner in the center at the top.
const smallDeviceOptions = {
    adId: admobIds.topBanner,
    adSize: BannerAdSize.BANNER,
    position: BannerAdPosition.TOP_CENTER,
    margin: 0,
}

//Add 5px padding around the banner ad.
let smallDeviceAdBackgroundElem = document.createElement("div")
smallDeviceAdBackgroundElem.style.width = "330px"
smallDeviceAdBackgroundElem.style.height = "55px"
smallDeviceAdBackgroundElem.style.position = "fixed"
smallDeviceAdBackgroundElem.style.top = "0"
smallDeviceAdBackgroundElem.style.left = "50%"
smallDeviceAdBackgroundElem.style.transform = "translateX(-50%)"
smallDeviceAdBackgroundElem.style.background = "black"
smallDeviceAdBackgroundElem.style.zIndex = "100000"
smallDeviceAdBackgroundElem.style.display = "none"
document.body.appendChild(smallDeviceAdBackgroundElem)

const adOptions = isSmallDevice ? smallDeviceOptions : largeDeviceOptions

/*
 * The possible statuses of the banner ad.
 * @typedef {Object} BannerStatus
 * @property {string} HIDDEN - The banner is hidden.
 * @property {string} BOTTOM_ONLY - The banner can only be shown at the bottom.
 * @property {string} TOP_OR_BOTTOM - The banner can be shown at the top or bottom.
* */
const BannerStatus = {
    HIDDEN: "hidden",
    BOTTOM_ONLY: "bottomonly",
    TOP_OR_BOTTOM: "toporbottom",
}

let isBannerCreated = false; //We will only call showBanner once. We will call resume banner all other times (showBanner forces reload of banner ad).
let currentBannerStatus;

async function setBannerHidden() {
    if (currentBannerStatus === BannerStatus.HIDDEN) {return} //Nothing to do

    try {
        AdMob.hideBanner()
        smallDeviceAdBackgroundElem.style.display = "none"
    }
    catch (e) {
        console.error("Unable to hide banner", e)
    }
    currentBannerStatus = BannerStatus.HIDDEN
    document.body.style.setProperty("--minimumBottomMargin", "0px")
}

async function displayBanner(status) {
    //REMEMBER: If this is being called, status is NOT hidden.

    let currentlyShowing = currentBannerStatus === BannerStatus.TOP_OR_BOTTOM
        || (currentBannerStatus === BannerStatus.BOTTOM_ONLY && !isSmallDevice)

    let shouldShow = status === BannerStatus.TOP_OR_BOTTOM
        || (status === BannerStatus.BOTTOM_ONLY && !isSmallDevice)

    if (!currentlyShowing && !shouldShow && !isBannerCreated && isSmallDevice) {
        //Preload the small banner ad for small devices.
        AdMob.showBanner(adOptions);
        AdMob.hideBanner();
        isBannerCreated = true;
    }

    if (currentlyShowing === shouldShow) {
        return
    }
    else if (currentlyShowing && !shouldShow) {
        await setBannerHidden();
    }
    else if (!currentlyShowing && shouldShow) {
        if (isSmallDevice) {
            smallDeviceAdBackgroundElem.style.display = ""
        }
        if (isBannerCreated) {
            AdMob.resumeBanner()
        }
        else {
            AdMob.showBanner(adOptions);
            isBannerCreated = true;
        }
        currentBannerStatus = status
    }
}


/*
 * This function is used to set the status of the banner ad.
 * Note that it may take some time for this call to complete due to delays in hasAdFree
 * Therefore, we want to ensure that setBannerStatus calls are processed in order.
 * @param {boolean} status - The status of the banner.
* */

let mostRecentBannerStatusCall = Promise.resolve();

async function _setBannerStatus(status) {
    await window.onAdmobInitializedPromise;
    if (
        status === BannerStatus.HIDDEN
        || await hasAdFree()
        || window.innerWidth < absoluteMinimumWidthForTopAd
    ) {
        await setBannerHidden()
    }
    else {
        await displayBanner(status)
    }
}

function setBannerStatus(status) {
    //We will call _setBannerStatus after the most recent call has completed.
    mostRecentBannerStatusCall = new Promise((resolve, reject) => {
        mostRecentBannerStatusCall.finally(() => {
            _setBannerStatus(status).then(resolve, reject)
        })
    })
    return mostRecentBannerStatusCall
}

function addBannerElement(adContainer) {
    //Banner ads are complicated on mobile.
    //Ideally, we could use Adsense, however Adsense prohibits use in software applications. 

    //Furthermore, the "native" ad format (to integrate ads with content) doesn't really work that well with JavaScript & WebView/WebKit based apps. 
    //Just don't do anything for now. 

    adContainer.remove() //Delete the empty container
}

//Ensure the banner disappears immediately after a user makes or restores purchases.
window.addEventListener(CUSTOMER_INFO_UPDATED_EVENT_NAME, async function() {
    await window.onAdmobInitializedPromise
    if (await hasAdFree()) {
        await setBannerStatus(BannerStatus.HIDDEN)
    }
})

export {
    setBannerStatus, BannerStatus, addBannerElement
}