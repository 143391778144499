const Tile = require("../../Tile")
const TileContainer = require("../../TileContainer")
const Wall = require("../../Wall")
const calculateJokerAmount = require("../../Hand/calculateJokerAmount.js")
const {calculateWeightedDiff} = require("./calculateWeightedDiff.js")

function permutations(arr, maxLength) {
    if (maxLength) {
        return combinations(arr, maxLength).map(combo => permutations(combo)).flat(1)
    }
    return permute(arr)
}

//https://stackoverflow.com/a/37580979
//Unmodified
function permute(permutation) {
    var length = permutation.length,
    result = [permutation.slice()],
    c = new Array(length).fill(0),
    i = 1, k, p;
    
    while (i < length) {
        if (c[i] < i) {
            k = i % 2 && c[i];
            p = permutation[i];
            permutation[i] = permutation[k];
            permutation[k] = p;
            ++c[i];
            i = 1;
            result.push(permutation.slice());
        } else {
            c[i] = 0;
            ++i;
        }
    }
    return result;
}


//From n choose k
//Based on HACKEM, Gosper #175
function combinations(arr, k) {
    if (k > arr.length) {throw `Cannot choose ${k} from ${arr.length} items`}
    
    //From n choose k
    //HACKEM, Gosper #175
    
    let combos = []
    
    let n = arr.length
    
    let set = (1 << k) - 1;
    let limit = (1 << n);
    
    while (set < limit) {
        //set is now a number, with the binary digits indicating which items to include.
        let comboItems = []
        combos.push(comboItems)
        for (let i=0;i<arr.length;i++) {
            if (set & (1 << i)) {
                comboItems.push(arr[i])
            }
        }
        
        let c = set & -set;
        let r = set + c;
        set = (((r^set) >>> 2) / c) | r;
    }
    
    return combos;
}

//Generate all possible pairs for array arr
function getPairs(arr, pairs = []) {
    let pairingItem = arr.pop()
    pairs.push([pairingItem, pairingItem])
    if (arr.length === 0) {return}
    arr.forEach((item) => {
        pairs.push([pairingItem, item])
    })
    getPairs(arr, pairs)
    return pairs
}

function createTiles({type, value, amount}) {
    return new Array(amount).fill(Tile.get({type, value}))
}

var allSuits = ["bamboo", "character", "circle"]
var allSuitArrangements = permutations(allSuits)
var oddOptions = [1,3,5,7,9]
var evenOptions = [2,4,6,8]
var allOptions = oddOptions.concat(evenOptions).sort()
var windOptions = ["north", "east", "west", "south"]

//Dragons are associated with a suit.
var dragonOptions = ["red", "green", "white"]
var dragonArrangments = permutations(dragonOptions)
var suitDragonConversion = {
    "character": "red",
    "bamboo": "green",
    "circle": "white"
}

var windArrangments = permutations(windOptions)

function getUniqueTiles(hand) {
    let uniqueTiles = []
    hand.forEach((handItem) => {
        let tile;
        if (handItem.tiles) {handItem = handItem.tiles} //TileContainer.
        if (handItem instanceof Array) {tile = TileContainer.findBaseTile(handItem)}
        else {tile = handItem}
        
        if (tile.type === "any" || tile.value === "any") {return false} //We are simulating all possible tiles. Any tiles are not a tile.
        
        let isUnique = !(uniqueTiles.some((uniqueTile) => {
            return uniqueTile.matches(tile)
        }))
        
        if (isUnique) {
            uniqueTiles.push(tile)
        }
    })
    
    return uniqueTiles
}

//Provide details about current state of a specific handOption
//Number of remaining tiles before Mahjong, unused tiles for specific hand, etc.
function processHand(handOption, hand, options) {
    let canFillJoker = []
    let noFillJoker = []
    let anyValueSingletons = []
    
    options.addedExposedMatch = options.addedExposedMatch ?? true
    
    if (handOption.isAtomic) {
        if (handOption.maxSuits < 3) {
            //Find all combinations of maxSuits suits. 
            //Then call recursively with a clone of the option except with tiles of missing suits removed from hand and maxSuits removed
            
            let results = []
            
            combinations(allSuits, handOption.maxSuits).forEach((suitsAllowed) => {
                let simHandOption = Object.assign({}, handOption) //Clone our hand option.
                simHandOption.maxSuits = undefined //Remove maxSuits from our clone.
                
                let addToNotUsed = []
                
                let simOurHand = hand.slice(0) //Clone our hand.
                
                for (let i=simOurHand.length - 1;i>=0;i--) {
                    let handItem = simOurHand[i]
                    if (suitsAllowed.includes(handItem.type)) {
                        continue; //All good. 
                    }
                    else if (!(handItem instanceof Tile)) {
                        //This hand is unachievable, as something has been exposed. 
                        return; //Go to next combo. 
                    }
                    else {
                        simOurHand.splice(i, 1); //Remove from our hand.
                        simOurHand.push(new Tile({type: "removed", value: addToNotUsed.length})) //Swap in a tile that will never be used. Will be swapped out later (temp tile)
                        addToNotUsed.push(handItem); 
                        continue; //Tile not used but nothing necessarily wrong. 
                    }
                }
                
                let res = processHand(simHandOption, simOurHand, options)
                res.notUsed = addToNotUsed //Swap out temp tiles for the actual tiles. 
                
                //With normal atomic, each pair needs one more tile. Here, it might be 2, as we need to discard tiles in unneeded suits. Adjust for that. 
                let oldDiff = res.diff
                res.diff = Math.max(res.diff, addToNotUsed.length)
                                
                results.push(res)
            })
            
            return results;
        }
        
        
        let uniqueTiles = getUniqueTiles(hand).filter((tile) => {
            return tile.type === "bamboo" || tile.type === "character" || tile.type === "circle" //Atomic hand only allows number tiles.
        })
        let simHandOption = Object.assign({}, handOption) //Clone our hand option.
        simHandOption.tiles = []
        
        uniqueTiles.forEach((uniqueTile) => {
            let arr = new Array(2).fill(uniqueTile)
            simHandOption.tiles.push(arr)
        })
        
        handOption = simHandOption
    }
    
    
    //TODO: We need to do our concealed hand checking up here. 
    for (let handOptionIndex=0;handOptionIndex<handOption.tiles.length;handOptionIndex++) {
        let item = handOption.tiles[handOptionIndex]
        
        let anyType = (item[0].type === "any")
        let anyValue = (item[0].value === "any")
        
        if (item.length === 1 && anyType && anyValue) {
            continue; //Do nothing - this tile can be, quite literally, anything.
        }
        if (item.length === 1 && anyValue) {
            //This tile is a specific suit, but can be any value (1-9)
            //Add to noFillJoker AFTER everything else.
            anyValueSingletons.push(...item)
            continue;
        }
        if (item.length > 1 && (anyType || anyValue)) {
            //TODO: We ideally expand fully in Suggested Hands (visualizing every possible tile, not just tiles we have, at least for NMJL)
            let uniqueTiles = getUniqueTiles(hand).filter((tile) => {
                return item[0].matches(tile, true) //This "any" selector must match the tile - any bamboo should not match characters.
            })
            
            if (uniqueTiles.length > 0) {
                //Now for every unique tile, simulate this hand.
                let results = []
                for (let i=0;i<uniqueTiles.length;i++) {
                    let simHandOption = Object.assign({}, handOption) //Clone our hand option.
                    let simTiles = simHandOption.tiles.slice(0) //Clone the tiles
                    simTiles[handOptionIndex] = new Array(item.length).fill(uniqueTiles[i]) //Replace this "any" item with the simulated tiles.
                    simHandOption.tiles = simTiles //Swap in our simulated tiles.
                    let res = processHand(simHandOption, hand, options)
                    if (res instanceof Array) {
                        results.push(...res)
                    }
                    else if (res) {
                        results.push(res)
                    }
                }
                
                //TODO: Right now, we return all possible combos. We may want to begin rendering these an any pair, any tile, etc, instead, and return the best, with the anys swapped back in.
                //results.sort((a, b) => {return a.diff - b.diff})
                return results //[0]
            }
        }
        
        if (item.length <= 2 && item[0].type !== "joker") {
            noFillJoker.push(...item)
        }
        else {
            canFillJoker.push(...item)
        }
    }
    
    noFillJoker.push(...anyValueSingletons)
    
    let jokerCount = 0
    let diff;
    let exposedMatches = 0 //May not be used anymore - was supposed to be used to detect where concealed hands were an issue.
    
    let notUsed = [] //Used to return which tiles should be thrown for this hand. TODO: This slows things down quite a bit.
    
    //We need TileContainers and Arrays to be processed first, as those can't be discarded.
    function removeItem(item, removingJokerAllowingItem = false) {
        if (item.type === "joker" && !handOption.isAtomic) {
            ++jokerCount
            return true
        }
        
        //TODO: The splice and search should be able to be optimized.
        
        //If the tile cannot be filled in for with a joker, and is an EXACT match (not filling in for an any value or something), fill it in immediately. 
        let noFillIndex = noFillJoker.findIndex((tile) => {return tile.matches(item)})
        if (removingJokerAllowingItem) {noFillIndex = -1;} //Exposed match, so jokers must be allowable (cannot treat as singletons)
        if (noFillIndex !== -1) {
            noFillJoker.splice(noFillIndex, 1)
            return true
        }
        
        //Now try to put the tile in a pong, kong, etc.
        
        //Note: There are some cases where this tile might be needed to fill in for an any value or any suit tile. 
        //Therefore, we need to allow jokers to fill in for the corresponding any value / any suit tiles. 
        //We currently expand any suit, so we don't have complications preventing double use of a tile (ie, 1 bamboo being a 1 and being a bamboo)
        //Therefore, IF this tile can be used to fill in something in noFillJoker under a non-exact match, we will move said item into canFillJoker. 
        let canFillIndex = canFillJoker.findIndex((tile) => {return tile.matches(item)})
        
        noFillIndex = noFillJoker.findIndex((tile) => {return tile.matches(item, true)})
        if (removingJokerAllowingItem) {noFillIndex = -1;} //Exposed match, so jokers must be allowable (cannot treat as singletons)
        
        if (canFillIndex !== -1) {
            canFillJoker.splice(canFillIndex, 1)
            if (noFillIndex !== -1) {
                //Once again, we can assume that, single we only have EITHER any value OR any suit singletons in any specific hand,
                //each tile will ONLY match a maximum of 1 singleton any tile in noFillJoker. 
                
                canFillJoker.push(noFillJoker.splice(noFillIndex, 1)[0])
                wereTilesRelocatedIntoCanFillJoker = true
            }
            return true
        }
        
        //If this tile did not match exactly with anything in noFillJoker, nor with anything in canFillJoker, match with non-exact matches (any-value or any-suit singletons). 
        //Match non-joker section first. 
        
        if (noFillIndex !== -1) {
            noFillJoker.splice(noFillIndex, 1)
            return true
        }
        
        canFillIndex = canFillJoker.findIndex((tile) => {return tile.matches(item, true)})
        
        if (canFillIndex !== -1) {
            canFillJoker.splice(canFillIndex, 1)
            return true
        }
        
        return false
    }
    
    processHandItems:
    for (let i=0;i<hand.length;i++) {
        let handItem = hand[i]
        
        if (handItem instanceof TileContainer) {handItem = handItem.tiles}
        if (handItem instanceof Array) {
            if (handOption.concealed === true) {
                //Tiles are exposed! Label this! We'll check later to verify too much isn't exposed.
                exposedMatches++
            }
            
            //Like in the 2021 card, with 2021 #3, it is possible for the same tile to be used in multiple different
            //matches - therefore, we must verify that such a match exists in the target hand.
            //TODO: 2021 #3 still has issues - we remove the kong from noFillJoker instead of canFillJoker,
            //and we also would take a kong when possible, yet a kong makes the hand dead (need 1+ jokers in kong)
            
            let itemValue = TileContainer.isValidMatch(handItem, true) //True to allow jokers when matching.
            
            if (handOption.tiles.some((item) => {
                if (!itemValue) {return true} //This exposure must be a bunch of individual tiles, like a 2019.
                else if (item.length === handItem.length && itemValue.matches(TileContainer.findBaseTile(item))) {
                    return true
                }
                else {return false}
            })) {
                //It exists! Now remove the tiles.
                
                for (let i=0;i<handItem.length;i++) {
                    let tile = handItem[i]
                    
                    if (tile.type === "joker") {
                        //The jokers in this match are acting as something. (It must be a pong, kong, etc, to have jokers)
                        tile = itemValue
                    }
                    
                    //These items are coming from EXPOSED matches. Matches that can't be filled with a joker can never be exposed matches (except when maxJokers or noJokers relevant). 
                    //Therefore, pass true. 
                    let mustAllowJoker = handItem.length >= 3
                    if (!removeItem(tile, mustAllowJoker)) {
                        diff = Infinity //The hand is impossible with current exposures.
                        break processHandItems;
                    }
                }
            }
            else {
                diff = Infinity
                break processHandItems;
            }
        }
    }
    if (diff === Infinity) {return}
    
    let exposedJokerAmount = 0;
    if (handOption.maxJokers !== undefined) {
        //TODO: Add a diffWithoutChecks tooManyJokers. 
        //Pass true to compute exposed jokers only.
        exposedJokerAmount = calculateJokerAmount(hand, true)
        
        if (exposedJokerAmount > handOption.maxJokers) {
            return //The hand is impossible with current exposures.
        }
    }
    
    for (let i=0;i<hand.length;i++) {
        let handItem = hand[i]
        
        if (handItem instanceof TileContainer || handItem instanceof Array) {}
        else {
            if (!removeItem(handItem)) {
                notUsed.push(handItem) //TODO: If we have a joker acting as this item, put it at the beginning.
            }
        }
    }
    
    diff = noFillJoker.length + Math.max(0, canFillJoker.length - Math.min(jokerCount, (handOption.maxJokers ?? Infinity) - exposedJokerAmount))
    if (handOption.isAtomic) {
        diff = Math.ceil((diff + notUsed.length) / 2)
        
        if (hand.length === 0) {diff = 14} //Must be another player who hasn't yet made any exposures. Set diff to 14 (so it is tied with other items).
        
        handOption.tiles = handOption.tiles.filter((tilesArr) => {
            //Filter out all handOptions that are not fully filled.
            let baseTile = tilesArr[0]
            
            if (
                noFillJoker.some((tile) => {return baseTile.matches(tile)})
                || canFillJoker.some((tile) => {return baseTile.matches(tile)})
                ) {
                    return false
                }
                return true
            })
            
            while (handOption.tiles.length < 7) {
                //Fill remaining handOptions with "any"s
                handOption.tiles.push(new Array(2).fill(Tile.get({type: "any", value: "any"})))
            }
        }
        
        
        let diffWithoutChecks = {} //Used to tell users why their mahjong is invalid for common mistakes. 
        diffWithoutChecks.concealed = diff
        diffWithoutChecks.invalidJokerUse = diff
        diffWithoutChecks.all = diff
        
        //NOTE: (exposedMatches === 1 && diff a valid exposed matches check, only WHEN or AFTER the hand goes Mahjong.
        //Before the hand goes Mahjong, it is NOT a valid check.
        //We will have options.addedExposedMatch, which will default to true, to check this.
        //If passed as false (for naked mahjong calls where a match has not been added as part of mahjong checking), we will not allow any existing exposures.
        
        if (handOption.concealed && !(exposedMatches === 0 || (options.addedExposedMatch && exposedMatches === 1 && diff === 0))) {
            if (!options.skipConcealedCheck) {
                //Checking for concealed hands doesn't work when analyzing for duplicate removal. Therefore, we check options.skipConcealedCheck
                diff = Infinity
                // return; //This hand is impossible with current exposures, but we will NOT remove from the list (used for diffWithoutChecks)
            }
        }
        
        //Add jokers that we aren't using to fill anything
        //We could take a different approach - attempt to use every joker, and discard the actual tile first,
        //however the current approach, while making recovery plans worse (discards jokers that we could keep),
        //increases the odds of no joker point doubles (compared to discarding the actual tile and stashing jokers).
        while (jokerCount > canFillJoker.length) {
            notUsed.push(Tile.get({type: "joker", value: ""}))
            jokerCount--
            diffWithoutChecks.invalidJokerUse--
            diffWithoutChecks.all--
        }

        diffWithoutChecks.all = Math.max(0, diffWithoutChecks.all)
        diffWithoutChecks.invalidJokerUse = Math.max(0, diffWithoutChecks.invalidJokerUse)
        
        return {
            diff, handOption, noFillJoker, canFillJoker, notUsed, jokerCount, diffWithoutChecks
        }
    }
    
    function getTileDifferential(handOptions, hand, options = {}) {
        //options.skipConcealedCheck - default false. If true, we don't check if concealed hands are impossible due to exposures.
        
        //getTileDifferential takes an array of tiles are determines how many tiles away hand is
        //from every achivable handOption (TODO: Allow passing remaining wall tiles / already exposed tiles)
        
        //TODO: Eliminate hands if they are impossible based on currently discarded tiles and current exposures.
        //Doing this perfectly is nearly impossible, but improvements would be nice:
        // - Detect when current exposures make non-joker tiles unobtainable.
        // - Jokers (especially for quints) are complicated, because of joker swaps. Detecting when a hand is dead because
        //jokers are needed given present exposures, and said jokers can't be obtained (tiles to swap for exposed jokers dead, etc)
        
        if (handOptions.combos) {
            //A card was passed, instead of an array.
            handOptions = handOptions.combos
        }
        
        let results = []
        //Passing an array of handOptions.
        for (let i=0;i<handOptions.length;i++) {
            let handOption = handOptions[i]
            
            let res = processHand(handOption, hand, options)
            if (res instanceof Array) {
                results.push(...res)
            }
            else if (res) {results.push(res)}
        }
        
        

        
        
        results = results.sort((function(a,b) {
            //We will cache weightedDiffs, as sort may call this function multiple times for any given entry. 
            a.weightedDiff = a.weightedDiff ?? calculateWeightedDiff(a)
            b.weightedDiff = b.weightedDiff ?? calculateWeightedDiff(b)


            if (a.weightedDiff !== b.weightedDiff) {return a.weightedDiff - b.weightedDiff} //Sort by closest to Mahjong
            
            return b.handOption.score - a.handOption.score //Sort by score.
        }))
        return results
    }
    
    let nonJokerTiles = Wall.getNonPrettyTiles(1)
    nonJokerTiles.push(Tile.get({type: "flower"}))
    
    let allTiles = nonJokerTiles.slice(0)
    allTiles.push(Tile.get({type: "joker"})) //TODO: Test with these - could be weird.
    
    module.exports = {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations}
