const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//LLL AA PPP III NNN
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)
		
		newArr.push(createTiles({type: "any", value: 7, amount: 3}))
		
		newArr.push(createTiles({type: "any", value: "any", amount: 2}))
		
		newArr.push(createTiles({type: "any", value: "any", amount: 3}))
		
		newArr.push(createTiles({type: "any", value: 1, amount: 3}))
		
		newArr.push(createTiles({type: "wind", value: "north", amount: 3}))
		
		return {
			tiles,
			score: 35,
			concealed: false
		}
	},
	//AA NNN GGG O RRR AA
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)
		
		newArr.push(createTiles({type: "any", value: "any", amount: 2}))
		
		newArr.push(createTiles({type: "wind", value: "north", amount: 3}))
		
		newArr.push(createTiles({type: "dragon", value: "green", amount: 3}))
		
		newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
		
		newArr.push(createTiles({type: "dragon", value: "red", amount: 3}))
		
		newArr.push(createTiles({type: "any", value: "any", amount: 2}))
		
		return {
			tiles,
			score: 35,
			concealed: false
		}
	},
	//M AA KKKK E M AA GIC
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)
		
		
		newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
		
		newArr.push(createTiles({type: "any", value: "any", amount: 2}))
		
		newArr.push(createTiles({type: "any", value: "any", amount: 4}))
		
		newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
		
		newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
		
		newArr.push(createTiles({type: "any", value: "any", amount: 2}))
		
		newArr.push(createTiles({type: "dragon", value: "green", amount: 1}))
		newArr.push(createTiles({type: "any", value: 1, amount: 1}))
		newArr.push(createTiles({type: "character", value: "any", amount: 1}))
		
		return {
			tiles,
			score: 35,
			concealed: false
		}
	},
	//LI KKKK ES 2 44 AGE
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)
		
		
		newArr.push(createTiles({type: "any", value: 7, amount: 1}))
		newArr.push(createTiles({type: "any", value: 1, amount: 1}))
		
		newArr.push(createTiles({type: "any", value: "any", amount: 4}))
		
		newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
		newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
		
		newArr.push(createTiles({type: "any", value: 2, amount: 1}))
		newArr.push(createTiles({type: "any", value: 4, amount: 2}))
		
		newArr.push(createTiles({type: "any", value: "any", amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "green", amount: 1}))
		newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
		
		return {
			tiles,
			score: 35,
			concealed: false
		}
	},
	//LONG WIS KKKK ERS
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)
		
		
		newArr.push(createTiles({type: "any", value: 7, amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
		newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "green", amount: 1}))
		
		newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
		newArr.push(createTiles({type: "any", value: 1, amount: 1}))
		newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
		
		newArr.push(createTiles({type: "any", value: "any", amount: 4}))
		
		newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
		newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
		
		return {
			tiles,
			score: 40,
			concealed: false
		}
	},
	//SO LONG FOL KKKK S
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)
		
		newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
		
		newArr.push(createTiles({type: "any", value: 7, amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
		newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "green", amount: 1}))
		
		newArr.push(createTiles({type: "flower", amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
		newArr.push(createTiles({type: "any", value: 7, amount: 1}))
		
		newArr.push(createTiles({type: "any", value: "any", amount: 4}))
		
		newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
		
		return {
			tiles,
			score: 40,
			concealed: false
		}
	},
	//JAC KKKK AA LO PPP 
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)
		
		newArr.push(createTiles({type: "joker", amount: 1}))
		newArr.push(createTiles({type: "any", value: "any", amount: 1}))
		newArr.push(createTiles({type: "character", value: "any", amount: 1}))
		
		newArr.push(createTiles({type: "any", value: "any", amount: 4}))
		
		newArr.push(createTiles({type: "any", value: "any", amount: 2}))
		
		newArr.push(createTiles({type: "any", value: 7, amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
		
		newArr.push(createTiles({type: "any", value: "any", amount: 3}))
		
		return {
			tiles,
			score: 40,
			concealed: false
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "M AA GIC AA L"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

