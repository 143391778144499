const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//KKKK PPP 33 66 999
	function(tiles = []) {
		allSuits.forEach((suitForRun) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "any", value: "any", amount: 4}))

			newArr.push(createTiles({type: "any", value: "any", amount: 3}))

			newArr.push(createTiles({type: suitForRun, value: 3, amount: 2}))

			newArr.push(createTiles({type: suitForRun, value: 6, amount: 2}))

			newArr.push(createTiles({type: suitForRun, value: 9, amount: 3}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//3 66 999 33 666 999
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 3}))

			newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 2}))

			newArr.push(createTiles({type: suitOrder[2], value: 6, amount: 3}))
			newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 3}))
		})

		return {
			tiles,
			score: 25,
			concealed: false,
		}
	},
	//A 333 666 999 KKKK
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "any", value: "any", amount: 1}))

			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 3}))

			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 3}))

			newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 3}))

			newArr.push(createTiles({type: "any", value: "any", amount: 4}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//W 333 666 999 PPP E
	function(tiles = []) {
		windArrangments.forEach((winds) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)
	
				newArr.push(createTiles({type: "wind", value: winds[0], amount: 1}))
	
				newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 3}))
	
				newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 3}))
	
				newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 3}))
	
				newArr.push(createTiles({type: "any", value: "any", amount: 3}))

				newArr.push(createTiles({type: "wind", value: winds[2], amount: 1}))
			})
	
			allSuits.forEach((suit) => {
				let newArr = []
				tiles.push(newArr)
	
				newArr.push(createTiles({type: "wind", value: winds[0], amount: 1}))
	
				newArr.push(createTiles({type: suit, value: 3, amount: 3}))
	
				newArr.push(createTiles({type: suit, value: 6, amount: 3}))
	
				newArr.push(createTiles({type: suit, value: 9, amount: 3}))
	
				newArr.push(createTiles({type: "any", value: "any", amount: 3}))

				newArr.push(createTiles({type: "wind", value: winds[2], amount: 1}))			})
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//MO PPP SIE 333 999
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)

		newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))

		newArr.push(createTiles({type: "any", value: "any", amount: 3}))

		newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
		newArr.push(createTiles({type: "any", value: 1, amount: 1}))
		newArr.push(createTiles({type: "wind", value: "east", amount: 1}))

		newArr.push(createTiles({type: "any", value: 3, amount: 3}))
		newArr.push(createTiles({type: "any", value: 9, amount: 3}))

		return {
			tiles,
			score: 35,
			concealed: false
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "MO PPP SIE"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

