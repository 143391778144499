const Tile = require("../Tile")
const Pretty = require("../Pretty")
const SeedRandom = require("seed-random")

class Wall {
	constructor(seed = Math.random(), config = {}) {
		this.drawFirst = function() {
			return this.tiles.pop()
		}

		this.readFirst = function() {
			return this.tiles[this.tiles.length - 1]
		}

		this.tiles = []

		//Time to add the tiles to the deck...
		this.tiles = this.tiles.concat(Wall.getNonPrettyTiles())

		if (!config.noPrettys) {
			;[false, true].forEach((isSeason) => {
				for (let i=1;i<=4;i++) {
					//Prettys are tiles ("flowers") in American Mahjong
					if (!config.prettysAsTiles) {
						this.tiles.push(new Pretty({
							value: i,
							seasonOrFlower: isSeason?"season":"flower"
						}))
					}
					else {
						this.tiles.push(Tile.get({
							value: i,
							type: isSeason?"season":"flower"
						}))
					}
				}
			})
		}

		if (config.includeJokers) {
			for (let i=0;i<config.includeJokers;i++) {
				this.tiles.push(Tile.get({
					type: "joker",
					value: ""
				}))
			}
		}


		//Randomly mix the tiles.
		Wall.shuffleArray(this.tiles, seed)

		this.toJSON = (function() {
			return seed
		}).bind(this)
	}

	static _getNonPrettyTiles(amount) {
		//We have this as a static method because it can be useful to obtain a copy of every playing tiles in the game.
		let tiles = []
		for (let i=1;i<=9;i++) {
			for (let c=0;c<amount;c++) {
				["bamboo", "character", "circle"].forEach((type) => {
					tiles.push(Tile.get({
						type,
						value: i
					}))
				})
			}
		}

		;["red", "green", "white"].forEach((value) => {
			for (let i=0;i<amount;i++) {
				tiles.push(Tile.get({
					type: "dragon",
					value: value
				}))
			}
		})

		;["north", "south", "east", "west"].forEach((value) => {
			for (let i=0;i<amount;i++) {
				tiles.push(Tile.get({
					type: "wind",
					value: value
				}))
			}
		})
		return tiles
	}

	static _nonPrettyTilesCache = {}

	//To avoid repeatedly creating new Tile objects, just reference the same objects repeatedly.
	//We need to generate a new array for each amount parameter for backwards compatability.
	//If we merely concatenated, we would have [1,2,3,4,1,2,3,4] whereas we currently have [1,1,2,2,3,3,4,4]
	//This would cause the wall at the start of the game to be different, breaking old save files.
	static getNonPrettyTiles(amount = 4) {
		if (!Wall._nonPrettyTilesCache[amount]) {
			Wall._nonPrettyTilesCache[amount] = Wall._getNonPrettyTiles(amount)
		}
		return Wall._nonPrettyTilesCache[amount].slice(0)
	}

	static shuffleArray(array, seed = Math.random()) {
		let random = SeedRandom(seed)

		//Durstenfeld shuffle
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
	}

	static fromJSON(str) {
		let seed = str
		return new Wall(seed)
	}
}

module.exports = Wall
