const americanUtilities = require("../../lib/american/utilities/index.js")
const Tile = require("../Tile")
const calculateJokerAmount_nonHandAssociated = require("./calculateJokerAmount.js")

//scoreAmerican does not consider Drew Own Tile as part of the score (so we don't take it),
//because being payed double for drawing your own tile is simply an extension of the mahjong tile thrower paying double.
function scoreAmerican(config) {
	if (!config.card) {throw "Must pass config.card"}

	let result = {
		noJokers: false,
		score: 0,
		handName: "Unable to Score"
	}

	let hands = americanUtilities.getTileDifferential(config.card, this.contents)

	if (!hands?.[0]) {return result} //If there isn't a hand, we can't score. 

	if (hands[0].diff !== 0) {
		//The hand is not Mahjong. 
		//Now we're going to iterate through all available hands and check diffWithoutChecks.
		
		//This will allow us to detect frequent user mistakes. 

		let reasons = []

		for (let hand of hands) {
			if (hand.diffWithoutChecks.all === 0) {
				if (hand.diffWithoutChecks.concealed === 0) {
					reasons.push(`${hand.handOption.handName} invalid: The hand is concealed so cannot contain exposures. `)
				}
				else if (hand.diffWithoutChecks.invalidJokerUse === 0) {
					reasons.push(`${hand.handOption.handName} not complete: Jokers cannot be used in pairs or as single tiles. Please note that 2023 and year number groups are composed of single tiles per NMJL rules. `)
				}
				else {
					//We need both to be zero!
					reasons.push(`${hand.handOption.handName} invalid: The hand is concealed so cannot contain exposures. `)
					reasons.push(`${hand.handOption.handName} not complete: Jokers cannot be used in pairs or as single tiles. Please note that 2023 and year number groups are composed of single tiles per NMJL rules. `)
				}
			}


			//Now one really weird check:
			//A significant number of people are confusing the flowers and the one bamboo. 
			//So if noFillJoker and canFillJoker contain flowers, and notUsed contains one bamboos, alert. 

			if (hand.notUsed.every((tile) => {
				return tile.type === "bamboo" && tile.value === 1
			})) {
				//notUsed contains only flowers. 
				//Now, canFillJoker can contain non-flowers as well. 
				//We just need to determine if the number of flowers not filled equals the diff. 


				let flowerCount = hand.canFillJoker.concat(hand.noFillJoker).reduce((sum, tile) => {
					return sum + Number(tile.type === "flower")
				}, 0)

				if (flowerCount === hand.diff) {
					reasons.push(`${hand.handOption.handName}: It appears that you mistook at least one 1 bamboo (bird tile) for a flower. `)
				}
			}
		}

		result.reasons = reasons
		return result
	}


	let hand = hands[0]
	let handOption = hand.handOption

	result.score = handOption.score
	result.handName = handOption.handName

	//Some hands (Marvelous) have pure jokers (Js) as part of the hand. These jokers do not count against the noJokers double.  
	let pureJokersRequiredByHand = calculateJokerAmount_nonHandAssociated(handOption.tiles.flat())
	let jokersInHand = this.calculateJokerAmount(this.contents)

	if (pureJokersRequiredByHand === jokersInHand) {
		//The user uses no jokers that are not required by the card. 
		//Now we must check if the hand has room for any jokers (singles and pairs hands, and hands with no space for jokers, cannot receive no joker bonuses)
		let maxUsableJokers = americanUtilities.getTileDifferential([handOption], [])[0].canFillJoker.length

		if (maxUsableJokers - pureJokersRequiredByHand > 0) {
			//This hand has spots for optional jokers, and jokers are not used in any of those spots.
			//Therefore, we should double points for no jokers. 
			result.noJokers = true
			result.score *= 2
		}
	}
	else if (pureJokersRequiredByHand > jokersInHand) {
		console.warn("Pure jokers required by hand exceeds jokers in hand. This should only result from an overridden Mahjong call. ")
	}

	return result
}

module.exports = scoreAmerican
