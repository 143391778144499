const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//FF (22 46 88) (22 46 88) - 2 Suits
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 2}))

			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 2}))

			newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 2}))
			newArr.push(createTiles({type: suitOrder[1], value: 4, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 2}))
		})
		
		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//FF (11 33 55) (55 77 99)
	function(tiles = []) {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: "flower", amount: 2}))

				newArr.push(createTiles({type: suitOrder[0], value: 1, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 5, amount: 2}))

				newArr.push(createTiles({type: suitOrder[1], value: 5, amount: 2}))
				newArr.push(createTiles({type: suitOrder[1], value: 7, amount: 2}))
				newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 2}))
			})
		
		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//(112) (11223) (112233) or (998) (99887) (998877) - 3 Suits
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: suitOrder[0], value: 1, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))

			newArr.push(createTiles({type: suitOrder[1], value: 1, amount: 2}))
			newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 2}))
			newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 1}))

			newArr.push(createTiles({type: suitOrder[2], value: 1, amount: 2}))
			newArr.push(createTiles({type: suitOrder[2], value: 2, amount: 2}))
			newArr.push(createTiles({type: suitOrder[2], value: 3, amount: 2}))
		})

		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 1}))

			newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 2}))
			newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 2}))
			newArr.push(createTiles({type: suitOrder[1], value: 7, amount: 1}))

			newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 2}))
			newArr.push(createTiles({type: suitOrder[2], value: 8, amount: 2}))
			newArr.push(createTiles({type: suitOrder[2], value: 7, amount: 2}))

		})
		
		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//FF (33 66 99) (369) (369) - 3 Suits
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 2}))

			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 2}))

			newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 1}))

			newArr.push(createTiles({type: suitOrder[2], value: 3, amount: 1}))
			newArr.push(createTiles({type: suitOrder[2], value: 6, amount: 1}))
			newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 1}))
		})
		
		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//(11 22 33 44 55) DD DD - 3 Suits, consec
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			allOptions.slice(0, -4).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 1+offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 2+offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 3+offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 4+offset, amount: 2}))

				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 2}))
				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[2]], amount: 2}))
			})
		})
		
		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//2024 NN EW SS 2024 (Any 2 Suits)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
			newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 1}))


			newArr.push(createTiles({type: "wind", value: "north", amount: 2}))

			newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
			newArr.push(createTiles({type: "wind", value: "west", amount: 1}))

			newArr.push(createTiles({type: "wind", value: "south", amount: 2}))


			newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 1}))
			newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 4, amount: 1}))
		})
		return {
			tiles,
			score: 75,
			concealed: true
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "Singles and Pairs"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

