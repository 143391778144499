let durationStringMap = {
  "P1D": {
    inWords: "1 Day",
    durationDays: 1,
  },
  "P3D": {
    inWords: "3 Days",
    durationDays: 3,
  },
  "P1W": {
    inWords: "1 Week",
    durationDays: 7,
  },
  "P2W": {
    inWords: "2 Weeks",
    durationDays: 14,
  },
  "P1M": {
    inWords: "1 Month",
    durationDays: 30,
  },
  "P2M": {
    inWords: "2 Months",
    durationDays: 60,
  },
  "P3M": {
    inWords: "3 Months",
    durationDays: 90,
  },
  "P6M": {
    inWords: "6 Months",
    durationDays: 180,
  },
  "P1Y": {
    inWords: "1 Year",
    durationDays: 365,
  }
}

export {durationStringMap}