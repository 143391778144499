const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//222 000 222 3333 (Any 2 Suits)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 3}))
			
			newArr.push(createTiles({type: "dragon", value: "white", amount: 4}))
			
			newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 3}))
			newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 4}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FF 2023 2222 3333 (Any 3 Suits)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 2}))
			
			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
			newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 1}))
			
			newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 4}))
			
			newArr.push(createTiles({type: suitOrder[2], value: 3, amount: 4}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FFFF DDD 2023 DDD (2023 any suit, any dragons)
	function(tiles = []) {
		allSuits.forEach((suit) => {
			allSuitArrangements.forEach((dragonSuits) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: "flower", amount: 4}))
				
				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[dragonSuits[0]], amount: 3}))
				
				newArr.push(createTiles({type: suit, value: 2, amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
				newArr.push(createTiles({type: suit, value: 2, amount: 1}))
				newArr.push(createTiles({type: suit, value: 3, amount: 1}))
				
				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[dragonSuits[1]], amount: 3}))
			})
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//22 000 NEWS 222 33 (2 suits, pair of 2s any suit)
	//Per NMJL clarification, the pair of 2s must be a different suit from the 222 33
	//So (22) 000 NEWS (222 33) - 2 Suits
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			allSuits.forEach((suitFor2s) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 2}))
				
				newArr.push(createTiles({type: "dragon", value: "white", amount: 3}))
				
				newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
				newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
				newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
				newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
				
				newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 2}))
			})
		})
		
		return {
			tiles,
			score: 30,
			concealed: true
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "2023"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

