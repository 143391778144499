//We want to ad ads to the suggested hands menu
//To do that, we'll need to enlarge the suggested hands menu. 
import adsenseIds from "./ids.js"
import {hasAdFree} from "../../ownedProducts";
import {CUSTOMER_INFO_UPDATED_EVENT_NAME} from "../../event_names";
import {Capacitor} from "@capacitor/core";

//Initalizes adsense library
window.adsbygoogle = window.adsbygoogle || []
window.adBreak = window.adConfig = function(o) {adsbygoogle.push(o);} //HTML5 beta functions. 


let alreadyInitialized = false
function initialize() {
    if (Capacitor.isNativePlatform()) {return} //Don't use on native platforms.
    if (alreadyInitialized) {return} //Don't double load. 

    alreadyInitialized = true //Mark as loaded

    //Standard Adsense Code. 
    let adsenseScript = document.createElement("script")
    adsenseScript.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${adsenseIds.publisherId}`
    adsenseScript.setAttribute("async", "async")
    adsenseScript.setAttribute("crossorigin", "anonymous")
                
    //HTML5 Ads Beta
    adsenseScript.setAttribute("data-ad-client", adsenseIds.publisherId)
    adsenseScript.setAttribute("data-ad-frequency-hint", "120s")

    document.head.appendChild(adsenseScript)  

    // if (!deviceCanFitBanner) {
    //     //We only show interstitial ads when banner does not fit
    //     adConfig({
    //         preloadAdBreaks: 'on',
    //         onReady: () => {console.log("HTML5 Interstitial Ads Ready")},
    //      });
    // }
}

function deviceCanFitBanner() {
    //Approximate check for whether or not a banner ad would fit on the screen. 
    return window.innerWidth >= 750 || window.innerHeight >= 850
}

let bannerHiddenBeforeShown = false //We might delay showing an ad to check ad-free status. Make sure we don't show if hide called before shown.

async function addBannerElement(adContainer) {
    // if (!deviceCanFitBanner()) {return} //Don't show banner if it won't appear on viewport without scrolling.
    bannerHiddenBeforeShown = false
    if (await hasAdFree()) {
        return
    }

    if (bannerHiddenBeforeShown) {
        //If the banner was hidden before it was shown, don't show it.
        return
    }

    let adInsert = document.createElement("ins")
    adInsert.style.display = "block"
    adInsert.classList.add("adsbygoogle")
    adInsert.setAttribute("data-ad-client", adsenseIds.publisherId)
    adInsert.setAttribute("data-ad-slot", adsenseIds.bottomBannerSlot)
    adInsert.setAttribute("data-ad-format", "auto")
    adInsert.setAttribute("data-full-width-responsive", "true")

    adContainer.appendChild(adInsert)
    
    window.adsbygoogle.push({});
}

async function hideBannerElements() {
    bannerHiddenBeforeShown = true
    let ads = document.getElementsByClassName("adsbygoogle")
    for (let ad of ads) {
        ad.style.display = "none"
    }
}

async function showBannerElements() {
    bannerHiddenBeforeShown = false
    let ads = document.getElementsByClassName("adsbygoogle")
    for (let ad of ads) {
        ad.style.display = ""
    }
}

let BannerStatus = {}
function setBannerStatus() {
    //Do nothing.
}

function isInterstitialReady() {
    return true //We can't control preloading so just assume it's ready. 
}

function displayInterstitial() {
    // if (deviceCanFitBanner()) {return} //Don't currently show interstitial ads when banner ad can fit. 
    // console.log("Showing Interstitial Ad")

    // adBreak({
    //     type: 'next', 
    //     name: 'game-over',
    //     adBreakDone: function(data) {console.log(data)}
    // });

    return true //Return true if interstitial actually shown. 
}

function prepareInterstitial() {
    //We cannot control anything here. 
}

function syncVolume() {
    //Sync app volume setting with adsense. 
    //There's no direct support for app volume, so just set to 0 if volume under 0.1, set to on if volume over 0.1. 
    let sound = "off"
    if (window.settings.soundEffectVolume.value > 0.1) {
        sound = "on"
    }
    adConfig({sound});
}
//Enable volume syncing. 
syncVolume()
window.settings.soundEffectVolume.addOnValueSetListener(syncVolume)

//Ensure the banner disappears immediately after a user makes or restores purchases.
window.addEventListener(CUSTOMER_INFO_UPDATED_EVENT_NAME, async function() {
    if (await hasAdFree()) {
        hideBannerElements()
    }
    else {
        showBannerElements()
    }
})

export {initialize, addBannerElement, setBannerStatus, BannerStatus, isInterstitialReady, displayInterstitial, prepareInterstitial}