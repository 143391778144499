//How we will do this:


//User will have camera and microphone controls for themselves
//For other players, clicking on the video disables BOTH video and audio. 

//Connections:
//We only want one connection between each pair of clients. 
//Every client is notified at the same time when a new client arrives. 
//Perhaps we want to use alphabetical ordering to determine which client opens the connection?
// (If we do that, what happens if the other client drops the connection? When is the connection determined to no longer be live?)

//So idea:
//When it is detected that there is a client with which we do not have an active connection:
//1. If we are the alphabetically first client by client ID, we shall send a connection request
// We always respond to any connection request received. 
//If we receive a new connection request from a client, we cancel all prior connections with that client.



import { Peer } from "peerjs";
import {Capacitor} from "@capacitor/core";

window.Peer = Peer

// let usPeer;
window.usPeer = undefined



let iceServers = [
    { url: 'stun:stun01.sipphone.com' },
    { url: 'stun:stun.ekiga.net' },
    { url: 'stun:stunserver.org' },
    { url: 'stun:stun.softjoys.com' },
    { url: 'stun:stun.voiparound.com' },
    { url: 'stun:stun.voipbuster.com' },
    { url: 'stun:stun.voipstunt.com' },
    { url: 'stun:stun.voxgratia.org' },
    { url: 'stun:stun.xten.com' },
]

//Fetch TURN server credentials from metered. 
//TODO: Wait on creating usPeer until this has loded. 

//TODO: Create our own STUN & TURN servers?

// let meteredAPIResponse = fetch("https://mahjong4friends.metered.live/api/v1/turn/credentials?apiKey=8d934a87045814658c9d05254a0a635f78c1");
// meteredAPIResponse.then(async (res) => {
//     iceServers = iceServers.concat(await res.json())
// })






//TODO: PeerJS goes crazy when there are two connections for the same userId - is there a good way to make the second always override the first?

async function updatePeerObject() {
    let notInMultiplayerGame = !window.stateManager.connectedClientId || window.stateManager.connectedClientId === "offlineUserClientId" || !window.stateManager.inRoom
    
    //If our id has changed, or if we are not in a game, delete the current peer. 
    if (usPeer && (usPeer?.id !== window.stateManager.connectedClientId || notInMultiplayerGame)) {
        //TODO: We should probably go through connections and try to terminate all MediaStreams first - doesn't look like PeerJS always cleans them up properly. 
        usPeer.disconnect()
        usPeer = undefined
        
        //Close the tracks
        if (window.myVideoStream) {
            console.warn("Closing all tracks")
            for (let track of window.myVideoStream.getTracks()) {track.stop()}
            window.myVideoStream = undefined
        }
        
        console.warn("Deleting peer")
    }
    
    
    
    if (!usPeer) {
        if (notInMultiplayerGame) {
            //Don't create peer in single player. 
            getVideos() //Ensure that any videos have been cleared (like after leaving a room)
            return
        }
        
        
        usPeer = new Peer(window.stateManager.connectedClientId, {
            host: Capacitor.isNativePlatform() ? "mahjong4friends.com" : window.location.hostname,
            secure: true,
            port: Capacitor.isNativePlatform() ? 443 : window.location.port,
            path: '/peerjs',
            config: {iceServers},
            
            debug: 3
        })
        
        console.log(usPeer)
        
        usPeer.on("open", (id) => {
            console.warn("Peer Opened")
            console.log('my id is' + id);
        });
        
        
        // let myVideoStream;
        window.myVideoStream = undefined
        
        
        //On the iOS app, use the Capacitor plugin to polyfill getUserMedia. 
        if (!navigator.mediaDevices) {
            navigator.mediaDevices = {}
        }
        
        if (!navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia = cordova?.plugins?.iosrtc?.getUserMedia
        }
        //End of iOS app specific code. 
        
        
        //Use video/audio if both given, else use whichever one is given (if any)
        //TODO: There's probably a much cleaner way to check our permissions. 
        try {
            window.myVideoStream = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: true,
            })
        }
        catch (e) {
            console.warn("Failed to get both audio and video")
            try {
                window.myVideoStream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                })
            }
            catch (e) {
                console.warn("Failed to get audio only")
                try {
                    window.myVideoStream = await navigator.mediaDevices.getUserMedia({
                        video: true,
                    })
                }
                catch (e) {
                    console.warn("Failed to get video only")
                    // window.myVideoStream = new MediaStream() //Create a blank stream for this user. 
                }
            }
        }
        
        //Due to PeerJS bug #944, users can only see tracks that they send (so if user sends only audio, can only receive audio)
        //To correct for this, we need to add blank tracks for any missing tracks. 
        
        //TODO: Major glitches occured when adding the blank tracks (like the party that had a blank video track stopped sending audio kind of thing)
        //Try to work those out. 
        
        // if (window.myVideoStream.getVideoTracks().length === 0) {
        //     try {
        //         let canvas = document.createElement("canvas")
        
        //         canvas.height = 150
        //         canvas.width = 200
        
        //         let ctx = canvas.getContext("2d")
        
        //         ctx.fillStyle = "black"
        //         ctx.fillRect(0, 0, canvas.width, canvas.height)
        
        //         let videoStream = canvas.captureStream()
        
        //         window.myVideoStream.addTrack(videoStream.getVideoTracks()[0])
        //     }
        //     catch (e) {
        //         console.error("Failed creating blank video stream")
        //     }
        // }
        
        // if (window.myVideoStream.getAudioTracks().length === 0) {
        //     try {
        //         let ctx = new AudioContext()
        //         let oscillator = ctx.createOscillator();
        //         let dst = oscillator.connect(ctx.createMediaStreamDestination());
        //         oscillator.start();
        
        //         let audioStream = dst.stream
        
        //         window.myVideoStream.addTrack(audioStream.getAudioTracks()[0])
        //     }
        //     catch (e) {
        //         console.error("Failed creating blank audio stream", e)
        //     }
        // }
        
        
        usPeer.on("call", (call) => {
            console.warn("Called", call)
            call.answer(myVideoStream);
            //Note that stream is called once for each track in the stream with the same stream object (weird behavior by PeerJS)
            call.on("stream", (userVideoStream) => {
                console.warn("Received video stream from caller", userVideoStream)
                
                //TODO: Close all other streams for this caller. 
                let connectionsWithPeer = usPeer.connections[call.peer]
                for (let connection of connectionsWithPeer) {
                    if (connection.remoteStream !== userVideoStream) {
                        console.warn("Closing old stream", connection.remoteStream)
                        connection.close()
                    }
                }
                
                getVideos()
                updateConnectionBandwidths()
            });
        });
        
        function callUser(userId) {
            console.warn('Calling', userId);
            const call = usPeer.call(userId, myVideoStream);
            call.on("stream", (userVideoStream) => {
                console.warn("Received video stream from callee", userVideoStream)
                getVideos()
                updateConnectionBandwidths()
            });
        }
        
        window.callUser = callUser        
        
        //We are a new peer, so we should do the calling. 
        for (let client of window?.stateManager?.lastState?.message?.clients || []) {
            if (!client.isYou) {
                if (!usPeer.connections[client.id] && !client.isBot) {
                    callUser(client.id)
                }
            }
        }
    }
    
    //If we are not connected to a client, attempt to open a connection. 
    for (let client of window?.stateManager?.lastState?.message?.clients || []) {
        if (!client.isYou && !client.isBot) {
            //Check for at least 1 connection. The connections array might be undefined or an empty array. 
            
            //The 1000ms delay is a "magic" number that seems to give time for the other peers to start up and stuff. 
            //If this delay is not enough, that is ok - auto-reconnection will kick in eventually, though may take 10s or so. 
            
            setTimeout(function() {
                //Ensure there is no open connection before opening a new one. 
                if (!!usPeer.connections?.[client.id]?.length === false) {
                    console.warn("Opening connection auto")
                    
                    window.callUser(client.id)
                }
            }, 1000)
        }
    }
    
    updateConnectionBandwidths()
    getVideos()
}



window.stateManager.addEventListener("auth", updatePeerObject)
window.stateManager.addEventListener("state", updatePeerObject)
window.stateManager.addEventListener("leaveRoom", updatePeerObject)    

window.stateManager.addEventListener("joinRoom", getVideos) //This is used to ensure videos are removed and microphone buttons reset if we enter a single player game. 

setInterval(function() {
    //Remedy for rare issues where audio would not connect between some pairs of players when joining games.
    //Display issue - the connection was made, but until getVideos was called by state sync it wouldn't show. 
    if (window?.stateManager?.inRoom) {
        updatePeerObject()
    }
}, 6000) 




window.userVideos = []


class UserVideo {
    container = document.createElement("div")
    videoElem = document.createElement("video")
    
    roomScreenMicrophone = document.createElement("img")
    
    //Used to mute the user's audio from playing on their own device. 
    //NOT used to mute the microphone. 
    //For other users, we will disable the track rather than muting the video element. 
    
    isSilent = false
    isForcedSilent = false
    
    setSilent(newSilent) {
        this.isSilent = newSilent
        let newMuted = this.isForcedSilent || this.isSilent
        if (this.videoElem.muted !== newMuted) {
            this.videoElem.muted = newMuted
        }
    }
    
    //Used during ads to enforce silence. 
    //WE CANNOT USE THE MUTED PROPERTY:
    //On iOS, after muting and unmuting a video it appears to be permanently corrupted
    //This looks to be some weird interference between AdMob and the videos. It only occurs when the videos are muted, then an ad is played. Neither causes it individually. 
    setForcedSilent(newForcedSilent) {
        if (newForcedSilent) {this.videoElem.pause()}
        else if (this.videoElem.paused) {this.videoElem.play()}
    }
    
    isMicrophoneEnabled() {
        return this?.videoElem?.srcObject?.getAudioTracks?.()?.[0]?.enabled
    }
    
    isCameraEnabled() {
        return this?.videoElem?.srcObject?.getVideoTracks?.()?.[0]?.enabled
    }
    
    setMicrophoneEnabled(enabled) {
        this.videoElem.srcObject.getAudioTracks()[0].enabled = enabled
        this.updateControls()
    }
    
    setCameraEnabled(enabled) {
        this.videoElem.srcObject.getVideoTracks()[0].enabled = enabled
        this.updateControls()
    }
    
    toggleMute() {
        this.setMicrophoneEnabled(!this.isMicrophoneEnabled())
    }
    
    toggleCamera() {
        this.setCameraEnabled(!this.isCameraEnabled())
    }
    
    constructor() {
        this.roomScreenMicrophone.classList.add("volumeIcon")
        
        this.container.classList.add("gameboardVideoItem")
        this.container.appendChild(this.videoElem)
        
        this.videoElem.style.display = "none"
        
        this.updateControls()
    }
    
    setMediaStream(stream) {
        if (this.videoElem.srcObject !== stream) {
            //If the stream has changed, update it. We don't want to set unnecessarily as that causes flashing on some platforms. 
            this.videoElem.srcObject = stream
        }
        
        this.videoElem.style.display = stream ? "" : "none"; //Hide video element if no video/audio (avoid the erroring video player images on Android, etc). 
        
        if (!stream) {
            //No stream. Ensure controls are hidden. 
            this.updateControls()
            return;
        }
        
        this.videoElem.setAttribute("playsinline", true) //Prevent iOS from fullscreening the video automatically. 
        
        this.videoElem.addEventListener("loadedmetadata", () => {
            console.warn("Metadata loaded")
            this.videoElem.play();
            this.updateControls();
            
            //Due to browser autoplay restrictions, we might have an issue if the user hasn't clicked on the document yet.
            //Let's be ultra careful. If the video is still paused, try to play until it starts. 
            
            //I haven't seen any issues when we have camera and microphone permissions, 
            //but it definitely does happen if we don't and reload the page (so document not click activated) 
            
            //We'll only try for 30 seconds. 
            ;(async function() {
                let timesRun = 0
                while (this.videoElem.paused && timesRun < 30) {
                    timesRun++
                    this.videoElem.play();
                    this.updateControls();
                    await new Promise((resolve) => {setTimeout(resolve, 1000)})
                }
            }.bind(this)());
        }, {once: true});
    }
    
    updateControls() {
        if (this.videoControlsOverlay) {this.videoControlsOverlay.remove()}
        
        if (this.videoElem.style.display === "none") {return} //If videoElem is hidden (probably because no stream), hide controls too. 
        
        let overlay = document.createElement("div")
        overlay.classList.add("videoControlsOverlay")
        
        let micOn = this.isMicrophoneEnabled()
        let cameraOn = this.isCameraEnabled()
        
        this.roomScreenMicrophone.src = `assets/microphone-${micOn ? "on":"off"}.svg`
        
        let microphoneIcon = document.createElement("img")
        microphoneIcon.src = `assets/microphone-${micOn ? "on":"off"}.svg`
        microphoneIcon.classList.add("videoControlsButton")
        
        let cameraIcon = document.createElement("img")
        cameraIcon.src = `assets/camera-${cameraOn ? "on":"off"}.svg`
        cameraIcon.classList.add("videoControlsButton")
        
        this.roomScreenMicrophone.onclick = this.toggleMute.bind(this) //This button doesn't require lastActivatingEvent check as it is always visible (not a video overlay)
        microphoneIcon.onclick = (function() {
            if (Date.now() - this.lastActivatingEvent > 30) {
                this.toggleMute()
            }
        }.bind(this))
        
        cameraIcon.onclick = (function() {
            //See below - lastActivatingEvent check is used for platforms that would otherwise treat a click as both focus and click 
            //to ensure that users can see what the current settings are without accidentally changing them
            if (Date.now() - this.lastActivatingEvent > 30) {
                this.toggleCamera()
            }
        }).bind(this)
        
        overlay.appendChild(microphoneIcon)
        overlay.appendChild(cameraIcon)
        
        this.container.appendChild(overlay)
        
        this.videoControlsOverlay = overlay
        
        
        //Android doesn't seem to make the thing visible after being clicked on. 
        //We'll ensure it is visible for at least 1 second after clicked on. 
        this.videoControlsOverlay.addEventListener("click", (function() {
            clearTimeout(this.currentTimeout)
            
            this.videoControlsOverlay.style.opacity = "1"
            this.currentTimeout = setTimeout((function() {
                this.videoControlsOverlay.style.opacity = ""
                
                //Once the overlay dissapears we will reset lastActivatingEvent
                let interval;
                interval = setInterval((function() {
                    //Intentionally ==
                    if (getComputedStyle(this.videoControlsOverlay).opacity == 0) {
                        clearInterval(interval)
                        delete this.lastActivatingEvent
                    }
                }).bind(this), 1000)
            }).bind(this), 1000)
        }).bind(this))
        
        
        //Android can be problematic as the hover event fires when the element is clicked, and the click goes through. 
        //We will require an activating event more than 30ms before the click for the click to be processed. 
        //This will ensure that clicking on the controls when they are not visible merely opens the controls, 
        //rather than opening the controls AND changing one of them. 
        let activatingEventTriggered = (function() {
            if (!this.lastActivatingEvent) {
                this.lastActivatingEvent = Date.now()
            }
        }).bind(this)
        
        this.videoControlsOverlay.addEventListener("mouseover", activatingEventTriggered)
        this.videoControlsOverlay.addEventListener("touchmove", activatingEventTriggered)
        this.videoControlsOverlay.addEventListener("focus", activatingEventTriggered)
    }
}


//Iterates all connections and adjusts bandwdith and sizes on videos. 
async function updateConnectionBandwidths() {
    for (let peerId of Object.keys(usPeer.connections)) {
        
        let connections = usPeer.connections[peerId]
        let validConnections = 0
        for (let connection of connections) {         
            console.log(connection)
            console.log(connection?.peerConnection?.iceConnectionState)
            
            if (
                connection?.peerConnection?.iceConnectionState //Can be undefined - don't close if undefined. 
                && !["new", "checking", "connected"].includes(connection.peerConnection.iceConnectionState)) {
                    console.warn("Closing connection with ", peerId, connection.peerConnection.iceConnectionState, connection.peerConnection.connectionState)
                    connection.close()
                    continue;
                }
                
                if (connection.open === false) {
                    //If the connection has been open for a while and is still not open we should close
                    setTimeout(function() {
                        if (connection.open === false) {
                            console.warn("Closing non-open connection with ", peerId)
                            connection.close()
                        }
                    }, 7000)
                }
                
                validConnections++
                
                //TODO: What if one client thinks there is a connection, but the other client doesn't know about it? If the alphabetically lower client thinks there 
                //is a connection that the other client doesn't know about, it will never get disconnected. 
                //If PeerJS does heartbeat checking it should correct for this eventually. 
                
                let isPrimaryClient = stateManager.connectedClientId > peerId //The client with the alphabetically greater clientId is in charge of disconnecting duplicate connections. 
                if (isPrimaryClient && validConnections > 1) {
                    console.warn("Closing duplicate connection with ", peerId)
                    connection.close()
                    continue;
                }
                
                //It is possible for the video element to end up "dead" - 
                //Everything appears fine (streams active, etc), but the readyState of the video is 0 and nothing appears. 
                //We will remedy that by checking the readyState of the video and closing the MediaStream if the video fails to open. 
                
                let userVideo = window.userVideos.find((userVideo) => {
                    return userVideo.associatedClientId === peerId
                })
                if (userVideo?.videoElem?.srcObject && userVideo?.videoElem?.readyState === 0) {
                    setTimeout(function() {
                        if (userVideo.videoElem.srcObject && userVideo.videoElem.readyState === 0) {
                            console.warn("Closing connection with non-loading video with ", peerId)
                            connection.close()
                        }
                    }, 5000)
                }
                
                // //If we determine we're using TURN, we should cut video and maybe even audio quality. 
                // let stats = await connection.peerConnection.getStats()
                // let selectedLocalCandidate;
                // for (const {type, state, localCandidateId} of stats.values()) {
                //     if (type === 'candidate-pair' && state === 'succeeded' && localCandidateId) {
                //         selectedLocalCandidate = localCandidateId
                //         break
                //     }
                // }
                
                // let isUsingTurn = (!!selectedLocalCandidate) && (stats.get(selectedLocalCandidate)?.candidateType === 'relay')
                
                
                //We want to downscale the RECEIVERS, not the senders. 
                //The senders send to other users (who might need high-res). The receivers are us (we know what resolution we can use)
                
                //TODO: We could just determine if there are any premium users in the room. Or, we could notate in client ids which users are premium. 
                //For now, let's just give everyone max quality. If we have issues, restrict non-premium users. 
                
                let senders = connection.peerConnection.getSenders()
                for (let sender of senders) {
                    if (sender.track.kind === "video") {
                        let trackSettings = sender.track.getSettings()
                        
                        //Set maxWidth and maxHeight based on the available space for each video. 
                        // let maxHeight = document.querySelector(".gameboardVideoItem")?.getBoundingClientRect()?.height * window.devicePixelRatio || 200
                        // let maxWidth = document.querySelector(".gameboardVideoItem")?.getBoundingClientRect()?.width * window.devicePixelRatio || 400
                        
                        let maxHeight = 200
                        let maxWidth = 300
                        
                        const params = sender.getParameters();
                        params.encodings[0].scaleResolutionDownBy = Math.max(trackSettings.height / maxHeight, trackSettings.width/maxWidth, 1);
                        
                        // if (
                        //     isUsingTurn //Only reduce quality if using TURN (we don't care about bandwidth if we aren't paying)
                        //     && !hasPremium() //Premium users get full quality always
                        //     && !window?.stateManager?.lastState?.message?.settings?.exemptFromAds //Rooms hosted by Premium users get full quality. 
                        // ) {
                        //     let maxRate = 15000 //15kbits/sec minimum
                        //     maxRate = Math.max(maxRate, maxHeight * maxWidth)
                        //     maxRate = Math.min(50000, maxRate) //50kbits/sec maximum. 
                        
                        //     params.encodings[0].maxBitrate = maxRate
                        // }
                        
                        await sender.setParameters(params);
                    }
                    // else if (sender.track.kind === "audio") {
                    //     //Audio bitrate experimental - going with 32kits/sec maximum.
                    //     //It appears that audio cannot be set below 6kits/sec on Chrome
                    //     if (isUsingTurn && !hasPremium()) {
                    //         const params = sender.getParameters();
                    //         params.encodings[0].maxBitrate = 32000;
                    
                    //         await sender.setParameters(params);
                    //     } 
                    // }
                }
            }
        }
        
        
    }
    
    
    function getVideos() {
        let gameboardVideoContainer = document.querySelector(".gameboardVideoContainer")
        if (!gameboardVideoContainer) {return}
        
        let clientNumber = 0
        let anyAssociatedClientIdChanged = false; //If this is changed we need to dispatch state event so that room screen microphone icons, etc, can update. 
        
        for (let client of window?.stateManager?.lastState?.message?.clients || []) {   
            if (userVideos.length === clientNumber) {
                let newUserVideo = new UserVideo()
                userVideos.push(newUserVideo)
                gameboardVideoContainer.appendChild(newUserVideo.container)
            }    
            let userVideo = userVideos[clientNumber++]
            
            
            if (userVideo.associatedClientId !== client.id) {
                anyAssociatedClientIdChanged = true
                userVideo.associatedClientId = client.id
            }
            
            userVideo.setSilent(client.isYou)
            
            if (client.isYou) {
                userVideo.setMediaStream(window.myVideoStream)
            }
            else {
                //We'll use the first MediaStream. 
                //This handles cases where one MediaStream is broken. 
                
                if (!usPeer?.connections?.[client.id]) {
                    userVideo.setMediaStream() //Remove video
                    continue
                }
                
                let wasSet = false
                for (let connection of usPeer?.connections?.[client.id]) {
                    if (connection?.remoteStream) {
                        userVideo.setMediaStream(connection.remoteStream)
                        wasSet = true
                        break;
                    }
                }
                
                if (!wasSet) {
                    userVideo.setMediaStream() //Remove video (we are changing associatedClientId)
                }
            }
        }
        
        while (clientNumber < userVideos.length) {
            let userVideo = userVideos.pop()
            userVideo.container.remove()
        }
        
        if (anyAssociatedClientIdChanged) {
            //Since this could lead to an infinite loop, it is important that we only fire the state event when there was actually a CHANGE on this side. 
            window.stateManager.triggerEventListeners("state", window.stateManager.lastState)
        }
    }
    
    
    
    
    
    window.updateConnectionBandwidths = updateConnectionBandwidths
    window.getVideos = getVideos
    
    
