const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//44 66 888 KKKK PPP
	function(tiles = []) {
		allSuits.forEach((suitForRun) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suitForRun, value: 4, amount: 2}))
			newArr.push(createTiles({type: suitForRun, value: 6, amount: 2}))
			newArr.push(createTiles({type: suitForRun, value: 8, amount: 3}))
			
			newArr.push(createTiles({type: "any", value: "any", amount: 4}))
			newArr.push(createTiles({type: "any", value: "any", amount: 3}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	function(tiles = []) {
		//888 642 44 642 888
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 3}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
			
			newArr.push(createTiles({type: suitOrder[1], value: 4, amount: 2}))
			
			newArr.push(createTiles({type: suitOrder[2], value: 6, amount: 1}))
			newArr.push(createTiles({type: suitOrder[2], value: 4, amount: 1}))
			newArr.push(createTiles({type: suitOrder[2], value: 2, amount: 1}))
			newArr.push(createTiles({type: suitOrder[2], value: 8, amount: 3}))
		})
		
		return {
			tiles,
			score: 25,
			concealed: false,
		}
	},
	function(tiles = []) {
		//FFFF AA 222 46 888
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 4}))
			
			newArr.push(createTiles({type: "any", value: "any", amount: 2}))
			
			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 3}))
			newArr.push(createTiles({type: suitOrder[1], value: 4, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 1}))
			newArr.push(createTiles({type: suitOrder[2], value: 8, amount: 3}))
			
		})
		
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)
			
			newArr.push(createTiles({type: "flower", amount: 4}))
			
			newArr.push(createTiles({type: "any", value: "any", amount: 2}))
			
			newArr.push(createTiles({type: suit, value: 2, amount: 3}))
			newArr.push(createTiles({type: suit, value: 4, amount: 1}))
			newArr.push(createTiles({type: suit, value: 6, amount: 1}))
			newArr.push(createTiles({type: suit, value: 8, amount: 3}))
			
		})
		
		return {
			tiles,
			score: 25,
			concealed: false,
		}
	},
	function(tiles = []) {
		//PPP LAIN 444 4 444
		allSuitArrangements.forEach((suitOrder) => {
			evenOptions.forEach((evenNo) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: "any", value: "any", amount: 3}))
				
				newArr.push(createTiles({type: "any", value: 7, amount: 1}))
				newArr.push(createTiles({type: "any", value: "any", amount: 1}))
				newArr.push(createTiles({type: "any", value: 1, amount: 1}))
				newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
				
				newArr.push(createTiles({type: suitOrder[0], value: evenNo, amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: evenNo, amount: 1}))
				newArr.push(createTiles({type: suitOrder[2], value: evenNo, amount: 3}))
			})
		})
		
		return {
			tiles,
			score: 30,
			concealed: false,
		}
	},
	//2023 4 4 6666 8888
	function(tiles = []) {
		allSuitArrangements.forEach((triColorOrder) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: triColorOrder[0], value: 2, amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
				newArr.push(createTiles({type: triColorOrder[1], value: 2, amount: 1}))
				newArr.push(createTiles({type: triColorOrder[2], value: 3, amount: 1}))
				
				newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 1}))
				
				newArr.push(createTiles({type: suitOrder[1], value: 4, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 4}))
				
				newArr.push(createTiles({type: suitOrder[2], value: 8, amount: 4}))
			})
			
			allSuits.forEach((suit) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: triColorOrder[0], value: 2, amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
				newArr.push(createTiles({type: triColorOrder[1], value: 2, amount: 1}))
				newArr.push(createTiles({type: triColorOrder[2], value: 3, amount: 1}))
				
				newArr.push(createTiles({type: suit, value: 4, amount: 1}))
				
				newArr.push(createTiles({type: suit, value: 4, amount: 1}))
				newArr.push(createTiles({type: suit, value: 6, amount: 4}))
				
				newArr.push(createTiles({type: suit, value: 8, amount: 4}))
			})
		})
		
		return {
			tiles,
			score: 35,
			concealed: false
		}
	},
	function(tiles = []) {
		//222 ALI SSS MA NNN 
		let newArr = []
		tiles.push(newArr)
		
		newArr.push(createTiles({type: "any", value: 2, amount: 3}))
		
		newArr.push(createTiles({type: "any", value: "any", amount: 1}))
		newArr.push(createTiles({type: "any", value: 7, amount: 1}))
		newArr.push(createTiles({type: "any", value: 1, amount: 1}))
		
		newArr.push(createTiles({type: "wind", value: "south", amount: 3}))
		
		newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
		newArr.push(createTiles({type: "any", value: "any", amount: 1}))
		
		newArr.push(createTiles({type: "wind", value: "north", amount: 3}))
		
		return {
			tiles,
			score: 35,
			concealed: false,
		}
	},
	//PPP EEE 2R 4 666 88
	function(tiles = []) {
		allSuitArrangements.forEach((triColorOrder) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: "any", value: "any", amount: 3}))
				
				newArr.push(createTiles({type: "wind", value: "east", amount: 3}))
				
				newArr.push(createTiles({type: "any", value: 2, amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
				
				
				newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 1}))
				
				newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 3}))
				
				newArr.push(createTiles({type: suitOrder[2], value: 8, amount: 2}))
			})
			
			allSuits.forEach((suit) => {
				let newArr = []
				tiles.push(newArr)
				
				newArr.push(createTiles({type: "any", value: "any", amount: 3}))
				
				newArr.push(createTiles({type: "wind", value: "east", amount: 3}))
				
				newArr.push(createTiles({type: "any", value: 2, amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
				
				
				newArr.push(createTiles({type: suit, value: 4, amount: 1}))
				
				newArr.push(createTiles({type: suit, value: 6, amount: 3}))
				
				newArr.push(createTiles({type: suit, value: 8, amount: 2}))
			})
		})
		
		return {
			tiles,
			score: 35,
			concealed: false
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "PPP EEE 2R"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

