const TileSet = require("./TileSet")

let currentTilesetUpdate;
let hasErroredBefore = 0

//When we load a tileset we will always transfer the current tileset, reusing everything we can. 
function loadTileset(
        borderID = window.settings.tileBorder.value, 
        centerID = window.settings.tileCenter.value,
        decorationID = window.settings.tileDecoration.value
    ) {

    if (centerID === "auto") {
        //Prefer current game when detecting game mode, otherwise use setting. 
        let currentMode = stateManager?.lastState?.message?.settings?.gameStyle || window.settings.gameStyle.currentValue
		if (currentMode === "american") {
            centerID = "american"
        }
        else {
            centerID = "classic"
        }
	}

    //If nothing is changing just return
    if (
        window?.tileSet?.borderID === borderID
        && window?.tileSet?.centerID === centerID
        && window?.tileSet?.decorationID === decorationID
        ) {
return false
        }

	let tileSet = new TileSet(borderID, centerID, decorationID)

    console.time("Load Tileset")
	currentTilesetUpdate = tileSet.initiate(window.tileSet).then(() => {
        window.tileSet = tileSet
		console.timeEnd("Load Tileset")
        window.dispatchEvent(new Event("tileSetUpdated"))
    }).catch((e) => {
        //RETRY before erroring!
        if (hasErroredBefore < 3) {
            hasErroredBefore++
            setTimeout(loadTileset, 1000)
        }
        else {
            alert("Error Loading Tileset. Please reload the app. If this continues to occur, please contact support. ")
            console.error(e)
        }
    })
}

loadTileset()




function ensureTilesetUpdated() {
    //Avoid multiple async calls - all calls proceed in order so caching maximized. 
    currentTilesetUpdate = currentTilesetUpdate.finally(loadTileset)
}

window.settings.tileBorder.addOnValueSetListener(ensureTilesetUpdated)
window.settings.tileCenter.addOnValueSetListener(ensureTilesetUpdated)
window.settings.tileDecoration.addOnValueSetListener(ensureTilesetUpdated)
window.settings.gameStyle.addOnValueSetListener(ensureTilesetUpdated)

window.stateManager.addEventListener("state", function(obj) {    
    //Checking for state update ensures that, if the user enters a game that would change the tileset used by auto, but they aren't the host and their 
    //settings do not cause auto to select the correct tileset, that the tileset is updated. 
    ensureTilesetUpdated()
})

module.exports = loadTileset