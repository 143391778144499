const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//FF 1 222 3 444 5 666
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			allOptions.slice(0,-5).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 2}))

				newArr.push(createTiles({type: suitOrder[0], value: offset+0, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: offset+1, amount: 3}))
				newArr.push(createTiles({type: suitOrder[0], value: offset+2, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: offset+3, amount: 3}))
				newArr.push(createTiles({type: suitOrder[0], value: offset+4, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: offset+5, amount: 3}))
			})
		})

		allSuits.forEach((suit) => {
			allOptions.slice(0,-5).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 2}))

				newArr.push(createTiles({type: suit, value: offset+0, amount: 1}))
				newArr.push(createTiles({type: suit, value: offset+1, amount: 3}))
				newArr.push(createTiles({type: suit, value: offset+2, amount: 1}))
				newArr.push(createTiles({type: suit, value: offset+3, amount: 3}))
				newArr.push(createTiles({type: suit, value: offset+4, amount: 1}))
				newArr.push(createTiles({type: suit, value: offset+5, amount: 3}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false,
		}
	},
	//F 333 NNN 5 PPP 777
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			allOptions.slice(0,-4).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 1}))

				newArr.push(createTiles({type: suitOrder[0], value: offset+0, amount: 3}))

				newArr.push(createTiles({type: "wind", value: "any", amount: 3}))

				newArr.push(createTiles({type: suitOrder[1], value: offset+2, amount: 1}))

				newArr.push(createTiles({type: "any", value: "any", amount: 3}))

				newArr.push(createTiles({type: suitOrder[2], value: offset+4, amount: 3}))

			})
		})

		allSuits.forEach((suit) => {
			allOptions.slice(0,-4).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 1}))

				newArr.push(createTiles({type: suit, value: offset+0, amount: 3}))

				newArr.push(createTiles({type: "wind", value: "any", amount: 3}))

				newArr.push(createTiles({type: suit, value: offset+2, amount: 1}))

				newArr.push(createTiles({type: "any", value: "any", amount: 3}))

				newArr.push(createTiles({type: suit, value: offset+4, amount: 3}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false,
		}
	},
	//444 55 PPP 77 8888
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			allOptions.slice(0,-4).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)


				newArr.push(createTiles({type: suitOrder[0], value: offset+0, amount: 3}))
				newArr.push(createTiles({type: suitOrder[0], value: offset+1, amount: 2}))

				newArr.push(createTiles({type: "any", value: "any", amount: 3}))

				newArr.push(createTiles({type: suitOrder[1], value: offset+3, amount: 2}))
				newArr.push(createTiles({type: suitOrder[1], value: offset+4, amount: 4}))
			})
		})

		allSuits.forEach((suit) => {
			allOptions.slice(0,-4).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: suit, value: offset+0, amount: 3}))
				newArr.push(createTiles({type: suit, value: offset+1, amount: 2}))

				newArr.push(createTiles({type: "any", value: "any", amount: 3}))

				newArr.push(createTiles({type: suit, value: offset+3, amount: 2}))
				newArr.push(createTiles({type: suit, value: offset+4, amount: 4}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false,
		}
	},
	//555 555 6789 KKKK
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			allOptions.slice(0,-4).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)


				newArr.push(createTiles({type: suitOrder[0], value: offset+0, amount: 3}))

				newArr.push(createTiles({type: suitOrder[1], value: offset+0, amount: 3}))

				newArr.push(createTiles({type: suitOrder[2], value: offset+1, amount: 1}))
				newArr.push(createTiles({type: suitOrder[2], value: offset+2, amount: 1}))
				newArr.push(createTiles({type: suitOrder[2], value: offset+3, amount: 1}))
				newArr.push(createTiles({type: suitOrder[2], value: offset+4, amount: 1}))

				newArr.push(createTiles({type: "any", value: "any", amount: 4}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false,
		}
	},
	//AA 123 123 444 555
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			allOptions.slice(0,-4).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "any", value: "any", amount: 2}))

				newArr.push(createTiles({type: suitOrder[0], value: offset+0, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: offset+1, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: offset+2, amount: 1}))

				newArr.push(createTiles({type: suitOrder[1], value: offset+0, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: offset+1, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: offset+2, amount: 1}))

				newArr.push(createTiles({type: suitOrder[2], value: offset+3, amount: 3}))
				newArr.push(createTiles({type: suitOrder[2], value: offset+4, amount: 3}))
			})
		})

		return {
			tiles,
			score: 30,
			concealed: false,
		}
	},
	//ROGER 1 22 333 444
	function(tiles = []) {
		allSuits.forEach((suit) => {
			allOptions.slice(0,-3).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "green", amount: 1}))
				newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))

				newArr.push(createTiles({type: suit, value: offset+0, amount: 1}))
				newArr.push(createTiles({type: suit, value: offset+1, amount: 2}))
				newArr.push(createTiles({type: suit, value: offset+2, amount: 3}))
				newArr.push(createTiles({type: suit, value: offset+3, amount: 3}))
			})
		})

		return {
			tiles,
			score: 40,
			concealed: false,
		}
	},
	//MJ 23 22 21 20 19 18
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)

		newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
		newArr.push(createTiles({type: "joker"}))

		newArr.push(createTiles({type: "any", value: 2, amount: 1}))
		newArr.push(createTiles({type: "any", value: 3, amount: 1}))

		newArr.push(createTiles({type: "any", value: 2, amount: 1}))
		newArr.push(createTiles({type: "any", value: 2, amount: 1}))

		newArr.push(createTiles({type: "any", value: 2, amount: 1}))
		newArr.push(createTiles({type: "any", value: 1, amount: 1}))

		newArr.push(createTiles({type: "any", value: 2, amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))

		newArr.push(createTiles({type: "any", value: 1, amount: 1}))
		newArr.push(createTiles({type: "any", value: 9, amount: 1}))

		newArr.push(createTiles({type: "any", value: 1, amount: 1}))
		newArr.push(createTiles({type: "any", value: 8, amount: 1}))

		return {
			tiles,
			score: 40,
			concealed: true
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "HO PPP, S KKKK I PPP, AND RUN"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

