//@ts-nocheck

import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';

//Once we have loaded, hide the splashscreen. 
SplashScreen.hide()

//Register serviceworker when on web. 
if ('serviceWorker' in navigator && !Capacitor.isNativePlatform()) {
    navigator.serviceWorker.register('sw.js');
}

const StateManager = require("./stateManager")

let url = new URL(window.location.origin + window.location.pathname)
if (window.location.protocol === "https:") {
    url.protocol = "wss:"
}
else {
    url.protocol = "ws:"
}
let websocketURL = url.toString()
if (Capacitor.isNativePlatform()) {
    websocketURL = "wss://mahjong4friends.com/"
}
window.stateManager = new StateManager(websocketURL)

try {
    require("./firebase/index.js")
}
catch (e) {console.error(e)}

import {i18n, initToBrowserLocale, initToClientLocale} from "../../lib/i18nHelper.js";

// bootstrap i18n using browser detected locale, note this is before user overide setting is applied.
// this is needed as settings itself need localization support
let browserLocale = initToBrowserLocale()

import {
    BooleanSetting,
    Setting,
    NumberSetting,
    NumberSliderSetting,
    ObjectStorage
} from "./Settings/Settings";
import {promptIfUpdateAvailable} from "./appUpdates";
import {createSettingsIcon} from "./settingsMenu";
import {triggerPurchasesMigration} from "./purchasesMigration";

window.settings = {
    //Global Settings (Game-mode independent)
    soundEffectVolume: new NumberSliderSetting("settingSoundEffectVolume", 0),
    voiceVolume: new NumberSliderSetting("settingVoiceVolume", 1),
    voiceSpeed: new NumberSliderSetting("settingVoiceSpeed", 1),
    autoSortTiles: new BooleanSetting("settingAutoSortTiles", true),
    lastUserNickname: new Setting("lastUserNickname", ""),
    tileBorder: new Setting("tileBorder", "classic"),
    tileCenter: new Setting("tileCenter", "auto"),
    tileDecoration: new Setting("tileDecoration", ""),
	locale: new Setting("settingLocale", browserLocale),
    voices: [
        new Setting("voice1", "none"),
        new Setting("voice2", "none"),
        new Setting("voice3", "none"),
    ],

    //Game Settings
    selectedGameMode: new Setting("selectedGameMode", "single"), //Multiplayer or singleplayer
    gameStyle: new Setting("gameStyle"),

    //Data storage
    timesAppOpened: new NumberSetting("timesAppOpened", 0),
    lastNewsSeenTimestamp: new NumberSetting("lastNewsSeenTimestamp", 0),
    overruledOnlineSinglePlayerAlert: new BooleanSetting("overruledOnlineSinglePlayerAlert", false),

    //Ad Details
    msUntilNextInterstitial: new NumberSetting("msUntilNextInterstitial", 75 * 60 * 1000), //New users get 75 minutes interstitial ad free. 
    lastReceivedInterstitialMinutesTimestamp: new NumberSetting("lastReceivedInterstitialMinutesTimestamp", 0), //Timestamp for when daily interstitial-free minutes last given. 
    adsShown: new NumberSetting("adsShown", 0), //We won't show the privacy prompt until users have seen at least 1 video ad. 
    hideAdFreeMessage: new BooleanSetting("hideAdFreeMessage", false),
    hideSuggestedHandsText: new BooleanSetting("hideSuggestedHandsText", false),
    hideScoreSummaryText: new BooleanSetting("hideScoreSummaryText", false),
    iosAlwaysRequestTracking: new BooleanSetting("iosAlwaysRequestTracking", false), //For Apple Reviewers, allows for quicker prompting. 

    //Card tracking storage. 
    gamesToSync: new ObjectStorage("gamesToSync", []),
    mostRecentUserData: new ObjectStorage("mostRecentUserData", {}),
}

window.settings.chinese = {
    tableLimitEnabled: new BooleanSetting("chinese.tableLimitEnabled", false),
    tableLimit: new NumberSetting("chinese.tableLimit", 500),
    allow4thTilePickup: new BooleanSetting("chinese.allow4thTilePickup", true),
    botCanStartCharleston: new BooleanSetting("chinese.botCanStartCharleston", false),
    pickupDiscardForDraw: new BooleanSetting("chinese.pickupDiscardForDraw", false),
    maximumSequences: new NumberSetting("chinese.maximumSequences", 4),
    checkForCalling: new BooleanSetting("chinese.checkForCalling", true),
    sortDiscardPile: new BooleanSetting("chinese.sortDiscardPile", true)
}

window.settings.american = {
    card: new Setting("american.card"),
    suggestedHands: new BooleanSetting("american.suggestedHands", true),
    botDifficulty: new NumberSliderSetting("american.botDifficulty", 50),
    ignoreBotMahjong: new BooleanSetting("american.ignoreBotMahjong", false),
    sortDiscardPile: new BooleanSetting("american.sortDiscardPile", true)
}

//Update times app loaded. 
window.settings.timesAppOpened.loaded.then(() => {
    window.settings.timesAppOpened.value++
})

const adLibrary = require("./monetization/ad_library/platformSelector") //Initialization will automatically begin. 

// get user set locale
let i18n_chain = window.settings.locale.loaded

//We will wait up to two seconds for the setting to load.
//This should work, absent a bug, etc.

let localeSettingTimeout = 2000
Promise.race([
    i18n_chain,
    new Promise((r, j) => {setTimeout(r, localeSettingTimeout)})
]).then(() => {
    if (window.settings.locale.currentValue === undefined) {
        console.error("Locale setting failed to load. Timeout triggered. ")
    }
    initToClientLocale()
    document.title = i18n.__("Mahjong 4 Friends - Free Mahjong, Friends and/or Bots")

    require("./RoomManager")
    require("./GameBoard.js")
})

if (Capacitor.isNativePlatform()) {
    promptIfUpdateAvailable().catch(e => {
        console.error("Error checking for updates: ", e)
    })
    require("../universalLinks.js")
}

let sizes = [16,24,32,64,96,160,196]
sizes.forEach((size) => {
    let favicon = document.createElement("link")
    favicon.rel = "shortcut icon"
    favicon.type = "image/png"
    favicon.sizes = size + "x" + size
    favicon.href = `assets/icons/${size}x${size}-green-dragon.png`
    document.head.appendChild(favicon)
})


//Mobile browsers use the touch API - desktop is drag and drop. We'll use a polyfill so we don't have to implement both.
require("drag-drop-touch")


//While viewport relative units work fine on desktop, some mobile browsers will not show the entire viewport, due to the url bar.
//See https://nicolas-hoizey.com/articles/2015/02/18/viewport-height-is-taller-than-the-visible-part-of-the-document-in-some-mobile-browsers/
//We will use CSS variables to counteract this bug.
function setVisibleAreaHeight(callAgain = true) {
    let width = Math.max(document.documentElement.clientWidth, Math.min(screen.width, window.innerWidth))
    let height = Math.max(document.documentElement.clientHeight, window.innerHeight)

	document.documentElement.style.setProperty('--vh', `${height/100}px`)
    document.documentElement.style.setProperty('--vw', `${width/100}px`)

    //Somewhat occasionally, we get some issues with resizes.
    if (callAgain && callAgain?.isTrusted !== false) {
        setTimeout(function() {
            setVisibleAreaHeight(false)
        }, 500)
    }
}

window.addEventListener('resize', setVisibleAreaHeight)
//Fire resize on orientationchange.
window.addEventListener('orientationchange', function() {
    window.dispatchEvent(new Event("resize"))
})

setVisibleAreaHeight()

//In case any issues surface with load, resize constantly for first 3000 ms.
let resizer = setInterval(function() {
    window.dispatchEvent(new Event("resize"))
}, 300)
setTimeout(function() {
    clearInterval(resizer)
}, 3000)

//We are probably being hit by a race condition where the JavaScript can load before the CSS,
//causing resizing to be intermittent. This should fix it.
document.body.addEventListener("load", setVisibleAreaHeight)

//Otherwise Safari will scroll the page when the user drags tiles.
//It's possible that we need feature detection for passive listeners, as it may be misinterpreted by older browsers, however I currently observe no side effects.
window.addEventListener('touchmove', function () {}, {passive: false});

try {
	document.body.appendChild(createSettingsIcon("globalSettingsIcon")) //Add general settings menu.
}
catch (e) {
	console.error(e)
}


triggerPurchasesMigration() //Trigger purchases migration if necessary.


//Make classes globally available for development and testing ease.
//These should not be used by code, only manually in console.
window.Hand = require("./Hand")
window.Tile = require("./Tile")
window.Sequence = require("../../lib/Sequence")
window.Pretty = require("./Pretty")
window.Match = require("../../lib/Match")
window.Wall = require("./Wall")

window.Popups = require("./Popups.js")
