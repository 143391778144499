import {Directory, Encoding, Filesystem} from "@capacitor/filesystem";
import {writeTextFile} from "./WriteTextFile";
import {deleteFile} from "./FileOps";

//TODO: We should move the settings into a settings specific directory
// This will require migrating over data from the old settings directory as well.

async function migrateLocalStorage(path: string, directory: Directory){
  //Migrating from the old localStorage based API to a new Filesystem based API.
  //We will copy values over then delete.
  //Migration performed in commit 48e794d.
  let val = localStorage.getItem(path)
  if (val) {
    await writeTextFile(path, val, directory);
    localStorage.removeItem(path)
  }
}

async function migrateToSettingsDirectory(path: string, directory: Directory){
  //We are migrating settings to a setting directory to minimize potential conflicts.
  if (path.startsWith("setting/")) {
    //Attempt to find the text file that had the string corresponding to where the setting directory
    //should have been stored, and migrate the file over.
    let adjustedPath = path.slice("setting/".length)
    let str = await _readTextFile(adjustedPath, directory);
    if (str) {
      await writeTextFile(path, str, directory);
      await deleteFile(adjustedPath, directory)
    }
  }
}


async function readTextFile(path: string, directory = Directory.Data) {
  let res = await _readTextFile(path, directory)
  if (res) {return res;}

  //If we didn't get a result, attempt to migrate, then try again.
  await migrateLocalStorage(path, directory);
  await migrateToSettingsDirectory(path, directory);
  return await _readTextFile(path, directory)
}

async function _readTextFile(path: string, directory = Directory.Data) {
  return await Filesystem.readFile({
    path,
    directory,
    encoding: Encoding.UTF8
  }).then(res => res.data).catch(e => {
    //As of 6/16/2024 there is no way to determine failure reasons other than checking error messages.
    if (
        e.message.includes("File does not exist")
        || e.message.includes("there is no such file")
    ) {
      //If the file doesn't exist, there is no concern: It just means the setting hasn't been set and we should
      //use the default value. Don't log any sort of error message.
    }
    else {
      console.warn("Failed to read file", e)
    }
    return null
  })
}

export {readTextFile};