//This code is for migrating from the old purchases system to RevenueCat.
//The migration started around 6/17/2024.

import {Capacitor} from "@capacitor/core";
import {Purchases} from "@revenuecat/purchases-capacitor";
import {getStoreToUse} from "./monetization/revenuecat_utilities";
import {ObjectStorage} from "./Settings/Settings";
import Popups from "./Popups";

function triggerPurchasesMigration() {
  if (!Capacitor.isNativePlatform()) {return} //No purchases to migrate.

  //For managing purchases.
  //storeRecord and cachedPurchases are both no longer used for new users, and are only used for purchases migration.
  // let storeRecord = new ObjectStorage("storeRecord", {})
  let cachedPurchases = new ObjectStorage("cachedPurchases", [])


  cachedPurchases.loaded.then(async function() {
    for (let i=0;i<10;i++) {
      if ((await Purchases.isConfigured()).isConfigured) {
        break
      }
      else {
        await new Promise((resolve) => setTimeout(resolve, 1000))
      }
    }

    //Purchases migration code.
    // NOTE: With Google Play, it appears that no special migration is necessary.
    // We will perform restore purchases anyways, just in case (and also because telling people about a free upgrade is probably good)
    if (
        cachedPurchases.value.length > 0
        && await getStoreToUse() !== "Web"
        && (await Purchases.isConfigured()).isConfigured
        && navigator.onLine) {
      //We will need to trigger restore purchases.
      let alertUserToMigrationPopup = new Popups.Notification("Subscription Upgrades", "A free upgrade of your subscriptions (if any) will start when you close this popup! You may see iOS/Android sign in prompts. You can read more about this upgrade in News. ")
      alertUserToMigrationPopup.ondismissed(() => {
        Purchases.restorePurchases().then(() => {
          new Popups.Notification("Success", "Subscription upgrade complete. If you experience problems please let me know. ").show()
          cachedPurchases.value = []
        }).catch((e) => {
          console.error(e)
          new Popups.Notification("Error", "Subscription upgrade has failed (this might be due to poor connection). Please go to the store, scroll down, and click Restore Purchases, or send me an email. The upgrade will not be automatically retried. ").show()
          cachedPurchases.value = []
        })
      })
      alertUserToMigrationPopup.show()
    }
  })
}

export {triggerPurchasesMigration}