//TODO: We should probably delete account that have been inactive for over a year. (and delete associated data)

const path = require("path")
let admin = require("firebase-admin");

let serviceAccount = require(path.join(__dirname, "../", "keys", "mahjong-4-friends-firebase-adminsdk-5h4ft-18ca5e93d4.json"));

let app = admin.initializeApp({
  credential: admin.credential.cert(serviceAccount)
});


let auth = app.auth()

//Returns Promise. 
function verifyIdToken(idToken) {
    return auth.verifyIdToken(idToken)
}

module.exports = {verifyIdToken}