import {Capacitor} from "@capacitor/core";

const Tile = require("./Tile")
const Hand = require("./Hand")
const BaseHandClass = require("../../lib/Hand")

const Wall = require("./Wall")
const Popups = require("./Popups.js")
const Sequence = require("../../lib/Sequence")
const Match = require("../../lib/Match")

const {updateTilesInContainer} = require("./updateTilesInContainer.js")
const {displayCenterTilePopup} = require("./displayCenterTilePopup.js")
const {i18n} = require("../../lib/i18nHelper.js")

const {readTextFile, writeTextFile, deleteFile} = require("./Files/FileOps.ts")

require("./loadTileSet.js")

let gameBoard = document.createElement("div")
gameBoard.id = "gameBoard"
document.body.appendChild(gameBoard)


let gameBoardCenterContainer = document.createElement("div") //Div containing everything in the center of the board - inside the area leftover haver the hands. 
gameBoardCenterContainer.classList.add("gameBoardCenterContainer")
gameBoard.appendChild(gameBoardCenterContainer)

function createTopOrBottomHand(handId) {
	let hand = document.createElement("div")
	hand.id = handId
	gameBoard.appendChild(hand)
	return hand
}

function createLeftOrRightHand(handId, containerId) {
	let hand = createTopOrBottomHand(handId)

	//We will return the container for the tiles. A container is used for the left and right hands in order to vertically center the tiles.
	let container = document.createElement("div")
	container.id = containerId
	hand.appendChild(container)

	return container
}

function Compass(config = {}) {
	config.id = config.id || "compass"

	this.compass = document.createElement("img")
	this.compass.id = config.id
	this.compass.alt = "Compass"
	gameBoardCenterContainer.appendChild(this.compass)

	this.setDirectionForUserWind = function(userWind) {
		this.compass.src = "assets/compass-" + userWind + ".svg"
	}
}

let compass = new Compass({id: "compass"})


function FullscreenControls(elementId) {

	let goFullscreenImage = "assets/go-full-screen.svg"
	let exitFullscreenImage = "assets/exit-full-screen.svg"

	if (!document.fullscreenEnabled && document.webkitFullscreenEnabled) {
		//We'll add some support for the webkit prefix.
		Object.defineProperty(document, "fullscreenElement", {
			get: function() {return document.webkitFullscreenElement}
		})
		document.documentElement.requestFullscreen = function() {document.documentElement.webkitRequestFullScreen()}
		document.exitFullscreen = function() {document.webkitExitFullscreen()}
		document.addEventListener("webkitfullscreenchange", function() {
			document.dispatchEvent(new Event("fullscreenchange"))
		})
	}

	if (Capacitor.isNativePlatform()) {
		//TODO: I don't believe there is any event to detect when the StatusBar appears/dissapears.
		//This is possible on Android by swiping down from the top of the screen.

		//These return promises or are async!
		this.goFullscreen = function() {
			return StatusBar.hide()
		}

		this.exitFullscreen = function() {
			return StatusBar.show()
		}

		this.isFullscreen = async function() {
			let info = await StatusBar.getInfo()
			return !info.visible
		}
	}
	else if (document.fullscreenElement !== undefined) {
		//Support check. This allows users to check toggleElement.
		//fullscreenElement is null when supported.

		this.goFullscreen = function() {
			document.documentElement.requestFullscreen()
		}

		this.exitFullscreen = function() {
			document.exitFullscreen()
		}

		this.isFullscreen = function() {
			return document.fullscreenElement
		}
	}

	if (this.goFullscreen) {
		//Confirm that fullscreen is supported.
		this.toggleElement = document.createElement("img")
		this.toggleElement.id = elementId
		this.toggleElement.title = i18n.__("Toggle Full Screen")

		let setIcon = (async function setIcon() {
			if (await this.isFullscreen()) {
				this.toggleElement.src = exitFullscreenImage
			}
			else {
				this.toggleElement.src = goFullscreenImage
			}
		}).bind(this)

		this.toggleElement.addEventListener("click", (async function() {
			let prom;
			if (await this.isFullscreen()) {
				prom = this.exitFullscreen()
			}
			else {
				prom = this.goFullscreen()
			}
			if (prom) {prom.then(setIcon)} //Capacitor uses promises.
		}).bind(this))

		document.addEventListener("fullscreenchange", setIcon)
		setIcon()
	}

}

let fullscreenControls = new FullscreenControls("fullscreenControls")
if (fullscreenControls.toggleElement) {
	gameBoardCenterContainer.appendChild(fullscreenControls.toggleElement)
//TODO: Probably disable this on mobile browsers, outside of app.
	//We will go fullscreen at the very beginning if possible on mobile.
	//If we can't, we will go fullscreen on first document click.
	function callFullScreen() {
	    //Check for mobile or currently being in fullscreen.
		//TODO: This may be too aggressive on browsers. It can be annoying.
	    if ((window.innerWidth < 600 || window.innerHeight < 600)) {
			fullscreenControls.goFullscreen()
	    }
	}

	let wentFullScreenPrior;
	document.addEventListener("click", function(e) {
	    //If the event is not trusted, it's not going to allow fullscreen. Ignore it.
	    if (!e.isTrusted || wentFullScreenPrior) {return}
	    wentFullScreenPrior = true
	    callFullScreen()
	})

	try {
	    callFullScreen()
	}
	catch (e) {console.error(e)}
}


let syncButton = document.createElement("img")
syncButton.src = "assets/reload-icon.svg"
syncButton.id = "syncButton"
syncButton.title = i18n.__("Sync (Reload)")
gameBoardCenterContainer.appendChild(syncButton)

syncButton.addEventListener("click", async function() {
	try {
		await new Promise((r, j) => {
			window.saveOfflineGame().then(r)
			setTimeout(j, 500, "Ran Out Of Time to Save")
		})
	}
	catch (e) {console.error(e)}
	if (confirm("Sync Game with Server? Multiplayer games should auto-resume, single player games are resumable via resume button after syncing. ")) {
		window.location.reload()
	}
})

//For debugging.
window.Tile = Tile
window.Sequence = require("../../lib/Sequence")
window.TileContainer = require("../../lib/TileContainer")
window.Match = require("../../lib/Match")


function createTilePlacemat() {
	let tilePlacemat = document.createElement("div")
	tilePlacemat.id = "tilePlacemat"
	return tilePlacemat
}

let tilePlacemat = createTilePlacemat()
gameBoardCenterContainer.appendChild(tilePlacemat)


let historyButton = document.createElement("button")
historyButton.id = "historyButton"
historyButton.innerText = i18n.__("History")
gameBoardCenterContainer.appendChild(historyButton)

historyButton.addEventListener("click", function() {
	let elem = document.createElement("div")
	let p = document.createElement("p")
	p.innerText = i18n.__("Loading Game History... (This should only take a second or two - otherwise, close menu and try again)")
	elem.appendChild(p)

	let popup = new Popups.Notification(i18n.__("History"), elem)
	popup.show()

	stateManager.getMessageHistory().then((obj) => {
		let history = obj.message
		p.innerText = i18n.__("Click to a move reset back in history")

		let buttonContainer = document.createElement("div")
		buttonContainer.className = "historyMenuButtonContainer"
		elem.appendChild(buttonContainer)

		history.forEach((move) => {
			let btn = document.createElement("button")
			btn.innerText = i18n.__("Move %d", move.move) +  ` - ${move.message}`

			buttonContainer.appendChild(btn)
			btn.addEventListener("click", function() {
				let msg = i18n.__("Are you sure you would like to revert the game state to move %s? ", move.move)
				msg += i18n.__("This will affect ALL players, and can't be undone!")
				if (confirm(msg)) {
					window.stateManager.revertState(move.move)
					popup.dismiss()
				}
			})
		})

		buttonContainer.scrollTo(0, 999999) //Scroll to the bottom.


		if (!stateManager.online) {
			p.innerText += " or "

			let downloadGameButton = document.createElement("button")
			downloadGameButton.innerText = i18n.__("Save Current Game")
			downloadGameButton.style.fontSize = "1em"
			downloadGameButton.classList.add("orangeGreenGradient")
			p.appendChild(downloadGameButton)

			downloadGameButton.addEventListener("click", async function() {
				let downloadName = "mahjong4friends.room"
				let downloadContent = serverStateManager.getRoom("Offline").toJSON()
				
				if (Capacitor.isNativePlatform()) {
					//iOS and Android Capacitor don't support link downloads. Use share menu.
					//Android share menu isn't as good, as it doesn't provide a way to download the file.
					//Not sure there's a great solution to that though.
					let saveInfo = await writeTextFile(downloadName, downloadContent, "CACHE")
					let shareConfig = {
						title: downloadName,
						url: saveInfo.uri,
						//text: 'Save/Share to Play Later on Other Devices!' //Haven't seen this in testing, and it works without it, so not messing with it yet.
						//dialogTitle: "" //Same as above.
					}
					await Share.share(shareConfig)
				}
				else {
					var elem = document.createElement('a');
					elem.download = downloadName

					let blob = new Blob([downloadContent], {type: "application/json"})
					let url = URL.createObjectURL(blob)

					elem.href = url

					document.body.appendChild(elem);
					elem.click();
					elem.remove()

					URL.revokeObjectURL(url)
				}
			})
		}
	})
})

let swapJokerButton = document.createElement("button")
swapJokerButton.id = "swapJokerButton"
swapJokerButton.innerText = i18n.__("Swap Joker")
gameBoardCenterContainer.appendChild(swapJokerButton)

swapJokerButton.addEventListener("click", function() {
	if (userHand.inPlacemat.length !== 1) {
		new Popups.Notification("Error With Swap", "You must have exactly one tile in your placemat to swap - act like you are going to discard the tile, but hit Swap Joker instead of proceed. ").show()
		return
	}

	let elem = document.createElement("div")
	let p = document.createElement("p")
	p.innerText = "Who would you like to try and swap with?"
	elem.appendChild(p)

	let popup;

	//TODO: Consider adding an "auto" to this.
	stateManager.lastState.message.clients.forEach((item, i) => {
		let btn = document.createElement("button")
		btn.innerText = item.nickname
		btn.className = "swapJokerOptionMenuButton"
		if (item.isYou) {btn.innerText += " (You)"}

		elem.appendChild(btn)
		btn.addEventListener("click", function() {
			window.stateManager.placeTiles(userHand.inPlacemat, false, {swapJoker: item.id})
			popup.dismiss()
		})
	});

	popup = new Popups.Notification("Swap Tile For Joker", elem)
	popup.show()
})


let proceedButton = document.createElement("button")
proceedButton.id = "proceedButton"
proceedButton.innerText = i18n.__("Proceed")
gameBoardCenterContainer.appendChild(proceedButton)

proceedButton.addEventListener("click", function() {

	let placement = userHand.inPlacemat

	//If the user has 0 tiles in placemat, or 1 tile, which is the thrown one, next turn.
	if (placement.length === Number(placement.some((obj) => {return obj.evicting}))) {
		window.stateManager.placeTiles([])
		return;
	}

	console.log(placement)
	window.stateManager.placeTiles(placement)
})

window.stateManager.addEventListener("placeTiles", function(obj) {
	if (obj.status === "error") {
		new Popups.Notification(i18n.__("Error Placing Tiles"), obj.message, {plainText: true}).show()
	}
})

let hintButton = document.createElement("button")
hintButton.id = "hintButton"
hintButton.innerText = i18n.__("Suggested Hands")
gameBoardCenterContainer.appendChild(hintButton)


let userHand; //Initialize as styling adjusted during suggested hands. 
function createSuggestedHands(hand, playerName = "") {
	let isUser = !playerName

	let popup;
	try {
		if (stateManager.lastState.message.settings.gameStyle === "chinese") {
			//Not American Mahjong - must be Chinese/Panama.

			let results = hand.score()
			let titleText = results.scoreText //To save space, we'll display scoreText as the header.

			let panel = new Popups.Panel(titleText)

			let itemTableContainer = document.createElement("div")
			itemTableContainer.className = "itemTableContainer"

			panel.panel.appendChild(itemTableContainer)


			function createTable(items, isSetsTable = false) {
				let tableContainer = document.createElement("div")
				tableContainer.className = "tableContainer"

				let table = document.createElement("table")
				tableContainer.appendChild(table)

				function createData(tr) {
					let td = document.createElement("td")
					tr.appendChild(td)
					return td
				}

				let headerRow = document.createElement("tr")
				table.appendChild(headerRow)

				createData(headerRow).innerText = i18n.__(isSetsTable ? "Sets (In-Hand Blue)" : "Bonuses")
				createData(headerRow).innerText = i18n.__("Pts")
				createData(headerRow).innerText = i18n.__("Dbs")

				items.forEach((item) => {
					let tr = document.createElement("tr")
					table.appendChild(tr)

					if (item.text) {
						//Display text label.
						createData(tr).innerText = item.text
					}
					else {
						let td = createData(tr)
						//Display the tile images as label.

						if (isSetsTable && !item.match.exposed) {
							tr.style.backgroundColor = "rgb(214, 226, 231)"
						}

						item.match.tiles.flat().forEach((tile) => {
							td.appendChild(tile.createImageElem({
								gameStyle: stateManager?.lastState?.message?.settings?.gameStyle
							}))
						})
					}

					createData(tr).innerText = item.points
					createData(tr).innerText = item.doubles
				})

				return tableContainer
			}

			let matchesTable = createTable(results.matchItems, true)
			let otherTable = createTable(results.otherItems)

			itemTableContainer.appendChild(matchesTable)
			itemTableContainer.appendChild(otherTable)

			panel.show(gameBoard)

			return
		}
		else if (stateManager.lastState.message.settings.gameStyle === "american") {
			let cardName = stateManager.lastState.message.settings.card

			let titleText = isUser ? "Suggested Hands" : playerName + " Possible Hands"

			let tiles = hand.contents.concat(hand.inPlacemat)
			tiles = tiles.filter((tile) => {return !tile.evicting}).filter((tile) => {return !tile.faceDown})

			if (cardName === "Random") {
				popup = new Popups.Notification(titleText, `'Other Card' does not support ${titleText}. `)
			}
			else if (!stateManager.lastState.message.settings.suggestedHands) {
				popup = new Popups.Notification(titleText, `${titleText}/Hints are disabled by the host. `)
			}
			else {
				const cards = require("../../lib/american/cards.js")
				const utilities = require("../../lib/american/utilities/index.js")

				let card = cards[cardName]
				let options;
				console.time("Gen Options")

				if (window.handIndex !== undefined) {
					//Setting window.handIndex will filter the list to a specific item on the card.
					//Set to a number. It will pick that number item on the card, starting with index 0 (assuming card is properly ordered in code)
					//Intended for debugging and developer use.
					let combos = []
					let handIndex = 0;
					let name = card.combos[0].handName
					let currentName = name
					for (let i=0;i<card.combos.length && handIndex < window.handIndex;i++) {
						currentName = card.combos[i].handName
						if (name !== currentName) {
							name = currentName
							handIndex++
						}
					}
					combos = card.combos.filter((combo) => {
						return name === combo.handName
					})

					options = utilities.getTileDifferential({combos}, tiles)
				}
				else {
					options = utilities.getTileDifferential(card, tiles)
				}
				console.timeEnd("Gen Options")



				if (options.length > 0 && options.every((option) => {
					return option.diff === Infinity && option.diffWithoutChecks.concealed !== Infinity
				})) {
					popup = new Popups.Notification("No Hands Found", ` - All hands matching this pattern are concealed. Concealed hands cannot be used given current exposures. \n - This hand might be dead\n - You might be playing with the wrong card (using ${cardName}). \n - We might have a bug, or not properly support this hand (unlikely)`, {plainText: true})
				}
				else {
					options = options.filter((option) => {return option.diff < Infinity})
					if (options.length === 0) {
						popup = new Popups.Notification("No Hands Found", ` - This hand might be dead\n - You might be playing with the wrong card (using ${cardName}). \n - We might have a bug, or not properly support this hand (unlikely)`, {plainText: true})
					}
					else {
						let elem = document.createElement("div")
						let p = document.createElement("p")
						//TODO: Exposed tiles could imply that not all tiles are factored in after Mahjong is called. Visible, etc, implies discard is counted.
						p.innerText = isUser ? `Discard pile not analyzed` : `Only Exposed Tiles Considered`
						if (options.length > 1) {p.innerText += " - Scroll for more"}
						elem.appendChild(p)
	
						//Progressively render 200 suggestions 2 at a time.
						let table = document.createElement("div")
						let index = 0
						function drawMore() {
							let amount = 2
							for (let i=index;i<(index + amount);i++) {
								let item = options[i]
								if (!item) {return}

								let itemContainer = document.createElement("div")
								itemContainer.classList.add("suggestedHandsItem")
	
								let nameElem = document.createElement("div")
								nameElem.innerText = `${item.handOption.handName} - ${item.diff} Tiles Away (${item.handOption.concealed?"C":"X"}, ${item.handOption.score}pt)`
								itemContainer.appendChild(nameElem)
	
								let tileRow = document.createElement("div")
								itemContainer.appendChild(tileRow)
	
								item.handOption.tiles.flat().forEach((tile) => {
									tileRow.appendChild(tile.createImageElem({
										gameStyle: stateManager?.lastState?.message?.settings?.gameStyle
									}))
								})

								table.appendChild(itemContainer)
							}
	
							index += amount
							if (index > 200) {return}
							window.requestAnimationFrame(drawMore)
						}
	
						window.requestAnimationFrame(drawMore)
	
						elem.appendChild(table)
						table.className = "suggestedHandsTable"
						console.log(elem)
	
						popup = new Popups.Notification(titleText, elem)
						//We need to allow the user to reorder their hand while suggested hands is open. 
						//For some reason, when on the documentElement, the popup cover is still covering up the hand, so move to document.body
						//User cannot scroll while on gameboard so this is not a problem
						popup.cover.remove()
						document.body.appendChild(popup.cover)
						userHand.handToRender.style.zIndex = 10000 //Increase z-index such that the user can reorder their hand while suggested hands is open. 
						popup.ondismissed(function() {
							userHand.handToRender.style.zIndex = "" //Reset z-index to default.  
						})
					}
				}
			}
		}
		else {
			//TODO: Display extra-large version of hand for user.
			//Note that displayCenterTilePopup only accepts Tile objects - we will need to extract Tiles from Matches, etc.

			// let tiles = hand.contents.concat(hand.inPlacemat)
			// tiles = tiles.filter((tile) => {return !tile.evicting}).filter((tile) => {return !tile.faceDown})
			//
			// let titleText = isUser ? "Hand View" : playerName + " Hand View"
			//
			// displayCenterTilePopup(tiles, titleText);
			return;
		}

	}
	catch (e) {
		console.error(e)
		popup = new Popups.Notification("Suggested Hands", "There was an error displaying suggested hands. Sorry. ")
	}
	popup.show()
}

hintButton.addEventListener("click", function() {
	createSuggestedHands(userHand)
})

let claimButton = document.createElement("button")
claimButton.id = "claimButton"
claimButton.innerText = i18n.__("Claim")
claimButton.addEventListener("click", function() {
	stateManager.placeTiles("Claim")
})
gameBoardCenterContainer.appendChild(claimButton)

let instructionBubble = document.createElement("div")
instructionBubble.id = "instructionBubble"
instructionBubble.style.display = "none"
gameBoardCenterContainer.appendChild(instructionBubble)

window.stateManager.addEventListener("state", function(obj) {
	instructionBubble.style.display = ""
	instructionBubble.innerText = obj.message.instructions
})


import { TextToSpeech } from '@capacitor-community/text-to-speech';
import {StatusBar} from "@capacitor/status-bar";
import {Share} from "@capacitor/share";
window.stateManager.addEventListener("gameplayAlert", function(obj) {
	//Play sound.
	let sound = document.createElement("audio");

	let baseUrl = "assets/sounds/"
	let urls = [];
	if (obj.message.includes(i18n.__("threw"))) {
		sound.volume = 0.5 * window.settings.soundEffectVolume.value
		urls = ["tile-drop-table.mp3"]
	}
	else if (obj.message.includes("mahjong")) {
		sound.volume = 1 * window.settings.soundEffectVolume.value
		urls = ["tiles-dropping-table.mp3"]
	}

	if (urls.length > 0 && sound.volume > 0) {
		sound.src = baseUrl + urls[Math.floor(Math.random() * urls.length)];
		sound.setAttribute("preload", "auto");
		sound.setAttribute("controls", "none");
		sound.style.display = "none";
		document.body.appendChild(sound);
		sound.play()
		setTimeout(function() {
			sound.remove()
		}, 2000)
	}

	let alert = new Popups.BlocklessAlert(obj.message, {
		duration: 4000 * (obj?.status?.durationMultiplier || 1),
		optional: obj?.status?.optional,
		onStart: async function() {
			//Get the settingId for the client. 
			let clientId = obj?.status?.clientId
			let settingId = 0

			if (!obj.status.speech || clientId === window.stateManager.connectedClientId) {return} //Don't play a voice. 

			let lastStateClientsList = window.stateManager.lastState.message.clients
			for (let i=0;i<lastStateClientsList.length;i++) {
				let client = lastStateClientsList[i]
				if (client.id === clientId) {
					break;
				}
				if (!client.isYou) {
					settingId++
				}
			}

			let voiceURI = window.settings.voices[settingId].value

			if (voiceURI === "none") {return}

			let supportedVoices = (await TextToSpeech.getSupportedVoices()).voices
			let indexOfVoiceURI = supportedVoices.findIndex((voice) => {
				return voice.voiceURI === voiceURI
			})
			
			TextToSpeech.speak({
				text: obj.status.speech,
				lang: window.settings.locale.value,
				rate: window.settings.voiceSpeed.value,
				pitch: 1.0,
				volume: window.settings.voiceVolume.value,
				category: 'ambient',
				voice: (indexOfVoiceURI !== -1) ? indexOfVoiceURI : undefined
			})
		}
	})
})

let shouldConfirm = true;
function endGame(confirmMessage) {
	//Require confirmation unless the game is over. Note that this might be slightly bugged with revert.
	if (
		endGameButton.innerText === i18n.__("Leave Room") //Spectating
		|| !shouldConfirm
		|| confirm(confirmMessage)
	) {
		window.stateManager.endGame()
		return true
	}
	return false
}

let endGameButton = document.createElement("button")
endGameButton.id = "endGameButton"
gameBoardCenterContainer.appendChild(endGameButton)

let newGameNoLobbyButton = document.createElement("button")
newGameNoLobbyButton.id = "newGameNoLobbyButton"
newGameNoLobbyButton.innerText = i18n.__("New Game")
gameBoardCenterContainer.appendChild(newGameNoLobbyButton)

endGameButton.addEventListener("click", function() {
	endGame(i18n.__("Are you sure you want to end the game?"))
})

newGameNoLobbyButton.addEventListener("click", function() {
	if (endGame(i18n.__("Are you sure you want to start a new game?"))) {
		document.getElementById("startGameButton").click() //Clicks button on RoomManager - not currently visible.
	}
})

let goMahjongButton = document.createElement("button")
goMahjongButton.id = "goMahjongButton"
gameBoardCenterContainer.appendChild(goMahjongButton)

goMahjongButton.addEventListener("click", function() {
	let placement = userHand.inPlacemat
	console.log(placement)
	window.stateManager.placeTiles(placement, {mahjong: true})
})

let wallAndDiscardContainer = document.createElement("div")
wallAndDiscardContainer.id = "wallAndDiscardContainer"
gameBoardCenterContainer.appendChild(wallAndDiscardContainer)

let wallRendering = document.createElement("div")
wallRendering.className = "wall"
wallAndDiscardContainer.appendChild(wallRendering)

let discardPile = document.createElement("div")
discardPile.id = "discardPile"
wallAndDiscardContainer.appendChild(discardPile)

function renderDiscardPile(tileStrings) {
	let tiles = tileStrings.map((str) => {return Tile.fromJSON(str)})

	if (window?.stateManager?.lastState?.message?.settings?.sortDiscardPile) {
		tiles = Hand.sortTiles(tiles)
	}

	updateTilesInContainer(discardPile, tiles)

	discardPile.onclick = function() {
		displayCenterTilePopup(tiles, i18n.__(`Discard Pile (%s tile${tiles.length === 1 ? "":"s"})`, tiles.length))
	}
}


let userHandElem = createTopOrBottomHand("userHand")
let userHandElemExposed = createTopOrBottomHand("userHandExposed")
userHand = new Hand({
	handToRender: userHandElem,
	handForExposed: userHandExposed,
	interactive: true,
	tilePlacemat: tilePlacemat
})
window.userHand = userHand

let rightHandContainer = createLeftOrRightHand("rightHand", "rightHandContainer")
let rightHand = new Hand({
	handToRender: rightHandContainer
})

let topHandElem = createTopOrBottomHand("topHand")
let topHand = new Hand({
	handToRender: topHandElem
})

let leftHandContainer = createLeftOrRightHand("leftHand", "leftHandContainer")
let leftHand = new Hand({
	handToRender: leftHandContainer
})


let nametagIds = ["bottomNametag", "rightNametag", "topNametag", "leftNametag"]
let nametags = nametagIds.map((id) => {
	let nametag = document.createElement("p")
	nametag.id = id
	gameBoardCenterContainer.appendChild(nametag)
	return nametag
})

//TODO: Reading the nametags feels like REALLY bad practice. It works, but...
//Probably not worth redoing unless there is a problem.
topHandElem.addEventListener("click", function() {
	createSuggestedHands(topHand, nametags[2].innerText)
})

leftHandContainer.addEventListener("click", function() {
	createSuggestedHands(leftHand, nametags[3].innerText)
})

rightHandContainer.addEventListener("click", function() {
	createSuggestedHands(rightHand, nametags[1].innerText)
})

userHandExposed.addEventListener("click", function() {
	createSuggestedHands(userHand)
})



let videoContainer = document.createElement("div")
videoContainer.classList.add("gameboardVideoContainer")
gameBoardCenterContainer.appendChild(videoContainer)









let showSpectating = true



function clearSyncCache() {
	//Clear contents, but don't render the changes
	//This forces the next sync to sync the entire hand contents,
	//which prevents it from being treated as a Charleston.

	//Without this, reverts into an American Mahjong charleston might result
	//in the changed tiles going into the placemat.
	userHand.contents = []
}

window.stateManager.addEventListener("revertState", clearSyncCache)
window.stateManager.addEventListener("endGame", clearSyncCache) //This is basically irrelevant, as 4+ tiles almost always vary between draws.


window.stateManager.addEventListener("state", drawBoardForState)
window.addEventListener("tileSetUpdated", function() {
	try {
		drawBoardForState(window.stateManager.lastState) //Force a re-render. 
	}
	catch (e) {
		//Probably not an issue, but if tileset isn't re-rending it is. This is never a fatal bug though - at worst, the wrong tileset is being used. 
		console.warn(e)
	}
})

function drawBoardForState(obj) {
	goMahjongButton.innerText = i18n.__("Mahjong!")		// EXC Note to Tucker: Note the ! is to differentiate the two Mahjong translations, as a game, or as goMahjong
	if (window.stateManager.isHost) {
		newGameNoLobbyButton.style.display = ""
	}
	else {
		newGameNoLobbyButton.style.display = "none"
	}

	gameBoard.className = obj?.message?.settings?.gameStyle //Buttons use this class to determine layout and visibility.
	if (obj?.message?.settings?.gameStyle === "filipino") {gameBoard.className = "chinese"} //Currently the same layout.

	let message = obj.message

	shouldConfirm = !message.isGameOver

	hintButton.style.display = ""
	proceedButton.classList.add("shrinkForHintButton")

	if (!message?.settings?.suggestedHands) {
		hintButton.style.display = "none"
		proceedButton.classList.remove("shrinkForHintButton")
	}

	claimButton.style.display = ""
	proceedButton.classList.add("shrinkForClaimButton")

	function hideClaimButton() {
		claimButton.style.display = "none"
		proceedButton.classList.remove("shrinkForClaimButton")
	}

	if (!message?.settings?.pickupDiscardForDraw) {
		hideClaimButton()
	}

	if (!message.inGame) {
		document.body.style.overflow = ""
		return
	};
	document.body.style.overflow = "hidden"

	if (message.wallTiles !== undefined) {
		if (typeof message.wallTiles === "object") {
			if (message.wallTiles?.[0] instanceof Tile) {
				//This must be a lastState from stateManager, that we've already processed. Let it though unchanged. 
			}
			else {
				//Convert to tiles. 
				message.wallTiles = Hand.convertStringsToTiles(message.wallTiles)
			}
		}
		else {
			message.wallTiles = new Array(message.wallTiles).fill(Tile.get({faceDown: true}))
		}
		Wall.renderWall(wallRendering, message.wallTiles)
	}

	if (message.discardPile) {
		renderDiscardPile(message.discardPile)
	}

	let clients = message.clients
	let winds = ["north", "east", "south", "west"]
	let hands = [userHand, rightHand, topHand, leftHand]

	delete userHand.wind //Make sure this is cleared - if we are spectating, we don't want this to be defined.
	clients.forEach((client) => {
		if (client.hand && !(client.hand instanceof BaseHandClass)) {
			//Should ALWAYS be a string, EXCEPT when this is called with lastState from stateManager (in which case, BaseHandClass). 
			client.hand = Hand.fromString(client.hand)
		}

		if (client.isYou) {
			userHand.sync(client.hand, message?.currentTurn?.charleston)
		}
	})

	let userWindIndex = winds.indexOf(userHand.wind)

	let windOrder = winds.slice(userWindIndex).concat(winds.slice(0, userWindIndex))
	if (!userHand.wind) {
		//Must be spectating.
		compass.setDirectionForUserWind(windOrder[0])
		endGameButton.innerText = i18n.__("Leave Room")

		if (showSpectating) {
			new Popups.MessageBar(i18n.__("You are spectating (message will close automatically)")).show(10000)
			showSpectating = false
		}
	}
	else {
		compass.setDirectionForUserWind(userHand.wind)
		endGameButton.innerText = i18n.__("End Game")
	}

	let currentTurnWind;
	clients.forEach((client) => {
		let windPosition = 0;
		if (client.hand.wind) {
			windPosition = windOrder.indexOf(client.hand.wind)
		}

		let hand = hands[windPosition]

		if (client.hand && !client.isYou) {
			hand.sync(client.hand, message?.currentTurn?.charleston)
		}

		let nametag = nametags[windPosition]
		nametag.innerText = client.nickname
		nametag.style.color = ""

		if (message.currentTurn && client.id === message.currentTurn.userTurn) {
			currentTurnWind = client.hand.wind
			nametag.style.color = "red"
		}
	})

	hands.forEach((hand) => {hand.renderTiles(message?.currentTurn?.lastDrawn)}) //lastDrawn only affects unexposed tiles, so there isn't a problem passing it to all.
	swapJokerButton.disabled = ""

	userHand.setEvictingThrownTile() //Clear evictingThrownTile

	if (message.currentTurn.charleston) {hideClaimButton()}

	if (message.currentTurn?.playersReady?.length > 0) {
		//The person has thrown their tile. Waiting on players to ready.
		let clientIdReady = message.currentTurn.playersReady.includes(window.stateManager.connectedClientId)
		claimButton.disabled =
			goMahjongButton.disabled =
			proceedButton.disabled = clientIdReady?"disabled":""

		//Completely hide the claim button if we aren't the next in order.
		if ((winds.indexOf(currentTurnWind) + 1) % 4 !== winds.indexOf(userHand.wind)) {
			hideClaimButton()
		}

		swapJokerButton.disabled = "disabled"
		proceedButton.innerText = i18n.__("Proceed (%s/4)", message.currentTurn.playersReady.length)
		//If you haven't thrown, are not in charleston, and it is your turn, override and enable.
		if (!message.currentTurn.thrown && !message.currentTurn.charleston && message.currentTurn.userTurn === clientId) {proceedButton.disabled = ""}
		if (message.currentTurn.charleston && message.currentTurn.userTurn !== clientId) {
			//You have 13 tiles. Mahjong impossible.
			goMahjongButton.disabled = "disabled"
		}
		if (message.currentTurn.userTurn !== clientId && message.currentTurn.thrown) {
			userHand.setEvictingThrownTile(Tile.fromJSON(message.currentTurn.thrown))
		}
		userHand.renderPlacemat(false)
	}
	else {
		hideClaimButton()
		proceedButton.disabled = ""
		goMahjongButton.disabled = ""
		proceedButton.innerText = i18n.__("Proceed")

		//The person has not yet thrown a tile.
		if (message.currentTurn.charleston) {
			//TODO: Not sure if East wind is allowed to go Mahjong during a charleston. As of now, Room will treat mahjong just like place tiles during charleston,
			//so we'll disable the option
			goMahjongButton.disabled = "disabled"
		}

		if (message.currentTurn.userTurn === window.stateManager.connectedClientId) {
			userHand.renderPlacemat(true)
			swapJokerButton.disabled = ""
		}
		else {
			userHand.renderPlacemat(false) //Not sure if this is needed, but it isn't harming anything.
			swapJokerButton.disabled = "disabled"
			if (!message.currentTurn.charleston) {
				proceedButton.disabled = "disabled"
				goMahjongButton.disabled = "disabled"
			}
		}
	}

	if (!proceedButton.disabled && !message.isGameOver) {
		proceedButton.classList.add("scaleAnimation")
	}
	else {proceedButton.classList.remove("scaleAnimation")}

	if (message.isGameOver) {
		//Enable proceedButton and goMahjongButton as View Scores
		proceedButton.innerText = i18n.__("View Scores")
		proceedButton.disabled = ""
		goMahjongButton.innerText = i18n.__("Scores")
		goMahjongButton.disabled = ""
	}
}

proceedButton.addEventListener("click", function() {
	//When clicked, remove proceed button scale animation.
	proceedButton.style.animation = "none"
	setTimeout(function() {
		proceedButton.style.animation = ""
	}, 100)
})

//Add hotkeys
document.addEventListener("keyup", function(e) {
	let chars = ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "a", "s", "d", "f"] //qwertyuiopasdf will correspond to first 14 hand spots. Pressing will move to placemat.
	if (!stateManager.inGame) {
		return;
	}
	if (e.code === "Space" && e.shiftKey) {
		goMahjongButton.click()
	}
	else if (e.code === "Space") {
		proceedButton.click()
	}
	else if (Number(e.key) > 0 && Number(e.key) < 5) {
		//1,2,3, and 4 will correspond to the 4 placemat spots. Pressing them will remove the specified tile.
		let pos = Number(e.key) - 1
		userHand.moveTile(userHand.inPlacemat[pos])
	}
	else if (chars.includes(e.key.toLowerCase())) {
		let index = chars.indexOf(e.key.toLowerCase())
		let tiles = userHand.contents.filter((item) => {return item instanceof Tile})
		userHand.moveTile(tiles[index])
	}
})

function handleScreenResize() {
	topHand.renderTiles()
	leftHand.renderTiles()
	rightHand.renderTiles()
	userHand.renderTiles()
}
window.addEventListener("resize", handleScreenResize)
window.addEventListener("orientationchange", handleScreenResize)

window.addEventListener("scroll", function(event) {
	if (window.stateManager.inGame) {
		window.scrollTo(0,0)
	}
})


export {gameBoard}
