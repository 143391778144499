const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js");

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//11111 NNNN 11111 (2 suits, like nums, any wind)
	function(tiles = []) {
		allOptions.forEach((num) => {
			allSuitArrangements.forEach((suitOrder) => {
				windOptions.forEach((wind) => {
					let newArr = []
					tiles.push(newArr)
					newArr.push(createTiles({type: suitOrder[0], value: num, amount: 5}))
	
					newArr.push(createTiles({type: "wind", value: wind, amount: 4}))

					newArr.push(createTiles({type: suitOrder[1], value: num, amount: 5}))
				})
			})
		})

		return {
			tiles,
			score: 45,
			concealed: false
		}
	},
	//22 333 4444 55555 (1 suit)
	function(tiles = []) {
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)
			newArr.push(createTiles({type: suit, value: 2, amount: 2}))
			newArr.push(createTiles({type: suit, value: 3, amount: 3}))
			newArr.push(createTiles({type: suit, value: 4, amount: 4}))
			newArr.push(createTiles({type: suit, value: 5, amount: 5}))
		})

		return {
			tiles,
			score: 40,
			concealed: false
		}
	},
	//FFFF 11111 22222 (2 suits, 2 consec nums)
	function(tiles = []) {
		allOptions.slice(0, -1).forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 4}))
				newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: 5}))
				newArr.push(createTiles({type: suitOrder[1], value: 1+offset, amount: 5}))
			})
		})

		return {
			tiles,
			score: 45,
			concealed: false
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "Quints"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

