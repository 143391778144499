const utilities = require("./utilities/index.js")

class Card {
    generateHands() {return []}
    name = ""

    constructor() {
        Object.defineProperty(this, "hands", {
            get: function() {
                console.time("Generate Hands for " + this.name)
        
                //Clear the getter. 
                Object.defineProperty(this, "hands", {
                    value: this.generateHands()
                })
        
                console.timeEnd("Generate Hands for " + this.name)

                Object.freeze(this.hands) //Prevent accidental modification. 

                return this.hands
            },
            configurable: true
        })
        
        
        
        Object.defineProperty(this, "combos", {
            get: function() {
                console.time("Generate Combos for " + this.name)

                let combos = []
                for (let hand of this.hands) {
                    combos.push(...hand.combos)
                }
        
                //Clear the getter. 
                Object.defineProperty(this, "combos", {
                    value: combos
                })
        
                console.timeEnd("Generate Combos for " + this.name)

                Object.freeze(this.hands) //Prevent accidental modification. 

                return this.combos
            },
            configurable: true
        })
    }
}

module.exports = Card