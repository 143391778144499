const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
//Hand #6 not implemented (utilizes current date)
module.exports = [
	//G OOO D LC KKKK 4 23
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)
		
		
		newArr.push(createTiles({type: "dragon", value: "green", amount: 1}))
		
		newArr.push(createTiles({type: "dragon", value: "white", amount: 3}))
		
		newArr.push(createTiles({type: "dragon", value: "any", amount: 1}))
		
		
		newArr.push(createTiles({type: "any", value: 7, amount: 1}))
		newArr.push(createTiles({type: "character", value: "any", amount: 1}))
		
		newArr.push(createTiles({type: "any", value: "any", amount: 4}))
		
		newArr.push(createTiles({type: "any", value: 4, amount: 1}))
		
		newArr.push(createTiles({type: "any", value: 2, amount: 1}))
		newArr.push(createTiles({type: "any", value: 3, amount: 1}))
		
		return {
			tiles,
			score: 40,
			concealed: false
		}
	},
	//GR AA SSS NI BB LER
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)
		
		newArr.push(createTiles({type: "dragon", value: "green", amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
		
		newArr.push(createTiles({type: "any", value: "any", amount: 2}))
		
		newArr.push(createTiles({type: "wind", value: "south", amount: 3}))
		
		newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
		newArr.push(createTiles({type: "any", value: 1, amount: 1}))
		
		newArr.push(createTiles({type: "bamboo", value: "any", amount: 2}))
		
		newArr.push(createTiles({type: "any", value: 7, amount: 1}))
		newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
		
		return {
			tiles,
			score: 50,
			concealed: false
		}
	},
	//MJ 365 247 4 LI FF E
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)
		
		newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
		newArr.push(createTiles({type: "joker", amount: 1}))
		
		newArr.push(createTiles({type: "any", value: 3, amount: 1}))
		newArr.push(createTiles({type: "any", value: 6, amount: 1}))
		newArr.push(createTiles({type: "any", value: 5, amount: 1}))
		
		newArr.push(createTiles({type: "any", value: 2, amount: 1}))
		newArr.push(createTiles({type: "any", value: 4, amount: 1}))
		newArr.push(createTiles({type: "any", value: 7, amount: 1}))
		
		newArr.push(createTiles({type: "any", value: 4, amount: 1}))
		
		newArr.push(createTiles({type: "any", value: 7, amount: 1}))
		newArr.push(createTiles({type: "any", value: 1, amount: 1}))
		
		newArr.push(createTiles({type: "flower", amount: 2}))
		newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
		
		return {
			tiles,
			score: 75,
			concealed: true
		}
	},
	//ON A SCALE OF 1 2 10
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)
		
		newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
		newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
		
		newArr.push(createTiles({type: "any", value: "any", amount: 1}))
		
		newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
		newArr.push(createTiles({type: "character", value: "any", amount: 1}))
		newArr.push(createTiles({type: "any", value: "any", amount: 1}))
		newArr.push(createTiles({type: "any", value: 7, amount: 1}))
		newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
		
		newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
		newArr.push(createTiles({type: "flower", amount: 1}))
		
		newArr.push(createTiles({type: "any", value: 1, amount: 1}))
		
		newArr.push(createTiles({type: "any", value: 2, amount: 1}))
		
		newArr.push(createTiles({type: "any", value: 1, amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
		
		return {
			tiles,
			score: 100,
			concealed: true
		}
	},
	//11 22 33 44 55 66 77
	function(tiles = []) {
		allOptions.slice(0,-6).forEach((offset) => {
			let newArr = []
			tiles.push(newArr)

			for (let i=0;i<7;i++) {
				newArr.push(createTiles({type: "any", value: offset + i, amount: 2}))
			}
		})
		
		return {
			tiles,
			score: 100,
			concealed: true,
		}
	},
	//MJ 2D AA XX/XX/2023 
	//Where XX/XX is current date. 
	//NOT IMPLEMENTING RIGHT NOW!
	function(tiles = []) {
		return {
			tiles,
			score: 100,
			concealed: true,
		}
	},
	//ALICE IN 1DRLAND
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)
		
		newArr.push(createTiles({type: "any", value: "any", amount: 1}))
		newArr.push(createTiles({type: "any", value: 7, amount: 1}))
		newArr.push(createTiles({type: "any", value: 1, amount: 1}))
		newArr.push(createTiles({type: "character", value: "any", amount: 1}))
		newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
		
		newArr.push(createTiles({type: "any", value: 1, amount: 1}))
		newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
		
		newArr.push(createTiles({type: "any", value: 1, amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "any", amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
		newArr.push(createTiles({type: "any", value: 7, amount: 1}))
		newArr.push(createTiles({type: "any", value: "any", amount: 1}))
		newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
		newArr.push(createTiles({type: "dragon", value: "any", amount: 1}))
		
		
		return {
			tiles,
			score: 100,
			concealed: true,
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "PPP ULL THIS OUT OF YOUR HAT!"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

