//We need to keep the server in sync with the user's authentication status.
import {FirebaseAuthentication} from "@capacitor-firebase/authentication";

function onAuthStateChanged() {
  //TODO: This code seems to be triggering an excessive number of getCurrentRoom calls on startup.
  window.stateManager.getServerAuthMessage().then((serverAuthMessage) => {
    window.stateManager.sendMessage(serverAuthMessage)
    window.stateManager.getCurrentRoom()
  })
}

FirebaseAuthentication.addListener('authStateChange', onAuthStateChanged)
onAuthStateChanged()