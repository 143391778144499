const Tile = require("../../../lib/Tile")

Tile.prototype.getTileSrc = function() {
	if (!window.tileSet) {
		// console.warn("No Tileset Available"); 
		return;
	}
	return window.tileSet.getTileSrc(this.faceDown ? "face-down" : this.type, this.value)
}

Tile.prototype.createImageElem = function createImageElem(options = {}) {
	let img = document.createElement("img")
	if (this.getTileSrc()) {
		img.src = this.getTileSrc()
	}
	img.title = this.getTileName(options.gameStyle)
	return img
}

module.exports = Tile
