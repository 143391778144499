const {i18n} = require("../../../lib/i18nHelper.js")

function createLinksToStores() {
    let externalAppStoresDiv = document.createElement("div")
	externalAppStoresDiv.id = "externalAppStoresDiv"

	function createButton({href, src, text}) {
		let link = document.createElement("a")
		link.href = href
		link.target = "_blank"
		link.rel = "noopener"

		let img = document.createElement("img")
		img.setAttribute("loading", "lazy")
		img.src = src
		img.alt = text

		link.appendChild(img)
		externalAppStoresDiv.appendChild(link)
	}

	//We will display the current platform first.
	//Ordering for other the non-current platform is currently ordered App Store/Windows/Google Play.
	//Not sure if this is ideal or not. It aligns with the userbase relatively well (Android is smallest)
	let buttons = [
		{
			href: "https://apps.apple.com/us/app/mahjong-4-friends/id1552704332",
			src: `assets/badges/${i18n.locale}/appstore.svg`,
			text: "Get Mahjong 4 Friends on the App Store",
			strings: ["iPad", "iPhone", "Mac OS X"]
		},
		{
			href: "https://www.microsoft.com/store/apps/9NQS9Z5JJJ8P",
			src: `assets/badges/${i18n.locale}/microsoft.svg`,
			text: "Get Mahjong 4 Friends from Microsoft",
			strings: ["Windows"]
		},
		{
			href: "https://play.google.com/store/apps/details?id=com.mahjong4friends.twa",
			src: `assets/badges/${i18n.locale}/googleplay.svg`,
			text: "Get Mahjong 4 Friends on Google Play",
			strings: ["Android", "CrOS"]
		},
		{
			href: "https://www.amazon.com/gp/product/B099WK2KB2/",
			src: `assets/badges/${i18n.locale}/amazon.svg`,
			text: "Get Mahjong 4 Friends on Amazon Appstore",
			strings: ["Android", "Kindle", "Silk", "KFTT", "KFOT", "KFJWA", "KFJWI", "KFSOWI", "KFTHWA", "KFTHWI", "KFAPWA", "KFAPWI", "KFASWI", "KFTBWI", "KFMEWI", "KFFOWI","KFSAWA", "KFSAWI", "KFARWI"]
		},
	]

	function getVal(button) {
		if (!button.strings) {return 0}

		for (let i=0;i<button.strings.length;i++) {
			if (navigator?.userAgent?.includes(button.strings[i])) {
				return 1
			}
		}
		return -1
	}

	buttons.sort((a, b) => {
		return getVal(b) - getVal(a)
	}).forEach((button) => {createButton(button)})

    return externalAppStoresDiv
}


module.exports = {createLinksToStores}