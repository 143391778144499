const Card = require("../Card.js")

class ImplementedCard extends Card {
	name = "2024 Marvelous Mah Jongg"

	generateHands() {
		let output = []
	
		output.push(...require("./even-if-you-dont-believe.js"))
		output.push(...require("./odd-twists-and-turns.js"))
		output.push(...require("./atomic.js"))
		output.push(...require("./sub-atomic.js"))
		output.push(...require("./flights-of-fantasy.js"))
		output.push(...require("./3-kingdoms.js"))
		output.push(...require("./its-a-numbers-game.js"))
		output.push(...require("./running-scared.js"))
		output.push(...require("./its-no-joke.js"))
		output.push(...require("./slay-the-beast.js"))
	
		return output
	}
}

module.exports = new ImplementedCard()