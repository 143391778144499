//RevenueCat does not allow us to see whether a subscription is renewing UNLESS it is the last-expiring subscription for that entitlement.
//Additionally, RevenueCat billing allows for duplicative subscriptions.
//We will handle this by alerting the user that they might have unused subscriptions that they should ensure are cancelled.
//(note that, because of the previous issue, we can't actually confirm whether or not they are cancelled)


//Also, managementURL may not work if the subscription was purchased on a different store! We need to handle that scenario, probably by
//informing them which platform it must be cancelled from.


function getExpirationDateForEntitlement(entitlementDetails) {
  console.log("Details", entitlementDetails)
  if (entitlementDetails.expirationDate instanceof Date) {
    //Web API
    return entitlementDetails.expirationDate
  }
  else if (entitlementDetails.expirationDateMillis !== undefined) {
    //Native
    return new Date(entitlementDetails.expirationDateMillis)
  }
  else {
    console.error("Cannot determine date", entitlementDetails)
  }
}





function getDurationStringForProduct(packageProduct) {
  //normalPeriodDuration on web, subscriptionPeriod on native.
  let subscriptionPeriodString = packageProduct.normalPeriodDuration || packageProduct.subscriptionPeriod

  //On Amazon, subscriptionPeriodString can be null.
  if (!subscriptionPeriodString) {
    //Attempt to determine the duration using the identifier.
    if (packageProduct.identifier.includes("year")) {
      subscriptionPeriodString = "P1Y"
    }
    else if (packageProduct.identifier.includes("quarter")) {
      subscriptionPeriodString = "P3M"
    }
    else if (packageProduct.identifier.includes("month")) {
      subscriptionPeriodString = "P1M"
    }
  }

  return subscriptionPeriodString
}

function getPriceStringForProduct(packageProduct) {
  //currentPrice.formattedPrice on web, priceString on native.
  return packageProduct?.currentPrice?.formattedPrice || packageProduct?.priceString
}



function getPackageProduct(offerPackage) {
  if (offerPackage.rcBillingProduct) {
    return offerPackage.rcBillingProduct
  }
  else if (offerPackage.product) {
    return offerPackage.product
  }
  else {
    console.error("Unsupported", offerPackage)
  }
}



/*
* @returns {Array} - An array of product identifiers that are not used towards any entitlements.
* This function is used to determine if the user has any unused subscriptions that they should cancel.
* Note that we cannot currently detect whether the subscription is scheduled to cancel or renew.
* */
function findUnusedSubscriptions(customerInfo) {
  let activeSubscriptions = Array.from(customerInfo.activeSubscriptions.values())
  let activeEntitlements = Object.values(customerInfo.entitlements.active)

  for (let entitlement of activeEntitlements) {
    //If a subscription is used towards an entitlement, then it is not unused.
    let productIdentifier = entitlement.productIdentifier
    if (entitlement.productPlanIdentifier && entitlement.store === "PLAY_STORE") {
      //Google Play
      productIdentifier += ":" + entitlement.productPlanIdentifier
    }
    let productIndex = activeSubscriptions.indexOf(productIdentifier)
    if (productIndex !== -1) {
      activeSubscriptions.splice(productIndex, 1)
    }
  }
  return activeSubscriptions
}


function determineOwnershipStatus(tierGroup, customerInfo) {
  //Given a tierGroup, we will determine whether the tierGroup is owned, included in another subscription, or unowned.
  //We will also attempt to provide an expiration and renewal date if possible.

  let earliestExpirationDate;
  let areAllRenewing = true;
  let hasSubscriptionEntitlements = true
  let stores = [] //Stores where the entitlements are from, taken from EntitlementInfo.store

  for (let entitlement of tierGroup.entitlements) {
    let entitlementDetails = customerInfo.entitlements.active[entitlement]
    if (!entitlementDetails) {
      //Customer does not have this entitlement, so cannot hold this tier.
      hasSubscriptionEntitlements = false
      break
    }

    if (earliestExpirationDate === undefined
        || getExpirationDateForEntitlement(entitlementDetails).getTime()
        < earliestExpirationDate.getTime()) {
      earliestExpirationDate = getExpirationDateForEntitlement(entitlementDetails)
    }

    if (!entitlementDetails.willRenew) {
      areAllRenewing = false
    }

    if (!stores.includes(entitlementDetails.store)) {
      stores.push(entitlementDetails.store)
    }
  }

  return {
    hasEntitlements: hasSubscriptionEntitlements,
    expirationDate: earliestExpirationDate,
    willRenew: areAllRenewing,
    stores: stores,
  }
}

/**
 * @param inferiorSubscription The tier group to check
 * @param superiorSubscription The tier group to check against
 * @returns true if inferiorSubscription is inferior to superiorSubscription, false otherwise.
 */

function isInferiorSubscription(inferiorSubscription, superiorSubscription) {
  //inferiorSubscription is inferior to superiorSubscription if superiorSubscription has
  // all the entitlements of inferiorSubscription, and at least one more.

  let inferiorSubscriptionEntitlements = inferiorSubscription.entitlements
  let superiorSubscriptionEntitlements = superiorSubscription.entitlements

  if (superiorSubscriptionEntitlements.length <= inferiorSubscriptionEntitlements.length) {
    return false
  }

  for (let entitlement of inferiorSubscriptionEntitlements) {
    if (!superiorSubscriptionEntitlements.includes(entitlement)) {
      return false
    }
  }

  return true
}



export {
  getPackageProduct,
  findUnusedSubscriptions,
  determineOwnershipStatus,
  isInferiorSubscription,
    getDurationStringForProduct,
    getPriceStringForProduct
}
