//Specific hand on card. 
const CardCombo = require("./CardCombo.js")

class CardHand {
    constructor(props) {
        Object.assign(this, props)

        //We will generate the combos for this hand on demand, then cache for further use. 
        Object.defineProperty(this, "combos", {
            get: function() {        
                //Clear the getter. 
                Object.defineProperty(this, "combos", {
                    value: this.getCombos()
                })
        
                Object.freeze(this.combos) //Prevent accidental modification. 

                return this.combos
            },
            configurable: true
        })
    }


    //Expand the output for this hand into individual, labeled, combos. 
    getCombos() {
        //combo composes all the possibilities that one item on the card can be
		let comboOutput = []
		this.tiles.forEach((tileCombo) => {
			//Create a seperate object for each possibility
			let obj = Object.assign({}, this)
			obj.tiles = tileCombo

            let totalLength = 0

            obj.tileValueSum = 0 //Used to accelarate duplicate removal
            obj.tiles.forEach((arr) => {
                totalLength += arr.length
                obj.tileValueSum += arr[0].getTileValue(true) * arr.length
            })

            //Verify input was the correct length.
            if (totalLength !== 14) {
                console.error(tileCombo, this)
                throw "Invalid Combo"
            }

            comboOutput.push(new CardCombo(obj))
		})

		//Combos might include duplicate outputs - while they wouldn't if they the generating code was perfectly optimized
        //the code is often much easier to write with duplicates. We will remove duplicate combos. 
        let duplicatesRemoved = 0

        let uniqueCombos = []
        for (let i=0;i<comboOutput.length;i++) {
            let combo = comboOutput[i]
            
            let isDuplicate = uniqueCombos.some((uniqueCombo) => {
                //Two identical combos must have the same tileValueSum, however identical tileValueSums do not mean identical combos.
                //tileValueSum is faster than the isIdenticalTo call, even though isIdenticalTo is highly optimized. 
                
                if (uniqueCombo.tileValueSum !== combo.tileValueSum) {return false}
                return uniqueCombo.isIdenticalTo(combo)
            })
            
            if (isDuplicate) {
                duplicatesRemoved++
            }
            else {
                uniqueCombos.push(combo)
            }
        }
        comboOutput = uniqueCombos

        if (duplicatesRemoved > 100) {
            //Unless we are removing at least 100 duplicates there probably isn't any meaningful performance boost to be gained with optimizations. 
            console.warn("Removed " + duplicatesRemoved +  " Duplicate Combos from " + this.handName)
        }

        for (let i=0;i<comboOutput.length;i++) {
            delete comboOutput[i].tileValueSum
            comboOutput[i].freeze() //Prevent further modification. 
        }

        return comboOutput
    }
}

module.exports = CardHand