const {i18n} = require("../../lib/i18nHelper.js")

let Bot; //Don't want both scripts importing each other.

class Client {
	constructor(clientId, websocket) {
		this.clientId = clientId
		this.websockets = []
		if (websocket) {
			this.addWebsocket(websocket)
		}

		this.clearMessageHistory()
	}

	config = {
		entitlements: []
	}

	locale = "en"

	setEntitlements(entitlements = []) {
		this.config.entitlements = entitlements
	}

	getEntitlements() {
		return this.config.entitlements
	}

	localizeMessage(message) {
		//We will pass the client object as "this" to all callbacks triggered from localization through localizeMessage.
		return i18n.localizeMessage(message, this.locale, this);
	}

	// client message usages:
	// plain, concat, printf with data, data itself needs handling, callback function on args aka 3-bamboo handling
	/* plain:
					message(type, "plain message", status)
			printf with data:
					message(.., {format:"msg sprintf format %(var1)s etc", args: {var1, var2}}, ..)
					message(.., {format:"this is a test for %{namedVar}s", args:{namedVar: varData}})
			concat:
					message(.., {foramt:["test %(param1)s.", "concat with %(param2)s."], args: {param1:Xxx, param2: Yyy})
			data itself needs generic translation:
					message(.., {format:"test %(param1)s, %(param2)s", args:{param1:Xxx}, args_i18n:{param2:Yyy-toBeLocalized}})
			data itself needs special handling via callback functions, aka "3-bamboo" handling:
				message(.., {foramt:"test %(tile)s.", args: {tile: tileJsonObj}, argsOption: {tile:localizeTile_CallBackFunc} }
	*/
	message(type, message, status) {

		//We add the message to history BEFORE localizing such that history messages can be re-localized if client locale changes.
		if (type === "roomActionGameplayAlert") {
			this.addMessageToHistory(message)
		}

		if (type === "roomActionStartGame") {
			this.clearMessageHistory()
		}

		if (this.suppressed) {return}

		// our changes shall do NO harm to existing code
		// Here, getCurrentRoom, joinRoom:  the message is the room name, we must not toich that.
		if (type !== "getCurrentRoom" && type !== "joinRoom" && !globalThis.runBotClientAutoPlay) {
			if (type === "displayMessage") {
					if (typeof message.title !== "undefined") {
						message.title = this.localizeMessage(message.title)
					}
					if (typeof message.body !== "undefined") {
						message.body = this.localizeMessage(message.body)
					}
			}
			else {
				message = this.localizeMessage(message)
			}
		}


		if (this.websockets.length > 0) {
			//Handle errors where the websocket connection has closed.
			//We can probably do this simply by not sending the message, as the client should sync state if they disconnected.
			for (let websocket of this.websockets) {
				try {
					websocket.send(JSON.stringify({
						type, message, status
					}))
				}
				catch (e) {
					console.error(e)
				}
			}
		}
	}

	clearMessageHistory() {
		this.messageHistory = []
		this.messageHistory.push({message: "Game Started", move: 0})
	}

	//Return the messages in history, localizing for current client locale.
	getMessageHistory() {
		return this.messageHistory.map(obj => {
			//Copy properties - don't modify by reference.
			return {
				message: this.localizeMessage(obj.message),
				move: obj.move
			}
		})
	}

	addMessageToHistory(message, offset = 0) {
		//Do not localize message before adding to history.
		//This way messages can be re-localized later.

		//Offset can be used if the message is for the previous turn, etc.
		this.messageHistory.push({message, move: this?.getRoom()?.state?.moves?.length + offset})
	}

	setInstructions(instructions) {
		this.instructions = instructions
	}

	getInstructions() {
		//Return localized instructions.
		return this.localizeMessage(this.instructions) ?? ""
	}

	addWebsocket(websocket) {
		//Close the previous websocket properly
		if (!this.websockets.includes(websocket)) {
			this.websockets.push(websocket)
		}

		this.websockets = this.websockets.filter((socket) => {				
			return socket.readyState < 2 //2 and 3 are closing and closed. We only want active websokets. 

			//TODO: We need to close websockets if they have been inactive for a while. 
			//Therefore, we should send a ping. 

			//We need to be careful to ensure the FakeWebsocket properly handles pings, or is otherwise immune to being disconnected. 
		})
	}

	setLocale(locale) {this.locale = locale || this.locale}
	getLocale() {console.error("HERE");return this.locale}

	suppressed = false
	suppress() {this.suppressed = true}
	unsuppress() {this.suppressed = false}

	//roomId should be removed once this client is removed from a room. Probably moot due to getRoomId checks though.
	setRoomId(roomId) {
		this.roomId = roomId
	}

	getRoomId() {
		//Validate that the client is actually in the room...
		let room = globalThis.serverStateManager.getRoom(this.roomId)
		if (room && room.clientIds.includes(this.clientId)) {
			return this.roomId
		}
	}

	getRoom() {
		return globalThis.serverStateManager.getRoom(this.getRoomId())
	}

	delete() {
		for (let websocket of this.websockets) {
			try {
				websocket.close(1000) //Status code: Normal close.
			}
			catch(e) {}
		}
		globalThis.serverStateManager.deleteClient(this.clientId)
	}

	toJSON() {
		let obj = {
			clientId: this.clientId,
			locale: this.locale,
			roomId: this.roomId,
			isBot: this.isBot
		}
		return JSON.stringify(obj)
	}

	static fromJSON(str) {
		//Create client from a string.

		let obj = JSON.parse(str)
		let client;
		if (obj.isBot) {
			if (!Bot) {Bot = require("../Bot")}
			client = new Bot(obj.clientId)
		}
		else {
			client = new Client(obj.clientId)
		}
		client.setLocale(obj.locale)
		client.setRoomId(obj.roomId)

		return client
	}
}

module.exports = Client
