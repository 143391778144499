import {getStoreToUse}  from "./revenuecat_utilities";
import {CUSTOMER_INFO_UPDATED_EVENT_NAME} from "./event_names";

let purchasePackage, setUserUid, getPurchasesInstance, getCustomerInfo;

await getStoreToUse().then(async (store) => {
    if (store === "Web") {
        ({purchasePackage, setUserUid, getPurchasesInstance, getCustomerInfo} = await import("./webBilling"))
    }
    else {
        ({purchasePackage, setUserUid, getPurchasesInstance, getCustomerInfo} = await import("./nativeBilling"))
    }

    //Sync uid from Firebase Authentication.
    function setUidFromCurrentUserResponse(userResponse) {
        setUserUid(userResponse?.user?.uid)
    }

    FirebaseAuthentication.getCurrentUser().then(setUidFromCurrentUserResponse)
    FirebaseAuthentication.addListener('authStateChange', function() {
        FirebaseAuthentication.getCurrentUser().then(setUidFromCurrentUserResponse)
    })
    window.getPurchasesInstance = getPurchasesInstance
})


async function hasEntitlement(entitlementName) {
    let customerInfo = await getCustomerInfo()
    let ownedEntitlements = Object.keys(customerInfo.entitlements.active)

    return ownedEntitlements.includes(entitlementName)
}

export {purchasePackage, setUserUid, getPurchasesInstance, hasEntitlement, getCustomerInfo}